import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import ApiService from "../../../helpers/ApiServices";

export default function ProductBigCardModal({ showModal, cancelOption, index, product, uniteType, login }) {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
    cancelOption();
  }

  useEffect(() => {
    if (showModal) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [showModal]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl max-h-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div
                    className="flex items-center justify-end cursor-pointer"
                    onClick={closeModal}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18 18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                  {/* modal body */}

                  <div className="px-[40px] pb-[19px] sm:px-[67px] sm:pb-[40.5px] sm:pt-[22px] xl:px-[92.5px] xl:pt-[25px] xl:pb-0 flex items-center justify-center">
                    <div
                      className="w-[160px] h-[388px] sm:w-[486px] sm:h-[281px] lg:w-[715px] lg:h-[520px] flex flex-col sm:flex-row justify-between gap-[20px] sm:gap-[50px] xl:gap-[60px]"
                      style={{ fontFamily: "Open Sans" }}
                    >
                      {/* section 1 Image */}
                      <div className="w-full h-full">
                        <img
                          src={`${ApiService.getBaseImagePath()}products/${product?.name?.includes(":")
                            ? product?.name?.split(":")[1].trim()
                            : product?.name
                            }.jpg`}
                          alt="Product"
                          className="w-[160px] h-[160px] sm:w-[280px] sm:h-[280px] xl:w-[480px] xl:h-[520px]"
                        />
                      </div>
                      {/* section 2 content */}
                      <div className="h-[208px] sm:h-[281px] xl:h-[381px] flex flex-col gap-[12px] sm:gap-[20px] xl:gap-[40px]">
                        {/* design name */}
                        <div className="h-[47px] sm:w-[156px] sm:h-[59px] lg:w-[175px] xl:h-[64px] gap-[5px] flex flex-col justify-between">
                          <div className="h-[15px] sm:w-[156px] sm:h-[21px] xl:w-[175px] flex justify-between">
                            <div>
                              <p className="text-[10px] sm:text-[12px] text-[#9D9C99]">
                                {product?.displayName}
                              </p>
                            </div>
                            <div className="">
                              <p className=" text-[10px] sm:text-[12px] text-[#9D9C99]">
                                {product?.colorDescription}
                              </p>
                            </div>
                          </div>
                          <p className="h-[27px] sm:w-[104px] sm:h-[33px] xl:w-[121px] xl:h-[38px] text-[18px] sm:text-[24px] xl:text-[28px] font-semibold text-black text-nowrap">
                            {product?.name?.includes(":")
                              ? product?.name?.split(":")[1].trim()
                              : product?.name}
                          </p>
                        </div>
                        {/* price */}
                        {login !== null && <div className="w-[76px] h-[27px] sm:w-[95px] sm:h-[33px]">
                          <p className="text-[18px] sm:text-[24px] text-black text-nowrap">
                            ${product?.priceFromPM}{" "}
                            <span className="text-[#9D9C99] text-[16px] sm:text-[18px]">
                              /{uniteType}
                            </span>
                          </p>
                        </div>}
                        {/* details */}
                        <div className="text-[12px] sm:text-[14px] xl:text-[16px] h-[110px] sm:h-[149px] xl:h-[204px] text-black flex flex-col gap-[5px] sm:gap-[20px]">
                          <p className="font-bold w-[42px] h-[18px] sm:w-[49px] sm:h-[21px] xl:w-[56px] xl:h-[24px]">
                            Details
                          </p>
                          <div className=" h-[87px] sm:h-[108px] xl:h-[160px] flex flex-col gap-[5px] sm:gap-[8px] xl:gap-[10px]">
                            <div className="xl:w-[220px] h-[18px] sm:h-[21px] xl:h-[24px] flex items-center text-nowrap gap-[15px]">
                              <p className="w-[90px]">Design :</p>
                              <p className="w-[95px] text-left">{product?.displayName}</p>
                            </div>
                            <div className="xl:w-[220px] h-[18px] sm:h-[21px] xl:h-[24px] flex items-center text-nowrap gap-[15px]">
                              <p className="w-[90px] break-all">Color :</p>
                              <p className="w-[95px] text-left">{product?.colorDescription}</p>
                            </div>
                            <div className="xl:w-[220px] h-[18px] sm:h-[21px] xl:h-[24px] flex items-center text-nowrap gap-[15px]">
                              <p className="w-[90px]">Type :</p>
                              <p className="w-[95px] text-left">{product?.type}</p>
                            </div>
                            <div className=" h-[18px] sm:h-[21px] xl:w-[220px] xl:h-[24px] flex items-center text-nowrap gap-[15px]">
                              <p className="w-[90px]">Fabrication :</p>
                              <p className="w-[95px] text-left">{product?.fabrication}</p>
                            </div>
                            <div className=" h-[18px] sm:h-[21px] xl:w-[220px] xl:h-[24px] flex items-center text-nowrap gap-[15px]">
                              <p className="w-[90px]">Origin :</p>
                              {
                                product?.origin ? <p className="w-[95px] text-left">{product?.origin}</p>
                                  :
                                  <p className="w-[95px] text-left">-</p>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition >
    </>
  );
}
