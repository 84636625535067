import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import DesignerImage from "../../assets/Designer/Default.png";

import ApiService from "../../helpers/ApiServices";

const DesignerProfile = () => {

  const [collectionOrPatterns, setCollectionOrPatterns] = useState(false);
  const [featuredPatterns, setFeaturedPatterns] = useState(true);
  const [designerDetail, setDesignerDetail] = useState([]);
  const [designerCategories, setDesignerCategories] = useState([])

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams()
  let division = searchParams.get('division')?.toUpperCase() || "NORTHOCOTT";

  const getDesignerById = (id) => {
    ApiService.setHeader();
    ApiService.get(`shop/designer/${id}?division=${division}`)
      .then((response) => {
        if (response?.data.isSuccess) {
          setDesignerDetail(response?.data.document);
          if (response?.data.document && response?.data.document.categories.length > 0) {
            let categories = []
            response?.data.document.categories.forEach(category => {
              if (category.text)
                categories.push(category.text)
            })
            setDesignerCategories(categories)
          }
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getDesignerById(id)
  }, [id])


  return (
    <div
      className="my-[80px] sm:my-[100px] flex flex-col items-center justify-center gap-y-[80px] xl:gap-y-[100px]"
      style={{ fontFamily: "Open Sans" }}
    >
      {/* row 1 breadcrum designer detail */}
      <div className="w-[280px] sm:w-[624px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col items-center justify-center gap-[60px]">
        <div className="w-[280px] h-[21px] sm:w-[624px]  xl:w-[1160px] 2xl:w-[1320px] flex justify-start">
          {/* back to cart */}
          <Link to={`/designers-list?division=${division}`}>
            <div className="h-[21px] sm:h-[34px] flex items-center cursor-pointer justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-[14px] h-[14px] text-[#6B6B66]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5 8.25 12l7.5-7.5"
                />
              </svg>
              <p className="text-[14px] text-[#6B6B66] hover:text-[#E2A856]">
                View all designers
              </p>
            </div>
          </Link>
        </div>
        {/* designer detail */}
        <div className="w-[280px] sm:w-[624px]  xl:w-[1160px] 2xl:w-[1320px] flex flex-col xl:flex-row items-center justify-center xl:items-start gap-[40px] xl:gap-[100px]">
          <div
            className={`w-[280px] h-[280px] sm:w-[500px] sm:h-[500px]`}
            style={{ backgroundImage: `url(${DesignerImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <img
              src={`${ApiService.getBaseImagePath()}designers/${designerDetail?.imageName}`}
              alt="I"
              className="w-[280px] h-[280px] sm:w-[500px] sm:h-[500px]"
            />
          </div>
          <div
            className="w-[280px] sm:w-[624px]  xl:w-[560px] 2xl:w-[720px] flex flex-col items-center justify-center 
          gap-[40px]"
          >
            <div className="w-[280px] sm:w-[624px]  xl:w-[560px] 2xl:w-[720px] flex flex-col items-center justify-center xl:items-start">
              <h1 className="text-black text-center text-[14px]">
                {designerCategories.length > 0 && designerCategories.join(' & ')}
              </h1>
              <h1 className="text-black text-center text-[28px] sm:text-[32px] font-bold">
                {designerDetail?.name}
              </h1>
              <h1 className="text-black text-center text-[14px]">
                {designerDetail?.website}
              </h1>
            </div>

            <div dangerouslySetInnerHTML=
              {{
                __html: designerDetail?.bioData
              }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignerProfile;