// import "bootstrap/dist/css/bootstrap.min.css";
import "react-responsive-carousel/lib/styles/carousel.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { React } from "react";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import "react-step-progress-bar/styles.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "tippy.js/dist/tippy.css"; // optional
import "tippy.js/themes/light.css"; // optional
import "tippy.js/animations/scale.css";
import "tippy.js/animations/scale-subtle.css";
import "tippy.js/animations/scale-extreme.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./index.css";
import "./indexCssBackup.css";
import AppRoutes from "./routes/AppRoutes";
import {
  UserContext,
  UserContextProvider,
} from "./states/contexts/UserContext";
import ApiService from "./helpers/ApiServices";
import {
  CustomerContextProvider,
} from "./states/contexts/CustomerContext";
import { BrandIconContextProvider } from "./states/contexts/BrandIconContext";
import { CartContextProvider } from "./states/contexts/CartContext";
import { WishListContextProvider } from "./states/contexts/wishListContext";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
// import 'react-toastify/dist/ReactToastify.css';
// import { ToastContainer } from 'react-toastify';
// import 'bootstrap/dist/css/bootstrap.min.css';



ApiService.init();

const customAlertStyles = {
  backgroundColor: 'white',
  color: 'black',
  padding: '10px',
  borderRadius: '5px',
  boxShadow: '0 2px 5px rgba(0,0,0,0.3)'
};

const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 2000,
  offset: '10px',
  transition: transitions.FADE
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <UserContextProvider>
    <CustomerContextProvider>
      <BrandIconContextProvider>
        <CartContextProvider>
          <WishListContextProvider>
            <AlertProvider template={AlertTemplate} {...options}>
              <AppRoutes />
              {/* <ToastContainer /> */}
            </AlertProvider>
          </WishListContextProvider>
        </CartContextProvider>
      </BrandIconContextProvider>
    </CustomerContextProvider>
  </UserContextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
