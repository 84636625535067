export const BrandIconReducer = (state, action) => {
  switch (action.type) {
    case "IS_NORTHCOTT":
      return {
        isNorthchott: true,
        isBanyan: false,
        isPatrick: false,
        isDefault: false,
        isFigo: false,
        color: "#ffce30",
        textColor: "#000000",
        headerColor: "#686661",
        divisionType: "NORTHCOTT",
        backgroundColor: "#E2A856",
        darkBackgroundColor: "#7d5402",
        footerColor: "#090800",
        footerLogoColor: "#E2A856",
        bodyLogoColor: "#E2A856",
        buttonBgColor: "#E2A856",
        textColorCommon: "#000000",
      };

    case "IS_BANYAN":
      return {
        isNorthchott: false,
        isBanyan: true,
        isPatrick: false,
        isDefault: false,
        isFigo: false,
        color: "#00cc66",
        textColor: "#FFFFFF",
        headerColor: "#006371",
        divisionType: "BANYAN BATIKS",
        backgroundColor: "#3BA6A6",
        darkBackgroundColor: "#004f5a",
        footerColor: "#002E40",
        footerLogoColor: "#3BA6A6",
        bodyLogoColor: "#3BA6A6",
        buttonBgColor: "#3BA6A6",
        textColorCommon: "#FFFFFF",
      };

    case "IS_PATRICK":
      return {
        isNorthchott: false,
        isBanyan: false,
        isPatrick: true,
        isDefault: false,
        isFigo: false,
        color: "#000000",
        textColor: "#FFFFFF",
        headerColor: "#000000",
        divisionType: "PATRICK LOSE",
        backgroundColor: "#FFFFFF",
        darkBackgroundColor: "#000000",
        footerColor: "#000000",
        footerLogoColor: "#FFFFFF",
        bodyLogoColor: "#FA861B",
        buttonBgColor: "#000000",
        textColorCommon: "#000000",
      };
    case "IS_FIGO":
      return {
        isNorthchott: false,
        isBanyan: false,
        isPatrick: false,
        isDefault: false,
        isFigo: true,
        color: "#000000",
        textColor: "#ffffff",
        headerColor: "#000000",
        divisionType: "FIGO",
        backgroundColor: "",
        darkBackgroundColor: "#000000",
        footerColor: "#000000",
        footerLogoColor: "#FFFFFF",
        bodyLogoColor: "#FFFFFF",
        buttonBgColor: "#000000",
        textColorCommon: "#000000"
      };

    default:
      return state;
  }
};

export default BrandIconReducer;
