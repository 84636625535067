// import { Fragment, useContext, useEffect, useState } from "react";
// import { Menu, Transition } from "@headlessui/react";
// import { ChevronDownIcon } from "@heroicons/react/20/solid";
// import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
// import { CustomerContext } from "../../states/contexts/CustomerContext";
// import { UserContext } from "../../states/contexts/UserContext";
// import { CartContext } from "../../states/contexts/CartContext";
// import { WishListContext } from "../../states/contexts/wishListContext";
// import { BrandIconContext } from "../../states/contexts/BrandIconContext";
// import ApiService from "../../helpers/ApiServices";
// import { convertHtmlToString, getModelRoute, showMessage } from "../../helpers/Utils";
// import ProductBigCard from "../../components/elements/components/ProductBigCard";
// import ProductNormalCard from "../../components/elements/components/ProductNormalCard";
// import { useAlert } from 'react-alert'
// import moment from "moment";

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

// const ProductDetail = () => {
//   const [collection, setCollection] = useState(null);
//   const [carts, setCarts] = useState([]);
//   const [products, setProducts] = useState(['Loading...']);
//   const [wishLists, setWishLists] = useState([]);
//   const [country, setCountry] = useState(null);
//   const [productImage, setProductImage] = useState(null);
//   const [cadMultiplier, setCADMultiplier] = useState(null);
//   const [largeImageProductsForLoggedInUser, setLargeImageProductsForLoggedInUser] = useState([]);
//   const [smallImageProductsForLoggedInUser, setSmallImageProductsForLoggedInUser] = useState([]);
//   const [usdMatrix, setUsdMatrix] = useState([]);
//   const [cadMatrix, setCadMatrix] = useState([]);
//   const [qtys, setQtys] = useState([]);
//   const [state, setState] = useState([]);
//   const [industryCodes, setIndustryCodes] = useState([]);
//   const [industryCode, setIndustryCode] = useState(null);
//   const [loading, setIsLoading] = useState(false);
//   const [modalShow, setModalShow] = useState(false);
//   const [largeModalShow, setLargeModalShow] = useState(false);
//   const [finalColorwayArray, setFinalColorwayArray] = useState([]);
//   const [restProducts, setRestProducts] = useState([]);
//   const [uniteType, setUniteType] = useState([]);
//   const [isAddFullCollectionLoading, setIsAddFullCollectionLoading] = useState(false);
//   const [isAddFullCollectionHalfPieceLoading, setIsAddFullCollectionHalfPieceLoading] = useState(false);
//   const [isAddFullCollectionFreeFraightLoading, setIsAddFullCollectionFreeFraightLoading] = useState(false);
//   const [pricematrixdata, setPriceMatrixData] = useState([]);
//   const [NonLoggedInUser, setNonLoggedInUser] = useState(false);




//   const { dispatch: customerDispatch, customer } = useContext(CustomerContext)
//   const { dispatch, user } = useContext(UserContext)
//   const { dispatch: cartDispatch, quantity } = useContext(CartContext)
//   const { dispatch: wishListDispatch, quantity: wishListQuantity } = useContext(WishListContext)
//   const { dispatch: iconDispatch, isNorthchott, isBanyan, isPatrick, color, textColor, brandType, backgroundColor, darkBackgroundColor } = useContext(BrandIconContext)

//   let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))

//   const { id } = useParams();
//   const { search } = useLocation();
//   const navigate = useNavigate();
//   const [searchParams, setSearchParams] = useSearchParams()
//   const alert = useAlert()



//   let categoryName = searchParams.get('categoryName');
//   let categoryId = searchParams.get('categoryId') ? searchParams.get('categoryId') : collection?.categories[0]?.id;
//   console.log(collection);

//   let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"
//   let type = search.split("=")[1]
//   let largeImages = [];
//   let smallImages = []
//   let selectedProductArray = []
//   let prices = []


//   // login or not login user
//   const [login, setLogin] = useState((user || customer) ? true : false);

//   // modalBox
//   const [openModal, setOpenModal] = useState(null);

//   // COLORWAY DATA STARTS HERE
//   const [filteredProductsByColorway, setFilteredProductsByColorway] = useState([]);


//   // for all colorway card image hover
//   const colorWayNormalCardArrayLength =
//     filteredProductsByColorway.filteredNormalCards;
//   const colorWayBigCardArrayLength =
//     filteredProductsByColorway.filteredBigCards;

//   const [isHovered, setIsHovered] = useState(
//     Array(filteredProductsByColorway.filteredNormalCards).fill(false)
//   );

//   const handleMouseEnter = (index) => {
//     const updatedHoverState = [...isHovered];
//     updatedHoverState[index] = true;
//     setIsHovered(updatedHoverState);
//   };

//   const handleMouseLeave = (index) => {
//     const updatedHoverState = [...isHovered];
//     updatedHoverState[index] = false;
//     setIsHovered(updatedHoverState);
//   };
//   // COLORWAY DATA ENDS HERE


//   // for noncolorway only
//   const [nonColorwayNormalCardSizeOnly, setNonColorwayNormalCardSizeOnly] = useState(false);
//   const [nonColorwayBigAndNormalCardSize, setNonColorwayBigAndNormalCardSIze] = useState(false);
//   const [nonColorwayMultiPanel, setnonColorwayMultiPanel] = useState(false);
//   const [filteredNormalCardsNonColorway, setFilteredNormalCardsNonColorway] = useState([]);
//   const [filteredBigCardsNonColorway, setFilteredBigCardsNonColorway] = useState([]);


//   // Noncolorway data
//   useEffect(() => {
//     if (
//       filteredNormalCardsNonColorway.length > 0 &&
//       filteredBigCardsNonColorway.length === 0
//     ) {
//       setNonColorwayNormalCardSizeOnly(true);
//       setNonColorwayBigAndNormalCardSIze(false);
//       setnonColorwayMultiPanel(false);
//     }
//     if (
//       filteredNormalCardsNonColorway.length > 0 &&
//       filteredBigCardsNonColorway.length > 0 &&
//       filteredNormalCardsNonColorway.length > filteredBigCardsNonColorway.length
//     ) {
//       setNonColorwayNormalCardSizeOnly(false);
//       setNonColorwayBigAndNormalCardSIze(true);
//       setnonColorwayMultiPanel(false);
//     }
//     if (
//       filteredNormalCardsNonColorway.length > 0 &&
//       filteredBigCardsNonColorway.length > 0 &&
//       filteredNormalCardsNonColorway.length < filteredBigCardsNonColorway.length
//     ) {
//       setNonColorwayNormalCardSizeOnly(false);
//       setNonColorwayBigAndNormalCardSIze(false);
//       setnonColorwayMultiPanel(true);
//     }
//   }, [filteredNormalCardsNonColorway, filteredBigCardsNonColorway]);

//   // for all noncolorway card image hover
//   const nonColorWayNormalCardArrayLength =
//     filteredNormalCardsNonColorway.length;
//   const nonColorWayBigCardArrayLength = filteredBigCardsNonColorway.length;
//   // NON-COLORWAY DATA ENDS HERE


//   // All Methods
//   const setTestCustomer = (customers, array) => {
//     if (customers?.length) {
//     }
//     return array;
//   }

//   const getRegion = async () => {
//     let options = [];
//     let regions = [];

//     try {
//       ApiService.setHeader()
//       const response1 = await ApiService.get(`/shop/customList/title?model=${getModelRoute(user).model}&title=${"Territory Name USA"}`)
//       const response2 = await ApiService.get(`/shop/customList/title?model=${getModelRoute(user).model}&title=${"Territory Name Canada"}`)

//       Array.prototype.push.apply(regions, [{ _id: "-CANADA-", name: "-CANADA-" }])
//       Array.prototype.push.apply(regions, response2?.data.document.options)

//       Array.prototype.push.apply(regions, [{ _id: "-USA-", "name": "-USA-" }])
//       Array.prototype.push.apply(regions, response1?.data.document.options)


//       const array = setTestCustomer(customers, regions)
//       array.unshift({ _id: 'Select State / Prov', name: 'Select State / Prov' })
//       setState(array)
//     } catch (error) {
//       showMessage(error.response.data.message, "error")
//     }
//   }

//   const getCartDetail = () => {
//     ApiService.setHeader()
//     ApiService.get(`shop/cart/${customers[customers?.length - 1]?._id}?protectModel=${getModelRoute(user).model}`).then(response => {
//       if (response?.data.isSuccess) {
//         setCarts(response?.data.documents)
//         cartDispatch({ type: "ADD_TO_CART_QUANTITY", payload: response?.data.documents?.length });
//       }
//     }).catch(error => {
//       console.log(error.response.data)
//     })
//   }

//   const getWishListDetail = () => {
//     ApiService.setHeader()
//     ApiService.get(`shop/wishList/${customers[customers?.length - 1]?._id}?protectModel=${getModelRoute(user).model}`).then(response => {
//       if (response?.data.isSuccess) {
//         setWishLists(response?.data?.documents)
//         wishListDispatch({ type: "ADD_TO_WISHLIST_QUANTITY", payload: response?.data?.documents?.length });
//       }
//     }).catch(error => {
//       console.log(error.response.data)
//     })
//   }

//   const getCollection = () => {
//     setIsLoading(true)
//     ApiService.setHeader()
//     ApiService.get(`shop/pricingMatrix?protectModel=${getModelRoute(user).model}`).then(response => {

//       if (response?.data.isSuccess) {
//         prices = response.data.documents
//         setPriceMatrixData(response.data.documents)
//         if (response.data.documents.length) {
//           ApiService.setHeader()
//           ApiService.get(`shop/collection/${id}?protectModel=${getModelRoute(user).model}`).then(response => {

//             if (response?.data.isSuccess) {
//               setCollection(response?.data.document)
//               if (customers != null) {
//                 getAllProductsByCollection(response?.data.document?.name)
//               } else {
//                 getAllProductsByCollectionForNonLoggedInUser(response?.data.document?.name)
//               }

//             }
//           }).catch(error => {
//             console.log(error)
//           })
//         } else {
//           navigate(-1)
//           showMessage("No Pricing matrix data found!! Please contact to administrator.", "info")
//         }
//       }
//     }).catch(error => {
//       console.log(error.response.data)
//     })
//   }

//   const getAllProductsByCollection = (name) => {
//     let country = ""
//     let array = []
//     let productTypeArray = []
//     let allProductOfCollection

//     let productType = customers != null ? customers[customers?.length - 1]?.productType?.length && customers[customers?.length - 1]?.productType.map(ele => {
//       let obj = {}
//       obj.type = ele.text
//       productTypeArray.push(obj)
//     }) : []

//     let countrydesignations = customers != null ? customers[customers?.length - 1]?.countryDesignations?.length && customers[customers?.length - 1]?.countryDesignations?.map(ele => {
//       let obj = {}
//       obj.countryDesignation = ele.text
//       array.push(obj)
//     }) : []
//     array.push({ countryDesignation: "UNIVERSAL" })

//     ApiService.setHeader()

//     ApiService.get(`shop/product/collection/${name}?protectModel=${getModelRoute(user).model}&productType=${JSON.stringify(productTypeArray)}&countryDesinations=${JSON.stringify(array)}`).then(response => {
//       if (response?.data.isSuccess) {
//         if (customers !== null) {
//           if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
//             setUniteType('y')
//             country = "UNITED STATES"
//           } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA")) {
//             country = "CANADA"
//             setUniteType('m')
//           }
//         }
//         setFilteredProductsByColorway(response.data.documents);
//         setNonLoggedInUser(false)



//         response?.data.documents.filter((ele) => {
//           return ele
//         }).map((product, index) => {
//           let key
//           if (customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("USA") || customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("CANADA")) {
//             key = customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()
//           } else {
//             key = `${country === 'UNITED STATES' ? "USA" : 'CANADA'} ${customers[customers?.length - 1]?.priceLevel?.split("-")[1]}`.toUpperCase()
//           }

//           const pm = prices?.filter(ele => ele.Code === product.priceCode)
//           if (pm.length) {
//             for (let ele of Object.entries(pm[0])) {
//               if (ele[0].toUpperCase() === key) {
//                 product.priceFromPM = ele[1]
//               }
//             }
//           }

//           if (product?.isOversized) {
//             largeImages.push(product)
//             if (country == "CANADA") {
//               product.multipliers = formatArray(product?.cadFullMultiplier ? product?.cadFullMultiplier : 1)
//             } else {
//               product.multipliers = formatArray(product?.usdFullMultiplier ? product?.usdFullMultiplier : 1)
//             }
//           } else {
//             smallImages.push(product)

//             if (country == "CANADA") {
//               product.multipliers = formatArray(product?.cadFullMultiplier ? product?.cadFullMultiplier : 1)
//             } else {
//               product.multipliers = formatArray(product?.usdFullMultiplier ? product?.usdFullMultiplier : 1)
//             }
//           }
//         })
//         setProducts(response?.data?.documents)

//         let colorwayArray = []
//         let colorwayProductsArray = []
//         let restProductsArray = []

//         // Get colorways by collection
//         ApiService.get(`shop/colorway/getColorways/${id}?protectModel=${getModelRoute(user).model}`).then(res => {
//           if (res?.data.isSuccess) {
//             if (customers !== null) {
//               if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
//                 country = "UNITED STATES"
//               } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
//                 country = "CANADA"
//             }

//             res.data.documents?.map(colorway => {
//               let obj = {}
//               let lgImgArray = []
//               let smImgArray = []

//               obj.colorwayName = colorway?.name

//               colorway.products?.map(product => {

//                 let key
//                 if (customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("USA") || customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("CANADA")) {
//                   key = customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()
//                 } else {
//                   key = `${country === 'UNITED STATES' ? "USA" : 'CANADA'} ${customers[customers?.length - 1]?.priceLevel?.split("-")[1]}`.toUpperCase()
//                 }

//                 const pm = prices?.filter(ele => ele.Code === product.id.priceCode)
//                 // Set price from pricing matrix in each product
//                 if (pm.length) {
//                   for (let ele of Object.entries(pm[0])) {
//                     if (ele[0].toUpperCase() === key) {
//                       product.id.priceFromPM = ele[1]
//                     }
//                   }

//                 }

//                 if (product?.id.isOversized) {
//                   lgImgArray.push(product.id)

//                   if (country == "CANADA") {
//                     product.id.multipliers = formatArray(product?.id.cadFullMultiplier ? product?.id.cadFullMultiplier : 1)
//                   } else {
//                     product.id.multipliers = formatArray(product?.id.usdFullMultiplier ? product?.id.usdFullMultiplier : 1)
//                   }
//                   obj.filteredBigCards = lgImgArray
//                 } else {
//                   smImgArray.push(product.id)

//                   if (country == "CANADA") {
//                     product.id.multipliers = formatArray(product?.id.cadFullMultiplier ? product?.id.cadFullMultiplier : 1)
//                   } else {
//                     product.id.multipliers = formatArray(product?.id.usdFullMultiplier ? product?.id.usdFullMultiplier : 1)
//                   }
//                   obj.filteredNormalCards = smImgArray.sort(function (a, b) { return a.order - b.order; });
//                 }
//               })

//               if (obj.filteredBigCards?.length || obj.filteredNormalCards?.length)
//                 colorwayArray.push(obj)
//             })
//             // setFilteredProductsByColorway(colorwayArray)
//             setFinalColorwayArray(colorwayArray)
//             setLargeImageProductsForLoggedInUser(largeImages)
//             setSmallImageProductsForLoggedInUser(smallImages)


//             colorwayArray?.length && colorwayArray?.map(ele => {
//               if (ele?.filteredNormalCards?.length)
//                 colorwayProductsArray.push(...ele?.filteredNormalCards)
//               if (ele?.filteredBigCards?.length)
//                 colorwayProductsArray.push(...ele?.filteredBigCards)
//             })
//             if (colorwayArray?.length) {
//               restProductsArray = response?.data.documents.filter(ele => {

//                 if (ele?.name?.includes(':') || ele?.name?.includes('PTN')) {
//                   return ele
//                 }
//               }).filter(a => !colorwayProductsArray.some(b => a.name === b.name));
//             } else {
//               setFilteredBigCardsNonColorway(largeImages)
//               setFilteredNormalCardsNonColorway(smallImages)
//             }

//             if (restProductsArray?.length) {

//               setFilteredBigCardsNonColorway(restProductsArray.filter(ele => ele?.isOversized))
//               setFilteredNormalCardsNonColorway(restProductsArray.filter(ele => !ele?.isOversized))
//             }
//             setRestProducts(restProductsArray)
//             setIsLoading(false)
//           }
//         }).catch(error => {
//           setProducts([]);
//           setIsLoading(false);
//         })
//       }
//     }).catch(error => {
//       setProducts([]);
//       setIsLoading(false);
//     })
//   }

//   const getAllProductsByCollectionForNonLoggedInUser = async (name) => {

//     let country = ""
//     let array = []
//     let productTypeArray = []

//     ApiService.setHeader()

//     ApiService.get(`shop/product/collection/${name}?protectModel=${getModelRoute(user).model}&for=nonLoggedInUser`).then(response => {

//       if (response?.data.isSuccess) {
//         if (customers !== null) {
//           if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
//             country = "UNITED STATES"
//           } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
//             country = "CANADA"
//         }
//         setFilteredProductsByColorway(response?.data?.documents);
//         setNonLoggedInUser(true)

//         // New UI code
//         response?.data?.documents?.filter(
//           (item) => {

//             return item
//           }).map(async product => {


//             if (product?.isOversized) {
//               largeImages.push(product)
//               if (country == "CANADA") {
//                 product.multipliers = formatArray(product?.cadFullMultiplier ? product?.cadFullMultiplier : 1)
//               } else {
//                 product.multipliers = formatArray(product?.usdFullMultiplier ? product?.usdFullMultiplier : 1)
//               }
//             } else {
//               smallImages.push(product)

//               if (country == "CANADA") {
//                 product.multipliers = formatArray(product?.cadFullMultiplier ? product?.cadFullMultiplier : 1)
//               } else {
//                 product.multipliers = formatArray(product?.usdFullMultiplier ? product?.usdFullMultiplier : 1)
//               }
//             }


//           })

//         setFilteredBigCardsNonColorway(largeImages)
//         setFilteredNormalCardsNonColorway(smallImages)
//         // New UI code end
//         setProducts(response?.data.documents)

//         setIsLoading(false)
//       }
//     }).catch(error => {
//       console.log(error)
//     })
//   }


//   const AddAllSelected = async (data) => {
//     let country

//     data?.map(ele => {
//       ele.shipDate = moment(new Date()).isAfter(new Date(collection?.date)) ? new Date() : collection?.date
//       ele.collectionName = collection?.name
//     })

//     if (customers !== null) {
//       if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
//         country = "UNITED STATES"
//       } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
//         country = "CANADA"
//     }

//     ApiService.post(`shop/cart/addAllSelected?protectModel=${getModelRoute(user).model}&country=${country}&collection=${collection?.name}&customerId=${customer?._id}`, data).then(response => {

//       if (response?.data.isSuccess) {
//         getCartDetail()
//         showMessage("Items added", "success")
//         selectedProductArray = []
//       }
//     }).catch(error => {
//       console.log(error)
//     })
//     getCartDetail()
//   }


//   const addCart = async (data) => {
//     let country
//     data.shipDate = moment(new Date()).isAfter(new Date(collection?.date)) ? new Date() : moment(collection?.date)?.add({ days: 1 })?._i
//     data.newShipDate = moment(new Date()).isAfter(new Date(collection?.date)) ? new Date() : moment(collection?.date)?.add({ days: 1 })?._i
//     data.collectionName = collection?.name

//     if (customers !== null) {
//       if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
//         country = "UNITED STATES"
//       } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
//         country = "CANADA"
//     }

//     ApiService.post(`shop/cart?protectModel=${getModelRoute(user).model}&country=${country}&collection=${collection?.name}&customerId=${customer?._id}`, data).then(response => {

//       alert.success("Item added")
//       if (response?.data.isSuccess) {
//         getCartDetail()

//         showMessage("Item added", "success")
//         selectedProductArray = []
//       }
//     }).catch(error => {
//       getCartDetail()
//     })
//   }

//   const addToCart = (data) => {
//     addCart(data)
//   }

//   const getPricingMatrix = async () => {
//     await ApiService.get(`shop/pricingMatrix/getPrice?protectModel=${getModelRoute(user).model}&priceLevel=${customers[customers?.length - 1]?.priceLevel}`).then(response => {

//       if (response?.data.isSuccess) {
//         setUsdMatrix(response.data.documents.usd)
//         setCadMatrix(response.data.documents.cad)

//       }
//     }).catch(error => {
//       console.log(error.response.data)
//     })
//   }


//   const getPriceByPricingMatrix = (data) => {

//     let cntry = null

//     let priceCode = data.toLowerCase().trim()

//     if (customers !== null) {
//       if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
//         country = "UNITED STATES"
//       } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
//         country = "CANADA"
//     }

//     if (cntry == "CANADA") {
//       return cadMatrix?.filter(ele => (ele?.label == customers[customers?.length - 1].priceLevel && ele?.scriptid == `_pct_ncs_${priceCode}`))[0]?.value
//     } else {
//       return usdMatrix?.filter(ele => (ele?.label == customers[customers?.length - 1].priceLevel && ele?.scriptid == `_pct_ncs_${priceCode}`))[0]?.value
//     }
//   }

//   const findProductMultiplierAndFormatArray = (productId) => {
//     setCADMultiplier([])
//     setQtys([])


//     ApiService.setHeader()
//     ApiService.get(`shop/product/${productId}?protectModel=${getModelRoute(user).model}`).then(response => {
//       if (response?.data.isSuccess) {

//         if (customers !== null) {
//           if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
//             country = "UNITED STATES"
//           } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
//             country = "CANADA"
//         }

//         if (country == "CANADA") {
//           formatArray(response.data.document.cadFullMultiplier ? response.data.document.cadFullMultiplier : 1)
//         } else {
//           formatArray(response.data.document.usdFullMultiplier ? response.data.document.usdFullMultiplier : 1)
//         }

//       }


//     }).catch(error => {
//       console.log(error.response.data)
//     })
//   }

//   const formatArray = (multiplier, calledFrom) => {

//     let array = []
//     for (let i = 1; i <= 10; i++) {
//       array.push((i * parseInt(multiplier)))
//     }
//     // if (calledFrom !== "checkBox") {
//     //   setQtys(array)
//     // }
//     return array
//   }

//   const setQuantity = () => {
//     setCADMultiplier(qtys)
//   }

//   const moveToWishList = async (product, customer) => {

//     const data = {
//       productId: product?._id,
//       userId: customer?._id
//     }

//     await ApiService.post(`shop/wishList?protectModel=${getModelRoute(user).model}`, data).then(response => {
//       if (response?.data.isSuccess) {
//         getCartDetail()
//         getWishListDetail()

//         showMessage("Item added to wishlist.", "success")
//       }
//     }).catch(error => {
//       console.log(error.response.data)
//     })
//   }


//   const addAllProductToCart = async (buttonType) => {
//     let array = []
//     let arr = []
//     let finalProductArray = []
//     let qtyArray = []
//     let country

//     let buttonTypeArray = JSON.parse(localStorage.getItem("PCTeRP.BUTTONTYPEARRAY")) == null ? [] : JSON.parse(localStorage.getItem("PCTeRP.BUTTONTYPEARRAY"))
//     let obj = {}
//     obj.customerId = customers[customers?.length - 1]?._id
//     obj.buttonType = buttonType
//     obj.collectionId = id
//     obj.collectionName = collection?.name
//     buttonTypeArray.push(obj)
//     localStorage.setItem("PCTeRP.BUTTONTYPEARRAY", JSON.stringify(buttonTypeArray))

//     if (customers !== null) {
//       if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
//         country = "UNITED STATES"
//       } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
//         country = "CANADA"
//     }

//     const finalProducts = products?.filter(ele => {

//       return ele
//     }).map(product => {


//       let key
//       if (customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("USA") || customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("CANADA")) {
//         key = customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()
//       } else {
//         key = `${country === 'UNITED STATES' ? "USA" : 'CANADA'} ${customers[customers?.length - 1]?.priceLevel?.split("-")[1]}`.toUpperCase()
//       }


//       const pm = prices?.filter(ele => ele.Code.toUpperCase() === product.priceCode.toUpperCase())


//       // Set price from pricing matrix in each product
//       if (pm.length) {
//         for (let ele of Object.entries(pm[0])) {
//           if (ele[0].toUpperCase() === key) {
//             product.priceFromPM = ele[1]
//           }
//         }

//       }


//       product.productQuantity = product?.noPromos?.length ? product?.noPromos?.includes(collection?.name?.toUpperCase()) ? 0.00 : product?.multipliers[0] : product?.multipliers[0]

//       finalProductArray.push(product)
//     })

//     const shipDate = moment(new Date()).isAfter(new Date(collection?.date)) ? new Date() : moment(collection?.date)?.add({ days: 1 })?._i
//     const newShipDate = moment(new Date()).isAfter(new Date(collection?.date)) ? new Date() : moment(collection?.date)?.add({ days: 1 })?._i

//     if (buttonType == "FullCollectionButton") {
//       setIsAddFullCollectionLoading(true)
//     } else if (buttonType == "FullCollectionHalfPieceButton") {
//       setIsAddFullCollectionHalfPieceLoading(true)
//     } else if (buttonType == "FullCollectionFreeFreightButton") {
//       setIsAddFullCollectionFreeFraightLoading(true)
//     }

//     ApiService.setHeader()
//     ApiService.post(`shop/cart/addProducts?model=${getModelRoute(user).model}&country=${country}&customerId=${customer?._id}&collection=${collection?.name}&buttonType=${buttonType}&shipDate=${shipDate}&newShipDate=${newShipDate}`, finalProductArray).then(response => {

//       alert.success("Items added")
//       if (response?.data.isSuccess) {
//         setIsAddFullCollectionFreeFraightLoading(false)
//         setIsAddFullCollectionLoading(false)
//         setIsAddFullCollectionHalfPieceLoading(false)

//         getCartDetail()

//       }
//     }).catch(error => {
//       showMessage(error.response.data.message, "error")
//       getCartDetail()
//     })
//   }

//   const addColorwayProductsToCart = async (buttonType, colorway) => {
//     let array = []
//     let qtyArray = []
//     let country
//     console.log(buttonType, colorway);

//     if (customers !== null) {
//       if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
//         country = "UNITED STATES"
//       } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
//         country = "CANADA"
//     }

//     colorway?.filter(ele => {
//       // if (ele?.name?.includes(':') || ele?.name?.includes('PTN')) {
//       //   return ele
//       // }
//       return ele
//     }).map((product, index) => {
//       let key
//       if (customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("USA") || customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("CANADA")) {
//         key = customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()
//       } else {
//         key = `${country === 'UNITED STATES' ? "USA" : 'CANADA'} ${customers[customers?.length - 1]?.priceLevel?.split("-")[1]}`.toUpperCase()
//       }

//       // const pm = pricematrixdata?.filter(ele => ele.Code.toUpperCase() === product.priceCode.toUpperCase())
//       // if (pm.length) {
//       //   for (let ele of Object.entries(pm[0])) {
//       //     if (ele[0].toUpperCase() === key) {
//       //       product.priceFromPM = ele[1]
//       //     }
//       //   }
//       // }

//       // Set price from pricing matrix in each product
//       const pm = pricematrixdata?.filter(ele => ele.Code === product.priceCode)
//       if (pm.length > 0) {
//         for (let ele of Object.keys(pm[0])) {
//           if (ele.toUpperCase() === key) {
//             products[index].priceFromPM = pm[ele]
//           }
//         }
//       }
//     })

//     const shipDate = moment(new Date()).isAfter(new Date(collection?.date)) ? new Date() : collection?.date

//     ApiService.setHeader()
//     ApiService.post(`shop/cart/addColorwayProducts?model=${getModelRoute(user).model}&country=${country}&customerId=${customers[customers?.length - 1]?._id}&collectionName=${collection?.name}&buttonType=${buttonType}&shipDate=${shipDate}`, colorway).then(response => {
//       if (response?.data.isSuccess) {
//         getCartDetail()

//       }
//     }).catch(error => {
//       showMessage(error.response.data.message, "error")
//     })
//   }

//   const getCustomerCounry = () => {


//     if (customers?.length >= 1) {
//       if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
//         setCountry("UNITED STATES")
//       } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase().includes("CANADA")) {

//         setCountry("CANADA")
//       }

//     } else {
//       setCountry(null)
//     }

//   }


//   const addSelectedProductsToCart = async (id, product, e, quantity) => {
//     if (document.getElementById(id).checked) {
//       let obj = {}
//       let arr = []

//       getCustomerCounry()
//       let price = product.price


//       let key
//       if (customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("USA") || customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("CANADA")) {
//         key = customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()
//       } else {
//         key = `${country === 'UNITED STATES' ? "USA" : 'CANADA'} ${customers[customers?.length - 1]?.priceLevel?.split("-")[1]}`.toUpperCase()
//       }


//       const pm = prices?.filter(ele => ele.Code.toUpperCase() === product.priceCode.toUpperCase())

//       // Set price from pricing matrix in each product
//       if (pm.length) {
//         for (let ele of Object.entries(pm[0])) {
//           if (ele[0].toUpperCase() === key) {
//             product.priceFromPM = ele[1]
//           }
//         }

//       }
//       obj.productId = product?._id
//       obj.userId = customers[customers?.length - 1]?._id
//       obj.price = product.priceFromPM
//       obj.productQuantity = quantity
//       obj.multipliers = product.multipliers
//       obj.subTotal = (parseFloat(quantity) * parseFloat(product.priceFromPM)).toFixed(2)
//       obj.image = product?.uploadLargePoster?.url ? product?.uploadLargePoster?.url : product?.uploadSmallPoster?.url

//       selectedProductArray.push(obj)


//     } else {
//       let filterproductArray = selectedProductArray.filter((item) => item.productId !== e.target.value);
//       selectedProductArray = filterproductArray
//     }
//   }

//   const getIndustryCodes = (data) => {
//     ApiService.setHeader()
//     ApiService.get(`shop/customList/title?protectModel=${getModelRoute(user).model}&title=NCS Industry Codes`, data).then(response => {
//       if (response?.data.isSuccess) {
//         setIndustryCodes(response?.data.document.options)
//       }
//     }).catch(error => {
//       console.log(error.response.data)
//     })
//   }


//   const gotoDesignerPage = (designerNsId) => {
//     ApiService.setHeader()
//     ApiService.get(`shop/designer/byId/${designerNsId}?protectModel=${getModelRoute(user).model}`).then(response => {
//       if (response?.data.isSuccess) {
//         navigate(`/designer-profile/${response?.data?.document?._id}?protectModel=${getModelRoute(user).model}`)
//       }
//     }).catch(error => {
//       console.log(error.response.data)
//     })
//   }


//   useEffect(() => {

//     if (customers != null) {
//       getCartDetail()
//       getWishListDetail()
//       getCustomerCounry()
//     }

//     getIndustryCodes()
//     getRegion()
//     getCollection()

//   }, [id]);

//   const productCount = (objectData) => {
//     const data = Object.values(objectData)
//     return data.reduce((total, array) => total + array.length, 0)
//   }


//   return (
//     <>
//       <div
//         className="bg-white flex flex-col items-center justify-center gap-[60px] sm:gap-20 xl:gap-[60px] mb-[60px] sm:mb-[100px]"
//         style={{ fontFamily: "Open Sans", marginTop: "20px" }}
//       >
//         {/* section 1 */}
//         {/* top banner  */}
//         {collection?.posterImageName ? <div className="w-full h-[235px] sm:h-[257px] xl:h-[296px]">
//           <img
//             // src={PageBanner}
//             src={`${ApiService.getBaseImagePath()}colorwaybanners/${collection?.posterImageName}`}
//             alt="Image"
//             className="h-[235px] sm:h-[257px] xl:h-[296px] w-full"
//           />
//         </div> : ""}
//         {/* section 2 */}
//         <div className="w-[320px] sm:w-[744px] h-[376px] sm:h-[222px] xl:w-[1160px] xl:h-[96px] 2xl:w-[1320px] grid grid-cols-1 xl:grid-cols-2 items-center justify-items-center xl:items-end xl:justify-items-start gap-10 xl:gap-0 ">
//           <div className="w-[280px] h-[132px] sm:w-[539px] sm:h-[100px] xl:h-[96px] col-span-1 flex flex-col gap-[10px] lg:gap-2 text-center lg:text-left">
//             <div className="text-[14px] hover:underline cursor-pointer">
//               {(collection?.designer?.length || collection?.designerTwo?.length) ? 'By' : ""} {collection?.designer?.length ? collection?.designer?.map(designer => {
//                 if (collection?.designer?.length == 1 && designer?.id) {

//                   return <Link className='' key={designer?._id} style={{}} as={Link} to={`/designer-profile/${designer?.id}`}>{designer?.text}&nbsp; {collection?.designerTwo?.length ? "&" : ''} &nbsp;</Link>

//                 } else if (collection?.designer?.length == 1 && !designer?.id) {
//                   return <span key={designer?._id} style={{ color: "black", fontWeight: "bold", cursor: "pointer" }}
//                     onClick={() => gotoDesignerPage(designer?.value)}
//                   >{designer?.text}&nbsp; {collection?.designerTwo?.length ? "&" : ""} &nbsp;</span>
//                 }
//               }) : ""}

//               {collection?.designerTwo?.length ? collection?.designerTwo?.map((designer, index) => {
//                 if (designer?.id) {
//                   if (collection?.designerTwo[index + 1]) {
//                     return <Link className='' key={designer?._id} style={{}} as={Link} to={`/designer-profile/${designer?.id}`}>{designer?.text}&nbsp; & &nbsp;</Link>
//                   } else {
//                     return <Link className='' key={designer?._id} style={{}} as={Link} to={`/designer-profile/${designer?.id}`}>{designer?.text}</Link>
//                   }

//                 } else if (!designer?.id) {
//                   if (collection?.designerTwo[index + 1]) {
//                     return <span key={designer?._id} style={{ color: "black", fontWeight: "bold", cursor: "pointer" }}
//                       onClick={() => gotoDesignerPage(designer?.value)}
//                     >{designer?.text}&nbsp;& &nbsp;</span>
//                   } else {
//                     return <span key={designer?._id} style={{ color: "black", fontWeight: "bold", cursor: "pointer" }}
//                       onClick={() => gotoDesignerPage(designer?.value)}
//                     >{designer?.text}</span>
//                   }
//                 }
//               }) : ""}
//             </div>
//             <div className="text-[28px] lg:text-[32px] font-bold">
//               <p>{collection?.name}</p>
//             </div>
//             <div className="text-[14px]">
//               <p>{moment(new Date()).format('DD-MMM-YYYY').replaceAll("-", " ") === moment(collection?.date).format('DD-MMM-YYYY').replaceAll("-", " ") ? "Available Now" : `Coming to stores in ${moment(collection?.date).format('MMMM YYYY')}`}</p>
//             </div>
//           </div>

//           <div className="xl:ms-[150px] 2xl:ms-52 w-[204px] h-[204px] sm:w-[448px] sm:h-[82px] xl:w-[428px] xl:h-[82px] flex flex-col sm:flex-row items-center justify-between lg:justify-end mx-auto gap-10 sm:gap-5 text-center">
//             {/* product finder  */}
//             <div className="text-center text-base font-bold flex flex-col gap-[15px]">
//               <p className="text-left">Product Finder</p>

//               {/*product finder dropdown input box */}
//               <div className="w-[204px] h-[40px] bg-white">
//                 <Menu
//                   as="div"
//                   className=" relative flex justify-start text-left"
//                 >
//                   <div>
//                     <Menu.Button className="inline-flex w-[204px] h-[40px] items-center justify-between gap-x-1.5 bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 font-sm">
//                       <div className="text-[14px] text-[#9D9C99] font-semibold">
//                         Find Retailers
//                       </div>
//                       <ChevronDownIcon
//                         className="-mr-1 h-5 w-5 text-gray-400"
//                         aria-hidden="true"
//                       />
//                     </Menu.Button>
//                   </div>

//                   <Transition
//                     as={Fragment}
//                     enter="transition ease-out duration-100"
//                     enterFrom="transform opacity-0 scale-95"
//                     enterTo="transform opacity-100 scale-100"
//                     leave="transition ease-in duration-75"
//                     leaveFrom="transform opacity-100 scale-100"
//                     leaveTo="transform opacity-0 scale-95"
//                   >
//                     <Menu.Items className="absolute z-10 mt-2 w-[204px] h-[40px] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
//                       <div className="py-1 bg-white">
//                         {
//                           industryCodes?.length && industryCodes.map(industryCode => {
//                             return <Menu.Item>
//                               {({ active }) => (
//                                 <Link
//                                   to={`/product-finder/${id}?state=${null}&IC=${industryCode?.text}`}
//                                   className={classNames(
//                                     active
//                                       ? "bg-gray-100 text-gray-900"
//                                       : "text-gray-700",
//                                     "block px-4 py-2 text-[14px]"
//                                   )}
//                                 >
//                                   {industryCode?.text}
//                                 </Link>
//                               )}
//                             </Menu.Item>
//                           })
//                         }

//                       </div>
//                     </Menu.Items>
//                   </Transition>
//                 </Menu>

//                 {/*product finder Dropdown menu  */}
//                 <div
//                   id="dropdown"
//                   className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
//                 >
//                   <ul
//                     className="py-2 text-[14px] text-gray-700 dark:text-gray-200"
//                     aria-labelledby="dropdownDefaultButton"
//                   >
//                     <li>
//                       <a
//                         href="#"
//                         className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
//                       >
//                         Dashboard
//                       </a>
//                     </li>
//                     <li>
//                       <a
//                         href="#"
//                         className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
//                       >
//                         Settings
//                       </a>
//                     </li>
//                     <li>
//                       <a
//                         href="#"
//                         className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
//                       >
//                         Earnings
//                       </a>
//                     </li>
//                     <li>
//                       <a
//                         href="#"
//                         className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
//                       >
//                         Sign out
//                       </a>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//             {/* fabric files */}
//             <div className="flex flex-col gap-[15px]">
//               <div className="text-base font-bold">
//                 <p className="text-left">Fabric Files</p>
//               </div>
//               <div className="flex justify-start w-[204px] h-[40px]">
//                 <button
//                   type="button"
//                   className=" text-white bg-[#E2A856] rounded-[4px] uppercase text-[14px] text-center mb-10 w-[204px] h-[40px] lg:tracking-tighter hover:bg-[#090800] font-semibold"
//                 >
//                   Download fabric files
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* section 3 */}
//         {(collection?.promoMessage !== "&lt;p>&lt;br>&lt;/p>") ? <div
//           className={`${((user || customer) && collection?.promoMessage)
//             ? "mx-auto bg-[#D9D2CB] w-[280px] h-[276px] sm:w-[664px] sm:h-[132px] xl:w-[1160px] xl:h-[114px] 2xl:w-[1320px] flex items-center justify-items-center text-center font-bold px-[30px] py-[40px] text-base"
//             : "mx-auto bg-white w-[280px] h-[276px] sm:w-[664px] sm:h-[132px] xl:w-[1160px] xl:h-[114px] 2xl:w-[1320px] flex items-center justify-items-center text-center font-bold px-[30px] py-[40px] text-base"
//             }`}
//         >
//           <p dangerouslySetInnerHTML={collection?.promoMessage && convertHtmlToString(collection?.promoMessage)}></p>
//         </div> : ""}
//         {/* section 4 */}
//         {/* breadcrum */}
//         <div className="w-[280px] h-[18px] sm:w-[664px] sm:h-[18px] xl:w-[1160px] 2xl:w-[1320px] flex justify-start">
//           <nav className="flex" aria-label="Breadcrumb">
//             <ol className="inline-flex space-x-1 md:space-x-2 rtl:space-x-reverse">
//               <li className="inline-flex mt-[2px]">
//                 <p className="inline-flex text-[12px] cursor-pointer font-medium text-[#6B6B66] hover:text-[#090800] hover:underline"
//                   onClick={() => {
//                     navigate(`/?division=${division}`)
//                   }}
//                 >
//                   Home
//                 </p>
//               </li>
//               <li>
//                 <div className="flex">
//                   {" / "}
//                   <p className="mt-[2px] ms-1 text-[12px] cursor-pointer font-medium text-[#6B6B66] hover:text-[#090800] hover:underline md:ms-2"
//                     onClick={() => {
//                       navigate(`/product-collection/${categoryId}?division=${division}`)
//                     }}
//                   >
//                     {categoryName ? categoryName : "category"}
//                   </p>
//                 </div>
//               </li>
//               <li aria-current="page">
//                 <div className="flex">
//                   {" / "}
//                   <span className="mt-[2px] ms-1 text-[12px] font-medium text-[#6B6B66] md:ms-2 flex justify-center">
//                     {collection?.name}
//                   </span>
//                 </div>
//               </li>
//             </ol>
//           </nav>
//         </div>
//         {/* products count and buttons */}
//         <div className="flex flex-col sm:flex-row justify-center sm:justify-between w-[280px] h-[159px] sm:w-[664px] sm:h-[39px] xl:w-[1160px] 2xl:w-[1320px] gap-10 sm:gap-0 ">
//           <div className="text-[14px] text-nowrap text-center sm:text-start w-[280px] sm:w-[150px] h-[39px] flex items-center justify-start sm:items-end">
//             {loading ? (
//               "Loading..."
//             ) : (
//               filteredProductsByColorway ? (
//                 `${productCount(filteredProductsByColorway)} Products`
//               ) : (
//                 "No Products Found !"
//               )
//             )}
//           </div>

//           {(user || customer) && (
//             <div className="w-[554px] h-[130px] sm:w-[752px] sm:h-[39px] flex flex-col sm:flex-row items-center justify-end gap-y-[10px] sm:gap-y-0 sm:gap-x-[40px]">
//               {/* buttons */}
//               {((customer?.subsidiary?.text?.toUpperCase()?.includes("CANADA") && collection?.isShowFullCollectionButtonCanada) || (customer?.subsidiary?.text?.toUpperCase()?.includes("USA") && collection?.isShowFullCollectionButton)) ? <div className="w-[184px] h-[39px] flex items-center justify-center">
//                 <button
//                   type="button"
//                   className=" text-white bg-[#E2A856] font-semibold rounded w-[184px] h-[39px] text-center hover:bg-[#090800] text-[14px]"
//                   onClick={() => addAllProductToCart('FullCollectionButton')}
//                 >
//                   Add Full Collection
//                 </button>
//               </div> : ""}

//               {((customer?.subsidiary?.text?.toUpperCase()?.includes("CANADA") && collection?.isShowFullCollectionHalfPieceButtonCanada) || (customer?.subsidiary?.text?.toUpperCase()?.includes("USA") && collection?.isShowFullCollectionHalfPieceButton)) ? <div className="w-[274px] h-[39px] flex justify-center">
//                 <button
//                   type="button"
//                   className="hover:bg-[#090800] text-white bg-[#E2A856] font-semibold rounded w-[274px] h-[39px] text-center text-[14px]"
//                   onClick={() => addAllProductToCart('FullCollectionHalfPieceButton')}
//                 >
//                   Add Full Collection - Half Piece
//                 </button>
//               </div> : ""}

//               {(user && user?.userTypes[0] !== "CUSTOMER") && ((customer?.subsidiary?.text?.toUpperCase()?.includes("CANADA") && collection?.isShowFullCollectionFreeFreightButtonCanada) || (customer?.subsidiary?.text?.toUpperCase()?.includes("USA") && collection?.isShowFullCollectionFreeFreightButton)) ? <div className="w-[274px] h-[39px] flex justify-center">
//                 <button
//                   type="button"
//                   className="hover:bg-[#090800] text-white bg-[#E2A856] font-semibold rounded w-[274px] h-[39px] text-center text-[14px]"
//                   onClick={() => addAllProductToCart('FullCollectionFreeFreightButton')}
//                 >
//                   Add Full Collection - Free Freight
//                 </button>
//               </div> : ""}
//             </div>
//           )}
//         </div>
//         {/* section 5 */}
//         {/* product section */}
//         {/* horizontal line  */}
//         <hr className=" text-[#CECECC] h-[1px] w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px]" />


//         {/* COLORWAY PRODUCT FOR NORMAL , BIGANDNORMAL , MULTI CARD */}
//         {Object.keys(filteredProductsByColorway).map((item) => {
//           const colorway = item
//           let products = filteredProductsByColorway[item]
//           if (item === "Available Products") return;

//           const filteredNormalCardFromColorwayData = products.filter(
//             (nonOversizedProduct) => !nonOversizedProduct.isOversized
//           );

//           const filteredBigCardFromColorwayData = products.filter(
//             (oversizedProduct) => oversizedProduct.isOversized
//           );

//           let screen1 = false;
//           let screen2 = false;
//           let screen3 = false;

//           if (
//             filteredNormalCardFromColorwayData?.length > 0 &&
//             filteredBigCardFromColorwayData?.length == 0
//           ) {
//             screen1 = true;
//           } else if (
//             filteredNormalCardFromColorwayData?.length > 0 &&
//             filteredBigCardFromColorwayData?.length > 0 &&
//             filteredNormalCardFromColorwayData?.length >
//             filteredBigCardFromColorwayData?.length
//           ) {
//             screen2 = true;
//           }
//           else if (
//             filteredNormalCardFromColorwayData?.length > 0 &&
//             filteredBigCardFromColorwayData?.length > 0 &&
//             filteredNormalCardFromColorwayData?.length <
//             filteredBigCardFromColorwayData?.length
//           ) {
//             screen3 = true;
//           }

//           {
//             !NonLoggedInUser && products?.map((product, index) => {
//               let key
//               if (customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("USA") || customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("CANADA")) {
//                 key = customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()
//               } else {
//                 key = `${country === 'UNITED STATES' ? "USA" : 'CANADA'} ${customers[customers?.length - 1]?.priceLevel?.split("-")[1]}`.toUpperCase()
//               }

//               const pm = pricematrixdata?.filter(ele => ele.Code === product.priceCode)
//               if (pm.length > 0) {
//                 for (let ele of Object.keys(pm[0])) {
//                   if (ele.toUpperCase() === key) {
//                     products[index].priceFromPM = pm[ele]
//                   }
//                 }
//               }

//               if (product?.isOversized) {
//                 if (country == "CANADA") {
//                   product.multipliers = formatArray(product?.cadFullMultiplier ? product?.cadFullMultiplier : 1)
//                 } else {
//                   product.multipliers = formatArray(product?.usdFullMultiplier ? product?.usdFullMultiplier : 1)
//                 }
//               } else {
//                 if (country == "CANADA") {
//                   product.multipliers = formatArray(product?.cadFullMultiplier ? product?.cadFullMultiplier : 1)
//                 } else {
//                   product.multipliers = formatArray(product?.usdFullMultiplier ? product?.usdFullMultiplier : 1)
//                 }
//               }
//             })
//           }

//           return (
//             <div>
//               <div className="mb-[60px] xl:mb-[40px] w-[280px] h-[96px] sm:w-[664px] sm:h-[96px] xl:w-[1160px] 2xl:w-[1320px] xl:h-[39px] grid grid-cols-1 sm:grid-cols-1 xl:grid-cols-3 gap-[30px] xl:gap-0 items-center justify-center xl:justify-around">
//                 <div className="col-span-1 hidden xl:flex h-[39px]"></div>
//                 <div className="col-span-1 h-[39px] flex items-center justify-center">
//                   <p className="font-bold text-center text-[18px]">
//                     {colorway}
//                   </p>
//                 </div>
//                 <div className="flex justify-center xl:justify-end col-span-1 h-[39px]">
//                   <div className="w-[280px] sm:w-[664px] xl:w-[500px] flex items-center justify-center gap-10">
//                     {/* {((user || customer) && collection?.isShowAddThisColorwayOnlyButton) && <button */}
//                     {<button
//                       type="button"
//                       className=" text-black bg-white border-2 border-black h-[39px] rounded-[4px] font-semibold text-center w-[223px] hover:bg-[#E2A856] hover:text-white hover:border-[#E2A856] text-[14px]"
//                       onClick={() => addColorwayProductsToCart('addThisColorwayOnly', products)}
//                     >
//                       Add This Colorway Only
//                     </button>}

//                     {/* {((user || customer) && collection?.isShowAddThisColorwayHalfPieceButton) && */}
//                     {<button
//                       type="button"
//                       className=" text-black bg-white border-2 border-black h-[39px] rounded-[4px] font-semibold text-center w-[223px] hover:bg-[#E2A856] hover:text-white hover:border-[#E2A856] text-[14px]"
//                       onClick={() => addColorwayProductsToCart('addThisColorwayHalfPieces', products)}
//                     >
//                       Add this colorway half pieces
//                     </button>
//                     }
//                   </div>
//                 </div>
//               </div>

//               {screen1 && (
//                 <div className="w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid grid-cols-2 sm:grid-cols-3 mx-auto xl:grid-cols-5 justify-items-center grid-flow-row gap-x-[20px] sm:gap-x-[62px] xl:gap-x-[65px] 2xl:gap-x-[105px] gap-y-[40px]">
//                   {filteredNormalCardFromColorwayData?.map((item, index) => (
//                     <ProductNormalCard
//                       key={index + item._id}
//                       login={(user || customer)}
//                       index={index}
//                       item={item}
//                       data={colorWayNormalCardArrayLength}
//                       product={item}
//                       uniteType={uniteType}
//                       setQuantity={setQuantity}
//                       customers={customers}
//                       wishLists={wishLists}
//                       navigate={navigate}
//                       moveToWishList={moveToWishList}
//                       carts={carts}
//                       addSelectedProductsToCart={addSelectedProductsToCart}
//                       getPriceByPricingMatrix={getPriceByPricingMatrix}
//                       findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
//                       cadMultiplier={cadMultiplier} addToCart={addToCart}
//                       setProductImage={setProductImage}
//                       setModalShow={setModalShow}
//                       country={country}
//                       collection={collection}
//                     />
//                   ))}
//                 </div>
//               )}

//               {screen2 && (
//                 <div className="w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid xl:grid-cols-12 gap-[40px] 2xl:gap-[80px]">
//                   <div className="xl:col-span-3">
//                     <div className="h-auto w-[280px] sm:w-[540px] xl:w-[250px] grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 mx-auto justify-items-center grid-flow-row sm:gap-x-[40px] xl:gap-x-0 gap-y-[40px]">
//                       {filteredBigCardFromColorwayData?.map((item, index) => {
//                         return (
//                           <ProductBigCard
//                             key={index + item._id}
//                             login={(user || customer)}
//                             index={index}
//                             item={item}
//                             data={colorWayBigCardArrayLength}
//                             multiBigCard={false}
//                             product={item}
//                             uniteType={uniteType}
//                             setQuantity={setQuantity}
//                             customers={customers}
//                             wishLists={wishLists}
//                             navigate={navigate}
//                             moveToWishList={moveToWishList}
//                             carts={carts}
//                             addSelectedProductsToCart={addSelectedProductsToCart}
//                             getPriceByPricingMatrix={getPriceByPricingMatrix}
//                             findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
//                             cadMultiplier={cadMultiplier} addToCart={addToCart}
//                             setProductImage={setProductImage}
//                             setModalShow={setModalShow}
//                             country={country}
//                             collection={collection}
//                           />
//                         )
//                       })}
//                     </div>
//                   </div>
//                   <div className="xl:col-span-9">
//                     <div className="h-auto w-[280px] sm:w-[664px] xl:w-[870px] 2xl:w-[990px] grid grid-cols-2 sm:grid-cols-3 mx-auto xl:grid-cols-4 justify-items-center gap-x-[20px] sm:gap-x-[62px] xl:gap-x-[50px] 2xl:gap-x-[90px] gap-y-[40px]">
//                       {filteredNormalCardFromColorwayData?.map((item, index) => {
//                         return (
//                           <ProductNormalCard
//                             key={index + item._id}
//                             login={(user || customer)}
//                             index={index}
//                             item={item}
//                             data={colorWayNormalCardArrayLength}
//                             product={item}
//                             uniteType={uniteType}
//                             setQuantity={setQuantity}
//                             customers={customers}
//                             wishLists={wishLists}
//                             navigate={navigate}
//                             moveToWishList={moveToWishList}
//                             carts={carts}
//                             addSelectedProductsToCart={addSelectedProductsToCart}
//                             getPriceByPricingMatrix={getPriceByPricingMatrix}
//                             findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
//                             cadMultiplier={cadMultiplier} addToCart={addToCart}
//                             setProductImage={setProductImage}
//                             setModalShow={setModalShow}
//                             country={country}
//                             collection={collection}
//                           />
//                         )
//                       })}
//                     </div>
//                   </div>
//                 </div>
//               )}

//               {screen3 && (

//                 <div className="w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col items-center justify-center gap-[40px]">
//                   <div className="h-auto w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-y-[62px] sm:gap-y-[40px] sm:gap-x-[62px] xl:gap-x-[53.5px] 2xl:gap-x-[106.5px] items-center justify-items-center sm:px-[51px] xl:px-0">
//                     {filteredBigCardFromColorwayData?.map((item, index) => (
//                       <ProductBigCard
//                         key={index + item._id}
//                         login={(user || customer)}
//                         index={index}
//                         item={item}
//                         data={colorWayBigCardArrayLength}
//                         multiBigCard={true}
//                         product={item}
//                         uniteType={uniteType}
//                         setQuantity={setQuantity}
//                         customers={customers}
//                         wishLists={wishLists}
//                         navigate={navigate}
//                         moveToWishList={moveToWishList}
//                         carts={carts}
//                         addSelectedProductsToCart={addSelectedProductsToCart}
//                         getPriceByPricingMatrix={getPriceByPricingMatrix}
//                         findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
//                         cadMultiplier={cadMultiplier} addToCart={addToCart}
//                         setProductImage={setProductImage}
//                         setModalShow={setModalShow}
//                         country={country}
//                         collection={collection}
//                       />
//                     ))}
//                   </div>
//                   <div className="h-auto w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid grid-cols-2 sm:grid-cols-3 mx-auto xl:grid-cols-5 justify-items-center grid-flow-row gap-y-[40px] gap-x-[20px] sm:gap-x-[22px] xl:gap-x-[55px] 2xl:gap-x-[90px] sm:px-[40px] xl:px-0">
//                     {filteredNormalCardFromColorwayData?.map((item, index) => (
//                       <ProductNormalCard
//                         key={index + item._id}
//                         login={(user || customer)}
//                         index={index}
//                         item={item}
//                         data={colorWayNormalCardArrayLength}
//                         product={item}
//                         uniteType={uniteType}
//                         setQuantity={setQuantity}
//                         customers={customers}
//                         wishLists={wishLists}
//                         navigate={navigate}
//                         moveToWishList={moveToWishList}
//                         carts={carts}
//                         addSelectedProductsToCart={addSelectedProductsToCart}
//                         getPriceByPricingMatrix={getPriceByPricingMatrix}
//                         findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
//                         cadMultiplier={cadMultiplier} addToCart={addToCart}
//                         setProductImage={setProductImage}
//                         setModalShow={setModalShow}
//                         country={country}
//                         collection={collection}
//                       />
//                     ))}
//                   </div>
//                 </div>
//               )}

//             </div>
//           );
//         })}

//         {/*NON-COLORWAY PRODUCT FOR NORMAL , BIGANDNORMAL , MULTI CARD */}
//         {Object.keys(filteredProductsByColorway).map((item) => {
//           const colorway = item
//           let products = filteredProductsByColorway[item]
//           if (item !== "Available Products") return;

//           const filteredNormalCardFromColorwayData = products.filter(
//             (nonOversizedProduct) => !nonOversizedProduct.isOversized
//           );

//           const filteredBigCardFromColorwayData = products.filter(
//             (oversizedProduct) => oversizedProduct.isOversized
//           );

//           let screen1 = false;
//           let screen2 = false;
//           let screen3 = false;

//           if (
//             filteredNormalCardFromColorwayData?.length > 0 &&
//             filteredBigCardFromColorwayData?.length == 0
//           ) {
//             screen1 = true;
//           } else if (
//             filteredNormalCardFromColorwayData?.length > 0 &&
//             filteredBigCardFromColorwayData?.length > 0 &&
//             filteredNormalCardFromColorwayData?.length >
//             filteredBigCardFromColorwayData?.length
//           ) {
//             screen2 = true;
//           }
//           else if (
//             filteredNormalCardFromColorwayData?.length > 0 &&
//             filteredBigCardFromColorwayData?.length > 0 &&
//             filteredNormalCardFromColorwayData?.length <
//             filteredBigCardFromColorwayData?.length
//           ) {
//             screen3 = true;
//           }

//           {
//             !NonLoggedInUser && products?.map((product, index) => {
//               let key
//               if (customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("USA") || customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("CANADA")) {
//                 key = customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()
//               } else {
//                 key = `${country === 'UNITED STATES' ? "USA" : 'CANADA'} ${customers[customers?.length - 1]?.priceLevel?.split("-")[1]}`.toUpperCase()
//               }

//               const pm = pricematrixdata?.filter(ele => ele.Code === product.priceCode)
//               if (pm.length > 0) {
//                 for (let ele of Object.keys(pm[0])) {
//                   if (ele.toUpperCase() === key) {
//                     products[index].priceFromPM = pm[ele]
//                   }
//                 }

//               }

//               if (product?.isOversized) {
//                 if (country == "CANADA") {
//                   product.multipliers = formatArray(product?.cadFullMultiplier ? product?.cadFullMultiplier : 1)
//                 } else {
//                   product.multipliers = formatArray(product?.usdFullMultiplier ? product?.usdFullMultiplier : 1)
//                 }
//               } else {
//                 if (country == "CANADA") {
//                   product.multipliers = formatArray(product?.cadFullMultiplier ? product?.cadFullMultiplier : 1)
//                 } else {
//                   product.multipliers = formatArray(product?.usdFullMultiplier ? product?.usdFullMultiplier : 1)
//                 }
//               }
//             })
//           }

//           return (
//             <div>
//               <div className="mb-[60px] xl:mb-[40px] w-[280px] h-[96px] sm:w-[664px] sm:h-[96px] xl:w-[1160px] 2xl:w-[1320px] xl:h-[39px] grid grid-cols-1 sm:grid-cols-1 xl:grid-cols-3 gap-[30px] xl:gap-0 items-center justify-center xl:justify-around">
//                 <div className="col-span-1 hidden xl:flex h-[39px]"></div>
//                 <div className="col-span-1 h-[39px] flex items-center justify-center">
//                   <p className="font-bold text-center text-[18px]">
//                     {colorway}
//                   </p>
//                 </div>
//                 <div className="flex justify-center xl:justify-end col-span-1 h-[39px]">
//                   <div className="w-[280px] sm:w-[664px] xl:w-[500px] flex items-center justify-center gap-10">
//                     {((user || customer) && collection?.isShowAddThisColorwayOnlyButton) && <button
//                       type="button"
//                       className=" text-black bg-white border-2 border-black h-[39px] rounded-[4px] font-semibold text-center w-[223px] hover:bg-[#E2A856] hover:text-white hover:border-[#E2A856] text-[14px]"
//                       onClick={() => addColorwayProductsToCart('addThisColorwayOnly', item)}
//                     >
//                       Add This Colorway Only
//                     </button>}

//                     {((user || customer) && collection?.isShowAddThisColorwayHalfPieceButton) &&
//                       <button
//                         type="button"
//                         className=" text-black bg-white border-2 border-black h-[39px] rounded-[4px] font-semibold text-center w-[223px] hover:bg-[#E2A856] hover:text-white hover:border-[#E2A856] text-[14px]"
//                         onClick={() => addColorwayProductsToCart('addThisColorwayHalfPieces', item)}
//                       >
//                         Add this colorway half pieces
//                       </button>
//                     }
//                   </div>
//                 </div>
//               </div>

//               {screen1 && (
//                 <div className="w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid grid-cols-2 sm:grid-cols-3 mx-auto xl:grid-cols-5 justify-items-center grid-flow-row gap-x-[20px] sm:gap-x-[62px] xl:gap-x-[65px] 2xl:gap-x-[105px] gap-y-[40px]">
//                   {filteredNormalCardFromColorwayData?.map((item, index) => (
//                     <ProductNormalCard
//                       key={index + item._id}
//                       login={(user || customer)}
//                       index={index}
//                       item={item}
//                       data={colorWayNormalCardArrayLength}
//                       product={item}
//                       uniteType={uniteType}
//                       setQuantity={setQuantity}
//                       customers={customers}
//                       wishLists={wishLists}
//                       navigate={navigate}
//                       moveToWishList={moveToWishList}
//                       carts={carts}
//                       addSelectedProductsToCart={addSelectedProductsToCart}
//                       getPriceByPricingMatrix={getPriceByPricingMatrix}
//                       findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
//                       cadMultiplier={cadMultiplier} addToCart={addToCart}
//                       setProductImage={setProductImage}
//                       setModalShow={setModalShow}
//                       country={country}
//                       collection={collection}
//                     />
//                   ))}
//                 </div>
//               )}

//               {screen2 && (
//                 <div className="w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid xl:grid-cols-12 gap-[40px] 2xl:gap-[80px]">
//                   <div className="xl:col-span-3">
//                     <div className="h-auto w-[280px] sm:w-[540px] xl:w-[250px] grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 mx-auto justify-items-center grid-flow-row sm:gap-x-[40px] xl:gap-x-0 gap-y-[40px]">
//                       {filteredBigCardFromColorwayData?.map((item, index) => {
//                         return (
//                           <ProductBigCard
//                             key={index + item._id}
//                             login={(user || customer)}
//                             index={index}
//                             item={item}
//                             data={colorWayBigCardArrayLength}
//                             multiBigCard={false}
//                             product={item}
//                             uniteType={uniteType}
//                             setQuantity={setQuantity}
//                             customers={customers}
//                             wishLists={wishLists}
//                             navigate={navigate}
//                             moveToWishList={moveToWishList}
//                             carts={carts}
//                             addSelectedProductsToCart={addSelectedProductsToCart}
//                             getPriceByPricingMatrix={getPriceByPricingMatrix}
//                             findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
//                             cadMultiplier={cadMultiplier} addToCart={addToCart}
//                             setProductImage={setProductImage}
//                             setModalShow={setModalShow}
//                             country={country}
//                             collection={collection}
//                           />
//                         )
//                       })}
//                     </div>
//                   </div>
//                   <div className="xl:col-span-9">
//                     <div className="h-auto w-[280px] sm:w-[664px] xl:w-[870px] 2xl:w-[990px] grid grid-cols-2 sm:grid-cols-3 mx-auto xl:grid-cols-4 justify-items-center gap-x-[20px] sm:gap-x-[62px] xl:gap-x-[50px] 2xl:gap-x-[90px] gap-y-[40px]">
//                       {filteredNormalCardFromColorwayData?.map((item, index) => {
//                         return (
//                           <ProductNormalCard
//                             key={index + item._id}
//                             login={(user || customer)}
//                             index={index}
//                             item={item}
//                             data={colorWayNormalCardArrayLength}
//                             product={item}
//                             uniteType={uniteType}
//                             setQuantity={setQuantity}
//                             customers={customers}
//                             wishLists={wishLists}
//                             navigate={navigate}
//                             moveToWishList={moveToWishList}
//                             carts={carts}
//                             addSelectedProductsToCart={addSelectedProductsToCart}
//                             getPriceByPricingMatrix={getPriceByPricingMatrix}
//                             findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
//                             cadMultiplier={cadMultiplier} addToCart={addToCart}
//                             setProductImage={setProductImage}
//                             setModalShow={setModalShow}
//                             country={country}
//                             collection={collection}
//                           />
//                         )
//                       })}
//                     </div>
//                   </div>
//                 </div>
//               )}

//               {screen3 && (

//                 <div className="w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col items-center justify-center gap-[40px]">
//                   <div className="h-auto w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-y-[62px] sm:gap-y-[40px] sm:gap-x-[62px] xl:gap-x-[53.5px] 2xl:gap-x-[106.5px] items-center justify-items-center sm:px-[51px] xl:px-0">
//                     {filteredBigCardFromColorwayData?.map((item, index) => (
//                       <ProductBigCard
//                         key={index + item._id}
//                         login={(user || customer)}
//                         index={index}
//                         item={item}
//                         data={colorWayBigCardArrayLength}
//                         multiBigCard={true}
//                         product={item}
//                         uniteType={uniteType}
//                         setQuantity={setQuantity}
//                         customers={customers}
//                         wishLists={wishLists}
//                         navigate={navigate}
//                         moveToWishList={moveToWishList}
//                         carts={carts}
//                         addSelectedProductsToCart={addSelectedProductsToCart}
//                         getPriceByPricingMatrix={getPriceByPricingMatrix}
//                         findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
//                         cadMultiplier={cadMultiplier} addToCart={addToCart}
//                         setProductImage={setProductImage}
//                         setModalShow={setModalShow}
//                         country={country}
//                         collection={collection}
//                       />
//                     ))}
//                   </div>
//                   <div className="h-auto w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid grid-cols-2 sm:grid-cols-3 mx-auto xl:grid-cols-5 justify-items-center grid-flow-row gap-y-[40px] gap-x-[20px] sm:gap-x-[22px] xl:gap-x-[55px] 2xl:gap-x-[90px] sm:px-[40px] xl:px-0">
//                     {filteredNormalCardFromColorwayData?.map((item, index) => (
//                       <ProductNormalCard
//                         key={index + item._id}
//                         login={(user || customer)}
//                         index={index}
//                         item={item}
//                         data={colorWayNormalCardArrayLength}
//                         product={item}
//                         uniteType={uniteType}
//                         setQuantity={setQuantity}
//                         customers={customers}
//                         wishLists={wishLists}
//                         navigate={navigate}
//                         moveToWishList={moveToWishList}
//                         carts={carts}
//                         addSelectedProductsToCart={addSelectedProductsToCart}
//                         getPriceByPricingMatrix={getPriceByPricingMatrix}
//                         findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
//                         cadMultiplier={cadMultiplier} addToCart={addToCart}
//                         setProductImage={setProductImage}
//                         setModalShow={setModalShow}
//                         country={country}
//                         collection={collection}
//                       />
//                     ))}
//                   </div>
//                 </div>
//               )}

//             </div>
//           );
//         })}



//         {/* NON-COLORWAY PRODUCT FOR NORMAL , BIGANDNORMAL , MULTI CARD */}
//         <div className="mb-[40px] w-[280px] h-[96px] sm:w-[664px] sm:h-[96px] xl:w-[1160px] 2xl:w-[1320px] xl:h-[39px] grid grid-cols-1 sm:grid-cols-1 xl:grid-cols-3 gap-[30px] xl:gap-0 items-center justify-center xl:justify-between">
//           <div className="col-span-1 hidden xl:flex h-[39px]"></div>
//           <div className="col-span-1 h-[39px] flex items-center justify-center">
//             <p className="font-bold text-center text-white"></p>
//           </div>
//         </div>

//         {nonColorwayNormalCardSizeOnly && (
//           <div className="-mt-[200px] xl:-mt-[100px] w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid grid-cols-2 sm:grid-cols-3 mx-auto xl:grid-cols-5 justify-items-center grid-flow-row gap-10">
//             {filteredNormalCardsNonColorway?.map((item, index) => (
//               <ProductNormalCard
//                 key={index + item._id}
//                 login={(user || customer)}
//                 index={index}
//                 product={item}
//                 uniteType={uniteType}
//                 data={nonColorWayNormalCardArrayLength}
//                 setQuantity={setQuantity}
//                 customers={customers}
//                 wishLists={wishLists}
//                 navigate={navigate}
//                 moveToWishList={moveToWishList}
//                 carts={carts}
//                 addSelectedProductsToCart={addSelectedProductsToCart}
//                 getPriceByPricingMatrix={getPriceByPricingMatrix}
//                 findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
//                 cadMultiplier={cadMultiplier} addToCart={addToCart}
//                 setProductImage={setProductImage}
//                 setModalShow={setModalShow}
//                 country={country}
//                 collection={collection}
//               />
//             ))}
//           </div>
//         )}

//         {nonColorwayBigAndNormalCardSize && (
//           <div className="-mt-[200px] xl:-mt-[100px] w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid xl:grid-cols-12 gap-10 xl:gap-[48px] 2xl:gap-[85px]">
//             <div className="xl:col-span-3">
//               <div className="h-auto w-[280px] sm:w-[664px] xl:w-[250px] grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 mx-auto justify-items-center grid-flow-row gap-10">
//                 {filteredBigCardsNonColorway?.map((item, index) => {
//                   return <ProductBigCard
//                     key={index + item._id}
//                     login={(user || customer)}
//                     index={index}
//                     product={item}
//                     uniteType={uniteType}
//                     data={nonColorWayBigCardArrayLength}
//                     multiBigCard={false}
//                     setQuantity={setQuantity}
//                     customers={customers}
//                     wishLists={wishLists}
//                     navigate={navigate}
//                     moveToWishList={moveToWishList}
//                     carts={carts}
//                     addSelectedProductsToCart={addSelectedProductsToCart}
//                     getPriceByPricingMatrix={getPriceByPricingMatrix}
//                     findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
//                     cadMultiplier={cadMultiplier} addToCart={addToCart}
//                     setProductImage={setProductImage}
//                     setModalShow={setModalShow}
//                     country={country}
//                     collection={collection}
//                   />
//                 })}
//               </div>
//             </div>
//             <div className="xl:col-span-9">
//               <div className="h-auto w-[280px] sm:w-[664px] xl:w-[870px] 2xl:w-[990px] grid grid-cols-2 sm:grid-cols-3 mx-auto xl:grid-cols-4 justify-items-center grid-flow-row gap-y-10 gap-x-2">
//                 {filteredNormalCardsNonColorway?.map((item, index) => {

//                   return (<ProductNormalCard
//                     key={index + item._id}
//                     login={(user || customer)}
//                     index={index}
//                     product={item}
//                     uniteType={uniteType}
//                     data={nonColorWayNormalCardArrayLength}
//                     setQuantity={setQuantity}
//                     customers={customers}
//                     wishLists={wishLists}
//                     navigate={navigate}
//                     moveToWishList={moveToWishList}
//                     carts={carts}
//                     addSelectedProductsToCart={addSelectedProductsToCart}
//                     getPriceByPricingMatrix={getPriceByPricingMatrix}
//                     findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
//                     cadMultiplier={cadMultiplier} addToCart={addToCart}
//                     setProductImage={setProductImage}
//                     setModalShow={setModalShow}
//                     country={country}
//                     collection={collection}
//                   />)
//                 }
//                 )}
//               </div>
//             </div>
//           </div>
//         )}

//         {nonColorwayMultiPanel && (
//           <div className="-mt-[200px] xl:-mt-[100px] w-[280px] h-fit sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col justify-center gap-y-10">
//             <div className="h-auto w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 mx-auto justify-items-center grid-flow-row gap-y-[62px] sm:gap-y-10">
//               {filteredBigCardsNonColorway?.map((item, index) => (
//                 <ProductBigCard
//                   key={index + item._id}
//                   login={(user || customer)}
//                   index={index}
//                   product={item}
//                   uniteType={uniteType}
//                   data={nonColorWayBigCardArrayLength}
//                   multiBigCard={true}
//                   setQuantity={setQuantity}
//                   customers={customers}
//                   wishLists={wishLists}
//                   navigate={navigate}
//                   moveToWishList={moveToWishList}
//                   carts={carts}
//                   addSelectedProductsToCart={addSelectedProductsToCart}
//                   getPriceByPricingMatrix={getPriceByPricingMatrix}
//                   findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
//                   cadMultiplier={cadMultiplier} addToCart={addToCart}
//                   setProductImage={setProductImage}
//                   setModalShow={setModalShow}
//                   country={country}
//                   collection={collection}
//                 />
//               ))}
//             </div>
//             <div className="h-auto w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid grid-cols-2 sm:grid-cols-3 mx-auto xl:grid-cols-5 justify-items-center grid-flow-row gap-y-10 gap-x-2">
//               {filteredNormalCardsNonColorway?.map((item, index) => (
//                 <ProductNormalCard
//                  key={index + item._id}
//                   login={(user || customer)}
//                   index={index}
//                   product={item}
//                   uniteType={uniteType}
//                   data={nonColorWayNormalCardArrayLength}
//                   setQuantity={setQuantity}
//                   customers={customers}
//                   wishLists={wishLists}
//                   navigate={navigate}
//                   moveToWishList={moveToWishList}
//                   carts={carts}
//                   addSelectedProductsToCart={addSelectedProductsToCart}
//                   getPriceByPricingMatrix={getPriceByPricingMatrix}
//                   findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
//                   cadMultiplier={cadMultiplier} addToCart={addToCart}
//                   setProductImage={setProductImage}
//                   setModalShow={setModalShow}
//                   country={country}
//                   collection={collection}
//                 />
//               ))}
//             </div>
//           </div>
//         )}
//       </div>
//     </>


//   );
// };
// export default ProductDetail;


import { Fragment, useContext, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CustomerContext } from "../../states/contexts/CustomerContext";
import { UserContext } from "../../states/contexts/UserContext";
import { CartContext } from "../../states/contexts/CartContext";
import { WishListContext } from "../../states/contexts/wishListContext";
import { BrandIconContext } from "../../states/contexts/BrandIconContext";
import ApiService from "../../helpers/ApiServices";
import { convertHtmlToString, getModelRoute, showMessage } from "../../helpers/Utils";
import ProductBigCard from "../../components/elements/components/ProductBigCard";
import ProductNormalCard from "../../components/elements/components/ProductNormalCard";
import { useAlert } from 'react-alert'
import moment from "moment";
// import { toast } from "react-toastify";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ProductDetail = () => {
  const [collection, setCollection] = useState(null);
  const [carts, setCarts] = useState([]);
  const [products, setProducts] = useState(['Loading...']);
  const [wishLists, setWishLists] = useState([]);
  const [country, setCountry] = useState(null);
  const [productImage, setProductImage] = useState(null);
  const [cadMultiplier, setCADMultiplier] = useState(null);
  const [largeImageProductsForLoggedInUser, setLargeImageProductsForLoggedInUser] = useState([]);
  const [smallImageProductsForLoggedInUser, setSmallImageProductsForLoggedInUser] = useState([]);
  const [usdMatrix, setUsdMatrix] = useState([]);
  const [cadMatrix, setCadMatrix] = useState([]);
  const [qtys, setQtys] = useState([]);
  const [state, setState] = useState([]);
  const [industryCodes, setIndustryCodes] = useState([]);
  const [industryCode, setIndustryCode] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [AllProductAddedLoading, setAllProductAddedLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [largeModalShow, setLargeModalShow] = useState(false);
  const [finalColorwayArray, setFinalColorwayArray] = useState([]);
  const [restProducts, setRestProducts] = useState([]);
  const [uniteType, setUniteType] = useState([]);
  const [isAddFullCollectionLoading, setIsAddFullCollectionLoading] = useState(false);
  const [isAddFullCollectionHalfPieceLoading, setIsAddFullCollectionHalfPieceLoading] = useState(false);
  const [isAddFullCollectionFreeFraightLoading, setIsAddFullCollectionFreeFraightLoading] = useState(false);



  const { dispatch: customerDispatch, customer } = useContext(CustomerContext)
  const { dispatch, user } = useContext(UserContext)
  const { dispatch: cartDispatch, quantity } = useContext(CartContext)
  const { dispatch: wishListDispatch, quantity: wishListQuantity } = useContext(WishListContext)
  const { dispatch: iconDispatch, isNorthchott, isBanyan, isPatrick, color, textColor, brandType, backgroundColor, darkBackgroundColor } = useContext(BrandIconContext)

  let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))

  const { id } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const alert = useAlert()

  let categoryName = searchParams.get('categoryName');
  let categoryId = searchParams.get('categoryId');
  let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"
  let type = search.split("=")[1]
  let largeImages = [];
  let smallImages = []
  let selectedProductArray = []
  let prices = []


  // login or not login user
  const [login, setLogin] = useState((user || customer) ? true : false);

  // modalBox
  const [openModal, setOpenModal] = useState(null);

  // COLORWAY DATA STARTS HERE
  // for color only
  const [normalCardSizeOnly, setNormalCardSizeOnly] = useState(false);
  const [bigAndNormalCardSize, setBigAndNormalCardSIze] = useState(false);
  const [multiPanel, setMultiPanel] = useState(false);
  const [filteredProductsByColorway, setFilteredProductsByColorway] = useState([]);


  // colorwaya data for all colorway
  useEffect(() => {
    filteredProductsByColorway?.forEach((colorway) => {
      const filteredNormalCardFromColorwayData = colorway?.filteredNormalCards ? colorway?.filteredNormalCards : [];
      const filteredBigCardFromColorwayData = colorway?.filteredBigCards ? colorway?.filteredBigCards : [];

      if (
        filteredNormalCardFromColorwayData?.length > 0 &&
        filteredBigCardFromColorwayData?.length == 0
      ) {
        setNormalCardSizeOnly(true);
        setBigAndNormalCardSIze(false);
        setMultiPanel(false);
      }
      if (
        filteredNormalCardFromColorwayData?.length > 0 &&
        filteredBigCardFromColorwayData?.length > 0 &&
        filteredNormalCardFromColorwayData?.length >
        filteredBigCardFromColorwayData?.length
      ) {
        setNormalCardSizeOnly(false);
        setBigAndNormalCardSIze(true);
        setMultiPanel(false);
      }
      if (
        filteredNormalCardFromColorwayData?.length > 0 &&
        filteredBigCardFromColorwayData?.length > 0 &&
        filteredNormalCardFromColorwayData?.length <
        filteredBigCardFromColorwayData?.length
      ) {
        setNormalCardSizeOnly(false);
        setBigAndNormalCardSIze(false);
        setMultiPanel(true);
      }
    });
  }, [filteredProductsByColorway]);

  // for all colorway card image hover
  const colorWayNormalCardArrayLength =
    filteredProductsByColorway.filteredNormalCards;
  const colorWayBigCardArrayLength =
    filteredProductsByColorway.filteredBigCards;

  const [isHovered, setIsHovered] = useState(
    Array(filteredProductsByColorway.filteredNormalCards).fill(false)
  );

  const handleMouseEnter = (index) => {
    const updatedHoverState = [...isHovered];
    updatedHoverState[index] = true;
    setIsHovered(updatedHoverState);
  };

  const handleMouseLeave = (index) => {
    const updatedHoverState = [...isHovered];
    updatedHoverState[index] = false;
    setIsHovered(updatedHoverState);
  };
  // COLORWAY DATA ENDS HERE


  // for noncolorway only
  const [nonColorwayNormalCardSizeOnly, setNonColorwayNormalCardSizeOnly] = useState(false);
  const [nonColorwayBigAndNormalCardSize, setNonColorwayBigAndNormalCardSIze] = useState(false);
  const [nonColorwayMultiPanel, setnonColorwayMultiPanel] = useState(false);
  const [filteredNormalCardsNonColorway, setFilteredNormalCardsNonColorway] = useState([]);
  const [filteredBigCardsNonColorway, setFilteredBigCardsNonColorway] = useState([]);


  // Noncolorway data
  useEffect(() => {
    if (
      filteredNormalCardsNonColorway.length > 0 &&
      filteredBigCardsNonColorway.length === 0
    ) {
      setNonColorwayNormalCardSizeOnly(true);
      setNonColorwayBigAndNormalCardSIze(false);
      setnonColorwayMultiPanel(false);
    }
    if (
      filteredNormalCardsNonColorway.length > 0 &&
      filteredBigCardsNonColorway.length > 0 &&
      filteredNormalCardsNonColorway.length > filteredBigCardsNonColorway.length
    ) {
      setNonColorwayNormalCardSizeOnly(false);
      setNonColorwayBigAndNormalCardSIze(true);
      setnonColorwayMultiPanel(false);
    }
    if (
      filteredNormalCardsNonColorway.length > 0 &&
      filteredBigCardsNonColorway.length > 0 &&
      filteredNormalCardsNonColorway.length < filteredBigCardsNonColorway.length
    ) {
      setNonColorwayNormalCardSizeOnly(false);
      setNonColorwayBigAndNormalCardSIze(false);
      setnonColorwayMultiPanel(true);
    }
  }, [filteredNormalCardsNonColorway, filteredBigCardsNonColorway]);

  // for all noncolorway card image hover
  const nonColorWayNormalCardArrayLength =
    filteredNormalCardsNonColorway.length;
  const nonColorWayBigCardArrayLength = filteredBigCardsNonColorway.length;
  // NON-COLORWAY DATA ENDS HERE


  // All Methods
  const setTestCustomer = (customers, array) => {
    if (customers?.length) {
    }
    return array;
  }

  const getRegion = async () => {
    let options = [];
    let regions = [];

    try {
      ApiService.setHeader()
      const response1 = await ApiService.get(`/shop/customList/title?model=${getModelRoute(user).model}&title=${"Territory Name USA"}`)
      const response2 = await ApiService.get(`/shop/customList/title?model=${getModelRoute(user).model}&title=${"Territory Name Canada"}`)

      Array.prototype.push.apply(regions, [{ _id: "-CANADA-", name: "-CANADA-" }])
      Array.prototype.push.apply(regions, response2?.data.document.options)

      Array.prototype.push.apply(regions, [{ _id: "-USA-", "name": "-USA-" }])
      Array.prototype.push.apply(regions, response1?.data.document.options)


      const array = setTestCustomer(customers, regions)
      array.unshift({ _id: 'Select State / Prov', name: 'Select State / Prov' })
      setState(array)
    } catch (error) {
      showMessage(error.response.data.message, "error")
    }
  }

  const getCartDetail = () => {
    ApiService.setHeader()
    ApiService.get(`shop/cart/${customers[customers?.length - 1]?._id}?protectModel=${getModelRoute(user).model}`).then(response => {
      if (response?.data.isSuccess) {
        setCarts(response?.data.documents)
        cartDispatch({ type: "ADD_TO_CART_QUANTITY", payload: response?.data.documents?.length });
      }
    }).catch(error => {
      console.log(error.response.data)
    })
  }

  const getWishListDetail = () => {
    ApiService.setHeader()
    ApiService.get(`shop/wishList/${customers[customers?.length - 1]?._id}?protectModel=${getModelRoute(user).model}`).then(response => {
      if (response?.data.isSuccess) {
        setWishLists(response?.data?.documents)
        wishListDispatch({ type: "ADD_TO_WISHLIST_QUANTITY", payload: response?.data?.documents?.length });
      }
    }).catch(error => {
      console.log(error.response.data)
    })
  }

  const getCollection = () => {
    setIsLoading(true)

    ApiService.setHeader()
    ApiService.get(`shop/pricingMatrix?protectModel=${getModelRoute(user).model}`).then(response => {

      if (response?.data.isSuccess) {
        prices = response.data.documents

        if (response.data.documents.length) {
          ApiService.setHeader()
          ApiService.get(`shop/collection/${id}?protectModel=${getModelRoute(user).model}`).then(response => {

            if (response?.data.isSuccess) {
              setCollection(response?.data.document)
              if (customers != null) {
                getAllProductsByCollection(response?.data.document?.name)
              } else {
                getAllProductsByCollectionForNonLoggedInUser(response?.data.document?.name)
              }

            }
          }).catch(error => {
            console.log(error)
          })
        } else {
          navigate(-1)
          showMessage("No Pricing matrix data found!! Please contact to administrator.", "info")
        }
      }
    }).catch(error => {
      console.log(error.response.data)
    })
  }

  const getAllProductsByCollection = (name) => {
    let country = ""
    let array = []
    let productTypeArray = []
    let allProductOfCollection

    let productType = customers != null ? customers[customers?.length - 1]?.productType?.length && customers[customers?.length - 1]?.productType.map(ele => {
      let obj = {}
      obj.type = ele.text
      productTypeArray.push(obj)
    }) : []

    let countrydesignations = customers != null ? customers[customers?.length - 1]?.countryDesignations?.length && customers[customers?.length - 1]?.countryDesignations?.map(ele => {
      let obj = {}
      obj.countryDesignation = ele.text
      array.push(obj)
    }) : []
    array.push({ countryDesignation: "UNIVERSAL" })



    ApiService.setHeader()

    ApiService.get(`shop/product/collection/${name}?protectModel=${getModelRoute(user).model}&productType=${JSON.stringify(productTypeArray)}&countryDesinations=${JSON.stringify(array)}`).then(response => {
      if (response?.data.isSuccess) {
        if (customers !== null) {
          if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
            setUniteType('y')
            country = "UNITED STATES"
          } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA")) {
            country = "CANADA"
            setUniteType('m')
          }
        }


        response?.data.documents?.filter((ele) => {

          return ele
        }).map((product, index) => {
          let key
          if (customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("USA") || customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("CANADA")) {
            key = customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()
          } else {
            key = `${country === 'UNITED STATES' ? "USA" : 'CANADA'} ${customers[customers?.length - 1]?.priceLevel?.split("-")[1]}`.toUpperCase()
          }


          const pm = prices?.filter(ele => ele.Code === product.priceCode)
          if (pm.length) {
            for (let ele of Object.entries(pm[0])) {

              if (ele[0].toUpperCase() === key) {
                product.priceFromPM = ele[1]
              }
            }

          }

          if (product?.isOversized) {
            largeImages.push(product)

            if (country == "CANADA") {
              product.multipliers = formatArray(product?.cadFullMultiplier ? product?.cadFullMultiplier : 1)
              product.halfMultipliers = formatArray(product?.cadHalfMultiplier ? product?.cadHalfMultiplier : 1)
            } else {
              product.multipliers = formatArray(product?.usdFullMultiplier ? product?.usdFullMultiplier : 1)
              product.halfMultipliers = formatArray(product?.usdHalfMultiplier ? product?.usdHalfMultiplier : 1)
            }
          } else {
            smallImages.push(product)

            if (country == "CANADA") {
              product.multipliers = formatArray(product?.cadFullMultiplier ? product?.cadFullMultiplier : 1)
              product.halfMultipliers = formatArray(product?.cadHalfMultiplier ? product?.cadHalfMultiplier : 1)
            } else {
              product.multipliers = formatArray(product?.usdFullMultiplier ? product?.usdFullMultiplier : 1)
              product.halfMultipliers = formatArray(product?.usdHalfMultiplier ? product?.usdHalfMultiplier : 1)
            }
          }
        })
        setProducts(response?.data.documents)

        let colorwayArray = []
        let colorwayProductsArray = []
        let restProductsArray = []

        // Get colorways by collection
        ApiService.get(`shop/colorway/getColorways/${id}?protectModel=${getModelRoute(user).model}`).then(res => {

          if (res?.data.isSuccess) {
            if (customers !== null) {
              if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
                country = "UNITED STATES"
              } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
                country = "CANADA"
            }

            res.data.documents?.map(colorway => {
              let obj = {}
              let lgImgArray = []
              let smImgArray = []

              obj.colorwayName = colorway?.name

              colorway.products?.map(product => {

                let key
                if (customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("USA") || customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("CANADA")) {
                  key = customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()
                } else {
                  key = `${country === 'UNITED STATES' ? "USA" : 'CANADA'} ${customers[customers?.length - 1]?.priceLevel?.split("-")[1]}`.toUpperCase()
                }

                const pm = prices?.filter(ele => ele.Code === product.id.priceCode)
                // Set price from pricing matrix in each product
                if (pm.length) {
                  for (let ele of Object.entries(pm[0])) {
                    if (ele[0].toUpperCase() === key) {
                      product.id.priceFromPM = ele[1]
                    }
                  }

                }

                if (product?.id.isOversized) {
                  lgImgArray.push(product.id)

                  if (country == "CANADA") {
                    product.id.multipliers = formatArray(product?.id.cadFullMultiplier ? product?.id.cadFullMultiplier : 1)
                    product.id.halfMultipliers = formatArray(product?.id.cadHalfMultiplier ? product?.id.cadHalfMultiplier : 1)
                  } else {
                    product.id.multipliers = formatArray(product?.id.usdFullMultiplier ? product?.id.usdFullMultiplier : 1)
                    product.id.halfMultipliers = formatArray(product?.id.usdHalfMultiplier ? product?.id.usdHalfMultiplier : 1)
                  }
                  obj.filteredBigCards = lgImgArray
                } else {
                  smImgArray.push(product.id)

                  if (country == "CANADA") {
                    product.id.multipliers = formatArray(product?.id.cadFullMultiplier ? product?.id.cadFullMultiplier : 1)
                    product.id.halfMultipliers = formatArray(product?.id.cadHalfMultiplier ? product?.id.cadHalfMultiplier : 1)
                  } else {
                    product.id.multipliers = formatArray(product?.id.usdFullMultiplier ? product?.id.usdFullMultiplier : 1)
                    product.id.halfMultipliers = formatArray(product?.id.usdHalfMultiplier ? product?.id.usdHalfMultiplier : 1)
                  }
                  obj.filteredNormalCards = smImgArray.sort(function (a, b) { return a.order - b.order; });
                }
              })

              if (obj.filteredBigCards?.length || obj.filteredNormalCards?.length)
                colorwayArray.push(obj)
            })

            setFilteredProductsByColorway(colorwayArray)


            setFinalColorwayArray(colorwayArray)
            setLargeImageProductsForLoggedInUser(largeImages)
            setSmallImageProductsForLoggedInUser(smallImages)



            colorwayArray?.length && colorwayArray?.map(ele => {
              if (ele?.filteredNormalCards?.length)
                colorwayProductsArray.push(...ele?.filteredNormalCards)
              if (ele?.filteredBigCards?.length)
                colorwayProductsArray.push(...ele?.filteredBigCards)
            })

            if (colorwayArray?.length) {
              restProductsArray = response?.data.documents.filter(ele => {
                if (ele?.name?.includes(':') || ele?.name?.includes('PTN')) {
                  return ele
                }
              }).filter(a => !colorwayProductsArray.some(b => a.name === b.name));
            } else {
              setFilteredBigCardsNonColorway(largeImages)
              setFilteredNormalCardsNonColorway(smallImages)
            }

            if (restProductsArray?.length) {

              setFilteredBigCardsNonColorway(restProductsArray.filter(ele => ele?.isOversized))
              setFilteredNormalCardsNonColorway(restProductsArray.filter(ele => !ele?.isOversized))
            }
            setRestProducts(restProductsArray)
            setIsLoading(false)
          }
        }).catch(error => {
          setProducts([]);
          setIsLoading(false);
        })

      }
    }).catch(error => {
      setProducts([]);
      setIsLoading(false);
    })


  }

  const getAllProductsByCollectionForNonLoggedInUser = async (name) => {

    let country = ""
    let array = []
    let productTypeArray = []

    ApiService.setHeader()

    ApiService.get(`shop/product/collection/${name}?protectModel=${getModelRoute(user).model}&for=nonLoggedInUser`).then(response => {

      if (response?.data.isSuccess) {
        if (customers !== null) {
          if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
            country = "UNITED STATES"
          } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
            country = "CANADA"
        }

        // New UI code
        response?.data.documents.filter(
          (item) => {

            return item
          }).map(async product => {


            if (product?.isOversized) {
              largeImages.push(product)
              if (country == "CANADA") {
                product.multipliers = formatArray(product?.cadFullMultiplier ? product?.cadFullMultiplier : 1)
                product.halfMultipliers = formatArray(product?.cadHalfMultiplier ? product?.cadHalfMultiplier : 1)
              } else {
                product.multipliers = formatArray(product?.usdFullMultiplier ? product?.usdFullMultiplier : 1)
                product.halfMultipliers = formatArray(product?.usdHalfMultiplier ? product?.usdHalfMultiplier : 1)
              }
            } else {
              smallImages.push(product)

              if (country == "CANADA") {
                product.multipliers = formatArray(product?.cadFullMultiplier ? product?.cadFullMultiplier : 1)
                product.haldMultipliers = formatArray(product?.cadHalfMultiplier ? product?.cadHalfMultiplier : 1)
              } else {
                product.multipliers = formatArray(product?.usdFullMultiplier ? product?.usdFullMultiplier : 1)
                product.halfMultipliers = formatArray(product?.usdHalfMultiplier ? product?.usdHalfMultiplier : 1)
              }
            }


          })

        setFilteredBigCardsNonColorway(largeImages)
        setFilteredNormalCardsNonColorway(smallImages)
        // New UI code end
        setProducts(response?.data.documents)

        setIsLoading(false)
      }
    }).catch(error => {
      console.log(error)
    })
  }


  const AddAllSelected = async (data) => {
    let country

    data?.map(ele => {
      ele.shipDate = moment(new Date()).isAfter(new Date(collection?.date)) ? new Date() : collection?.date
      ele.collectionName = collection?.name
    })

    if (customers !== null) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        country = "UNITED STATES"
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
        country = "CANADA"
    }

    ApiService.post(`shop/cart/addAllSelected?protectModel=${getModelRoute(user).model}&country=${country}&collection=${collection?.name}&customerId=${customer?._id}`, data).then(response => {

      if (response?.data.isSuccess) {
        getCartDetail()
        showMessage("Items added", "success")
        selectedProductArray = []
      }
    }).catch(error => {
      console.log(error)
    })
    getCartDetail()
  }


  const addCart = async (data) => {
    let country
    data.shipDate = moment(new Date()).isAfter(new Date(collection?.date)) ? new Date() : moment(collection?.date)?.add({ days: 1 })?._i
    data.newShipDate = moment(new Date()).isAfter(new Date(collection?.date)) ? new Date() : moment(collection?.date)?.add({ days: 1 })?._i
    data.collectionName = collection?.name

    if (customers !== null) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        country = "UNITED STATES"
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
        country = "CANADA"
    }
    alert.success("Item added")
    // toast.success('Item added', {
    //   position: "bottom-right",
    //   autoClose: 2000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: false,
    //   draggable: false,
    //   progress: undefined,
    //   theme: "light"
    // });

    ApiService.post(`shop/cart?protectModel=${getModelRoute(user).model}&country=${country}&collection=${collection?.name}&customerId=${customer?._id}`, data).then(response => {

      if (response?.data.isSuccess) {
        getCartDetail()

        // showMessage("Item added", "success")
        selectedProductArray = []
      }
    }).catch(error => {
      // alert.error("Something went wrong !!")
      getCartDetail()
    })
  }

  const addToCart = (data) => {
    addCart(data)
  }

  const getPricingMatrix = async () => {
    await ApiService.get(`shop/pricingMatrix/getPrice?protectModel=${getModelRoute(user).model}&priceLevel=${customers[customers?.length - 1]?.priceLevel}`).then(response => {

      if (response?.data.isSuccess) {
        setUsdMatrix(response.data.documents.usd)
        setCadMatrix(response.data.documents.cad)

      }
    }).catch(error => {
      console.log(error.response.data)
    })
  }


  const getPriceByPricingMatrix = (data) => {

    let cntry = null

    let priceCode = data.toLowerCase().trim()

    if (customers !== null) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        country = "UNITED STATES"
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
        country = "CANADA"
    }

    if (cntry == "CANADA") {
      return cadMatrix?.filter(ele => (ele?.label == customers[customers?.length - 1].priceLevel && ele?.scriptid == `_pct_ncs_${priceCode}`))[0]?.value
    } else {
      return usdMatrix?.filter(ele => (ele?.label == customers[customers?.length - 1].priceLevel && ele?.scriptid == `_pct_ncs_${priceCode}`))[0]?.value
    }
  }

  const findProductMultiplierAndFormatArray = (productId) => {
    setCADMultiplier([])
    setQtys([])


    ApiService.setHeader()
    ApiService.get(`shop/product/${productId}?protectModel=${getModelRoute(user).model}`).then(response => {
      if (response?.data.isSuccess) {

        if (customers !== null) {
          if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
            country = "UNITED STATES"
          } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
            country = "CANADA"
        }

        if (country == "CANADA") {
          formatArray(response.data.document.cadFullMultiplier ? response.data.document.cadFullMultiplier : 1)
        } else {
          formatArray(response.data.document.usdFullMultiplier ? response.data.document.usdFullMultiplier : 1)
        }

      }


    }).catch(error => {
      console.log(error.response.data)
    })
  }

  const formatArray = (multiplier, calledFrom) => {

    let array = []
    for (let i = 1; i <= 10; i++) {
      array.push((i * parseInt(multiplier)))
    }
    if (calledFrom !== "checkBox") {
      setQtys(array)
    }
    return array
  }

  const setQuantity = () => {
    setCADMultiplier(qtys)
  }

  const moveToWishList = async (product, customer) => {

    const data = {
      productId: product?._id,
      userId: customer?._id
    }

    await ApiService.post(`shop/wishList?protectModel=${getModelRoute(user).model}`, data).then(response => {
      if (response?.data.isSuccess) {
        getCartDetail()
        getWishListDetail()

        showMessage("Item added to wishlist.", "success")
      }
    }).catch(error => {
      console.log(error.response.data)
    })
  }


  const addAllProductToCart = async (buttonType) => {
    let array = []
    let arr = []
    let finalProductArray = []
    let qtyArray = []
    let country

    let buttonTypeArray = JSON.parse(localStorage.getItem("PCTeRP.BUTTONTYPEARRAY")) == null ? [] : JSON.parse(localStorage.getItem("PCTeRP.BUTTONTYPEARRAY"))
    let obj = {}
    obj.customerId = customers[customers?.length - 1]?._id
    obj.buttonType = buttonType
    obj.collectionId = id
    obj.collectionName = collection?.name
    buttonTypeArray.push(obj)
    localStorage.setItem("PCTeRP.BUTTONTYPEARRAY", JSON.stringify(buttonTypeArray))

    if (customers !== null) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        country = "UNITED STATES"
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
        country = "CANADA"
    }

    const finalProducts = products?.filter(ele => {

      return ele
    }).map((product, index) => {

      let key
      if (customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("USA") || customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("CANADA")) {
        key = customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()
      } else {
        key = `${country === 'UNITED STATES' ? "USA" : 'CANADA'} ${customers[customers?.length - 1]?.priceLevel?.split("-")[1]}`.toUpperCase()
      }


      const pm = prices?.filter(ele => ele.Code.toUpperCase() === product.priceCode.toUpperCase())


      // Set price from pricing matrix in each product
      if (pm.length) {
        for (let ele of Object.entries(pm[0])) {
          if (ele[0].toUpperCase() === key) {
            product.priceFromPM = ele[1]
          }
        }

      }

      // for quantity

      if (('noPromoArray' in collection) && collection.noPromoArray.length > 0) {
        let noPromoArrayMatchData = collection.noPromoArray.findIndex(data => data.netsuiteId === product?.netsuiteId)

        if (noPromoArrayMatchData === -1) {
          if (buttonType == "FullCollectionButton") {
            product.productQuantity = product?.multipliers[0]
          } else if (buttonType == "FullCollectionHalfPieceButton") {
            product.productQuantity = product?.halfMultipliers[0]
          } else if (buttonType == "FullCollectionFreeFreightButton") {
            product.productQuantity = product?.multipliers[0]
          }

        } else {
          product.productQuantity = 0
        }
      }
      else {
        if (buttonType == "FullCollectionButton") {
          product.productQuantity = product?.multipliers[0]
        } else if (buttonType == "FullCollectionHalfPieceButton") {
          product.productQuantity = product?.halfMultipliers[0]
        } else if (buttonType == "FullCollectionFreeFreightButton") {
          product.productQuantity = product?.multipliers[0]
        }
      }

      // product.productQuantity = product?.noPromos?.length ? product?.noPromos?.includes(collection?.name?.toUpperCase()) ? 0.00 : product?.multipliers[0] : product?.multipliers[0]

      finalProductArray.push(product)
    })

    const shipDate = moment(new Date()).isAfter(new Date(collection?.date)) ? new Date() : moment(collection?.date)?.add({ days: 1 })?._i
    const newShipDate = moment(new Date()).isAfter(new Date(collection?.date)) ? new Date() : moment(collection?.date)?.add({ days: 1 })?._i

    if (buttonType == "FullCollectionButton") {
      setIsAddFullCollectionLoading(true)
    } else if (buttonType == "FullCollectionHalfPieceButton") {
      setIsAddFullCollectionHalfPieceLoading(true)
    } else if (buttonType == "FullCollectionFreeFreightButton") {
      setIsAddFullCollectionFreeFraightLoading(true)
    }
    alert.success(`Full collection added`)
    ApiService.setHeader()
    ApiService.post(`shop/cart/addProducts?model=${getModelRoute(user).model}&country=${country}&customerId=${customer?._id}&collection=${collection?.name}&buttonType=${buttonType}&shipDate=${shipDate}&newShipDate=${newShipDate}`, finalProductArray).then(response => {
      if (response?.data.isSuccess) {
        setIsAddFullCollectionFreeFraightLoading(false)
        setIsAddFullCollectionLoading(false)
        setIsAddFullCollectionHalfPieceLoading(false)
        getCartDetail()
      }
    }).catch(error => {
      showMessage(error.response.data.message, "error")
      getCartDetail()
    })
  }

  const addColorwayProductsToCart = async (buttonType, colorway) => {
    let array = []
    let qtyArray = []
    let country


    if (customers !== null) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        country = "UNITED STATES"
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
        country = "CANADA"
    }

    colorway?.filteredNormalCards?.filter(ele => {
      if (ele?.name?.includes(':') || ele?.name?.includes('PTN')) {
        return ele
      }
    }).map(product => {

      let key
      if (customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("USA") || customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("CANADA")) {
        key = customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()
      } else {
        key = `${country === 'UNITED STATES' ? "USA" : 'CANADA'} ${customers[customers?.length - 1]?.priceLevel?.split("-")[1]}`.toUpperCase()
      }

      const pm = prices?.filter(ele => ele.Code.toUpperCase() === product.priceCode.toUpperCase())


      // Set price from pricing matrix in each product
      if (pm.length) {
        for (let ele of Object.entries(pm[0])) {
          if (ele[0].toUpperCase() === key) {
            product.priceFromPM = ele[1]
          }
        }

      }
    })

    const shipDate = moment(new Date()).isAfter(new Date(collection?.date)) ? new Date() : collection?.date

    ApiService.setHeader()
    ApiService.post(`shop/cart/addColorwayProducts?model=${getModelRoute(user).model}&country=${country}&customerId=${customers[customers?.length - 1]?._id}&collectionName=${collection?.name}&buttonType=${buttonType}&shipDate=${shipDate}`, colorway?.filteredNormalCards).then(response => {
      if (response?.data.isSuccess) {
        getCartDetail()

      }
    }).catch(error => {
      showMessage(error.response.data.message, "error")
    })
  }

  const getCustomerCounry = () => {


    if (customers?.length >= 1) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        setCountry("UNITED STATES")
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase().includes("CANADA")) {

        setCountry("CANADA")
      }

    } else {
      setCountry(null)
    }

  }


  const addSelectedProductsToCart = async (id, product, e, quantity) => {
    if (document.getElementById(id).checked) {
      let obj = {}
      let arr = []

      getCustomerCounry()
      let price = product.price


      let key
      if (customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("USA") || customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()?.startsWith("CANADA")) {
        key = customers[customers?.length - 1]?.priceLevel?.split("-")[1]?.toUpperCase()
      } else {
        key = `${country === 'UNITED STATES' ? "USA" : 'CANADA'} ${customers[customers?.length - 1]?.priceLevel?.split("-")[1]}`.toUpperCase()
      }


      const pm = prices?.filter(ele => ele.Code.toUpperCase() === product.priceCode.toUpperCase())

      // Set price from pricing matrix in each product
      if (pm.length) {
        for (let ele of Object.entries(pm[0])) {
          if (ele[0].toUpperCase() === key) {
            product.priceFromPM = ele[1]
          }
        }

      }
      obj.productId = product?._id
      obj.userId = customers[customers?.length - 1]?._id
      obj.price = product.priceFromPM
      obj.productQuantity = quantity
      obj.multipliers = product.multipliers
      obj.subTotal = (parseFloat(quantity) * parseFloat(product.priceFromPM)).toFixed(2)
      obj.image = product?.uploadLargePoster?.url ? product?.uploadLargePoster?.url : product?.uploadSmallPoster?.url

      selectedProductArray.push(obj)


    } else {
      let filterproductArray = selectedProductArray.filter((item) => item.productId !== e.target.value);
      selectedProductArray = filterproductArray
    }
  }

  const getIndustryCodes = (data) => {
    ApiService.setHeader()
    ApiService.get(`shop/customList/title?protectModel=${getModelRoute(user).model}&title=NCS Industry Codes`, data).then(response => {
      if (response?.data.isSuccess) {
        setIndustryCodes(response?.data.document.options)
      }
    }).catch(error => {
      console.log(error.response.data)
    })
  }


  const gotoDesignerPage = (designerNsId) => {
    ApiService.setHeader()
    ApiService.get(`shop/designer/byId/${designerNsId}?protectModel=${getModelRoute(user).model}`).then(response => {
      if (response?.data.isSuccess) {
        navigate(`/designer-profile/${response?.data?.document?._id}?protectModel=${getModelRoute(user).model}`)
      }
    }).catch(error => {
      console.log(error.response.data)
    })
  }

  const getDateToDisplayInStoreOrAvailableFrom = (date, isArchived) => {

    const argDate = new Date(date);

    const currentDate = new Date();

    const argYear = argDate.getFullYear();
    const argMonth = argDate.getMonth() + 1;

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    if (isArchived !== undefined && isArchived) {
      return `Archived collection from ${moment(argDate).format('MMM YYYY')}`;
    }
    if (currentYear === argYear && currentMonth === argMonth) {
      return `Available Now`;
    } else if (currentYear < argYear || (currentYear === argYear && currentMonth < argMonth)) {
      return `Coming to stores in ${moment(argDate).format('MMM YYYY')}`;
    } else {
      return `Available Now`;
    }
  }

  const downloadPDF = () => {
    try {
      const fileName = collection?.downloadFabricFilesName;
      if (!fileName) return;
      const link = document.createElement("a")
      link.href = `https://ncs-img.azureedge.net/imageszip/${fileName}`;
      document.body.appendChild(link);
      link.click()
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {

    if (customers != null) {
      getCartDetail()
      getWishListDetail()
      getCustomerCounry()
    }

    getIndustryCodes()
    getRegion()
    getCollection()

  }, [id]);



  return (

    <>
      <div
        className="bg-white flex flex-col items-center justify-center gap-[60px] sm:gap-20 xl:gap-[60px] mb-[60px] sm:mb-[100px]"
        style={{ fontFamily: "Open Sans", marginTop: "20px" }}
      >
        {/* section 1 */}
        {/* top banner  */}
        {collection?.posterImageName ? <div className="w-full h-[235px] sm:h-[257px] xl:h-[296px]">
          <img
            // src={PageBanner}
            src={`${ApiService.getBaseImagePath()}colorwaybanners/${collection?.posterImageName}`}
            alt="Image"
            className="h-[235px] sm:h-[257px] xl:h-[296px] w-full"
          />
        </div> : ""}
        {/* section 2 */}
        <div className="w-[320px] sm:w-[744px] h-[376px] sm:h-[222px] xl:w-[1160px] xl:h-[96px] 2xl:w-[1320px] grid grid-cols-1 xl:grid-cols-2 items-center justify-items-center xl:items-end xl:justify-items-start gap-10 xl:gap-0 ">
          <div className="w-[280px] h-[132px] sm:w-[539px] sm:h-[100px] xl:h-[96px] col-span-1 flex flex-col gap-[10px] lg:gap-2 text-center lg:text-left">
            <div className="text-[14px] flex whitespace-nowrap overflow-visible">
              {
                (collection?.designer?.length || collection?.designerTwo?.length) ? 'By' : ""} {collection?.designer?.length ? collection?.designer?.map(designer => {
                  if (collection?.designer?.length == 1 && designer?.id) {

                    return <Link className='first:ms-[5px] font-bold text-black text-[14px] hover:underline cursor-pointer' key={designer?._id} as={Link} to={`/designer-profile/${designer?.id}`}>&nbsp; {collection?.designerTwo?.length ? "&" : ''} &nbsp;</Link>

                  } else if (collection?.designer?.length == 1 && !designer?.id) {
                    return <div key={designer?._id} className='flex first:ms-[5px] font-bold text-black text-[14px] cursor-pointer'>
                      <span className="hover:underline"
                        onClick={() => gotoDesignerPage(designer?.value)}
                      >
                        {designer?.text}
                      </span>
                      &nbsp;
                      {collection?.designerTwo?.length ? "&" : ""} &nbsp;</div>
                  }
                }) : ""}

              {collection?.designerTwo?.length ? collection?.designerTwo?.map((designer, index) => {
                if (designer?.id) {
                  if (collection?.designerTwo[index + 1]) {
                    return <Link key={designer?._id} className='first:ms-[5px] font-bold text-black text-[14px] hover:underline cursor-pointer' as={Link} to={`/designer-profile/${designer?.id}`}>{designer?.text}&nbsp; &&&& &nbsp;</Link>
                  } else {
                    return <Link key={designer?._id} className='first:ms-[5px] font-bold text-black text-[14px] hover:underline cursor-pointer' as={Link} to={`/designer-profile/${designer?.id}`}>{designer?.text}</Link>
                  }

                } else if (!designer?.id) {
                  if (collection?.designerTwo[index + 1]) {
                    return <span key={designer?._id} className='first:ms-[5px] font-bold text-black text-[14px] hover:underline cursor-pointer'
                      onClick={() => gotoDesignerPage(designer?.value)}
                    >{designer?.text}&nbsp;&& &nbsp;</span>
                  } else {
                    return <span key={designer?._id} className='first:ms-[5px] font-bold text-black text-[14px] hover:underline cursor-pointer'
                      onClick={() => gotoDesignerPage(designer?.value)}
                    >{designer?.text}</span>
                  }
                }
              }) : ""}
            </div>
            <div className="text-[28px] lg:text-[32px] font-bold">
              <p>{collection?.name}</p>
            </div>
            <div className="text-[14px]">
              <p>{collection?.date && getDateToDisplayInStoreOrAvailableFrom(collection?.date, collection?.isArchive)}</p>
            </div>
          </div>

          <div className="xl:ms-[150px] 2xl:ms-52 w-[204px] h-[204px] sm:w-[448px] sm:h-[82px] xl:w-[428px] xl:h-[82px] flex flex-col sm:flex-row items-center justify-between lg:justify-end mx-auto gap-10 sm:gap-5 text-center">
            {/* product finder  */}
            <div className="text-center text-base font-bold flex flex-col gap-[15px]">
              <p className="text-left">Product Finder</p>

              {/*product finder dropdown input box */}
              <div className="w-[204px] h-[40px] bg-white">
                <Menu
                  as="div"
                  className=" relative flex justify-start text-left"
                >
                  <div>
                    <Menu.Button className="inline-flex w-[204px] h-[40px] items-center justify-between gap-x-1.5 bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 font-sm">
                      <div className="text-[14px] text-[#9D9C99] font-semibold">
                        Find Retailers
                      </div>
                      <ChevronDownIcon
                        className="-mr-1 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute z-10 mt-2 w-[204px] h-[40px] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1 bg-white">
                        {
                          industryCodes?.length && industryCodes.map(industryCode => {
                            return <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to={`/product-finder/${id}?state=${null}&IC=${industryCode?.text}`}
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-[14px]"
                                  )}
                                >
                                  {industryCode?.text}
                                </Link>
                              )}
                            </Menu.Item>
                          })
                        }

                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>

                {/*product finder Dropdown menu  */}
                <div
                  id="dropdown"
                  className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                >
                  <ul
                    className="py-2 text-[14px] text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Dashboard
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Settings
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Earnings
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        Sign out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* fabric files */}
            <div className="flex flex-col gap-[15px]">
              <div className="text-base font-bold">
                <p className="text-left">Fabric Files</p>
              </div>
              <div className="flex justify-start w-[204px] h-[40px]">
                <button
                  type="button"
                  className={` rounded-[4px] uppercase text-[14px] text-center mb-10 w-[204px] h-[40px] lg:tracking-tighter font-semibold ${collection?.downloadFabricFilesName ? "text-white bg-[#E2A856] cursor-pointer hover:bg-[#090800]" : "bg-[#EECB9A] text-white"}`}
                  onClick={() => downloadPDF()}
                  disabled={!collection?.downloadFabricFilesName}
                >
                  Download fabric files
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* section 3 description and promo message*/}
        <div>

          <div
            className="w-[280px] sm:w-[664px] xl:w-[1160px] -mt-[20px] my-[20px] 2xl:w-[1320px] flex items-center text-[14px]"
          >
            <div dangerouslySetInnerHTML=
              {{
                __html: collection?.description
              }} />
          </div>
          {((user || customer)) ? <div
            className={`${((user || customer))
              ? "mx-auto bg-[#D9D2CB] w-[280px] h-[276px] sm:w-[664px] sm:h-[132px] xl:w-[1160px] xl:h-[114px] 2xl:w-[1320px] flex items-center justify-center text-center font-bold py-[40px] text-base"
              : "hidden"
              }`}
          >
            <div className="bg-[#D9D2CB]" dangerouslySetInnerHTML=
              {{
                __html: collection?.promoMessage
              }} />
          </div> : ""}
        </div>
        {/* section 4 */}
        {/* breadcrum */}
        <div className="w-[280px] h-[18px] sm:w-[664px] sm:h-[18px] xl:w-[1160px] 2xl:w-[1320px] flex justify-start">
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="inline-flex space-x-1 md:space-x-2 rtl:space-x-reverse">
              <li className="inline-flex mt-[2px]">
                <p className="inline-flex text-[12px] cursor-pointer font-medium text-[#6B6B66] hover:text-[#090800] hover:underline"
                  onClick={() => {
                    navigate(`/?division=${division}`)
                  }}
                >
                  Home
                </p>
              </li>
              <li>
                <div className="flex">
                  {" / "}
                  <p className="mt-[2px] ms-1 text-[12px] cursor-pointer font-medium text-[#6B6B66] hover:text-[#090800] hover:underline md:ms-2"
                    onClick={() => {
                      navigate(`/product-collection/${categoryId}?division=${division}`)
                    }}
                  >
                    {categoryName ? categoryName : "category"}
                  </p>
                </div>
              </li>
              <li aria-current="page">
                <div className="flex">
                  {" / "}
                  <span className="mt-[2px] ms-1 text-[12px] font-medium text-[#6B6B66] md:ms-2 flex justify-center">
                    {collection?.name}
                  </span>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        {/* products count and buttons */}
        <div className="flex flex-col sm:flex-row justify-center sm:justify-between w-[280px] h-[159px] sm:w-[664px] sm:h-[39px] xl:w-[1160px] 2xl:w-[1320px] gap-10 sm:gap-0 ">
          <div className="text-[14px] text-nowrap text-center sm:text-start w-[280px] sm:w-[150px] h-[39px] flex items-center justify-start sm:items-end">
            {loading ? (
              "Loading..."
            ) : (
              products.length ? (
                `${products?.length} Products`
              ) : (
                "No Products Found !"
              )
            )}
          </div>

          {(user || customer) && (
            <div className="w-[554px] h-[130px] sm:w-[752px] sm:h-[39px] flex flex-col sm:flex-row items-center justify-end gap-y-[10px] sm:gap-x-[40px]">
              {/* buttons */}
              {((customer?.subsidiary?.text?.toUpperCase()?.includes("CANADA") && collection?.isShowFullCollectionButtonCanada) || (customer?.subsidiary?.text?.toUpperCase()?.includes("USA") && collection?.isShowFullCollectionButton)) ? <div className="w-[184px] h-[39px] flex items-center justify-center">
                <button
                  type="button"
                  className=" text-white bg-[#E2A856] font-semibold rounded w-[184px] h-[39px] text-center hover:bg-[#090800] text-[14px]"
                  onClick={() => addAllProductToCart('FullCollectionButton')}
                >
                  Add Full Collection
                </button>
              </div> : ""}

              {((customer?.subsidiary?.text?.toUpperCase()?.includes("CANADA") && collection?.isShowFullCollectionHalfPieceButtonCanada) || (customer?.subsidiary?.text?.toUpperCase()?.includes("USA") && collection?.isShowFullCollectionHalfPieceButton)) ? <div className="w-[274px] h-[39px] flex justify-center">
                <button
                  type="button"
                  className="hover:bg-[#090800] text-white bg-[#E2A856] font-semibold rounded w-[274px] h-[39px] text-center text-[14px]"
                  onClick={() => addAllProductToCart('FullCollectionHalfPieceButton')}
                >
                  Add Full Collection - Half Piece
                </button>
              </div> : ""}

              {(user && user?.userTypes[0] !== "CUSTOMER") && ((customer?.subsidiary?.text?.toUpperCase()?.includes("CANADA") && collection?.isShowFullCollectionFreeFreightButtonCanada) || (customer?.subsidiary?.text?.toUpperCase()?.includes("USA") && collection?.isShowFullCollectionFreeFreightButton)) ? <div className="w-[274px] h-[39px] flex justify-center">
                <button
                  type="button"
                  className="hover:bg-[#090800] text-white bg-[#E2A856] font-semibold rounded w-[274px] h-[39px] text-center text-[14px]"
                  onClick={() => addAllProductToCart('FullCollectionFreeFreightButton')}
                >
                  Add Full Collection - Free Freight
                </button>
              </div> : ""}
            </div>
          )}
        </div>
        {/* section 5 */}
        {/* product section */}
        {/* horizontal line  */}
        <div className="mx-5 sm:mx-7 lg:mx-12 min-w-72 bg-[#CECECC] text-[#CECECC] h-[0.1rem] sm:w-[93vw] lg:w-[91vw] xl:w-[81vw] 2xl:w-[86vw] xl:mx-auto"></div>

        {/* COLORWAY PRODUCT FOR NORMAL , BIGANDNORMAL , MULTI CARD */}
        {filteredProductsByColorway.map((item, index) => {

          return (
            <div key={index}>
              <div className="mb-[60px] xl:mb-[40px] w-[280px] h-[96px] sm:w-[664px] sm:h-[96px] xl:w-[1160px] 2xl:w-[1320px] xl:h-[39px] grid grid-cols-1 sm:grid-cols-1 xl:grid-cols-3 gap-[30px] xl:gap-0 items-center justify-center xl:justify-around">
                <div className="col-span-1 hidden xl:flex h-[39px]"></div>
                <div className="col-span-1 h-[39px] flex items-center justify-center">
                  <p className="font-bold text-center text-[18px]">
                    {item?.colorwayName}
                  </p>
                </div>
                {/* {((user || customer) && collection?.isShowAddThisColorwayOnlyButton) && ( */}
                <div className="flex justify-center xl:justify-end col-span-1 h-[39px]">
                  <div className="w-[280px] sm:w-[664px] xl:w-[500px] flex items-center justify-center gap-10">
                    {((user || customer) && collection?.isShowAddThisColorwayOnlyButton) && <button
                      type="button"
                      className=" text-black bg-white border-2 border-black h-[39px] rounded-[4px] font-semibold text-center w-[223px] hover:bg-[#E2A856] hover:text-white hover:border-[#E2A856] text-[14px]"
                      onClick={() => addColorwayProductsToCart('addThisColorwayOnly', item)}
                    >
                      Add This Colorway Only
                    </button>}

                    {((user || customer) && collection?.isShowAddThisColorwayHalfPieceButton) &&
                      <button
                        type="button"
                        className=" text-black bg-white border-2 border-black h-[39px] rounded-[4px] font-semibold text-center w-[223px] hover:bg-[#E2A856] hover:text-white hover:border-[#E2A856] text-[14px]"
                        onClick={() => addColorwayProductsToCart('addThisColorwayHalfPieces', item)}
                      >
                        Add this colorway half pieces
                      </button>
                    }
                  </div>
                </div>
              </div>

              {normalCardSizeOnly && (
                <div className="w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid grid-cols-2 sm:grid-cols-3 mx-auto xl:grid-cols-5 justify-items-center grid-flow-row gap-x-[20px] sm:gap-x-[62px] xl:gap-x-[65px] 2xl:gap-x-[105px] gap-y-[40px]">
                  {item.filteredNormalCards?.map((item, index) => (
                    // card component
                    <ProductNormalCard
                      key={index + item._id}
                      login={(user || customer)}
                      index={index}
                      item={item}
                      data={colorWayNormalCardArrayLength}
                      product={item}
                      uniteType={uniteType}
                      setQuantity={setQuantity}
                      customers={customers}
                      wishLists={wishLists}
                      navigate={navigate}
                      moveToWishList={moveToWishList}
                      carts={carts}
                      addSelectedProductsToCart={addSelectedProductsToCart}
                      getPriceByPricingMatrix={getPriceByPricingMatrix}
                      findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
                      cadMultiplier={cadMultiplier} addToCart={addToCart}
                      setProductImage={setProductImage}
                      setModalShow={setModalShow}
                      country={country}
                      collection={collection}
                    />
                  ))}
                </div>
              )}

              {bigAndNormalCardSize && (
                <div className="w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid xl:grid-cols-12 gap-[40px] 2xl:gap-[80px]">
                  {/* big card */}
                  <div className="xl:col-span-3">
                    <div className="h-auto w-[280px] sm:w-[540px] xl:w-[250px] grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 mx-auto justify-items-center grid-flow-row sm:gap-x-[40px] xl:gap-x-0 gap-y-[40px]">
                      {item.filteredBigCards?.map((item, index) => (
                        <ProductBigCard
                          key={index + item._id}
                          login={(user || customer)}
                          index={index}
                          item={item}
                          data={colorWayBigCardArrayLength}
                          multiBigCard={false}
                          product={item}
                          uniteType={uniteType}
                          setQuantity={setQuantity}
                          customers={customers}
                          wishLists={wishLists}
                          navigate={navigate}
                          moveToWishList={moveToWishList}
                          carts={carts}
                          addSelectedProductsToCart={addSelectedProductsToCart}
                          getPriceByPricingMatrix={getPriceByPricingMatrix}
                          findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
                          cadMultiplier={cadMultiplier} addToCart={addToCart}
                          setProductImage={setProductImage}
                          setModalShow={setModalShow}
                          country={country}
                          collection={collection}
                        />
                      ))}
                    </div>
                  </div>
                  {/* normal card */}
                  <div className="xl:col-span-9">
                    <div className="h-auto w-[280px] sm:w-[664px] xl:w-[870px] 2xl:w-[990px] grid grid-cols-2 sm:grid-cols-3 mx-auto xl:grid-cols-4 justify-items-center gap-x-[20px] sm:gap-x-[62px] xl:gap-x-[50px] 2xl:gap-x-[90px] gap-y-[40px]">
                      {item.filteredNormalCards?.map((item, index) => (
                        <ProductNormalCard
                          key={index + item._id}
                          login={(user || customer)}
                          index={index}
                          item={item}
                          data={colorWayNormalCardArrayLength}
                          product={item}
                          uniteType={uniteType}
                          setQuantity={setQuantity}
                          customers={customers}
                          wishLists={wishLists}
                          navigate={navigate}
                          moveToWishList={moveToWishList}
                          carts={carts}
                          addSelectedProductsToCart={addSelectedProductsToCart}
                          getPriceByPricingMatrix={getPriceByPricingMatrix}
                          findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
                          cadMultiplier={cadMultiplier} addToCart={addToCart}
                          setProductImage={setProductImage}
                          setModalShow={setModalShow}
                          country={country}
                          collection={collection}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {multiPanel && (

                <div className="w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col items-center justify-center gap-[40px]">
                  {/* Big card */}
                  <div className="h-auto w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-y-[62px] sm:gap-y-[40px] sm:gap-x-[62px] xl:gap-x-[53.5px] 2xl:gap-x-[106.5px] items-center justify-items-center sm:px-[51px] xl:px-0">
                    {item.filteredBigCards?.map((item, index) => (
                      <ProductBigCard
                        key={index + item._id}
                        login={(user || customer)}
                        index={index}
                        item={item}
                        data={colorWayBigCardArrayLength}
                        multiBigCard={true}
                        product={item}
                        uniteType={uniteType}
                        setQuantity={setQuantity}
                        customers={customers}
                        wishLists={wishLists}
                        navigate={navigate}
                        moveToWishList={moveToWishList}
                        carts={carts}
                        addSelectedProductsToCart={addSelectedProductsToCart}
                        getPriceByPricingMatrix={getPriceByPricingMatrix}
                        findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
                        cadMultiplier={cadMultiplier} addToCart={addToCart}
                        setProductImage={setProductImage}
                        setModalShow={setModalShow}
                        country={country}
                        collection={collection}
                      />
                    ))}
                  </div>
                  {/* </div> */}
                  {/* normal card */}
                  <div className="h-auto w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid grid-cols-2 sm:grid-cols-3 mx-auto xl:grid-cols-5 justify-items-center grid-flow-row gap-y-[40px] gap-x-[20px] sm:gap-x-[22px] xl:gap-x-[55px] 2xl:gap-x-[90px] sm:px-[40px] xl:px-0">
                    {item.filteredNormalCards?.map((item, index) => (
                      <ProductNormalCard
                        key={index + item._id}
                        login={(user || customer)}
                        index={index}
                        item={item}
                        data={colorWayNormalCardArrayLength}
                        product={item}
                        uniteType={uniteType}
                        setQuantity={setQuantity}
                        customers={customers}
                        wishLists={wishLists}
                        navigate={navigate}
                        moveToWishList={moveToWishList}
                        carts={carts}
                        addSelectedProductsToCart={addSelectedProductsToCart}
                        getPriceByPricingMatrix={getPriceByPricingMatrix}
                        findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
                        cadMultiplier={cadMultiplier} addToCart={addToCart}
                        setProductImage={setProductImage}
                        setModalShow={setModalShow}
                        country={country}
                        collection={collection}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          );
        })}

        {/* NON-COLORWAY PRODUCT FOR NORMAL , BIGANDNORMAL , MULTI CARD */}
        <div className="mb-[40px] w-[280px] h-[96px] sm:w-[664px] sm:h-[96px] xl:w-[1160px] 2xl:w-[1320px] xl:h-[39px] grid grid-cols-1 sm:grid-cols-1 xl:grid-cols-3 gap-[30px] xl:gap-0 items-center justify-center xl:justify-between">
          <div className="col-span-1 hidden xl:flex h-[39px]"></div>
          <div className="col-span-1 h-[39px] flex items-center justify-center">
            <p className="font-bold text-center text-white"></p>
          </div>
        </div>

        {nonColorwayNormalCardSizeOnly && (
          <div className="-mt-[200px] xl:-mt-[100px] w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid grid-cols-2 sm:grid-cols-3 mx-auto xl:grid-cols-5 justify-items-center grid-flow-row gap-10">
            {filteredNormalCardsNonColorway?.map((item, index) => (
              <ProductNormalCard
                key={index + item._id}
                login={(user || customer)}
                index={index}
                product={item}
                uniteType={uniteType}
                data={nonColorWayNormalCardArrayLength}
                setQuantity={setQuantity}
                customers={customers}
                wishLists={wishLists}
                navigate={navigate}
                moveToWishList={moveToWishList}
                carts={carts}
                addSelectedProductsToCart={addSelectedProductsToCart}
                getPriceByPricingMatrix={getPriceByPricingMatrix}
                findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
                cadMultiplier={cadMultiplier} addToCart={addToCart}
                setProductImage={setProductImage}
                setModalShow={setModalShow}
                country={country}
                collection={collection}
              />
            ))}
          </div>
        )}

        {nonColorwayBigAndNormalCardSize && (
          <div className="-mt-[200px] xl:-mt-[100px] w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid xl:grid-cols-12 gap-10 xl:gap-[48px] 2xl:gap-[85px]">
            {/* Big card */}
            <div className="xl:col-span-3">
              <div className="h-auto w-[280px] sm:w-[664px] xl:w-[250px] grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 mx-auto justify-items-center grid-flow-row gap-10">
                {filteredBigCardsNonColorway?.map((item, index) => {
                  return <ProductBigCard
                    key={index + item._id}
                    login={(user || customer)}
                    index={index}
                    product={item}
                    uniteType={uniteType}
                    data={nonColorWayBigCardArrayLength}
                    multiBigCard={false}
                    setQuantity={setQuantity}
                    customers={customers}
                    wishLists={wishLists}
                    navigate={navigate}
                    moveToWishList={moveToWishList}
                    carts={carts}
                    addSelectedProductsToCart={addSelectedProductsToCart}
                    getPriceByPricingMatrix={getPriceByPricingMatrix}
                    findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
                    cadMultiplier={cadMultiplier} addToCart={addToCart}
                    setProductImage={setProductImage}
                    setModalShow={setModalShow}
                    country={country}
                    collection={collection}
                  />
                })}
              </div>
            </div>
            {/* normal card */}
            <div className="xl:col-span-9">
              <div className="h-auto w-[280px] sm:w-[664px] xl:w-[870px] 2xl:w-[990px] grid grid-cols-2 sm:grid-cols-3 mx-auto xl:grid-cols-4 justify-items-center grid-flow-row gap-y-10 gap-x-2">
                {filteredNormalCardsNonColorway?.map((item, index) => {
                  return (<ProductNormalCard
                    key={index + item._id}
                    login={(user || customer)}
                    index={index}
                    product={item}
                    uniteType={uniteType}
                    data={nonColorWayNormalCardArrayLength}
                    setQuantity={setQuantity}
                    customers={customers}
                    wishLists={wishLists}
                    navigate={navigate}
                    moveToWishList={moveToWishList}
                    carts={carts}
                    addSelectedProductsToCart={addSelectedProductsToCart}
                    getPriceByPricingMatrix={getPriceByPricingMatrix}
                    findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
                    cadMultiplier={cadMultiplier} addToCart={addToCart}
                    setProductImage={setProductImage}
                    setModalShow={setModalShow}
                    country={country}
                    collection={collection}
                  />)
                }
                )}
              </div>
            </div>
          </div>
        )}

        {nonColorwayMultiPanel && (
          <div className="-mt-[200px] xl:-mt-[100px] w-[280px] h-fit sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col justify-center gap-y-10">
            {/* Big card */}
            <div className="h-auto w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 mx-auto justify-items-center grid-flow-row gap-y-[62px] sm:gap-y-10">
              {filteredBigCardsNonColorway?.map((item, index) => (
                <ProductBigCard
                  key={index + item._id}
                  login={(user || customer)}
                  index={index}
                  product={item}
                  uniteType={uniteType}
                  data={nonColorWayBigCardArrayLength}
                  multiBigCard={true}
                  setQuantity={setQuantity}
                  customers={customers}
                  wishLists={wishLists}
                  navigate={navigate}
                  moveToWishList={moveToWishList}
                  carts={carts}
                  addSelectedProductsToCart={addSelectedProductsToCart}
                  getPriceByPricingMatrix={getPriceByPricingMatrix}
                  findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
                  cadMultiplier={cadMultiplier} addToCart={addToCart}
                  setProductImage={setProductImage}
                  setModalShow={setModalShow}
                  country={country}
                  collection={collection}
                />
              ))}
            </div>
            {/* normal card */}
            <div className="h-auto w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] grid grid-cols-2 sm:grid-cols-3 mx-auto xl:grid-cols-5 justify-items-center grid-flow-row gap-y-10 gap-x-2">
              {filteredNormalCardsNonColorway?.map((item, index) => (
                <ProductNormalCard
                  key={index + item._id}
                  login={(user || customer)}
                  index={index}
                  product={item}
                  uniteType={uniteType}
                  data={nonColorWayNormalCardArrayLength}
                  setQuantity={setQuantity}
                  customers={customers}
                  wishLists={wishLists}
                  navigate={navigate}
                  moveToWishList={moveToWishList}
                  carts={carts}
                  addSelectedProductsToCart={addSelectedProductsToCart}
                  getPriceByPricingMatrix={getPriceByPricingMatrix}
                  findProductMultiplierAndFormatArray={findProductMultiplierAndFormatArray}
                  cadMultiplier={cadMultiplier} addToCart={addToCart}
                  setProductImage={setProductImage}
                  setModalShow={setModalShow}
                  country={country}
                  collection={collection}
                />
              ))}
            </div>
          </div>
        )}
      </div >
    </>


  );
};
export default ProductDetail;