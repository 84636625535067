import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CustomerService } from "../../helpers/StorageServices";
import { BrandIconContext } from "../../states/contexts/BrandIconContext";
import { CustomerContext } from "../../states/contexts/CustomerContext";
import { UserContext } from "../../states/contexts/UserContext";
import { useForm } from "react-hook-form";
import { showMessage } from "../../helpers/Utils";
import ApiService from "../../helpers/ApiServices";
import { links } from "../../components/shared/headers/HomePageContent/Link";
import { useAlert } from 'react-alert'
const _ = require("lodash")

export default function RegionCustomers() {
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const [state, setState] = useState(null)
  const [isRegionSelected, setIsRegionSelected] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [customerList, setCustomerList] = useState(null)
  const { dispatch, customer } = useContext(CustomerContext)
  const { user } = useContext(UserContext)

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const alert = useAlert()

  let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))
  let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"

  const { register, control, reset, handleSubmit, getValues, setValue, watch, formState: { errors, isSubmitting } } = useForm();

  const setTestCustomer = (customers, array) => {
    return array;
  }

  const getRegion = async () => {
    let options = [];
    let regions = [];

    try {
      ApiService.setHeader()
      const response11 = await ApiService.get(`shop/customlist/titles?titles=["Territory Name USA","Territory Name Canada","NCS Export USA List","NCS Export CAD List"]`)

      const response = await ApiService.get(`shop/customer/region?userId=${user?.netsuiteId}&userType=${user?.userTypes[0]}&subsidiaryId=${user?.subsidiary?.value}&brandType=NORTHCOTT`)

      Array.prototype.push.apply(regions, [{ _id: "-CANADA-", name: "-CANADA-" }])
      Array.prototype.push.apply(regions, response11?.data?.documents[0]?.options)
      Array.prototype.push.apply(regions, response11?.data?.documents[2]?.options)

      Array.prototype.push.apply(regions, [{ "_id": "-USA-", "name": "-USA-" }])
      Array.prototype.push.apply(regions, response11?.data?.documents[1]?.options)
      Array.prototype.push.apply(regions, response11?.data?.documents[3]?.options)

      if (user?.userTypes.includes("POWER_USER") || user?.userTypes.includes("CUSTOMER_SERVICE")) {
        if (user?.subsidiary?.text.split(" ")[user?.subsidiary?.text.split(" ")?.length - 1].toUpperCase() === "USA") {
          Array.prototype.push.apply(options, [{ "_id": "-USA-", "name": "-USA-" }])
          Array.prototype.push.apply(options, response11?.data?.documents[1]?.options)
          Array.prototype.push.apply(options, response11?.data?.documents[3]?.options)

        } else if (user?.subsidiary?.text.split(" ")[user?.subsidiary?.text.split(" ")?.length - 1].toUpperCase() === "CANADA") {
          Array.prototype.push.apply(options, [{ _id: "-CANADA-", name: "-CANADA-" }])
          Array.prototype.push.apply(options, response11?.data?.documents[0]?.options)
          Array.prototype.push.apply(options, response11?.data?.documents[2]?.options)
        }

        const array = setTestCustomer(customers, options)

        setState(array)

      } else if (user?.userTypes.includes("SALES_REP") || user?.userTypes.includes("SALES_MANAGER")) {
        let cadRegions = [], usdRegions = []

        for (let region of response11?.data?.documents[1]?.options) {
          for (let filterRegion of response.data.regions) {
            if (region?.name == filterRegion?.name)
              cadRegions.push(filterRegion)
          }
        }

        for (let region of response11?.data?.documents[0]?.options) {
          for (let filterRegion of response.data.regions) {
            if (region?.name === filterRegion?.name)
              usdRegions.push(filterRegion)
          }
        }

        Array.prototype.push.apply(options, [{ _id: "-CANADA-", name: "-CANADA-" }])
        Array.prototype.push.apply(options, response.data.uniqueExportCadArray)
        Array.prototype.push.apply(options, cadRegions)

        Array.prototype.push.apply(options, [{ "_id": "-USA-", "name": "-USA-" }])
        Array.prototype.push.apply(options, response.data.uniqueExportUsaArray)
        Array.prototype.push.apply(options, usdRegions)

        const array = setTestCustomer(customers, options)

        setState(array)

      } else {
        const array = setTestCustomer(customers, regions)
        setState(array)
      }

    } catch (error) {
      showMessage(error.response.data.message, "error")
      alert.error(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    getRegion()
  }, []);

  const regionChangeHandler = async (event, data) => {
    switch (event.target?.value) {
      case "--TEST ACCOUNT--||--TEST ACCOUNT--":
        setIsRegionSelected(false);
        showMessage("Please select a region!", "warning")
        return false;
        break;

      case "--SELECT A REGION--||--SELECT A REGION--":
        setIsRegionSelected(false);
        showMessage("Please select a region!", "warning")
        return false;
        break;

      case "-CANADA-||-CANADA-":
        setIsRegionSelected(false);
        showMessage("Please select a region!", "warning")
        return false;
        break;

      case "-USA-||-USA-":
        setIsRegionSelected(false);
        showMessage("Please select a region!", "warning")
        return false;
        break;

      default:
        break;
    }

    if (event.target?.value.split("||")[1].includes("TEST")) {
      let array = new Array()

      // Load the selected customer into reducer
      dispatch({ type: "SELECT_CUSTOMER", payload: customers[0] });

      if (localStorage.getItem("PCTeRP.CUSTOMER_IDS") == null) {
        array.push(customers[0])

        // Save the customer array into localStorage, so that after refresh we can get the customer
        localStorage.setItem("PCTeRP.CUSTOMER_IDS", JSON.stringify(array))

      } else if (JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS")).length) {

        // Parse the string array to an actual array
        array = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))
        array.push(customers[0])

        // Save the customer array into localStorage, so that after refresh we can get the customer
        localStorage.setItem("PCTeRP.CUSTOMER_IDS", JSON.stringify(array))
      }

      // navigate('/product-category')
      navigate(`/?division=${division}`)
      return
    }


    const region = event.target?.value?.split('||')[1];

    try {
      const response = await ApiService.get(`shop/customer/customer?region=${region}&userId=${user?.netsuiteId}&userType=${user?.userTypes[0]}&subsidiaryId=${user?.subsidiary?.value}&brandType=NORTHCOTT`)
      setIsRegionSelected(true);

      setCustomerList(response.data.documents?.filter(ele => ele?.isPrimary)?.sort(function (a, b) {
        if (a.companyName < b.companyName) { return -1; }
        if (a.companyName > b.companyName) { return 1; }
        return 0;
      }));
    } catch (error) {
      showMessage(error.response?.data.message, "error")
      alert.error(error.response?.data.message)
    }

  }

  const customerChangeHandler = async (event, data) => {
    const id = event.target.value?.split('||')[0]
    setCustomerId(id);
  }


  const onSubmit = async () => {
    setSubmitButtonDisabled(true)
    let array = new Array()

    try {
      const response = await ApiService.get(`shop/customer/customer?id=${customerId}&userType=ID`);
      const customer = response?.data.documents;
      dispatch({ type: "SELECT_CUSTOMER", payload: customer });
      if (user && (user?.userTypes?.includes("CUSTOMER") || response?.data.documents)) {
        links?.map(link => {
          if (link?.name == "Customer")
            link['isVisible'] = true
        })
      }
      if (user && (!user?.userTypes?.includes("CUSTOMER") || !user?.userTypes?.includes("SALES_REP"))) {
        links?.map(link => {
          if (link?.name == "Rapid Order")
            link['isVisible'] = true
        })
      }
      setSubmitButtonDisabled(false)

      // Push customer into an array
      if (localStorage.getItem("PCTeRP.CUSTOMER_IDS") == null || !JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS")).length) {
        array.push(customer)

        // Save the customer array into localStorage, so that after refresh we can get the customer
        localStorage.setItem("PCTeRP.CUSTOMER_IDS", JSON.stringify(array))

      } else if (JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS")).length) {
        // Parse the string array to an actual array
        array = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))

        // Check the array if it is unique or not
        const result = array?.filter(e => e?.email == customer?.email);
        if (result?.length) {
          _.remove(array, function (e) {
            return e?.email == customer?.email
          })
        }
        array.push(customer)
        localStorage.setItem("PCTeRP.CUSTOMER_IDS", JSON.stringify(array))
      }


      navigate(`/?division=${division}`)
    } catch (error) {
      setSubmitButtonDisabled(false)
      showMessage(error.response.data.message, "error")
      alert.error(error?.response?.data?.message)
    }
  }

  return (
    <div
      style={{ fontFamily: "Open Sans" }}
      className="flex items-center justify-center my-[80px] sm:my-[100px]"
    >
      <div className="w-[280px] h-[446px] sm:w-[624px] sm:h-[396px] xl:w-[1160] 2xl:w-[1608] flex flex-col justify-center items-center gap-10">
        {/* top headings */}
        <div className="w-[280px] h-[122px] sm:w-[624px] sm:h-[72px] xl:w-[1160] 2xl:w-[1608] flex flex-col items-center justify-center gap-[10px]">
          <h2 className="w-[280px] h-[76px] sm:w-[624px] sm:h-[44px] xl:w-[1160] 2xl:w-[1608] text-center text-[28px] sm:text-[32px] font-bold text-gray-900 tracking-wider sm:tracking-normal">
            Regions & Customers
          </h2>
          <p className="w-[280px] h-[36px] sm:w-[624px] sm:h-[18px] xl:w-[1160] 2xl:w-[1608] text-center text-xs text-gray-600 ">
            Please select a region and a customer you would like to place orders
            for below.
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[280px] h-[284px] sm:w-[320px] flex flex-col justify-center items-center">
            <div className="w-[280px] h-[172px] sm:w-[320px] flex flex-col items-center justify-center gap-5">
              {/* region drop down */}
              <div className="w-[280px] h-[76px] sm:w-[320px] flex flex-col items-center justify-between">
                <label
                  htmlFor="region"
                  className="block text-sm font-bold text-gray-700 h-[21px] text-start w-[280px] sm:w-[320px]"
                >
                  Region*
                </label>
                <div className="h-[45px]">
                  <select
                    id="region"
                    name="region"
                    onChange={regionChangeHandler}
                    className="w-[280px] sm:w-[320px] appearance-none rounded relative block px-3 py-2 border text-gray-900 focus:outline-none focus:z-10 sm:text-sm"
                  >
                    <option value="">Select a region</option>
                    {state?.length && state.map((option, idx) => {
                      if (option?.name?.startsWith("-")) {
                        return <option key={idx} value={option?._id + "||" + option?.name} style={{ fontWeight: 500 }}>{option?.name}</option>
                      } else {
                        return <option key={idx} value={option?._id + "||" + option?.name} >{option?.name}</option>
                      }
                    })}
                  </select>
                </div>
              </div>

              {/* customer dropdown */}
              {isRegionSelected && <div className="w-[280px] h-[76px] sm:w-[320px] flex flex-col justify-between">
                <label
                  htmlFor="customer"
                  className="block text-sm font-bold text-gray-700 h-[21px] w-[280px] sm:w-[320px]"
                >
                  Customers*
                </label>
                <div className="h-[45px]">
                  <select
                    id="customer"
                    name="customer"
                    onChange={customerChangeHandler}
                    className="w-[280px] sm:w-[320px] placeholder:text-gray-100 appearance-none rounded-md relative block px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:z-10 sm:text-sm"
                  >
                    <option value="">Select a customer</option>

                    {customerList?.length && customerList?.map((option, index) => {
                      if (option?.name?.startsWith("-")) {
                        return <option key={option?._id} value={option?._id + "||" + option?.name} style={{ fontWeight: 500 }}>{option?.name}</option>
                      } else if (option?.city != undefined) {
                        return <option key={option?._id} value={option?._id + "||" + option?.name} >{`${option?.companyName} - ${option?.customerId} - ${option?.city} - (${(option.isExport && option.exportUSA?.length) ? option?.exportUSA.map(ele => ele.text) : (option.isExport && option.exportCAD?.length) ? option?.exportCAD.map(ele => ele.text) : option?.state}) - ${option?.name}`}</option>
                      }
                    })}
                  </select>
                </div>
              </div>}
            </div>
            <div className="mt-[30px] w-[290px] sm:w-[320px] h-[82px] flex flex-col items-center justify-between">
              {/* button */}
              <div className="w-[280px] h-[49px] sm:w-[320px] flex items-center justify-center">
                <button
                  type="submit"
                  disabled={!customerId || submitButtonDisabled}
                  className={`w-[280px] h-[49px] sm:w-[320px] uppercase text-base font-semibold rounded text-white ${(!customerId || submitButtonDisabled) ? "bg-[#EECB9A]" : "bg-[#E2A856] cursor-pointer"}`}
                >
                  Submit
                </button>
              </div>
              {/*return to login */}
              <div className="flex text-nowrap justify-center text-[#6B6B66] text-center cursor-pointer text-xs h-[18px]">
                <Link to={"/login"}>Return to sign in</Link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
