import { React, useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Controller } from 'react-hook-form';
import ApiService from '../../../helpers/ApiServices';
import '../../../components/elements/fields/DropDown.css'
import 'react-bootstrap-typeahead/css/Typeahead.css';

export default function ObjectSelectField({ register, control, field, states, errors, queryPath, index, multiple, changeHandler, blurHandler }) {
  const [state, setState] = useState([]);

  const getList = async () => {

    ApiService.setHeader();
    const response = await ApiService.get(`/${field?.selectRecordType}`);
    if (response.data.isSuccess) {
      if (response.data?.document?.options) {
        setState(response.data?.document.options)
      } else {
        setState(response.data?.documents)
      }

    }
  }

  useEffect(() => {
    getList();
  }, []);


  return (
    <div>
      <p className="block text-sm font-bold text-gray-700 h-[21px] text-start w-[280px] sm:w-[320px]">{field?.label}</p>
      <Controller
        name={field?.fieldId}
        control={control}
        rules={{
          required: {
            value: field?.required,
            message: field?.validationMessage || 'This field is required'
          }
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <div className="w-[280px] h-[76px] sm:w-[320px] flex flex-col items-center justify-between">
              <select
                required
                id="pct_objectSelect"
                multiple={field?.multiple}
                disabled={field?.disabled}
                onChange={(event) => {
                  const selectedOptions = Array.from(event.target.selectedOptions, option => ({
                    value: option.value,
                    text: option.text
                  }));
                  onChange(field?.multiple ? selectedOptions : selectedOptions[0]);

                  if (changeHandler) {
                    changeHandler(selectedOptions, {
                      type: field.type,
                      id: field.fieldId,
                      value: field?.multiple ? selectedOptions : selectedOptions[0]
                    });
                  }
                }}
                value={field?.multiple ? value.map(v => v.value) : value?.value || ""}
                className={`form-select ${error ? 'is-invalid text-red-500 text-[8px]' : 'w-[280px] sm:w-[320px] appearance-none relative block px-3 py-2 border border-[#CECECC] text-black focus:outline-none focus:z-10 sm:text-sm'}`}
              >
                <option value="" disabled className=''>{field?.placeholder || "Select an option"}</option>
                {state?.map((option, idx) => {
                  return (
                    <option key={idx} value={option.value}>
                      {option.text || option.name}
                    </option>
                  )
                })}
              </select>
              {error && <div className="invalid-feedback text-red-400 text-[8px]">{error.message}</div>}
            </div>
          )
        }}
      />

    </div>)
}

