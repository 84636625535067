import React, { useContext, useEffect, useState } from "react";
import CartProduct from "../../assets/ShoppingCart/CartProduct.svg";
import calendar_month from "../../assets/ShoppingCart/calendar_month.svg";
import { CustomerContext } from "../../states/contexts/CustomerContext";
import { CartContext } from "../../states/contexts/CartContext";
import { BrandIconContext } from "../../states/contexts/BrandIconContext";
import { UserContext } from "../../states/contexts/UserContext";
import { useNavigate, useParams, Link } from "react-router-dom";
import DisplayOderItems from "../../components/elements/components/DisplayOderItems";
import { getModelRoute, showMessage } from "../../helpers/Utils";
import ApiService from "../../helpers/ApiServices";
import moment from "moment";

const OrderDetails = () => {
  // All useStates
  const [state, setState] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [carts, setCarts] = useState([]);
  const [actualTot, setActualTot] = useState('');
  const [totalDiscount, setTotalDiscount] = useState('');

  // All reducers
  const { dispatch: customerDispatch, customer } = useContext(CustomerContext)
  const { dispatch: cartDispatch, quantity } = useContext(CartContext)
  const { backgroundColor, color } = useContext(BrandIconContext)
  const { dispatch, user } = useContext(UserContext)
  // const { dispatch: cartDispatch, quantity } = useContext(CartContext)

  const navigate = useNavigate()
  const { id } = useParams();

  // All local storage data
  let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))
  let uniteType = ''

  const getOrder = () => {
    setLoadingStatus(true)

    ApiService.setHeader()
    return ApiService.get(`shop/order/${id}?protectModel=${getModelRoute(user).model}`).then(async response => {
      if (response?.data.isSuccess) {
        console.log("ele", response.data.document);

        // let filteredCartItems = response.data?.document?.cartItems?.filter(ele => ele?.productQuantity !== 0)
        // response.data?.document['cartItems'] = filteredCartItems

        const actualTot = response?.data.document?.cartItems?.reduce(function getSum(total, cart) {
          // return total + cart?.price;
          return (parseFloat(total) + (parseFloat(cart?.productQuantity) * parseFloat(cart?.price))).toFixed(2);
        }, 0)
        setActualTot(actualTot)
        setTotalDiscount(parseFloat(parseFloat(actualTot) - parseFloat(response.data.document?.total))?.toFixed(2))

        // Format cart items
        response?.data.document?.cartItems?.map(ele => {
          // ele.productQuantity = formatData(ele).productQuantity
          // ele.multipliers = formatData(ele).multipliers
          ele.productQuantity = `${ele.productQuantity}/ ${ele?.productId?.primaryUnitsType == "Each" ? "e" : uniteType == 'm' ? 'Metre(s)' : 'Yard(s)'}`
          ele.price = `$${ele.price}/ ${ele?.productId?.primaryUnitsType == "Each" ? "e" : uniteType}`
          ele.subTotal = `$${ele.subTotal}`
          // ele.subTotal = ele?.subTotal
        })

        setState(response.data.document)

        // const addr = response.data?.document.addresses.filter(address => {
        //     console.log(address);
        //     return true
        // })
        // console.log(addr);

        setLoadingStatus(false)
      }
    }).catch(error => {
      console.log(error.response?.data)
      showMessage(error.response?.data.message, "error")
    })
  }

  const getCartDetail = () => {
    ApiService.setHeader()
    ApiService.get(`shop/cart/${customers[customers?.length - 1]?._id}?protectModel=${getModelRoute(user).model}`).then(response => {

      if (response?.data.isSuccess) {

        setCarts(response?.data.documents)
        cartDispatch({ type: "ADD_TO_CART_QUANTITY", payload: response?.data.documents?.length });
      }
    }).catch(error => {
      console.log(error.response.data)

    })
  }

  const addItemsToCart = async () => {
    state?.cartItems.map(cart => {

      ApiService.setHeader()
      ApiService.post(`shop/cart?protectModel=${getModelRoute(user).model}`, cart).then(response => {
        // console.log(response.data);
        if (response?.data.isSuccess) {
          // console.log(response?.data.document);
          getCartDetail()

          showMessage("Item added", "success")
        }
      }).catch(error => {
        console.log(error.response.data)
        // console.log("Else Catch")
        // showMessage(error.response.data.message, "error")
      })

    })
  }

  useEffect(() => {
    if (customers != null) getOrder()

    if (customers !== null) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        uniteType = 'y'
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
        uniteType = 'm'
    }
  }, []);

  console.log(state?.shippingDate);



  return (

    // WORKING
    // <div
    //   className="mb-20 max-w-[1320px] min-w-[320px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col gap-10 xl:gap-0 xl:flex-col xl:mx-auto items-center justify-center"
    //   style={{ fontFamily: "Open Sans" }}
    // >
    //   {/* shoppingcart heading desktop only*/}
    //   <div className="hidden w-[1024px] xl:flex xl:w-[1160px] 2xl:w-[1320px] xl:h-[85px] items-center justify-center xl:mt-[100px] xl:mb-[60px]">
    //     <div>
    //       <p className="xl:text-[32px] font-bold mx-auto text-center">
    //         Thank You!
    //       </p>
    //       <p className="text-[10] text-gray-400 mx-auto">
    //         Your order has been placed and you will receive a confirmation email
    //         shortly.
    //       </p>
    //     </div>
    //   </div>

    //   {/* orderdetail and summary for desktop */}
    //   {/* <div className="flex flex-col xl:flex-row xl:gap-10"> */}

    //   <div className="flex flex-col xl:flex-row xl:gap-10 2xl:gap-20">
    //     {/* orders details */}
    //     <div className="mx-auto w-[320px] sm:w-[685px] xl:w-[640px] mt-20 xl:mt-0 sm:rounded-lg">
    //       {/* top section */}
    //       <div className="flex flex-col items-center justify-center">
    //         {/* shoppingcart heading mobile */}
    //         <div className="w-[320px] sm:w-[685px] text-black h-[100px] sm:h-[85px] flex xl:hidden flex-col justify-center">
    //           <div className="flex flex-col gap-5">
    //             <p className="text-[28px] sm:text-[32px] w-[280px] h-[38px] sm:h-[44px] font-bold mx-auto text-center">
    //               Thank You!
    //             </p>
    //             <p className="text-[10] h-[42px] sm:h-[21px] tracking-tight mx-auto text-center">
    //               Your order has been placed and you will receive a confirmation
    //               email shortly.
    //             </p>
    //           </div>
    //         </div>
    //         {/* top button */}
    //         <div className="mt-[60px] xl:mt-0 min-w-[280px] w-[280px] sm:w-[640px] xl:w-[1160px] xl:mx-auto">
    //           <div className="h-[71px] sm:h-[34px] min-w-[280px] w-[280px] sm:w-[640px] xl:w-[1160px] flex flex-col sm:flex-row items-center justify-center sm:justify-between gap-[10px] sm:gap-0">
    //             {/* text */}
    //             <p
    //               type="button"
    //               className="w-[195px] h-[27px] uppercase font-bold text-lg text-nowrap"
    //             >
    //               your order details
    //             </p>
    //             {/* button */}
    //             <Link to={"/"} className="cursor-pointer z-50">
    //               <button
    //                 type="button"
    //                 className="w-[154px] h-[34px] text-white bg-[#E2A856] font-medium rounded text-[12px] hover:bg-black"
    //               >
    //                 CONTINUE SHOPPING
    //               </button>
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //       {/* table 1 */}
    //       <div className="w-[280px] sm:w-[640px] xl:w-[685px] mx-auto mt-5">
    //         <div className="w-[280px] sm:w-[640px] xl:w-[685px] flex flex-col items-center justify-center border-2">
    //           {/* date picker */}
    //           <div className="w-[240px] h-[80px] sm:h-[72px] sm:w-[600px] xl:w-[645px] md:flex-row md:justify-start mt-10 sm:mt-5">
    //             <div className="w-[240px] h-[72px] sm:w-[600px] xl:w-[645px] flex flex-col">
    //               {/* row 1 for all */}
    //               <div className="w-[240px] h-[44px] sm:w-[600px] xl:w-[645px] flex flex-col sm:flex-row items-center justify-center sm:justify-between">
    //                 {/* shipping date */}
    //                 <div className="mt-2 w-[252px] h-[44px] flex flex-col items-center justify-center gap-3 sm:gap-0 sm:flex-row">
    //                   <p className="text-[14px] w-[102px] h-[21px] font-bold text-nowrap">
    //                     Shipping Date:
    //                   </p>
    //                   <div className="w-[240px] h-[41px] flex items-center justify-center">
    //                     <img
    //                       src={calendar_month}
    //                       alt="Calender"
    //                       className="flex sm:hidden"
    //                     />
    //                     <p className="text-[14px] font-bold">
    //                       {moment(state?.shippingDate).format('DD-MMM-YYYY').replaceAll("-", " ")}
    //                     </p>
    //                   </div>
    //                 </div>
    //                 {/* wb order no */}
    //                 <div className="mt-5 sm:mt-0 text-[10px] text-gray-800 flex items-center justify-center sm:justify-between sm:w-[154px] sm:h-[15px] ">
    //                   <div className="w-[96px] h-[15px]">
    //                     <p>Web Order Number:</p>
    //                   </div>
    //                   <div className="w-[53px] h-[15px]">
    //                     {/* <p>CCHRP0-18</p> */}
    //                     <p>{state?.orderNumber}</p>
    //                   </div>
    //                 </div>
    //               </div>
    //               {/* horizontal line */}
    //               <div className="mt-4 h-[1px] bg-gray-400 w-[250px] sm:w-[160px] flex sm:hidden"></div>
    //               {/* row 2 for all */}
    //               <div className="mt-1 flex w-[240px] h-[35px] sm:h-[15px] text-[10px] text-gray-700 gap-2 items-center justify-center">
    //                 <p className="w-[54px] h-[15px]">Instruction: </p>
    //                 <p className="w-[132px] sm:w-[586px] h-[15px]">
    //                   {/* Lorem ipsum dolor sit amet. */}
    //                   {state?.specialInstruction !== null ? state?.specialInstruction : ""}
    //                 </p>
    //               </div>
    //             </div>
    //           </div>
    //           {/* table */}
    //           <table className="w-[240px] sm:w-[600px] xl:w-[645px] text-[14px] text-left rtl:text-right text-gray-500 flex flex-col">
    //             <thead className="text-[12px] text-gray-500 sm:w-[600px] xl:w-[645px] sm:h-[28px] sm:items-center sm:justify-center">
    //               <tr className="sm:border-b-2 w-[240px] h-[18px] sm:w-[600px] xl:w-[645px] sm:h-[28px] flex flex-row items-center justify-end sm:justify-normal sm:gap-[10px]">
    //                 <th
    //                   scope="col"
    //                   className="sm:w-[55px] h-[18px] hidden sm:flex items-center justify-center me-[10px]"
    //                 >
    //                   <span className="">Image</span>
    //                 </th>
    //                 <th
    //                   scope="col"
    //                   className="sm:w-[180px] xl:w-[200px] h-[18px] hidden sm:flex items-center justify-center px-4"
    //                 >
    //                   Product Details
    //                 </th>
    //                 <th
    //                   scope="col"
    //                   className="sm:w-[80px] xl:w-[90px] h-[18px] hidden sm:flex items-center sm:ms-5 xl:ms-0"
    //                 >
    //                   Price
    //                 </th>
    //                 <th
    //                   scope="col"
    //                   className="sm:w-[80px] xl:w-[90px] h-[18px] hidden sm:flex items-center xl:me-0"
    //                 >
    //                   Qty
    //                 </th>
    //                 <th
    //                   scope="col"
    //                   className="sm:w-[80px] xl:w-[90px] h-[18px] hidden sm:flex items-center"
    //                 >
    //                   Discount
    //                 </th>
    //                 <th
    //                   scope="col"
    //                   className="sm:w-[80px] xl:w-[90px] h-[18px] hidden sm:flex items-center justify-center"
    //                 >
    //                   Subtotal
    //                 </th>
    //               </tr>
    //             </thead>
    //             <tbody className=" border-gray-400">
    //               {
    //                 state?.cartItems?.length ? state?.cartItems.map((cart, idx) => {
    //                   // console.log(cart)
    //                   return < DisplayOderItems key={cart?._id} ele={cart} CartProduct={CartProduct} />
    //                 }) : null
    //               }

    //             </tbody>
    //           </table>
    //           {/* coupon code and instructor */}
    //           <div className="my-3 w-[240px] h-[74px] sm:w-[600px] sm:h-[86px] xl:w-[645px] xl:h-[86px] bg-white">
    //             {/* collection total */}
    //             <div className="w-[240px] h-[44px] sm:w-[600px] xl:w-[645px] sm:h-[50px] text-[10px] sm:flex sm:flex-col sm:items-end">
    //               <div className="flex justify-between sm:w-[314px] sm:h-[18px]">
    //                 <p>Collection Subtotal:</p>
    //                 <p>$ {state?.total?.toFixed(2)}</p>
    //               </div>
    //               {/* <div className="flex justify-between text-red-400 sm:w-[314px] sm:h-[18px]">
    //               <p>Discount (Promo name) applied:</p>
    //               <p>-$ 00.00</p>
    //             </div>
    //             <div className="flex justify-between text-red-400 sm:w-[314px] sm:h-[18px]">
    //               <p>Discount (Promo name) applied:</p>
    //               <p>-$ 00.00</p>
    //             </div> */}
    //               <div className="mt-2 flex justify-between font-bold text-[14px] sm:w-[314px] sm:h-[24px]">
    //                 <p>Collection Total:</p>
    //                 <p>$ {state?.total?.toFixed(2)}</p>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     {/* order summary and shipping details */}
    //     <div className="flex flex-col gap-10">
    //       {/* order summary */}
    //       <div className="w-[320px] h-[380px] sm:w-[740px] sm:h-[362px] 2xl:w-[435px] 2xl:h-[283px] mt-10 xl:mt-0">
    //         <div className="mx-auto w-[280px] h-[380px] sm:w-[500px] sm:h-[362px] xl:w-[435px] xl:h-[283px] xl:mt-[54px] xl:mb-[89px] border-2 mb-20 flex flex-col">
    //           <div className="flex flex-col items-center justify-center gap-10 my-5 sm:my-0 xl:w-[435px] xl:h-[283px]">
    //             {/* row1 */}
    //             <div className="sm:mt-5 xl:mt-0 w-[240px] h-[57px] sm:w-[460px] xl:w-[395px] xl:h-[57px] flex items-center justify-center sm:justify-start uppercase font-bold text-[18px] border-b">
    //               Order Summary
    //             </div>
    //             {/* row2 */}
    //             <div className="w-[240px] h-[154px] sm:w-[460px] sm:h-[136px] xl:w-[395px] xl:h-[154px]">
    //               <div className="flex justify-between text-base">
    //                 <p className="">Subtotal:</p>
    //                 <p>${state?.total?.toFixed(2)}</p>
    //               </div>
    //               <div className="flex justify-between text-red-400">
    //                 <p className="">Discount:</p>
    //                 <p>$000.00</p>
    //               </div>
    //               <div className="mt-1 font-bold flex justify-between">
    //                 <p className="">Total:</p>
    //                 <p>${state?.total?.toFixed(2)}</p>
    //               </div>
    //               <div className="w-[240px] sm:w-[460px] sm:h-[36px] xl:w-[395px] h-[36px] mt-5 text-gray-400 text-[12px]">
    //                 <ul className="ms-5">
    //                   <li className="list-disc">
    //                     prices and product availability are subject to change.
    //                     Total does not include applicable taxes or delivery. All
    //                   </li>
    //                 </ul>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       {/* shipping details */}
    //       <div className="w-[320px]  sm:w-[740px] 2xl:w-[435px] mt-10 xl:mt-0">
    //         <div className="mx-auto w-[280px] sm:w-[500px]  xl:w-[435px]  xl:mt-[54px] xl:mb-[89px] border-2 mb-20 flex flex-col">
    //           <div className="flex flex-col items-center justify-center gap-5 my-5 sm:my-0 xl:w-[435px]">
    //             {/* row1 name only*/}
    //             <div className="sm:mt-5 xl:mt-0 w-[240px] h-[57px] sm:w-[460px] xl:w-[395px] xl:h-[57px] flex items-center justify-center sm:justify-start uppercase font-bold text-[18px] border-b">
    //               SHipping Details
    //             </div>
    //             {/* row2 */}
    //             <div className="w-[240px] h-[66px] sm:w-[460px] sm:h-[51px] xl:w-[395px] xl:h-[51px] flex items-center text-base border-b gap-1">
    //               <p className="w-[65px] h-[36px] sm:w-[125px] font-bold sm:h-[21px] text-[12px]">
    //                 Order Date:
    //               </p>
    //               <p className="w-[90px] h-[18px] sm:w-[105px] sm:h-[21px] text-[12px]">
    //                 {/* 15 January 2024 */}
    //                 {moment(state?.createdat).format('DD-MMMM-YYYY').replaceAll("-", " ")}
    //               </p>
    //             </div>
    //             {/* row3 */}
    //             <div className="w-[240px] h-[66px] sm:w-[460px] sm:h-[72px] xl:w-[395px] xl:h-[72px] flex items-center text-base border-b gap-1">
    //               <p className="w-[65px] h-[36px] sm:w-[125px] sm:h-[42px] text-[12px] font-bold">
    //                 Web Order Number:
    //               </p>
    //               <p className="w-[170px] h-[36px] sm:w-[236px] sm:h-[21px] text-[12px]">
    //                 {state?.orderNumber}
    //               </p>
    //             </div>
    //             {/* row4 */}
    //             <div className="w-[240px] h-[66px] sm:w-[460px] sm:h-[72px] xl:w-[395px] xl:h-[72px] flex items-center text-base border-b gap-1">
    //               <p className="w-[65px] h-[36px] sm:w-[125px] sm:h-[42px] text-[12px] font-bold">
    //                 Sales Order Number:
    //               </p>
    //               <p className="w-[170px] h-[36px] sm:w-[236px] sm:h-[21px] text-[12px]"> {state?.proOrderNumber}</p>
    //             </div>
    //             {/* row5 */}
    //             <div className="w-[240px] h-[66px] sm:w-[460px] sm:h-[51px] xl:w-[395px] xl:h-[51px] flex items-center text-base border-b gap-1">
    //               <p className="w-[65px] h-[36px] sm:w-[125px] font-bold sm:h-[21px] text-[12px]">
    //                 Order Status:{" "}
    //               </p>
    //               <p className="w-[90px] h-[18px] sm:w-[105px] sm:h-[21px] text-[12px]">
    //                 {/* Processing{" "} */}
    //                 {state.status}
    //               </p>
    //             </div>

    //             {/* row6 */}
    //             <div className="w-[240px] h-[66px] sm:w-[460px] sm:h-[51px] xl:w-[395px] xl:h-[51px] flex items-center text-base border-b gap-1">
    //               <p className="w-[65px] h-[36px] sm:w-[125px] font-bold sm:h-[21px] text-[12px]">
    //                 Bill To:{" "}
    //               </p>
    //               <p className="w-[90px] h-[18px] sm:w-[105px] sm:h-[21px] text-[12px]">
    //                 {/* Processing{" "} */}
    //                 <pre style={{ lineHeight: "70%" }}>{state.billTo}</pre>
    //               </p>
    //             </div>
    //             {/* row7 */}
    //             <div className="w-[240px] h-[66px] sm:w-[460px] sm:h-[51px] xl:w-[395px] xl:h-[51px] flex items-center text-base border-b gap-1">
    //               <p className="w-[65px] h-[36px] sm:w-[125px] font-bold sm:h-[21px] text-[12px]">
    //                 Ship To:{" "}
    //               </p>
    //               <p className="w-[90px] h-[18px] sm:w-[105px] sm:h-[21px] text-[12px]">
    //                 {/* Processing{" "} */}
    //                 <pre style={{ lineHeight: "70%" }}>{state.shipTo}</pre>

    //               </p>
    //             </div>
    //           </div>
    //           {/* row last */}
    //           <div>
    //             <ul className="ms-5 w-[240px] h-[54px] sm:w-[460px] sm:h-[26px] xl:w-[395px] xl:h-[36px] text-gray-500 text-[12px]">
    //               <li className="list-disc">
    //                 NOTE: If the address we have on file is incorrect, please
    //                 contact us at 1-800-268-1466
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>


    <div
      className="mb-20 max-w-[1320px] min-w-[320px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col gap-10 xl:gap-0 xl:flex-col xl:mx-auto items-center justify-center"
      style={{ fontFamily: "Open Sans" }}
    >
      {/* shoppingcart heading desktop only*/}
      <div className="hidden w-[1024px] xl:flex xl:w-[1160px] 2xl:w-[1320px] xl:h-[85px] items-center justify-center xl:mt-[100px] xl:mb-[60px]">
        <div>
          <p className="xl:text-[32px] font-bold mx-auto text-center">
            Thank You!
          </p>
          <p className="text-[10] text-gray-400 mx-auto">
            Your order has been placed and you will receive a confirmation email
            shortly.
          </p>
        </div>
      </div>

      {/* orderdetail and summary for desktop */}
      {/* <div className="flex flex-col xl:flex-row xl:gap-10"> */}

      <div className="flex flex-col xl:flex-row sm:items-center xl:items-start gap-y-[40px] xl:gap-y-0 xl:gap-x-[40px] 2xl:gap-x-[80px] xl:w-[1160px] 2xl:w-[1320px]">
        {/* orders details */}
        <div className="w-[320px] xl:w-[685px] sm:w-[640px] mt-20 xl:mt-0 sm:rounded-lg">
          {/* top section */}
          <div className="flex flex-col items-center justify-center">
            {/* shoppingcart heading mobile */}
            <div className="w-[320px] sm:w-[685px] text-black h-[100px] sm:h-[85px] flex xl:hidden flex-col justify-center">
              <div className="flex flex-col gap-5">
                <p className="text-[28px] sm:text-[32px] w-[280px] h-[38px] sm:h-[44px] font-bold mx-auto text-center">
                  Thank You!
                </p>
                <p className="text-[10] h-[42px] sm:h-[21px] tracking-tight mx-auto text-center">
                  Your order has been placed and you will receive a confirmation
                  email shortly.
                </p>
              </div>
            </div>
            {/* top button */}
            <div className="mt-[60px] xl:mt-0 min-w-[280px] w-[280px] sm:w-[640px] xl:w-[1160px] 2xl:w-[1320px] xl:mx-auto">
              <div className="h-[71px] sm:h-[34px] min-w-[280px] w-[280px] sm:w-[640px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col sm:flex-row items-center justify-center sm:justify-between gap-[10px] sm:gap-0">
                {/* text */}
                <p
                  type="button"
                  className="w-[240px] h-[27px] uppercase font-bold text-lg text-nowrap"
                >
                  your order details
                </p>
                {/* button */}
                <Link to={"/"} className="cursor-pointer z-50">
                  <button
                    type="button"
                    className="w-[154px] h-[34px] text-white bg-[#E2A856] font-medium rounded text-[12px] hover:bg-black"
                  >
                    CONTINUE SHOPPING
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {/* table 1 */}
          <div className="w-[280px] sm:w-[640px] xl:w-[685px] mx-auto mt-5">
            <div className="w-[280px] sm:w-[640px] xl:w-[685px] flex flex-col items-center justify-center border-2">
              {/* date picker */}
              <div className="w-[240px] h-[80px] sm:h-[72px] sm:w-[600px] xl:w-[645px] md:flex-row md:justify-start mt-10 sm:mt-5">
                <div className="w-[240px] h-[72px] sm:w-[600px] xl:w-[645px] flex flex-col">
                  {/* row 1 for all */}
                  <div className="w-[240px] h-[44px] sm:w-[600px] xl:w-[645px] flex flex-col sm:flex-row items-center justify-center sm:justify-between">
                    {/* shipping date */}
                    <div className="mt-2 w-[252px] h-[44px] flex flex-col items-center justify-center gap-3 sm:gap-0 sm:flex-row">
                      <p className="text-[14px] w-[200px] h-[21px] font-bold text-nowrap">
                        Shipping Date:
                      </p>
                      <div className="w-[240px] h-[41px] flex items-center justify-center">
                        <img
                          src={calendar_month}
                          alt="Calender"
                          className="flex sm:hidden"
                        />
                        <p className="text-[14px] font-bold">
                          {moment(state?.shippingDate).format('DD-MMM-YYYY').replaceAll("-", " ")}
                        </p>
                      </div>
                    </div>
                    {/* wb order no */}
                    <div className="mt-5 sm:mt-0 text-[10px] text-gray-800 flex items-center justify-center sm:justify-between sm:w-[154px] sm:h-[15px] ">
                      <div className="w-[96px] h-[15px]">
                        <p>Web Order Number:</p>
                      </div>
                      <div className="w-[53px] h-[15px]">
                        <p>{state?.orderNumber}</p>
                      </div>
                    </div>
                  </div>
                  {/* horizontal line */}
                  <div className="mt-4 h-[1px] bg-gray-400 w-[250px] sm:w-[160px] flex sm:hidden"></div>
                  {/* row 2 for all */}
                  <div className="mt-1 flex w-[240px] h-[35px] sm:h-[15px] text-[10px] text-gray-700 gap-2 items-center justify-center">
                    <p className="w-[54px] h-[15px]">Instruction: </p>
                    <p className="w-[132px] sm:w-[586px] h-[15px]">
                      {state?.specialInstruction !== null ? state?.specialInstruction : ""}
                    </p>
                  </div>
                </div>
              </div>
              {/* table */}
              <table className="w-[240px] sm:w-[600px] xl:w-[645px] text-[14px] text-left rtl:text-right text-gray-500 flex flex-col">
                <thead className="text-[12px] text-gray-500 sm:w-[600px] xl:w-[645px] sm:h-[28px] sm:items-center sm:justify-center">
                  <tr className="sm:border-b-2 w-[240px] h-[18px] sm:w-[600px] xl:w-[645px] sm:h-[28px] flex flex-row items-center justify-end sm:justify-normal sm:gap-[10px]">
                    <th
                      scope="col"
                      className="sm:w-[55px] h-[18px] hidden sm:flex items-center justify-center me-[10px]"
                    >
                      <span className="">Image</span>
                    </th>
                    <th
                      scope="col"
                      className="sm:w-[180px] xl:w-[200px] h-[18px] hidden sm:flex items-center justify-center px-4"
                    >
                      Product Details
                    </th>
                    <th
                      scope="col"
                      className="sm:w-[80px] xl:w-[90px] h-[18px] hidden sm:flex items-center sm:ms-5 xl:ms-0"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="sm:w-[80px] xl:w-[90px] h-[18px] hidden sm:flex items-center xl:me-0"
                    >
                      Qty
                    </th>
                    <th
                      scope="col"
                      className="sm:w-[80px] xl:w-[90px] h-[18px] hidden sm:flex items-center"
                    >
                      Discount
                    </th>
                    <th
                      scope="col"
                      className="sm:w-[80px] xl:w-[90px] h-[18px] hidden sm:flex items-center justify-center"
                    >
                      Subtotal
                    </th>
                  </tr>
                </thead>
                <tbody className=" border-gray-400">
                  {
                    state?.cartItems?.length ? state?.cartItems?.filter(ele => ele?.productQuantity !== 0)?.map((cart, idx) => {
                      // console.log(cart)
                      return < DisplayOderItems key={cart?._id} ele={cart} CartProduct={CartProduct} />
                    }) : null
                  }
                </tbody>
              </table>
              {/* coupon code and instructor */}
              <div className="my-3 w-[240px] h-[74px] sm:w-[600px] sm:h-[86px] xl:w-[645px] xl:h-[86px] bg-white">
                {/* collection total */}
                <div className="w-[240px] h-[44px] sm:w-[600px] xl:w-[645px] sm:h-[50px] text-[10px] sm:flex sm:flex-col sm:items-end">
                  <div className="flex justify-between sm:w-[314px] sm:h-[18px]">
                    <p>Collection Subtotal:</p>
                    {/* <p>$ {state?.total?.toFixed(2)}</p> */}
                    <p>$ {actualTot}</p>
                  </div>
                  {/* <div className="flex justify-between text-red-400 sm:w-[314px] sm:h-[18px]">
                  <p>Discount (Promo name) applied:</p>
                  <p>-$ 00.00</p>
                </div>
                <div className="flex justify-between text-red-400 sm:w-[314px] sm:h-[18px]">
                  <p>Discount (Promo name) applied:</p>
                  <p>-$ 00.00</p>
                </div> */}
                  <div className="flex justify-between text-red-400 sm:w-[314px] sm:h-[18px]">
                    <p>Discount:</p>
                    <p>${totalDiscount}</p>
                  </div>
                  <div className="mt-2 flex justify-between font-bold text-[14px] sm:w-[314px] sm:h-[24px]">
                    <p>Collection Total:</p>
                    <p>$ {state?.total?.toFixed(2)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* order summary and shipping details */}
        <div className="flex flex-col sm:items-center gap-[40px] sm:gap-y-[40px]">
          {/* order summary */}
          <div className="w-[320px] h-[291px] sm:w-[500px] sm:h-[283px] xl:w-[435px] 2xl:w-[555px] xl:h-[337px] 2xl:h-[283px]">
            <div className="mx-auto w-[280px] h-[291px] sm:w-[500px] sm:h-[283px] xl:w-[435px] xl:h-[283px] 2xl:w-[555px] xl:mt-[54px] xl:mb-0 border-2 sm:mb-20 flex flex-col">
              <div className="flex flex-col items-center justify-center gap-10 mt-5 sm:my-0 xl:w-[435px] xl:h-[283px] 2xl:w-[555px]">
                {/* row1 */}
                <div className="sm:mt-5 xl:mt-0 w-[240px] h-[57px] sm:w-[460px] xl:w-[395px] 2xl:w-[515px] xl:h-[57px] flex items-center justify-center sm:justify-start uppercase font-bold text-[18px] border-b">
                  Order Summary
                </div>
                {/* row2 */}
                <div className="w-[240px] h-[154px] sm:w-[460px] sm:h-[136px] xl:w-[395px] xl:h-[154px] 2xl:w-[515px]">
                  <div className="flex justify-between text-base">
                    <p className="">Subtotal:</p>
                    <p>${actualTot}</p>
                  </div>
                  <div className="flex justify-between text-red-400">
                    <p className="">Discount:</p>
                    <p>${totalDiscount}</p>
                  </div>
                  <div className="mt-1 font-bold flex justify-between">
                    <p className="">Total:</p>
                    <p>$ {state?.total?.toFixed(2)}</p>
                  </div>
                  <div className="w-[240px] sm:w-[460px] sm:h-[36px] xl:w-[395px] h-[36px] mt-5 text-gray-400 text-[12px]">
                    <ul className="ms-5">
                      <li className="list-disc">
                        Total does not include applicable taxes or delivery. All
                        prices and product availability are subject to change.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* shipping details */}
          <div className="w-[320px] sm:w-[740px] xl:w-[435px] 2xl:w-[555px]">
            <div className="mx-auto w-[280px] sm:w-[500px] xl:w-[435px] 2xl:mt-[54px] 2xl:w-[555px] border-2 flex flex-col">
              <div className="flex flex-col justify-center items-center xl:w-[435px] 2xl:w-[555px] divide-y divide-[#CECECC]">
                {/* row1 name only*/}
                <div className="w-[240px] sm:w-[460px] xl:w-[395px] 2xl:w-[515px] flex items-center justify-center sm:justify-start uppercase font-bold text-[18px] my-2 pt-3">
                  Shipping Details
                </div>
                {/* row2 */}
                <div className="w-[240px] sm:w-[460px] xl:w-[395px] 2xl:w-[515px] flex items-center text-base gap-1 my-2 pt-3 ">
                  <p className="w-[65px] sm:w-[125px] font-bold text-[12px]">
                    Order Date:
                  </p>
                  <p className="w-[160px] sm:w-[300px] 2xl:w-[370px] text-[12px]">
                    {moment(state?.createdAt).format('DD-MMMM-YYYY').replaceAll("-", " ")}
                  </p>
                </div>
                {/* row3 */}
                <div className="w-[240px] sm:w-[460px] xl:w-[395px] 2xl:w-[515px] flex items-center text-base gap-1 my-2 pt-3 ">
                  <p className="w-[65px] sm:w-[125px] text-[12px] font-bold flex items-center">
                    Web Order Number:
                  </p>
                  <p className="w-[160px] sm:w-[300px] 2xl:w-[370px] text-[12px] flex items-center">
                    {state.orderNumber}
                  </p>
                </div>
                {/* row4 */}
                <div className="w-[240px] sm:w-[460px] xl:w-[395px] 2xl:w-[515px] flex items-center text-base gap-1 my-2 pt-3 ">
                  <p className="w-[65px] sm:w-[125px] text-[12px] font-bold leading-4">
                    Sales Order Number:
                  </p>
                  <p className="w-[160px] sm:w-[300px] 2xl:w-[370px] text-[12px]">
                    {state.proOrderNumber}
                  </p>
                </div>
                {/* row5 */}
                <div className="w-[240px] sm:w-[460px] xl:w-[395px] 2xl:w-[515px] flex items-center text-base gap-1 my-2 pt-3 " style={{ fontFamily: "Open Sans" }}>
                  <p className="w-[65px] sm:w-[125px] font-bold text-[12px]">
                    Order Status:
                  </p>
                  <p className="w-[160px] sm:w-[300px] 2xl:w-[370px] text-[12px]">
                    {state.status}
                  </p>
                </div>
                {/* row6 */}
                <div className="w-[240px] sm:w-[460px] xl:w-[395px] 2xl:w-[515px] flex items-center text-base gap-1 my-2 pt-3 ">
                  <p className="w-[65px] sm:w-[125px] font-bold text-[12px]">
                    Bill To:
                  </p>
                  <p className="w-[160px] sm:w-[300px] 2xl:w-[370px] text-[12px] break-words sm:flex sm:items-center">
                    {/* CHRISTINA'S1 NORTHCOTT SHOP123 Fun Street Vaughan Tel: (
                    +902) Cell: (+911 ) Email:CHRISTINA'S1@GMAIL.COM +902) Cell:
                    (+911 ) Email:CHRISTINA'S1@GMAIL.COM */}
                    <pre style={{ fontFamily: 'Open sans', lineHeight: '90%' }}>{state.billTo}</pre>
                  </p>
                </div>
                {/* row7 */}
                <div className="w-[240px] sm:w-[460px] xl:w-[395px] 2xl:w-[515px] flex items-center text-base gap-1 my-2 pt-3 ">
                  <p className="w-[65px] sm:w-[125px] font-bold text-[12px]">
                    Ship To:
                  </p>
                  <p className="w-[160px] sm:w-[300px] 2xl:w-[370px] text-[12px] break-words sm:flex sm:items-center">
                    {/* CHRISTINA'S1 NORTHCOTT SHOP123 Fun Street Vaughan Tel: (
                    +902) Cell: (+911 ) Email:CHRISTINA'S1@GMAIL.COM +902) Cell:
                    (+911 ) Email:CHRISTINA'S1@GMAIL.COM */}
                    <pre style={{ fontFamily: 'Open sans', lineHeight: '90%' }} >{state.shipTo}</pre>
                  </p>
                </div>
                {/* row last */}
                <ul className=" ms-5 w-[240px] sm:w-[460px] xl:w-[395px] 2xl:w-[515px] text-gray-500 text-[12px] my-2">
                  <li className="list-disc">
                    NOTE: If the address we have on file is incorrect, please
                    contact us at 1-800-268-1466
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default OrderDetails;
