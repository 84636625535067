import { React, useEffect, useContext, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import AppFooter from "../components/shared/footers/AppFooter";
import AppHeader from "../components/shared/headers/AppHeader";
import ApiService from "../helpers/ApiServices";
import ChangePassword from "../pages/auth/ChangePassword";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import Login from "../pages/login/Login";
import ProductCategory from "../pages/product/ProductCategory";
import ProductCollection from "../pages/product/ProductCollection";
import ProductDetail from "../pages/product/ProductDetail";
import CustomerProfile from "../pages/profile/CustomerProfile";
import { UserContext } from "../states/contexts/UserContext";
import { CustomerContext } from "../states/contexts/CustomerContext";
import { getModelRoute, showMessage } from "../helpers/Utils";
import DesignerList from "../pages/designer/DesignerList";
import DesignerProfile from "../pages/designer/DesignerProfile";
import ResourcesList from "../pages/resources/ResourcesList";
import ResourceCategoryList from "../pages/resources/ResourceCategoryList";
import ResourceDetails from "../pages/resources/ResourceDetails";
import CartDetail from "../pages/cart/CartDetail";
import { CartContext } from "../states/contexts/CartContext";
import Order from "../pages/order/Order";
import RecentCustomers from "../pages/recentCustomers/RecentCustomers";
import MyOrders from "../pages/order/MyOrders";
import Help from "../pages/help/Help";
import { WishListContext } from "../states/contexts/wishListContext";
import WishList from "../pages/wishList/WishList";
import PatternList from "../pages/pattern/PatternList";
import PatternDetails from "../pages/pattern/PatternDetails";
import OrderDetails from "../pages/order/OrderDetails";
import Productfinder from "../pages/product/Productfinder";
import RapidOrder from "../pages/rapidOrder/RapidOrder";
import InvoiceDetails from "../pages/invoice/InvoiceDetais";
import SearchDetail from "../pages/search/SearchDetail";
import PrintProductFinder from "../pages/print/PrintProductFinder";
import Body from "../components/shared/headers/HomePageContent/Body";
import RegionCustomers from "../pages/login/RegionCustomers";
import PasswordEmailSent from "../pages/auth/PasswordEmailSent";
import EmailNotFound from "../pages/auth/EmailNotFound";
import RetailerRegistration from "../pages/login/RetailerRegistration";
import EditPersonalInfo from "../pages/profile/EditPersonalInfo";
import ShippingAddress from "../pages/profile/ShippingAddress";
import EditStoreInfo from "../pages/profile/EditStoreInfo";
import AboutUs from "../pages/about/AboutUs";
import AboutWholesaleContacts from "../pages/about/AboutWholesaleContacts";
import AboutCharitiesWeSupport from "../pages/about/AboutCharitiesWeSupport";
import AboutCalender from "../pages/about/AboutCalender";
import AboutContact from "../pages/about/AboutContact";
import Lookbook from "../pages/lookbook/LookBook";
import ComingSoon from "../pages/comingSoon/ComingSoon";
import BecomeANorthcottDesigner from "../pages/designer/BecomeANorthcottDesigner";


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, [pathname]);
  return null;
};

export default function AppRoutes() {
  const [userId, setUserId] = useState();

  let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"));

  // All reducer
  const { dispatch: cartDispatch, quantity } = useContext(CartContext);
  const { dispatch, user } = useContext(UserContext);
  const { dispatch: customerDispatch, customer } = useContext(CustomerContext);
  const { dispatch: wishListDispatch, quantity: wishListQuantity } =
    useContext(WishListContext);



  //All methods Change in File

  const isLoggedIn = async () => {
    ApiService.setHeader();
    ApiService.get(`shop/user/loggedIn?model=${getModelRoute(user).model}`).then(async (response) => {
      dispatch({ type: "LOGIN_SUCCESS", payload: response.data.document });
      setUserId(response.data.document._id);

      setModelAndRoute(response.data.document);

      if (
        !response?.data.document.savePassword &&
        window.performance.getEntriesByType("navigation")[0].type ==
        "navigate"
      ) {

        dispatch({ type: "LOGIN_SUCCESS", payload: response.data.document });

      }

      if (
        response?.data.document.savePassword &&
        window.performance.getEntriesByType("navigation")[0].type ==
        "navigate"
      ) {


        if (
          (new Date().getTime() -
            response?.data.document.browserClosingTime) /
          60000 >
          60
        ) {
          customerDispatch({
            type: "SELECT_CUSTOMER",
            payload: customers[0],
          });
        } else {
          customerDispatch({
            type: "SELECT_CUSTOMER",
            payload: customers[customers?.length - 1],
          });
        }
      }
    }).catch(async (error) => {


    });
  };

  const getCustomer = async (customers) => {


    ApiService.setHeader();
    await ApiService.get(`shop/customer/${customers[customers?.length - 1]._id}?model=${getModelRoute(user).model}`)
      .then((response) => {
        customerDispatch({
          type: "SELECT_CUSTOMER",
          payload: response?.data.document,
        });
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const getCartDetail = () => {
    ApiService.setHeader();
    ApiService.get(
      `shop/cart/${customers?.length == 1
        ? customers[0]?._id
        : customers[customers?.length - 1]?._id
      }?model=${getModelRoute(user).model}`
    )
      .then((response) => {
        if (response?.data.isSuccess) {
          cartDispatch({
            type: "ADD_TO_CART_QUANTITY",
            payload: response?.data.documents?.length,
          });
        }
      })
      .catch((error) => {
        // console.log("Else Catch")
      });
  };

  const getWishListDetail = () => {
    ApiService.setHeader();
    ApiService.get(
      `shop/wishList/${customers[customers?.length - 1]?._id}?model=${getModelRoute(user).model
      }`
    )
      .then((response) => {
        if (response?.data.isSuccess) {
          wishListDispatch({
            type: "ADD_TO_WISHLIST_QUANTITY",
            payload: response?.data?.documents?.length,
          });
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };


  const setModelAndRoute = (user) => {
    if (user?.userTypes.includes("CUSTOMER")) {
      localStorage.setItem("PCTeRP.MODEL", "Customer");
      localStorage.setItem("PCTeRP.ROUTE", "Customer");
    } else {
      localStorage.setItem("PCTeRP.MODEL", "User");
      localStorage.setItem("PCTeRP.ROUTE", "user");
    }
  };

  useEffect(() => {
    isLoggedIn();
    if (customers != null) {
      getCartDetail();
      getWishListDetail();
    }

    if (customers?.length) getCustomer(customers);

    window.addEventListener("beforeunload", function (e) {
      if (
        window.performance.getEntriesByType("navigation")[0].type == "navigate"
      ) {
        ApiService.setHeader();
        ApiService.patch(
          `shop/user/${userId}?model=${getModelRoute(user).model}`,
          { browserClosingTime: new Date().getTime() }
        )
          .then((response) => { })
          .catch((err) => {
            showMessage(err.response.data.message, "error");
          });
      }
    });
  }, []);

  return (
    <BrowserRouter basename="/website">
      <AppHeader />
      <ScrollToTop />
      <Routes>
        <Route path="/*" element={<Body />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/retailerRegistration"
          element={<RetailerRegistration />}
        />
        <Route path="/region-customer" element={<RegionCustomers />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/wholesale-contacts" element={<AboutWholesaleContacts />} />
        <Route path="/charities-we-support" element={<AboutCharitiesWeSupport />} />
        <Route path="/calendar" element={<AboutCalender />} />
        <Route path="/contact-us" element={<AboutContact />} />
        <Route path="/help" element={<Help />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/passwordEmailSent" element={<PasswordEmailSent />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/passwordEmailSent" element={<PasswordEmailSent />} />
        <Route path="/emailNotFound" element={<EmailNotFound />} />
        <Route
          path="/recent-customer-page"
          element={user || customer ? <RecentCustomers /> : <Body />}
        />
        <Route
          path="/profile"
          element={user || customer ? <CustomerProfile /> : <Body />}
        />
        <Route
          path="/customer-profile"
          element={user || customer ? <CustomerProfile /> : <Body />}
        />

        <Route
          path="/customer-profile/editPersonalInfo"
          element={user || customer ? <EditPersonalInfo /> : <Body />}
        />

        <Route
          path="/customer-profile/editStoreInfo"
          element={user || customer ? <EditStoreInfo /> : <Body />}
        />

        <Route
          path="/customer-profile/shippingAddress"
          element={user || customer ? <ShippingAddress /> : <Body />}
        />

        <Route path="/designers-list" element={<DesignerList />} />
        <Route path="/designer-profile/:id" element={<DesignerProfile />} />
        <Route
          path="/designers-list/become-a-northcott-designer"
          element={<BecomeANorthcottDesigner />}
        />
        <Route
          path="/resource-category-list"
          element={<ResourceCategoryList />}
        />
        <Route path="/resources-list/:id" element={<ResourcesList />} />
        <Route path="/resource-details/:id" element={<ResourceDetails />} />
        <Route path="/product-category" element={<ProductCategory />} />
        <Route path="/product-collection/:id" element={<ProductCollection />} />
        <Route path="/product-detail/:id" element={<ProductDetail />} />
        <Route path="/product-finder/:id" element={<Productfinder />} />
        <Route path="/print/:id" element={<PrintProductFinder />} />
        <Route
          path="/rapid-order"
          element={user || customer ? <RapidOrder /> : <Body />}
        />
        <Route
          path="/cart-detail"
          element={<CartDetail />}
        />
        <Route path="/search-detail/:searchItem" element={<SearchDetail />} />
        <Route
          path="/wishlist-detail"
          element={user || customer ? <WishList /> : <Body />}
        />
        <Route path="/order/create/:shipType" element={<Order />} />
        <Route path="/order/:id" element={<OrderDetails />} />
        <Route path="/invoice/:id" element={<InvoiceDetails />} />
        <Route path="/patern-list" element={<PatternList />} />
        <Route path="/patern-detail/:id" element={<PatternDetails />} />
        <Route path="/my-orders" element={<MyOrders />} />
        <Route path="/update-password" element={<ChangePassword />} />
        <Route path="/update-password/:id" element={<ChangePassword />} />
        <Route path="/look-book" element={<Lookbook />} />
      </Routes>
      <AppFooter />
    </BrowserRouter>
  );
}
