import LookbookTopBannerMobile from "../../assets/Lookbook/LookbookTopBannerMobile.svg";
import LookbookTopBannerTab from "../../assets/Lookbook/LookbookTopBannerTab.svg";
import LookbookTopBannerDesktop from "../../assets/Lookbook/LookbookTopBannerDesktop.svg";

import { useState } from "react";
import ApiService from "../../helpers/ApiServices";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const Lookbook = () => {
    const [lookbookData, setLookbookData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams()

    let division = searchParams.get('division').toUpperCase();

    const getAllLookbook = () => {
        setLoading(true)
        ApiService.setHeader();
        ApiService.get(`shop/lookbook/list?division=${division}`)
            .then((response) => {
                if (response?.data.isSuccess) {
                    setLoading(false)
                    setLookbookData(response?.data.documents);
                }
            })
            .catch((error) => {
                setLoading(false)
                console.log(error.response);
            });
    };

    useEffect(() => {
        getAllLookbook()
    }, [division])

    const decodeAndExtractSrc = (encodedXmlString) => {
        const decodedString = encodedXmlString?.replace(/&amp;/g, '&')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&quot;/g, '"')
            .replace(/&apos;/g, "'");
        const pattern = /src="([^"]+)"/;
        const match = decodedString?.match(pattern);
        return match ? match[1] : null;
    };

    return (
        <div
            className="mb-[100px] flex flex-col items-center justify-center"
            style={{ fontFamily: "Open Sans" }}
        >
            {/* row 1 top banner */}
            <div className="w-full">
                <img
                    src={LookbookTopBannerMobile}
                    alt="Images"
                    className="object-cover sm:hidden w-full h-[235px] sm:h-[257px] lg:h-[288px]"
                />
                <img
                    src={LookbookTopBannerTab}
                    alt="Images"
                    className="object-cover hidden sm:flex xl:hidden w-full h-[235px] sm:h-[257px] lg:h-[288px]"
                />
                <img
                    src={LookbookTopBannerDesktop}
                    alt="Images"
                    className="object-cover hidden xl:flex w-full h-[235px] sm:h-[257px] lg:h-[288px]"
                />
            </div>
            {/* row 2 content */}
            <div className="w-full flex flex-col items-center justify-center gap-[60px] sm:gap-[100px] my-[60px] sm:my-[80px] xl:my-[100px]">
                <h1 className="w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] flex items-center justify-center text-[28px] sm:text-[32px] font-bold text-black">
                    Look Books
                </h1>
                {
                    loading
                        ?
                        "loading ..."
                        :
                        (<div className="flex flex-col items-center justify-center w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] gap-[100px]">
                            {lookbookData?.length ? lookbookData.map((item, index) => (
                                <div
                                    className="flex flex-col items-center justify-center"
                                    key={index}
                                >
                                    <div
                                        className={`flex flex-col xl:flex-row gap-[90px] justify-between w-[280px] h-[300px] sm:w-[624px] xl:w-[1080px] sm:h-[535px] ${index % 2 === 0 ? "" : "xl:flex-row-reverse"
                                            }`}
                                    >
                                        <div className={`flex flex-col justify-center items-center`}>
                                            <h1 className="text-[28px] w-[300px] sm:text-[32px] text-black font-bold text-center break-words">
                                                {item?.title}
                                            </h1>
                                            <h1 className="text-[16px] text-black text-center w-[300px]">
                                                {item?.content}
                                            </h1>
                                        </div>
                                        <div
                                            className="relative w-[280px] h-[300px] xl:h-[535px] xl:w-[825px] mx-auto xl:mx-0"
                                        >
                                            <iframe
                                                title="NOrthcott"
                                                sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms"
                                                allow="clipboard-write"
                                                className="xl:w-[825px] xl:h-[535px]"
                                                allowFullScreen={true}
                                                src={decodeAndExtractSrc(item?.embedCode)}
                                            ></iframe>
                                        </div>
                                    </div>
                                    {index < lookbookData.length - 1 && (
                                        <div className="h-[1px] w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] bg-[#CECECC] mt-[100px]"></div>
                                    )}
                                </div>
                            ))
                                : ""
                            }

                        </div>)
                }
            </div >
        </div >
    );
};

export default Lookbook;
