import React from "react";
import ApiService from "../../../helpers/ApiServices";

const DisplayOderItems = ({ ele, CartProduct }) => {

  console.log("ele", ele?.subTotal);


  return <tr className="mt-3 border-b bg-white w-[240px] h-[134px] sm:w-[600px] sm:h-[75px] xl:w-[645px] flex gap-4 mb-[1px] sm:items-start sm:gap-[25px] xl:gap-[10px]">
    {/* mobile col 1 */}
    {/* image for all devices*/}
    <div className="flex flex-col sm:flex-row w-[94px] h-[124px] sm:w-[55px] xl:w-[55px] sm:h-[55px]">
      <span className="">
        <img
          // src={CartProduct}
          src={`${ApiService.getBaseImagePath()}products/${ele.productId?.name?.includes(":") ? ele.productId?.name?.split(":")[1].trim() : ele.productId?.name}.jpg`}
          className="w-[94px] h-[94px] sm:w-[55px] sm:h-[55px] "
          alt="Product"
        />
      </span>
      {/* Quantity for mobile only*/}
      <span className="w-[94px] h-[15px] mt-[15px] flex items-center gap-1 sm:hidden text-gray-500 text-[10px] sm:text-[12px]">
        <div className="flex w-[19px] h-[15px]">Qty:</div>
        <div className="flex w-[19px] h-[15px] text-black font-semibold">
          {ele?.productQuantity}{" "}
          <span className="flex w-[45px] h-[15px] ms-1 text-gray-500">
            /Meter(s)
          </span>
        </div>
      </span>
    </div>
    {/* mobile col 2 */}
    <div className="w-[131px] h-[123px] sm:w-[420px] sm:h-[70px] flex">
      <div className="flex flex-col sm:flex-row">
        {/* col 2-1 */}
        <span className="w-[131px] h-[75px] sm:w-[180px] sm:h-[65px] xl:w-[180x] text-[10px] sm:text-[12px] text-gray-400">
          {/* product detail for all and delete button for mobile only */}
          <div className="-mt-1 sm:w-[180px] sm:h-[65px] xl:w-[180px] sm:flex sm:flex-col sm:items-start sm:justify-center sm:ms-[20px]">
            <div className="flex items-center">
              <p className="w-[140px] h-[18px] text-[12px] text-gray-950">

                {ele.productId?.name?.includes(":") ? ele.productId?.name?.split(":")[1] : ele.productId?.name}
              </p>
            </div>
            <div className="flex flex-col leading-[14px] text-gray-400">
              <p className="">
                Design : <span>{ele.productId?.displayName}</span>
              </p>
              <p>
                Color : <span>{ele.productId?.colorDescription}</span>
              </p>
              <p>
                Collection : <span>{ele?.collectionName}</span>
              </p>
            </div>
          </div>
        </span>
        <div className="flex flex-col sm:flex-row leading-[14px] sm:leading-4 text-gray-400">
          {/* price */}
          <span className="text-[10px] sm:text-[12px] sm:w-[60px] xl:w-[90px] sm:h-[30px] sm:flex-col sm:items-center sm:justify-center sm:text-center">
            <span className="sm:hidden">Price:</span>
            <div className="xl:flex xl:flex-col">
              <span className="text-black text-[12px]">
                {ele?.price}
              </span>
              {/* <span>/Meter(s)</span> */}
            </div>
          </span>
          {/* Quanitity for desktop and tab only*/}
          <span className="sm:mx-[25px] xl:mx-0 h-[30px] justify-center sm:flex hidden">
            <div className="flex w-[60px] sm:w-[60px] xl:w-[90px] h-[20px] sm:h-[30px] items-start sm:justify-center text-[10px] sm:text-[12px]">
              <div className="flex w-[19px] h-[15px] sm:hidden">
                Qty:
              </div>
              <div className="flex w-[31px] h-[18px] text-black font-semibold sm:flex-col">
                <span>{ele?.productQuantity} </span>
              </div>
            </div>
          </span>
          {/* discount */}
          <span className="text-[10px] sm:text-[12px] sm:w-[60px] xl:w-[90px] sm:h-[18px] sm:flex sm:items-center sm:justify-center sm:mx-[10px]">
            <span className="sm:hidden">Discount:</span>
            <span className="text-black">{ele?.discount}</span>
          </span>

          {/* subtotal */}
          <span className="sm:ms-5 xl:ms-5 text-[10px] sm:text-[12px] mt-[10px] sm:mt-0 sm:w-[60px] xl:w-[90px] sm:h-[18px] sm:flex sm:justify-center sm:mx-[10px]">
            <span className="sm:hidden">Subtotal: </span>
            <span className="text-black font-bold"> {ele?.subTotal}</span>
          </span>
        </div>
      </div>
    </div>
  </tr>;
};

export default DisplayOderItems;
