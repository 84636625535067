import React, { useContext, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import ApiService from '../../helpers/ApiServices';
import { formatAddr, getModelRoute, showMessage } from '../../helpers/Utils';
import { UserContext } from '../../states/contexts/UserContext';
import ObjectSelectField from '../../components/elements/fields/ObjectSelectField';
import ConditionalObjectSelectField from '../../components/elements/fields/ConditionalObjectSelectField';


export default function Address({ state, setCountry, country, addressFields, isEditMode, show, handleShow, addressAppend, checkDefault, setCheckDefault, isDefaultTick, isDefaultTickAdd, setIsDefaultTickAdd, setIsDefaultTick, setAddressValue, addressValueLineLevel, setAddressValueLineLevel, editAddressModalIndex, addressUpdate, addressInsert, addressRemove, setAddrTouchFields, setAddedAddress, setupdatedAddress, index, handleSubmit, onSubmit }) {
    const [regions, setRegions] = useState([]);
    const [countries, setCountries] = useState([]);


    const { dispatch, user } = useContext(UserContext)

    const { register, control, reset, getValues, setValue, watch, formState: { errors, touchedFields } } = useForm({
    });


    const getAllRegions = async () => {
        let regionArray = [];

        ApiService.setHeader()
        const response = await ApiService.get(`admin/customList/title?title=${country}&model=${getModelRoute(user).model}`)
        setRegions(response?.data.document?.options)
    }


    useEffect(() => {
        if (isEditMode) {
            reset(state);
        } else {
            reset({ isDefaultShipping: false, isDefaultBilling: false, attention: "", address1: "", address2: "", address3: "", addressee: "", addressPhone: "", city: "", zip: "", state: "", country: "", });
        }
    }, [state])


    useEffect(() => {
        getAllRegions()
        let c = getValues().country?.text
        setCountry(c == "United States" ? "NCS US STATE LIST" : "NCS CA STATE LIST")
    }, [country])


    const handleAddressAppend = () => {
        const data = getValues();
        data.isApproved = false
        data.index = index

        setAddedAddress(data)
        data.address = formatAddr(data)

        if (data.default) {
            const v = formatAddr(data)
            setAddressValue(v);
        }

        let arr = [];
        if (data.default) {
            if (isDefaultTick.length === 0) {
                setCheckDefault(true);
                setIsDefaultTickAdd(true)
            }
            isDefaultTick.map(e => {
                arr.push(true);
            })
            arr.push(!data.default);
            setIsDefaultTick(arr);
        } else {
            if (checkDefault) {
                let newArr = [...isDefaultTick];
                newArr.push(!data.default);
                setIsDefaultTick(newArr);
            } else {
                let newArr = [...isDefaultTick];
                newArr.push(isDefaultTickAdd);
                setIsDefaultTick(newArr);
            }
        }

        const addressArr = [...addressValueLineLevel];
        const formatAddressLineLevel = formatAddr(data)
        addressArr.push(formatAddressLineLevel);
        setAddressValueLineLevel(addressArr);

        addressAppend(data);
        handleShow(false);
        reset({ isDefaultShipping: false, isDefaultBilling: false, attention: "", address1: "", address2: "", address3: "", addressee: "", addressPhone: "", city: "", zip: "", state: "", country: "", });
        setAddrTouchFields(touchedFields)

    }


    const handleAddressUpdate = () => {
        const data = getValues();
        data.isApproved = false
        setupdatedAddress(data)
        delete data.address

        data.address = formatAddr(data)

        if (data.default) {
            const v = formatAddr(data)
            setAddressValue(v);
        }

        let addressArr = [...addressValueLineLevel];
        const formatAddressLineLevel = formatAddr(data);
        addressArr[editAddressModalIndex] = formatAddressLineLevel;
        setAddressValueLineLevel(addressArr);
        addressInsert(editAddressModalIndex, data);
        addressRemove(editAddressModalIndex + 1);
        handleShow(false);
        reset({ isDefaultShipping: false, isDefaultBilling: false, attention: "", address1: "", address2: "", address3: "", addressee: "", addressPhone: "", city: "", zip: "", state: "", country: "", });
        setAddrTouchFields(touchedFields)

    }

    const getAllCountry = () => {
        ApiService.setHeader()
        ApiService.get(`admin/customList/title?title=NCS COUNTRY LIST`).then(response => {
            if (response.data.isSuccess) {
                setCountries(response.data.document?.options)
            }
        }).catch(error => {
            showMessage(error?.response?.data?.message, "error")
        })
    }


    useEffect(() => {
        getAllCountry()
    }, [])


    return (
        <div>
            {/* right side */}
            <div className="w-[280px] sm:w-[624px] xl:w-[820px] flex flex-col gap-[60px]">
                <div className="w-[280px] sm:w-[624px] xl:w-[820px] flex flex-col gap-5">
                    {/* Add New Address */}
                    <div className="w-[280px] h-[38px] sm:w-[624px] xl:w-[820px] flex flex-col items-center justify-center sm:flex-row sm:justify-between sm:items-center gap-5 sm:gap-0">
                        {/* heading */}
                        <div className="w-[300px] h-[36px] font-bold text-[28px] text-center sm:text-start">
                            Add New Address
                        </div>
                    </div>
                    {/* horizontal line */}
                    <hr className=" text-[#CECECC] bg-[#CECECC] h-[1px] w-[280px] sm:w-[624px] xl:w-[820px]" />
                    {/* content */}
                    <div className="w-[280px] flex flex-col sm:w-[624px] xl:w-[820px] gap-[30px]">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {/* input fields */}
                            <div className="w-[280px] sm:w-[624px] xl:w-[320px] flex flex-col gap-[20px]">

                                {/* country */}
                                <div className="z-10 w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                                    <ObjectSelectField
                                        control={control}
                                        errors={errors}
                                        field={{
                                            description: "All country",
                                            label: "COUNTRY",
                                            fieldId: "country",
                                            placeholder: "",
                                            placedIn: "modal",
                                            multiple: false,
                                            required: true,
                                            selectRecordType: "admin/customList/title?title=NCS COUNTRY LIST",
                                        }}
                                        changeHandler={(data) => {
                                            if (data?.length) {
                                                setCountry(data[0]?.text == "United States" ? "NCS US STATE LIST" : "NCS CA STATE LIST")
                                            }
                                        }}
                                    />
                                </div>
                                {/* state / province */}
                                <div className="z-10 w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                                    <ConditionalObjectSelectField
                                        control={control}
                                        errors={errors}
                                        states={regions}
                                        field={{
                                            description: "All state",
                                            label: "STATE/PROVINCES",
                                            fieldId: "state",
                                            placeholder: "",
                                            placedIn: "modal",
                                            multiple: false,
                                            required: false,
                                            selectRecordType: `modal`,
                                        }}
                                        changeHandler={null}
                                    />
                                </div>
                                {/* city */}
                                <div className=" w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                                    <label
                                        htmlFor="city"
                                        className="block text-[14px] font-bold text-gray-700 sm:w-[77px] sm:h-[21px] text-nowrap"
                                    >
                                        City
                                    </label>
                                    <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                                        <input
                                            type="text"
                                            id="city"
                                            name="city"
                                            {...register(`city`)}
                                            className=" px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                                            placeholder=""
                                        />

                                    </div>
                                </div>
                                {/* Zip/Postal Code */}
                                <div className=" w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                                    <label
                                        htmlFor="zip"
                                        className="block text-[14px] font-bold text-gray-700 sm:w-[77px] sm:h-[21px] text-nowrap"
                                    >
                                        Zip/Postal Code
                                    </label>
                                    <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                                        <input
                                            type="text"
                                            id="zip"
                                            name="zip"
                                            {...register(`zip`)}
                                            className="px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                                            placeholder=""
                                        />
                                    </div>
                                </div>
                                {/* addressPhone */}
                                <div className=" w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                                    <label
                                        htmlFor="addressPhone"
                                        className="block text-[14px] font-bold text-gray-700 sm:w-[77px] sm:h-[21px] text-nowrap"
                                    >
                                        Address Phone
                                    </label>
                                    <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                                        <input
                                            type="text"
                                            id="addressPhone"
                                            name="addressPhone"
                                            {...register(`addressPhone`)}
                                            className="px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                                            placeholder=""
                                        />
                                    </div>
                                </div>
                                {/* attention */}
                                <div className=" w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                                    <label
                                        htmlFor="attention"
                                        className="block text-[14px] font-bold text-gray-700 sm:w-[77px] sm:h-[21px] text-nowrap"
                                    >
                                        Attention
                                    </label>
                                    <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                                        <input
                                            type="text"
                                            id="attention"
                                            name="attention"
                                            {...register(`attention`)}
                                            className=" px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                                            placeholder=""
                                        />
                                    </div>
                                </div>
                                {/* address1 */}
                                <div className=" w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                                    <label
                                        htmlFor="address1"
                                        className="block text-[14px] font-bold text-gray-700 sm:w-[77px] sm:h-[21px] text-nowrap"
                                    >
                                        Address1
                                    </label>
                                    <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                                        <input
                                            type="text"
                                            id="address1"
                                            name="address1"
                                            {...register(`address1`)}
                                            className="px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                                            placeholder=""
                                        />
                                    </div>
                                </div>
                                {/* address2 */}
                                <div className=" w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                                    <label
                                        htmlFor="address2"
                                        className="block text-[14px] font-bold text-gray-700 sm:w-[77px] sm:h-[21px] text-nowrap"
                                    >
                                        Address2
                                    </label>
                                    <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                                        <input
                                            type="text"
                                            id="address2"
                                            name="address2"
                                            {...register(`address2`)}
                                            className="px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                                            placeholder=""
                                        />
                                    </div>
                                </div>
                                {/* addressee */}
                                <div className=" w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                                    <label
                                        htmlFor="addressee"
                                        className="block text-[14px] font-bold text-gray-700 sm:w-[77px] sm:h-[21px] text-nowrap"
                                    >
                                        addressee
                                    </label>
                                    <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                                        <input
                                            type="text"
                                            id="addressee"
                                            name="addressee"
                                            {...register(`addressee`)}
                                            className="px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900  text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                                            placeholder=""
                                        />
                                    </div>
                                </div>
                                {/* checkbox */}
                                <div className="h-[52px] flex flex-col justify-between">
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            id="isDefaultBilling"
                                            name="isDefaultBilling"

                                            {...register(`isDefaultBilling`)}
                                            className="h-[17px] w-[17px] rounded-[2px] border-[#D9D9D9] text-black focus:ring-black hover:border-black checked:text-black peer relative shrink-0 appearance-none border after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-[url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==')] after:bg-[length:30px] after:bg-center after:bg-no-repeat after:content-[''] checked:bg-black focus:outline-none"
                                        />
                                        <label
                                            htmlFor="default-checkbox"
                                            className="ms-2 text-[14px] font-medium text-gray-700"
                                        >
                                            Make this my default billing address
                                        </label>
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            id="isDefaultShipping"
                                            name="isDefaultShipping"
                                            {...register(`isDefaultShipping`)}
                                            className="h-[17px] w-[17px] rounded-[2px] border-[#D9D9D9] text-black focus:ring-black hover:border-black checked:text-black peer relative shrink-0 appearance-none border after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-[url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==')] after:bg-[length:30px] after:bg-center after:bg-no-repeat after:content-[''] checked:bg-black focus:outline-none"
                                        />
                                        <label
                                            htmlFor="default-checkbox"
                                            className="ms-2 text-[14px] font-medium text-gray-700"
                                        >
                                            Make this my default shipping address
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* buttons */}
                            <div className="h-[49px] flex items-center justify-center sm:justify-start mt-[20px]">
                                <div className="w-[205px] h-[49px] flex items-center justify-between">
                                    {isEditMode
                                        ?
                                        <button
                                            className=" w-[95px] h-[40px] rounded text-center text-[16px] font-semibold bg-[#E2A856] text-[#FFFFFF]"
                                            type="button"
                                            onClick={handleAddressUpdate}
                                        >
                                            Update
                                        </button>
                                        :
                                        <button
                                            className=" w-[95px] h-[40px] rounded text-center text-[16px] font-semibold bg-[#E2A856] text-[#FFFFFF]"
                                            type="button"
                                            onClick={handleAddressAppend}
                                        >
                                            Add
                                        </button>

                                    }
                                    <button
                                        className="underline w-[62px] h-[24px] text-center text-[16px] text-[#E2A856] font-semibold bg-white text-nowrap"
                                        type="button"
                                        onClick={() => {
                                            setValue("country", state.country)
                                            setValue("state", state.state)
                                            handleShow(false)

                                        }}
                                    >
                                        Go Back
                                    </button>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </div >
    );
}



