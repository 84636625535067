import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getModelRoute, showMessage } from "../../../helpers/Utils";
import ApiService from "../../../helpers/ApiServices";
import { BrandIconContext } from "../../../states/contexts/BrandIconContext";
import { UserContext } from "../../../states/contexts/UserContext";
import { CustomerContext } from "../../../states/contexts/CustomerContext";
import { useFieldArray, useForm } from "react-hook-form";
import { CartContext } from "../../../states/contexts/CartContext";
import { useAlert } from "react-alert";


const DisplayCartDetails = ({ subTotalLoading, deleteIndividualLoading, key, isEditCart, cart, updateCartData, updateCartItem, deleteCartItem, total, deleteAllSelectedCartItem, getCartDetail, calendar_month, DeleteIcon, CartProduct, isShipComplete, isCombineShipment, cartItemCount, cartItemQuantity, setDeleteIndividualLoading }) => {

  const [groupTotal, setGroupTotal] = useState(null)
  const [groupActualTotal, setGroupActualTotal] = useState(null)
  const [qtyObj, setQtyObj] = useState(null)
  const [collection, setCollection] = useState(null)
  const [updateData, setUpdateData] = useState({ shipDate: new Date(cart[1][0].shipDate).toISOString().split("T")[0], specialInstruction: cart[1][0]?.specialInstruction })
  const [shipDate, setShipDate] = useState(new Date(cart[1][0].shipDate).toISOString().split("T")[0])
  const [startDate, setStartDate] = useState(new Date());
  const [appliedPromotions, setAppliedPromotions] = useState(null);
  const [promoDiscount, setPromoDiscount] = useState(null);
  const [promo, setPromo] = useState(null);
  const alert = useAlert();

  const { dispatch: cartDispatch, quantity } = useContext(CartContext)

  const { backgroundColor } = useContext(BrandIconContext)
  const { user } = useContext(UserContext)
  const { customer } = useContext(CustomerContext)


  let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))

  const { register, control, reset, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
    }
  });
  const { append: cartAppend, remove: cartRemove, fields: cartItemFields } = useFieldArray({ control, name: "cartItems" });


  const onSubmit = (data) => {

  }

  const updateDataToAllGroupItems = (e) => {
    if (!e.target.value) return;
    ApiService.setHeader()
    ApiService.patch(`shop/cart/bulkUpdate?protectModel=${getModelRoute(user).model}`, {
      updateData,
      cartItemIds: cart[1].map(e => e._id)

    }).then(response => {

      showMessage('Data updated!', "info")
      if (response?.data.isSuccess) {
      }
      getCartDetail()
    }).catch(error => {
      console.log(error)
      // showMessage(error.response.data.message, "error")
    })
  }

  const applyPromo = () => {
    setDeleteIndividualLoading(true)
    let array = []
    let country
    const collection = cart[0]
    const promocode = getValues("promoCode")
    if (customers !== null) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        country = "UNITED STATES"
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
        country = "CANADA"
    }

    if (!promocode) {
      alert.error("Please enter promocode!")
      setDeleteIndividualLoading(false)
      return showMessage("Please enter promocode!", 'info')
    }

    ApiService.setHeader()
    ApiService.patch(`shop/cart/applyPromo/${promocode}?protectModel=${getModelRoute(user).model}&country=${country}&collection=${collection}&customerId=${customer?._id}`, cart[1]).then(response => {

      if (response?.data.isSuccess) {
        if (response?.data?.isPromoApplied && response?.data?.isSuccess) {
          alert.success(response?.data?.message)
        } else if ((!response?.data?.isPromoApplied && response?.data?.isSuccess) || !response?.data?.isSuccess) {
          alert.error(response?.data?.message)
        }
        // console.log(response.data.documents[0]?.appliedPromoCode?.disCountType);
        array.push(collection)
        array.push(response?.data.documents)
        cart = array
        const total = cart[1]?.reduce(function getSum(total, cart) {
          return (parseFloat(total) + parseFloat(cart?.subTotal)).toFixed(2);
        }, 0)

        setGroupTotal(total)

        getCartDetail()

        setValue("promoCode", "")
        setDeleteIndividualLoading(false)
      }
    }).catch(error => {
      setDeleteIndividualLoading(false)
      alert.error("The promo code is invalid!")
      showMessage(error.response?.data?.message, "error")
      setValue("promoCode", "")
    })
  }


  const getCollection = (collectionName) => {
    ApiService.setHeader()
    ApiService.get(`shop/collection/name/${collectionName}?protectModel=${getModelRoute(user).model}`).then(response => {
      if (response?.data.isSuccess) {
        setCollection(response.data.document)
      }
    }).catch(error => {
      console.log(error)
      // showMessage(error.response.data.message, "error")
    })
  }


  useEffect(() => {
    setDeleteIndividualLoading(true)
    cart[1]?.map(ele => {
      ele.collectionName = String(`${ele?.collectionName && ele?.collectionName}\n${ele.productId.colorDescription}`)
      ele.productQuantity = ele?.productQuantity[0]?.name
    })
    getCollection(cart[0])
    let obj = {}
    obj.cartItems = cart[1]
    reset(obj)
    setValue(`specialInstruction`, cart[1][0].specialInstruction)
    setStartDate(new Date(cart[1][0]?.newShipDate ? cart[1][0]?.newShipDate : cart[1][0]?.shipDate))
    const actualTotal = cart[1]?.reduce(function getSum(total, cart) {
      return (parseFloat(total) + (parseFloat(cart?.productQuantity) * parseFloat(cart?.price))).toFixed(2);
    }, 0)
    setGroupActualTotal(actualTotal)
    const groupPromodiscountTotal = cart[1]?.reduce(function getSum(total, cart) {
      return (parseFloat(total) + (parseFloat(cart?.appliedPromoCode?.discountAmount))).toFixed(2);
    }, 0)
    setPromoDiscount(groupPromodiscountTotal)
    for (let cartObj of cart[1]) {
      if (cartObj?.appliedPromo) {
        setPromo(cartObj?.appliedPromo)
      }
    }
    const total = cart[1]?.reduce(function getSum(total, cart) {
      return (parseFloat(total) + parseFloat(cart?.subTotal)).toFixed(2);
    }, 0)
    setGroupTotal(total)
    getCartDetail()
    setDeleteIndividualLoading(false)

  }, [total, cartItemCount, cart[1][0]?.appliedPromo]);


  return (
    <form onSubmit={handleSubmit(onSubmit)} key={key}>
      <div className="xl:w-[780px] w-[280px] sm:w-[640px] mx-auto mt-5 relative">
        <div className="w-[280px] sm:w-[640px] xl:w-[780px] flex flex-col items-center justify-center border-2">
          {/* date picker */}
          <div className="w-[240px] h-[72px] sm:w-[600px] xl:w-[740px] flex flex-col items-center justify-between md:flex-row md:justify-start mt-5">
            <p className="text-[14px] w-[102px] h-[21px] font-bold text-nowrap">
              Shipping Date:
            </p>
            {/* datepicker */}
            <div className="flex flex-col items-center justify-center">
              <div className="w-[240px] h-[41px] flex items-center justify-center">
                <DatePicker
                  className="z-10 ms-[20px] font-bold sm:text-[16px]"
                  wrapperClassName="datePicker"
                  showIcon
                  {...register('shippingDate')}
                  selected={startDate}
                  minDate={collection && new Date(collection?.date)}
                  dateFormat="dd MMMM yyyy"
                  onChange={(date) => {
                    if (date) {
                      setStartDate(date)

                      setUpdateData((prev) => ({ ...prev, shipDate: new Date(date) }))
                      setShipDate(new Date(date))
                    }
                  }}
                  onBlur={(e) => {
                    updateDataToAllGroupItems()
                  }}
                />
              </div>
              <div className="h-[1px] bg-gray-400 w-[250px] sm:w-[160px]"></div>
            </div>
          </div>
          {/* table */}
          <table className=" w-[240px] sm:w-[600px] xl:w-[740px] mt-5 text-[14px] text-left rtl:text-right text-gray-500 flex flex-col">
            <thead className="text-[12px] text-gray-500 sm:w-[600px] sm:h-[28px] xl:w-[740px] sm:items-center sm:justify-center">
              <tr className="sm:border-b-2 w-[240px] h-[18px] sm:w-[600px] xl:w-[740px] sm:h-[28px] flex flex-row items-center justify-end sm:justify-normal sm:gap-[10px]">
                <th
                  scope="col"
                  className="w-[60px] xl:w-[80px] h-[18px] hidden sm:flex items-center justify-center me-[10px]"
                >
                  <span className="">Image</span>
                </th>
                <th
                  scope="col"
                  className="w-[180px] xl:w-[200px] h-[18px] hidden sm:flex items-center justify-center pe-[20px]"
                >
                  Product Details
                </th>
                <th
                  scope="col"
                  className="w-[60px] xl:w-[80px] h-[18px] hidden sm:flex items-center sm:ps-[10px]"
                >
                  Price
                </th>
                <th
                  scope="col"
                  className="w-[60px] xl:w-[80px] h-[18px] hidden sm:flex items-center justify-center sm:ps-[15px]"
                >
                  Qty
                </th>
                <th
                  scope="col"
                  className="w-[60px] xl:w-[80px] h-[18px] hidden sm:flex items-center justify-center"
                >
                  Discount
                </th>
                <th
                  scope="col"
                  className="w-[60px] xl:w-[80px] h-[18px] hidden sm:flex items-center justify-center"
                >
                  Subtotal
                </th>
                <th
                  scope="col"
                  style={{ cursor: "pointer" }}
                  className="flex w-[62px] sm:w-[60px] xl:w-[80px] h-[18px] underline sm:text-center text-nowrap items-center justify-center"
                  onClick={() => {
                    if (cart[1]?.length) {
                      deleteAllSelectedCartItem(cart[1].map(e => e._id))
                    } else {
                      showMessage("There is no item for delete!!")
                    }
                  }}
                >
                  Remove All
                </th>
              </tr>
            </thead>
            <tbody className="mt-5 z-0" >
              {cartItemFields?.map((field, index) => {
                return (
                  <tr key={index} className="border-b bg-white w-[240px] h-[134px] sm:w-[600px] sm:h-[70px] xl:w-[740px] xl:h-[90px] flex gap-4 mb-[11px] sm:items-start sm:gap-[10px]">
                    {/* image for all devices*/}
                    <div className="flex flex-col sm:flex-row w-[94px] h-[124px] sm:w-[60px] xl:w-[80px] sm:h-[60px]">
                      <span className="">
                        <img
                          src={`${ApiService.getBaseImagePath()}products/${getValues(`cartItems.${index}.productId.name`)?.includes(":") ? getValues(`cartItems.${index}.productId.name`)?.split(":")[1].trim() : getValues(`cartItems.${index}.productId.name`)}.jpg`}
                          className="w-[94px] h-[94px] sm:w-[60px] sm:h-[60px] xl:w-[80px] xl:h-[80px] "
                          alt="Product"
                        />
                      </span>

                    </div>

                    {/* mobile col 2 */}
                    <div className="w-[131px] h-[123px] sm:w-[420px] sm:h-[70px] flex">
                      <div className="flex flex-col sm:flex-row sm:ms-[20px]">
                        {/* col 2-1 */}
                        <span className="w-[131px] h-[75px] sm:w-[180px] sm:h-[60px] xl:w-[200px] xl:h-[65px] text-[10px] text-gray-500">
                          {/* product detail for all and delete button for mobile only */}
                          <div className=" sm:w-[180px] sm:h-[60px] xl:w-[200px] xl:h-[65px] sm:flex sm:flex-col sm:justify-center">
                            <div className="flex items-center justify-between ">
                              <p className="w-[131px] h-[18px] text-[12px] text-gray-950">

                                <input
                                  plaintext readOnly disabled
                                  style={{ backgroundColor: "white" }}
                                  type="text"
                                  id="name"
                                  name="name"
                                  {...register(`cartItems.${index}.productId.name`)} />
                              </p>
                              <div className="flex sm:hidden">
                                <span>
                                  <img
                                    src={DeleteIcon}
                                    className="w-[10px] h-[11.7px]"
                                  ></img>
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-col leading-[14px] text-gray-500">
                              <p>
                                Design : <span>{getValues(`cartItems.${index}.productId.displayName`)}</span>
                              </p>
                              <p>
                                Color : <span>{getValues(`cartItems.${index}.productId.colorDescription`)}</span>
                              </p>
                              <p>
                                Collection : <span>{cart[0]}</span>
                              </p>
                            </div>
                          </div>
                        </span>
                        <div className="flex flex-col sm:flex-row leading-[14px] sm:leading-4 text-gray-500">
                          {/* price */}
                          <span className="text-[10px] sm:w-[60px] xl:w-[80px] sm:h-[30px] sm:flex-col sm:items-center sm:justify-center sm:text-center">
                            <span className="sm:hidden">Price:</span>
                            <div className="xl:flex xl:flex-col">
                              <span className="text-black text-[12px]">
                                <input
                                  className="text-left sm:ms-[10px] bg-white"
                                  plaintext readOnly disabled
                                  type="text"
                                  id="price"
                                  name="price"
                                  {...register(`cartItems.${index}.price`)}

                                />
                              </span>{" "}

                              <span className="text-left ms-[5px]">/{getValues(`cartItems.${index}.productId`)?.primaryUnitsType == "Each" ? "Each" : customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA") ? 'yards' : customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA") ? "meters" : ""}</span>
                            </div>
                          </span>
                          {/* + - buttton for desktop and tab only*/}
                          <span className="h-[30px] justify-center sm:flex hidden sm:ms-[5px]">
                            <div className="flex w-[60px] xl:w-[80px] h-[20px] items-start">
                              <form className="max-w-xs mx-auto">
                                <label
                                  htmlFor="quantity-input"
                                  className="block text-[14px] font-medium text-gray-900"
                                ></label>
                                <div className="relative flex items-center sm:items-start max-w-[8rem]">
                                  <button
                                    type="button"
                                    id="decrement-button"
                                    data-input-counter-decrement="quantity-input"
                                    className="bg-white border border-gray-400 rounded-s p-1 h-5"
                                    onClick={() => {
                                      let selectedQty = ''

                                      let multipliers = getValues(`cartItems.${index}.multipliers`)?.map(ele => ele?.name)
                                      let selectedQtyToFindIdx = getValues(`cartItems.${index}.productQuantity`)
                                      let multiplierIndex = (parseInt(selectedQtyToFindIdx) / parseInt(multipliers[0])) - 1

                                      if (multiplierIndex !== 0) {
                                        if (Number.isInteger(multiplierIndex)) {
                                          selectedQty = multipliers[multiplierIndex - 1]
                                        } else if (!Number.isInteger(multiplierIndex)) {
                                          selectedQty = selectedQtyToFindIdx
                                        }

                                        setValue(`cartItems.${index}.productQuantity`, selectedQty)

                                        if (selectedQty) {
                                          let isQtyNotPresentInList = getValues(`cartItems.${index}.multipliers`)?.map(ele => ele.id == Number(selectedQty)).every(curr => curr == false)

                                          updateCartData.cartId = getValues(`cartItems.${index}._id`)
                                          updateCartData.quantity = selectedQty
                                          updateCartData.price = getValues(`cartItems.${index}.price`)
                                          updateCartData.collectionName = getValues(`cartItems.${index}.collectionName`).split("\n")[0]
                                          // updateCartData.promoCode = getValues(`cartItems.${index}`).appliedPromo
                                          updateCartData.promoCodeType = getValues(`cartItems.${index}`).appliedPromoType
                                          updateCartData.promoCode = ""

                                          const discount = getValues(`cartItems.${index}.discount`)

                                          if (discount === 'N/A' || discount === "Free Shipping") {

                                            updateCartData.subTotal = (getValues(`cartItems.${index}.price`) * parseInt(selectedQty)).toFixed(2)
                                            setValue(`cartItems.${index}.subTotal`, (getValues(`cartItems.${index}.price`) * parseInt(selectedQty)).toFixed(2))
                                          } else {
                                            let calsubTot = parseFloat(getValues(`cartItems.${index}.price`) * parseInt(selectedQty))
                                            updateCartData.subTotal = parseFloat(calsubTot - ((calsubTot * parseInt(discount?.replace("%", ""))) / 100)).toFixed(2)
                                            setValue(`cartItems.${index}.subTotal`, parseFloat(calsubTot - ((calsubTot * parseInt(discount?.replace("%", ""))) / 100)).toFixed(2))
                                          }
                                        } else {
                                          setValue(`cartItems.${index}.productQuantity`, qtyObj)
                                        }

                                        const cartId = getValues(`cartItems.${index}._id`)
                                        updateCartItem(cartId)
                                      } else {
                                        console.log("can not go pevious")
                                      }
                                    }}
                                  >
                                    <svg
                                      className={`w-2 h-2 text-gray-900
                                        //  ${updateCartData.quantity ? "text-gray-900" : "text-gray-400"}`
                                      }
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 18 2"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M1 1h16"
                                      />
                                    </svg>
                                  </button>
                                  <input
                                    type="text"
                                    id="quantity-input"
                                    {...register(`cartItems.${index}.productQuantity`)}
                                    disabled={(user?.userTypes[0] == "ADMIN_USER" || user?.userTypes[0] == "POWER_USER") ? false : true}
                                    data-input-counter
                                    aria-describedby="helper-text-explanation"
                                    className="border-y text-black placeholder:text-center placeholder:text-black border-gray-400 h-5 w-6 sm:w-6 text-[12px] text-center"
                                    onFocus={() => {
                                      setQtyObj(getValues(`cartItems.${index}.productQuantity`))
                                      setValue(`cartItems.${index}.productQuantity`, "")
                                    }}

                                    onClick={(e) => {
                                      console.log()
                                    }}

                                    onBlur={() => {
                                      let selectedQty = getValues(`cartItems.${index}.productQuantity`)
                                      setValue(`cartItems.${index}.productQuantity`, selectedQty)

                                      if (selectedQty) {
                                        let isQtyNotPresentInList = getValues(`cartItems.${index}.multipliers`)?.map(ele => ele.id == Number(selectedQty)).every(curr => curr == false)

                                        updateCartData.cartId = getValues(`cartItems.${index}._id`)
                                        updateCartData.quantity = selectedQty
                                        updateCartData.price = getValues(`cartItems.${index}.price`)
                                        updateCartData.collectionName = getValues(`cartItems.${index}.collectionName`).split("\n")[0]
                                        updateCartData.promoCode = getValues(`cartItems.${index}`).appliedPromo
                                        updateCartData.promoCodeType = getValues(`cartItems.${index}`).appliedPromoType

                                        const discount = getValues(`cartItems.${index}.discount`)

                                        if (discount === 'N/A') {

                                          updateCartData.subTotal = (getValues(`cartItems.${index}.price`) * parseInt(selectedQty)).toFixed(2)
                                          setValue(`cartItems.${index}.subTotal`, (getValues(`cartItems.${index}.price`) * parseInt(selectedQty)))
                                        } else {
                                          let calsubTot = parseFloat(getValues(`cartItems.${index}.price`) * parseInt(selectedQty))
                                          updateCartData.subTotal = parseFloat(calsubTot - ((calsubTot * parseInt(discount?.replace("%", ""))) / 100)).toFixed(2)
                                          setValue(`cartItems.${index}.subTotal`, parseFloat(calsubTot - ((calsubTot * parseInt(discount?.replace("%", ""))) / 100)))
                                        }
                                      } else {
                                        setValue(`cartItems.${index}.productQuantity`, qtyObj)
                                      }

                                      const cartId = getValues(`cartItems.${index}._id`)
                                      updateCartItem(cartId)
                                    }}
                                  />
                                  <button
                                    type="button"
                                    id="increment-button"
                                    data-input-counter-increment="quantity-input"
                                    className="bg-white border border-gray-400 rounded-e p-1 h-5"
                                    onClick={() => {
                                      let selectedQty = ''

                                      let multipliers = getValues(`cartItems.${index}.multipliers`)?.map(ele => ele?.name)
                                      let selectedQtyToFindIdx = getValues(`cartItems.${index}.productQuantity`)
                                      let multiplierIndex = (parseInt(selectedQtyToFindIdx) / parseInt(multipliers[0])) - 1

                                      if (parseInt(selectedQtyToFindIdx) < parseInt(multipliers[multipliers?.length - 1])) {
                                        if (Number.isInteger(multiplierIndex)) {
                                          selectedQty = multipliers[multiplierIndex + 1]
                                        } else if (!Number.isInteger(multiplierIndex)) {
                                          selectedQty = selectedQtyToFindIdx
                                        }

                                        setValue(`cartItems.${index}.productQuantity`, selectedQty)



                                        if (selectedQty) {
                                          let isQtyNotPresentInList = getValues(`cartItems.${index}.multipliers`)?.map(ele => ele.id == Number(selectedQty)).every(curr => curr == false)

                                          updateCartData.cartId = getValues(`cartItems.${index}._id`)
                                          updateCartData.quantity = selectedQty
                                          updateCartData.price = getValues(`cartItems.${index}.price`)
                                          updateCartData.collectionName = getValues(`cartItems.${index}.collectionName`).split("\n")[0]
                                          // updateCartData.promoCode = getValues(`cartItems.${index}`).appliedPromo
                                          updateCartData.promoCodeType = getValues(`cartItems.${index}`).appliedPromoType
                                          updateCartData.promoCode = ""

                                          const discount = getValues(`cartItems.${index}.discount`)

                                          if (discount === 'N/A' || discount === "Free Shipping") {

                                            updateCartData.subTotal = (getValues(`cartItems.${index}.price`) * parseInt(selectedQty)).toFixed(2)
                                            setValue(`cartItems.${index}.subTotal`, (getValues(`cartItems.${index}.price`) * parseInt(selectedQty)).toFixed(2))
                                          } else {
                                            let calsubTot = parseFloat(getValues(`cartItems.${index}.price`) * parseInt(selectedQty))
                                            updateCartData.subTotal = parseFloat(calsubTot - ((calsubTot * parseInt(discount?.replace("%", ""))) / 100)).toFixed(2)
                                            setValue(`cartItems.${index}.subTotal`, parseFloat(calsubTot - ((calsubTot * parseInt(discount?.replace("%", ""))) / 100)).toFixed(2))
                                          }
                                        } else {
                                          setValue(`cartItems.${index}.productQuantity`, qtyObj)
                                        }

                                        const cartId = getValues(`cartItems.${index}._id`)
                                        updateCartItem(cartId)
                                      } else {
                                        console.log("max limit reached")
                                      }
                                    }}
                                  >
                                    <svg
                                      className="w-2 h-2 text-gray-900"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 18 18"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M9 1v16M1 9h16"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </span>

                          {/* discount */}
                          <span className="text-[10px] sm:w-[60px] xl:w-[80px] sm:h-[20px] sm:flex sm:items-center sm:justify-center sm:mx-[10px]">
                            <span className="">
                              <input
                                type="text"
                                className=" bg-white hidden"

                                id="discount"
                                name="discount"
                                {...register(`cartItems.${index}.discount`)}
                              />
                            </span>
                            {field?.appliedPromoCode?.disCountType == "percentDiscount" && <span className="bg-white text-black text-[12px] text-center">{field?.discount} %</span>}
                            {field?.appliedPromoCode?.disCountType == "flatDiscount" && <span className="bg-white text-black text-[12px] text-center">$ {field?.discount}</span>}
                            {field?.appliedPromoCode?.disCountType == "freeShippingDiscount" && <span className="bg-white text-black text-[12px] text-center">{field?.discount}</span>}
                            {field?.appliedPromoCode?.disCountType == "N/A" && <span className="bg-white text-black text-[12px] text-center">{field?.discount}</span>}
                            {field?.appliedPromoCode?.disCountType == "" && <span className="bg-white text-black text-[12px] text-center">{field?.discount}</span>}
                          </span>

                          {/* subtotal */}
                          <span className="text-[12px] mt-[10px] text-black font-bold sm:mt-0 sm:w-[60px] xl:w-[80px] sm:h-[18px] sm:flex sm:justify-center sm:mx-[10px] sm:me-3 sm:pe-3">
                            ${"  "}<span className="">
                              <input
                                plaintext readOnly disabled
                                className=" bg-white font-bold text-black text-[12px]"
                                type="number"
                                id="subTotal"
                                name="subTotal"
                                {...register(`cartItems.${index}.subTotal`)} />
                            </span>
                          </span>
                        </div>
                      </div>
                      {/* desktop view delete icon */}
                      {!deleteIndividualLoading ?
                        <div className="cursor-pointer w-[60px] xl:w-[80px] h-[18px] sm:w-[60px] sm:h-[18px] hidden sm:flex sm:items-center sm:justify-center ms-[10px]"
                          onClick={(data) => {
                            const cartId = getValues(`cartItems.${index}._id`)
                            const cartItem = getValues(`cartItems.${index}`)
                            let cartItems = getValues(`cartItems`)
                            deleteCartItem(cartId, cartItem, cartItems, false)
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-[13px] h-[13px]"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                          </svg>
                        </div>
                        :
                        <div>...</div>
                      }
                    </div>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {/* )} */}
          {/* coupon code and instructor */}
          <div className=" my-3 w-[240px] h-[167px] sm:w-[600px] sm:h-[109px] sm:flex sm:flex-col sm:gap-6 xl:w-[740px] xl:h-[109px] bg-white">
            {/*input and buttons */}
            <div className="xl:ms-[140px] w-[240px] h-[88px] sm:w-[600px] sm:h-[34px] flex flex-col sm:flex-row justify-between xl:gap-5 mb-5 sm:mb-0">
              {/* 1 */}
              <div className="w-[240px] h-[34px] sm:w-[290px] sm:h-[34px] xl:w-[740px] xl:h-[109px] flex justify-between">
                <input
                  type="text"
                  className="w-[150px] h-[34px] sm:w-[200px] border-2 px-2 py-2 placeholder:text-[12px] text-[12px] text-black"
                  placeholder="Instruction"
                  id="specialInstruction"
                  name="specialInstruction"
                  {...register(`specialInstruction`)}
                  onChange={(e) => {

                    if (e.target.value) {
                      setUpdateData((prev) => ({ ...prev, specialInstruction: e.target.value }));
                    }
                  }}
                  onBlur={updateDataToAllGroupItems}
                />
              </div>
              {/* 2 */}
              <div className="w-[240px] h-[34px] sm:w-[290px] sm:h-[34px] xl:w-[740px] xl:h-[109px] flex justify-between">
                <input
                  type="text"
                  className="w-[150px] h-[34px] sm:w-[200px] border-2 px-2 py-2 placeholder:text-[12px] text-[12px] text-black"
                  placeholder="Promo Code"
                  id="promoCode"
                  name="promoCode"
                  {...register(`promoCode`)}
                />
                <button
                  type="button"
                  className="w-[80px] h-[34px] text-white bg-[#E2A856] font-medium rounded-[4px] text-[12px] uppercase hover:bg-black hover:text-white"
                  onClick={applyPromo}
                >
                  Submit
                </button>
              </div>
            </div>
            {/* collection total */}
            <div className=" w-full sm:flex sm:justify-end">
              <div className="w-[240px] h-[44px] sm:w-[314px] sm:h-[50px] text-[10px] sm:flex sm:flex-col sm:items-center sm:justify-end">
                <div className="flex justify-between sm:w-[314px] sm:h-[18px]">
                  <p>Collection Subtotal:</p>
                  {!subTotalLoading ? <p>$ {groupActualTotal ? groupActualTotal : 0}</p> :
                    <p>...</p>}
                </div>
                <div className="flex justify-between sm:w-[314px] sm:h-[18px] text-red-400 ">
                  <p>Discount applied:</p>
                  <p>-$ {parseFloat(parseFloat(groupActualTotal) - parseFloat(groupTotal))?.toFixed(2)}</p>
                </div>
                <div className=" jus tify-between text-red-400 hidden">
                  <p>Discount (Promo name) applied:</p>
                  <p>-$ 00.00</p>
                </div>
                <div className="mt-2 flex justify-between font-bold text-[14px] sm:w-[314px] sm:h-[24px]">
                  <p>Collection Total:</p>
                  {!subTotalLoading ? <p>$ {groupTotal}</p> :
                    <p>...</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};

export default DisplayCartDetails;