import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DeleteModal from "./DeleteModal";
import { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../states/contexts/CustomerContext";
import { UserContext } from "../../states/contexts/UserContext";
import { BrandIconContext } from "../../states/contexts/BrandIconContext";
import { useFieldArray, useForm } from "react-hook-form";
import ApiService from "../../helpers/ApiServices";
import { formatAddressByDefault, formatAddressForCustomer, getModelRoute, showMessage } from "../../helpers/Utils";
import Address from "./Address";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useAlert } from 'react-alert'


const ShippingAddress = () => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [country, setCountry] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const [tabKey, setTabKey] = useState('mediaLinks');
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [checkDefault, setCheckDefault] = useState(false)
  const [isDefaultTick, setIsDefaultTick] = useState([]);
  const [addressValue, setAddressValue] = useState('');
  const [addressValueLineLevel, setAddressValueLineLevel] = useState([]);
  const [editAddressModalState, setEditAddressModalState] = useState({});
  const [editAddressModalIndex, setEditAddressModalIndex] = useState();
  const [isDefaultTickAdd, setIsDefaultTickAdd] = useState(false);
  const [addrTouchFields, setAddrTouchFields] = useState(null);
  const [addedAddress, setAddedAddress] = useState(null);
  const [updatedAddress, setupdatedAddress] = useState(null);
  const [regions, setRegions] = useState([]);
  const [customerdata, setCustomerdata] = useState(null);
  const [showAddress, setShowAddress] = useState(true);
  const [addressArray, setAddressArray] = useState([]);
  const [index, setIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddrRemoveLoading, setIsAddrRemoveLoading] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);
  const [isNotRemoved, setIsNotRemoved] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // modalBox
  const [openModal, setOpenModal] = useState(null);

  const navigate = useNavigate();
  const alert = useAlert()

  const { dispatch: customerDispatch, customer } = useContext(CustomerContext)
  const { dispatch, user } = useContext(UserContext)
  const { backgroundColor, darkBackgroundColor } = useContext(BrandIconContext)


  const { register, control, reset, handleSubmit, getValues, setValue, watch, formState: { errors, isDirty, dirtyFields, touchedFields } } = useForm({

  });

  const { register: defaultRegister } = useForm();
  const { append: addressAppend, remove: addressRemove, fields: addressFields, update: addressUpdate, insert: addressInsert } = useFieldArray({ control, name: "addresses" });

  const [searchParams, setSearchParams] = useSearchParams()

  let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"
  let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))


  const onSubmit = (formData) => {
    let id;
    if (user?.userTypes.includes("CUSTOMER")) id = user?._id
    if (user?.userTypes.includes("SALES_MANAGER") || user?.userTypes.includes("SALES_REP") || user?.userTypes.includes("POWER_USER") || user?.userTypes.includes("ADMIN_USER")) id = customer?._id


    return updateDocument(id, formData)
  }

  const updateDocument = async (id, data) => {
    setIsLoading(true)

    // Delete address field from addresses array of object
    data?.addresses?.length && data?.addresses?.map(address => {
      delete address['address']
    })
    data.changedFields = Object.keys(touchedFields).filter(function (item) { return item !== "addresses" })


    await ApiService.patch(`shop/customer/${id}?protectModel=${getModelRoute(user).model}&updatedFrom=website`, data).then(response => {
      if (response.data.isSuccess) {
        let array = response.data.document?.addresses
        let document = response.data.document

        document.customerUpdate?.length && document.customerUpdate?.map(ele => {
          console.log(ele.fieldName, document[ele.fieldName])
          if (ele?.fieldName !== 'addresses')
            delete document[ele.fieldName]
        })

        document.customerUpdate?.length && document.customerUpdate?.map(ele => {
          reset({ [ele.fieldName]: ele?.new })
        })

        // Comparing two array of objects and replacing an object with another and add new
        let map = {};
        array.forEach(item => map[item._id] = item);
        document.customerUpdate.filter(ele => ele?.fieldName == "addresses").forEach(item => map[item.new?._id] = item?.new);
        const result = Object.values(map);

        document['addresses'] = result

        reset(document)
        setIsEditMode(false)
        showMessage("Profile has been updated successfully..", "success")
        alert.success("Profile has been updated successfully..")
        setIsLoading(false)
      }
    }).catch(error => {
      showMessage(error?.response?.data?.message, "error")
      alert.error(error?.response?.data?.message)
      setIsLoading(false)
    })
  }



  const findOneDocument = () => {
    ApiService.setHeader()
    ApiService.get(`shop/customer/${customer?._id}`).then(response => {
      if (response.data.isSuccess) {
        let array = response.data.document?.addresses
        let document = response.data.document

        document.customerUpdate?.length && document.customerUpdate?.map(ele => {

          if (ele?.fieldName !== 'addresses')
            delete document[ele.fieldName]
        })

        document.customerUpdate?.length && document.customerUpdate?.map(ele => {
          reset({ [ele.fieldName]: ele?.new })
        })

        // Comparing two array of objects and replacing an object with another and add new
        let map = {};
        array.forEach(item => map[item._id] = item);
        document.customerUpdate.filter(ele => ele?.fieldName == "addresses").forEach(item => map[item.new?._id] = item?.new);
        const result = Object.values(map);

        document['addresses'] = result

        reset(document)
        setIsEditMode(false)
        showMessage("Profile has been updated successfully..", "success")
        alert.success("Profile has been updated successfully..")
        setIsLoading(false)
      }
    }).catch(error => {

      showMessage(error?.response?.data?.message, "error")
      alert.error(error?.response?.data?.message)
      setIsLoading(false)
    })
  }

  const configureAddressArray = (customer) => {
    let addressArray = []

    customer?.addresses?.map(address => {
      let addressObj = address
      const v = formatAddressForCustomer(address)
      addressObj.address = v
      addressArray.push(addressObj)
    })
    return addressArray
  }

  const setCustomer = async () => {

    let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))

    ApiService.setHeader()
    await ApiService.get(`shop/customer/${customers?.length ? customers[customers?.length - 1]?._id : user?._id}`).then(response => {

      if (response.data.isSuccess) {
        let customer = response?.data.document
        setCustomerdata(customer)

        customer.addresses = configureAddressArray(customer)

        // Load the selected customer into reducer
        customerDispatch({ type: "SELECT_CUSTOMER", payload: customer });

        // Set customer data into field's
        customer.customerUpdate?.length && customer.customerUpdate?.map(ele => {
          if (ele?.fieldName !== 'addresses')
            customer[ele.fieldName] = ele?.new
        })

        // Comparing two array of objects and replacing an object with another and add new
        let map = {};
        customer?.addresses?.forEach(item => map[item._id] = item);
        customer?.customerUpdate?.filter(ele => ele?.fieldName == "addresses").forEach(item => map[item.new?._id] = item?.new);
        const result = Object.values(map);

        customer['addresses'] = result
        customer['state'] = ''
        customer['city'] = ''
        reset(customer)

      }
    }).catch(error => {

      showMessage(error.response.data.message, "error")

    })

  }

  const handleShow = (value) => {
    setShowAddressModal(value);

    if (isDefaultTick.length > 1) {
      let flag = true;
      isDefaultTick.map(e => {
        if (e) {
          flag = false;
          setCheckDefault(true);
        }
      })

      if (flag) {
        setCheckDefault(false)
      }
    }

  }

  const toggleHandler = () => {
    setIsUpdateForm(!isUpdateForm)
  }

  function closeModal() {
    setIsOpen(false);
    // cancelOption();
    setOpenModal(null)
  }

  const removeAddress = async (data, removedAddrIndex) => {
    setIsLoading(true)
    ApiService.setHeader()
    await ApiService.patch(`shop/customer/removeAddr/${customer?._id}?protectModel=${getModelRoute(user).model}`, data).then(response => {
      if (response.data.isSuccess) {
        setCustomer()
        setIsAddrRemoveLoading(false)
        showMessage("Address removed!", "success")
        alert.success("Address removed!")
        setIsLoading(false)
        addressRemove(removedAddrIndex);
      }
    }).catch(error => {
      setIsLoading(false)
      showMessage(error?.response?.data?.message, "error")
      alert.error(error?.response?.data?.message)
      setIsAddrRemoveLoading(false)
    })

  }


  useEffect(() => {
    setCustomer()

  }, []);

  return (
    <div
      style={{ fontFamily: "Open Sans" }}
      className="flex items-center justify-center"
    >

      {isLoading ? <div className="h-screen flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2400 2400" width="24" height="24"><g strokeWidth="200" strokeLinecap="round" stroke="#000" fill="none"><path d="M1200 600V100" /><path opacity=".5" d="M1200 2300v-500" /><path opacity=".917" d="M900 680.4l-250-433" /><path opacity=".417" d="M1750 2152.6l-250-433" /><path opacity=".833" d="M680.4 900l-433-250" /><path opacity=".333" d="M2152.6 1750l-433-250" /><path opacity=".75" d="M600 1200H100" /><path opacity=".25" d="M2300 1200h-500" /><path opacity=".667" d="M680.4 1500l-433 250" /><path opacity=".167" d="M2152.6 650l-433 250" /><path opacity=".583" d="M900 1719.6l-250 433" /><path opacity=".083" d="M1750 247.4l-250 433" /><animateTransform attributeName="transform" attributeType="XML" type="rotate" keyTimes="0;0.08333;0.16667;0.25;0.33333;0.41667;0.5;0.58333;0.66667;0.75;0.83333;0.91667" values="0 1199 1199;30 1199 1199;60 1199 1199;90 1199 1199;120 1199 1199;150 1199 1199;180 1199 1199;210 1199 1199;240 1199 1199;270 1199 1199;300 1199 1199;330 1199 1199" dur="0.83333s" begin="0s" repeatCount="indefinite" calcMode="discrete" /></g></svg>
      </div>
        :
        <div className="w-[320px] sm:w-[744px] xl:w-[1280px] 2x:w-[1728px] flex items-center justify-center">
          <div className="my-[80px] sm:my-[100px] w-[280px] sm:w-[624px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col items-center xl:flex-row xl:items-start gap-[60px] 2xl:gap-[100px]">
            {/* left side */}
            <div className="w-[280px] h-[258px] sm:w-[624px] xl:w-[280px] xl:h-[258px] flex flex-col gap-5">
              {/* heading */}
              <div className="w-[280px] h-[38px] sm:w-[624px] xl:w-[280px] text-center sm:text-start font-bold text-[28px]">
                Customer Profile
              </div>
              {/* horizontal line */}
              <hr className=" text-gray-500 bg-gray-500 h-1 w-[280px] sm:w-[624px] xl:w-[280px]" />
              {/* content */}
              <div className="w-[280px] h-[180px] flex flex-col sm:w-[624px] xl:w-[280px] bg-white">
                <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] text-[14px] xl:text-[14px] text-center flex items-center cursor-pointer">
                  <Link to={`/customer-profile?division=${division}`}>
                    <p className="ms-[15px]">Personal Info</p>
                  </Link>
                </div>
                <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] bg-[#F0EFEB] text-[14px] text-center flex items-center cursor-pointer">
                  <Link to={`/customer-profile/shippingAddress?division=${division}`}>
                    <p className="ms-[15px]">Shipping Addresses</p>
                  </Link>
                </div>
                <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] text-[14px] text-center flex items-center cursor-pointer">
                  {
                    user?.userTypes.includes("CUSTOMER") ? <Link to={`/update-password?division=${division}`}>
                      <p className="ms-[15px]">Change Password</p>
                    </Link> : user?.userTypes.includes("ADMIN_USER") && <Link to={`/update-password/${customer?._id}?division=${division}`}>
                      <p className="ms-[15px]">Change Password</p>
                    </Link>
                  }
                </div>
                <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] text-[14px] text-center flex items-center cursor-pointer">
                  <Link to={"/"}>
                    <p className="ms-[15px]"> Communication Preferences</p>
                  </Link>
                </div>
              </div>
            </div>

            {/* right side */}
            {!showAddressModal &&
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-[280px] h-[984px] sm:w-[624px] sm:h-[750px] xl:w-[820px] xl:h-[750px] 2xl:w-[940px] flex flex-col gap-[60px]">
                  {/* Shipping Addresses */}
                  <div className="w-[280px] sm:w-[624px] xl:w-[820px] flex flex-col gap-5">
                    {/* heading */}
                    <div className="w-[280px] h-[38px] font-bold text-[28px] text-center sm:text-start">
                      Shipping Addresses
                    </div>
                    {/* horizontal line */}
                    <hr className=" text-gray-500 bg-gray-500 h-[1px] w-[280px] sm:w-[624px] xl:w-[820px] 2xl:w-[940px]" />

                    {/* content */}
                    <div className="w-[280px] sm:w-[624px] xl:w-[820px] 2xl:w-[940px] gap-y-[20px] xl:gap-y-[30px] grid grid-cols-12 xl:grid-cols-12">
                      {addressFields.map((field, index) => (
                        <div
                          key={index}
                          className="w-[280px] h-[237px] sm:w-[309px] border-[1px] border-gray-300 rounded-md col-span-12 xl:col-span-5 flex flex-col p-[20px] justify-between"
                        >
                          {/* top section */}
                          <div className="w-[240px] text-[14px] h-[102px] sm:w-[269px] flex flex-col items-center justify-evenly">
                            <p className="w-[240px] h-[18px] sm:w-[269px]">
                              {getValues(`addresses.${index}.attention`)}
                            </p>
                            <p className="w-[240px] h-[18px] sm:w-[269px]">
                              {getValues(`addresses.${index}.address1`) && <div>{getValues(`addresses.${index}.address1`)}</div>}
                            </p>
                            <p className="w-[240px] h-[18px] sm:w-[269px]">
                              {getValues(`addresses.${index}.address2`) && <div>{getValues(`addresses.${index}.address2`)}</div>}
                            </p>
                            <p className="w-[240px] h-[18px] sm:w-[269px]">
                              {getValues(`addresses.${index}.state`)?.text}
                            </p>
                            <p className="w-[240px] h-[18px] sm:w-[269px]">
                              {getValues(`addresses.${index}.country`)?.text}
                            </p>
                            <p className="w-[240px] h-[18px] sm:w-[269px]">
                              {getValues(`addresses.${index}.zip`)}
                            </p>
                          </div>
                          {/* bottom section */}
                          <div className="w-[240px] h-[55px] sm:w-[269px] flex items-center justify-between">
                            {/* left */}
                            <div className="w-[136px] h-[55px] flex flex-col justify-between text-[10px] font-bold">
                              {getValues(`addresses.${index}.isDefaultBilling`) && (
                                <p className="w-[124px] h-[25px] bg-[#F0EFEB] flex items-center justify-center">
                                  Default Billing Address
                                </p>
                              )}
                              {getValues(`addresses.${index}.isDefaultShipping`) && (
                                <p className="w-[136px] h-[25px] bg-[#F0EFEB] flex items-center justify-center">
                                  Default Shipping Address
                                </p>
                              )}
                            </div>
                            {/* right */}
                            <div className="w-[83px] h-[55px] text-[12px] flex items-end justify-between font-bold">
                              <p className="w-[24px] cursor-pointer h-[18px] text-[#E2A856] hover:text-black hover:underline"
                                onClick={e => {
                                  handleShow(true);
                                  setIsEditMode(true);
                                  setEditAddressModalState(field);
                                  setEditAddressModalIndex(index);
                                }}
                              >
                                Edit
                              </p>
                              {/* </Link> */}
                              <Link>
                                {/* modal */}
                                <div>
                                  <DeleteModal
                                    showModal={openModal === index}
                                    cancelOption={() => setOpenModal(null)}
                                    index={index + 1}
                                    setIsNotRemoved={setIsNotRemoved}
                                    setIsRemoved={setIsRemoved}
                                  />


                                </div>
                                <div
                                >
                                  <p className="w-[49px] cursor-pointer h-[18px] text-[#E2A856] hover:text-black hover:underline"
                                    onClick={() => {
                                      removeAddress(getValues(`addresses.${index}`), index)
                                      let arr = [];
                                      if (!isDefaultTick[index]) {
                                        isDefaultTick.splice(index, 1);
                                        setIsDefaultTickAdd(false);
                                        setCheckDefault(false)
                                        isDefaultTick.map(e => {
                                          arr.push(false);
                                        })
                                        setIsDefaultTick(arr);
                                      } else {
                                        isDefaultTick.splice(index, 1);
                                      }

                                      const values = getValues();
                                      const v = formatAddressByDefault(values);
                                      setAddressValue(v);

                                      setAddedAddress(null)
                                      setupdatedAddress(null)
                                    }}
                                  >
                                    Remove
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* button */}
                    <div className="mt-[10px] w-full h-[49px] flex items-center justify-center sm:justify-start"
                      style={{ gap: 10 }}
                    >
                      <button type="submit"
                        className="w-[210px] h-[40px] rounded-[4px] uppercase text-center text-[16px] bg-[#E2A856] font-semibold text-white text-nowrap hover:bg-black"
                      >SAVE CHANGES</button>
                      <button
                        className="w-[210px] h-[40px] rounded-[4px] uppercase text-center text-[16px] bg-[#E2A856] font-semibold text-white text-nowrap hover:bg-black"
                        type="button"
                        onClick={() => {
                          setIndex(getValues('addresses')?.length)
                          handleShow(true);
                          setIsEditMode(false);
                          setEditAddressModalState([])

                          setAddedAddress(null)
                        }}
                      >
                        add new address
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            }

            {showAddressModal &&

              <Address
                state={editAddressModalState}
                isEditMode={isEditMode}
                show={showAddressModal}
                handleShow={(e) => handleShow(e)}
                addressAppend={addressAppend}
                checkDefault={checkDefault}
                addressFields={addressFields}
                isDefaultTick={isDefaultTick}
                setIsDefaultTick={setIsDefaultTick}
                setCountry={setCountry}
                country={country}
                isDefaultTickAdd={isDefaultTickAdd}
                setIsDefaultTickAdd={setIsDefaultTickAdd}
                setCheckDefault={setCheckDefault}
                setAddressValue={setAddressValue}
                addressValueLineLevel={addressValueLineLevel}
                setAddressValueLineLevel={setAddressValueLineLevel}
                editAddressModalIndex={editAddressModalIndex}
                addressUpdate={addressUpdate}
                addressInsert={addressInsert}
                addressRemove={addressRemove}
                setAddrTouchFields={setAddrTouchFields}
                setAddedAddress={setAddedAddress}
                setupdatedAddress={setupdatedAddress}
                index={index}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
              />
            }
          </div>
        </div>}
    </div>
  );
};
export default ShippingAddress;
