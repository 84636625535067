import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from "../../states/contexts/UserContext";
import { CustomerContext } from "../../states/contexts/CustomerContext";
import { BrandIconContext } from "../../states/contexts/BrandIconContext";
import { useContext, useState } from "react";
import { showMessage } from "../../helpers/Utils";
import ApiService from "../../helpers/ApiServices";
import { TokenService } from "../../helpers/StorageServices";
import { useForm } from "react-hook-form";
import { links } from "../../components/shared/headers/HomePageContent/Link";
import { useAlert } from 'react-alert'


const Login = () => {
  const [show, setShow] = useState(false);
  const [errorShow, seterrorShow] = useState("Your email or password is incorrect! Please try again.");

  const { dispatch, user } = useContext(UserContext)
  const { dispatch: customerDispatch, customer } = useContext(CustomerContext)
  const { backgroundColor, color, darkBackgroundColor } = useContext(BrandIconContext)

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const alert = useAlert()

  let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"

  const { register, control, reset, handleSubmit, getValues, setValue, watch, formState: { errors, isSubmitting, isSubmitted } } = useForm({});

  const onSubmit = async (e) => {
    let model, route

    e.preventDefault();
    const dataArr = [...new FormData(e.currentTarget)];
    const data = Object.fromEntries(dataArr);
    try {
      setShow(true)
      dispatch({ type: "LOGIN_START" });

      const response = await ApiService.post('/shop/user/login', data);

      if (response.data.isSuccess) {
        setShow(false)
        if (user && (user?.userTypes?.includes("CUSTOMER") || customer)) {
          links?.map(link => {
            if (link?.name == "Customer")
              link['isVisible'] = true
          })
        }
        if (user && (!user?.userTypes?.includes("CUSTOMER") || !user?.userTypes?.includes("SALES_REP"))) {

          links?.map(link => {
            if (link?.name == "Rapid Order")
              link['isVisible'] = true
          })
        }

        if (user && (user?.userTypes?.includes("CUSTOMER"))) {

          links?.map(link => {
            if (link?.name == "Rapid Order")
              link['isVisible'] = false
          })
        }

        const assignedWebsiteAccess = response?.data.document?.assignedWebsiteAccess.filter(e => e.text == "NORTHCOTT")[0]
        if (assignedWebsiteAccess != undefined) {

          // Check if the brand type is NORTHCOTT or not
          // Save the logged in user's token into lokalstorage
          TokenService.saveToken(response.data.token)

          const user = response.data.document;

          // Find model and route
          if (user?.userTypes?.includes("CUSTOMER")) {
            model = "Customer"
            route = "customer"
          } else {
            model = "User"
            route = "user"
          }

          ApiService.setHeader()
          const res = await ApiService.patch(`/shop/${route}/${user?._id}?protectModel=${model}&updatedFrom=website`, data.savePassword == "on" ? { savePassword: true } : { savePassword: false });
          if (res.data.isSuccess) {

            if (!user?.userTypes?.includes("CUSTOMER")) getTestAccount(user)

            if (res?.data.document.active) {

              dispatch({ type: "LOGIN_SUCCESS", payload: res.data.document });

              if (user.userTypes.includes("CUSTOMER")) {
                let array = []
                array.push(user)

                // Push empty array into local storage
                localStorage.setItem("PCTeRP.CUSTOMER_IDS", JSON.stringify(array))

                // Load the selected customer into reducer
                customerDispatch({ type: "SELECT_CUSTOMER", payload: user });


                showMessage("You have successfully logged in!", "success")
                alert.success("You have successfully logged in!")
                navigate(`/?division=${searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : 'NORTHCOTT'}`)
              } else {
                showMessage("You have successfully logged in!. Please select region and customer.", "success")
                alert.success("You have successfully logged in!. Please select region and customer.")
                navigate(`/region-customer?division=${searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : 'NORTHCOTT'}`)
              }


            } else {
              setShow(false)
              alert.info("this user does not have the access to login. Please contact with administrator.")
            }
          }
        } else {
          alert.info("You do not have the access to login!")
        }
      }



    } catch (error) {
      showMessage(error.response?.data.message, "error")
      alert.error(error.response?.data.message)
      setShow(false)
      dispatch({ type: "LOGIN_FAILURE" });

    }
  }

  const getTestAccount = async (user) => {
    let array = []
    // const customer = user?.testCustomerId
    const cadTestCustomer = user?.cadTestCustomerId
    const usdTestCustomer = user?.usdTestCustomerId
    if (cadTestCustomer)
      array.push(cadTestCustomer)
    if (usdTestCustomer)
      array.push(usdTestCustomer)

    // Push test customer into local storage
    localStorage.setItem("PCTeRP.CUSTOMER_IDS", JSON.stringify(array))
    if (user?.cadTestCustomerId) customerDispatch({ type: "SELECT_CUSTOMER", payload: cadTestCustomer });
    if (user?.usdTestCustomerId) customerDispatch({ type: "SELECT_CUSTOMER", payload: usdTestCustomer });
  }



  return (
    <div
      style={{ fontFamily: "Open Sans" }}
      className="flex items-center justify-center"
    >
      <div className="w-[280px] h-[446px] sm:w-[624px] sm:h-[452px] xl:w-[1160px] 2xl:w-[1608px] flex flex-col justify-center items-center my-[80px] sm:my-[100px] gap-10">
        {/* heading */}
        <div className="w-[280px] h-[66px] sm:w-[624px] sm:h-[72px] xl:w-[1160px] 2xl:w-[1608px] flex flex-col items-center justify-center gap-[10px]">
          <h2 className="w-[280px] h-[38px] sm:w-[624px] sm:h-[44px] xl:w-[1160px] 2xl:w-[1608px] text-center text-[28px] xl:text-[32px] font-bold text-gray-900">
            Sign In
          </h2>
          <p className="text-nowrap text-center text-xs text-gray-600 w-[225px] h-[18px]">
            Welcome back! Please enter your details
          </p>
        </div>
        {/* form and button*/}
        <div className="w-[280px] h-[340px] sm:w-[320px] sm:h-[340px] flex items-center justify-center">
          <form onSubmit={onSubmit}>
            {/* input fields */}
            <div className="w-[280px] h-[210px] sm:w-[320px] flex flex-col gap-5">
              {/* email */}
              <div className="w-[280px] h-[76px] sm:w-[320px] flex flex-col justify-between">
                <label
                  htmlFor="email"
                  className="block text-sm font-bold text-gray-700 "
                >
                  Email*
                </label>
                <div className="">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none rounded-md bg-white relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 sm:text-sm"
                    placeholder="Enter your email"
                  />
                </div>
              </div>
              {/* password */}
              <div className="w-[280px] h-[76px] sm:w-[320px] flex flex-col justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-bold text-gray-700"
                >
                  Password*
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 bg-white focus:z-10 sm:text-sm"
                    placeholder="Enter your password"
                  />
                </div>
              </div>
              <div className="w-[280px] h-[18px] sm:w-[320px] flex items-center justify-between">
                {/* remember me */}
                <div className="flex items-center">
                  <input
                    id="remember_me"
                    name="remember_me"
                    type="checkbox"
                    className="h-[17px] w-[17px] rounded-[2px] border-[#D9D9D9] text-black focus:ring-black hover:border-black checked:text-black peer relative shrink-0 appearance-none border after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-[url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==')] after:bg-[length:30px] after:bg-center after:bg-no-repeat after:content-[''] checked:bg-black focus:outline-none"
                  />
                  <label
                    htmlFor="remember_me"
                    className="ml-2 block text-xs text-gray-900"
                  >
                    Remember me
                  </label>
                </div>
                {/* forgot password */}
                <div className="text-xs">
                  <Link to={`/forgot-password?division=${division}`} className="text-xs ">
                    Forgot password?
                  </Link>
                </div>
              </div>
            </div>
            {/* sign in button */}
            <div className="mt-[30px] w-[280px] h-[49px] sm:w-[320px] flex items-center justify-center">
              <button
                type="submit"
                disabled={show}
                className={`w-[280px] h-[49px] cursor-pointer text-white sm:w-[320px] uppercase text-base font-semibold rounded ${show ? "bg-[#EECB9A]" : "bg-[#E2A856] cursor-pointer"}`}
              >
                Sign in
              </button>
              {/* </Link> */}
            </div>
            {/* bottom footer */}
            <div className="flex flex-col items-center mt-[15px]">
              <Link to={'/contact-us'}>
                <span className="flex justify-center text-[#6B6B66] hover:text-[#E2A856] cursor-pointer text-xs">
                  Contact our support
                </span>
              </Link>
            </div>
          </form>
        </div>
      </div >
    </div >
  );
};

export default Login;
