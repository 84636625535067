import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { Fragment, useContext, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { getModelRoute, showMessage } from "../../helpers/Utils";
import ApiService from "../../helpers/ApiServices";
import { CustomerContext } from "../../states/contexts/CustomerContext";
import { UserContext } from "../../states/contexts/UserContext";
import { BrandIconContext } from "../../states/contexts/BrandIconContext";
import { useForm } from "react-hook-form";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const EditStoreInfo = () => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const { dispatch: customerDispatch, customer } = useContext(CustomerContext)
  const { dispatch, user } = useContext(UserContext)
  const { backgroundColor, darkBackgroundColor } = useContext(BrandIconContext)


  const { register, control, reset, handleSubmit, getValues, setValue, watch, formState: { errors, isDirty, dirtyFields, touchedFields } } = useForm({

  });

  const [searchParams, setSearchParams] = useSearchParams()

  let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"
  let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))


  const onSubmit = (formData) => {
    let id;
    console.log(formData);
    if (user?.userTypes.includes("CUSTOMER")) id = user?._id
    if (user?.userTypes.includes("SALES_MANAGER") || user?.userTypes.includes("SALES_REP") || user?.userTypes.includes("POWER_USER") || user?.userTypes.includes("ADMIN_USER")) id = customer?._id
    return updateDocument(id, formData)
  }

  const updateDocument = async (id, data) => {
    setIsLoading(true)
    console.log("touch fields: ", Object.keys(touchedFields));

    // Delete address field from addresses array of object
    data?.addresses?.length && data?.addresses?.map(address => {
      delete address['address']
    })
    data.changedFields = Object.keys(touchedFields).filter(function (item) { return item !== "addresses" })

    await ApiService.patch(`shop/customer/${id}?protectModel=${getModelRoute(user).model}&updatedFrom=website`, data).then(response => {
      if (response.data.isSuccess) {
        let array = response.data.document?.addresses
        let document = response.data.document

        document.customerUpdate?.length && document.customerUpdate?.map(ele => {
          console.log(ele.fieldName, document[ele.fieldName])
          if (ele?.fieldName !== 'addresses')
            delete document[ele.fieldName]
        })

        document.customerUpdate?.length && document.customerUpdate?.map(ele => {
          reset({ [ele.fieldName]: ele?.new })
        })

        // Comparing two array of objects and replacing an object with another and add new
        let map = {};
        array.forEach(item => map[item._id] = item);
        document.customerUpdate.filter(ele => ele?.fieldName == "addresses").forEach(item => map[item.new?._id] = item?.new);
        const result = Object.values(map);

        document['addresses'] = result

        reset(document)
        showMessage("Profile has been updated successfully..", "success")
        setIsLoading(false)
        navigate(`/customer-profile?division=${division}`)
      }
    }).catch(error => {
      showMessage(error?.response?.data?.message, "error")
      setIsLoading(false)
    })
  }

  const findOneDocument = (id) => {


    ApiService.setHeader();
    return ApiService.get(`/shop/customer/${id}`).then(response => {
      const document = response?.data.document;
      reset(document);
    }).catch(e => {
      console.log(e.response?.data?.message);
    })

  }



  useEffect(() => {
    // Reset these data
    if (customer) {
      findOneDocument(customer?._id)
    }
  }, [])



  return (
    <div
      style={{ fontFamily: "Open Sans" }}
      className="flex items-center justify-center"
    >
      <div className="w-[320px] sm:w-[744px] xl:w-[1280px] 2x:w-[1728px] flex items-center justify-center">
        <div className="my-[80px] sm:my-[100px] w-[280px] sm:w-[624px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col items-center xl:flex-row xl:items-start gap-[60px] 2xl:gap-[100px]">
          {/* left side */}
          <div className="w-[280px] h-[258px] sm:w-[624px] xl:w-[280px] xl:h-[258px] flex flex-col gap-5">
            {/* heading */}
            <div className="w-[280px] h-[38px] sm:w-[624px] xl:w-[280px] text-center sm:text-start font-bold text-[28px]">
              Customer Profile
            </div>
            {/* horizontal line */}
            <hr className=" text-gray-500 bg-gray-500 h-1 w-[280px] sm:w-[624px] xl:w-[280px]" />
            {/* content */}
            <div className="w-[280px] h-[180px] flex flex-col sm:w-[624px] xl:w-[280px] bg-white">
              <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] bg-[#F0EFEB] text-[14px] xl:text-[14px] text-center flex items-center cursor-pointer">
                <Link to={`/customer-profile?division=${division}`}>
                  <p className="ms-[15px]">Personal Info</p>
                </Link>
              </div>
              <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] text-[14px] text-center flex items-center cursor-pointer">
                <Link to={`/customer-profile/shippingAddress?division=${division}`}>
                  <p className="ms-[15px]">Shipping Addresses</p>
                </Link>
              </div>
              <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] text-[14px] text-center flex items-center cursor-pointer">
                {
                  user?.userTypes.includes("CUSTOMER") ? <Link to={`/update-password?division=${division}`}>
                    <p className="ms-[15px]">Change Password</p>
                  </Link> : user?.userTypes.includes("ADMIN_USER") && <Link to={`/update-password/${customer?._id}?division=${division}`}>
                    <p className="ms-[15px]">Change Password</p>
                  </Link>
                }
              </div>
              <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] text-[14px] text-center flex items-center cursor-pointer">
                <Link to={"/"}>
                  <p className="ms-[15px]"> Communication Preferences</p>
                </Link>
              </div>
            </div>
          </div>

          {/* right side */}
          <div className="w-[280px] sm:w-[624px] xl:w-[820px] flex flex-col gap-[60px]">
            {/* storelinfo */}
            <div className="w-[280px] sm:w-[624px] xl:w-[820px]  flex flex-col gap-5">
              {/* heading */}
              <div className="w-[280px] h-[38px] sm:w-[624px] xl:w-[820px] flex flex-col items-center justify-center sm:flex-row sm:justify-between sm:items-center gap-5 sm:gap-0">
                {/* heading */}
                <div className="w-[186px] h-[36px] font-bold text-[28px] text-center sm:text-start">
                  Store Info
                </div>
              </div>
              {/* horizontal line */}
              <hr className=" text-gray-500 bg-gray-500 h-[1px] w-[280px] sm:w-[624px] xl:w-[820px]" />
              {/* content */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-[280px] flex flex-col sm:w-[624px] xl:w-[820px] gap-[30px]">
                  {/* input fields */}
                  <div className="w-[280px] sm:w-[624px] xl:w-[320px] flex flex-col gap-[20px]">
                    {/* company Name */}
                    <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                      <label
                        htmlFor="companyName"
                        className="block text-[14px] font-bold text-[#090800]  sm:w-[77px] sm:h-[21px] text-nowrap"
                      >
                        Company Name
                      </label>
                      <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                        <input
                          id="companyName"
                          name="companyName"
                          type="text"
                          {...register('companyName')}
                          className="appearance-none  relative block px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-non focus:z-10 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                          placeholder=""
                          disabled
                        />
                      </div>
                    </div>
                    {/* company Number */}
                    <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                      <label
                        htmlFor="companyNumber"
                        className="block text-[14px] font-bold text-[#090800] sm:w-[77px] sm:h-[21px] text-nowrap"
                      >
                        Company Number
                      </label>
                      <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                        <input
                          id="companyNumber"
                          name="companyNumber"
                          type="text"
                          {...register('customerId')}
                          className="appearance-none  relative block px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-non focus:z-10 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px] bg-[#CECECC]"
                          placeholder="CCHRP0"
                          disabled
                        />
                      </div>
                    </div>
                    {/* contact Name */}
                    {/* <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                      <label
                        htmlFor="contactName"
                        className="block text-[14px] font-bold text-[#090800]  sm:w-[77px] sm:h-[21px] text-nowrap"
                      >
                        Contact Name
                      </label>
                      <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                        <input
                          id="contactName"
                          name="contactName"
                          type="text"
                          className="appearance-none  relative block px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-non focus:z-10 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                          placeholder=""
                          disabled
                        />
                      </div>
                    </div> */}
                    {/* adddress 1 */}
                    {/* <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                      <label
                        htmlFor="address1"
                        className="block text-[14px] font-bold text-[#090800]  sm:w-[77px] sm:h-[21px] text-nowrap"
                      >
                        Address 1
                      </label>
                      <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                        <input
                          id="address1"
                          name="address1"
                          type="text"
                          className="appearance-none  relative block px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-non focus:z-10 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                          placeholder=""
                          disabled
                        />
                      </div>
                    </div> */}
                    {/* address 2 */}
                    {/* <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                      <label
                        htmlFor="address2"
                        className="block text-[14px] font-bold text-[#090800]  sm:w-[77px] sm:h-[21px] text-nowrap"
                      >
                        Address 2
                      </label>
                      <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                        <input
                          id="address2"
                          name="address2"
                          type="text"
                          className="appearance-none  relative block px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-non focus:z-10 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                          placeholder=""
                          disabled
                        />
                      </div>
                    </div> */}
                    {/* city */}
                    {/* <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                      <label
                        htmlFor="city"
                        className="block text-[14px] font-bold text-[#090800]  sm:w-[77px] sm:h-[21px] text-nowrap"
                      >
                        City
                      </label>
                      <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px] flex flex-col gap-[10px]">
                        <Menu
                          as="div"
                          className="relative inline-block text-left w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px]"
                        >
                          <div>
                            <Menu.Button className="text-[14px] text-[#9D9C99] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px] inline-flex gap-x-1.5 rounded bg-white px-3 py-2 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 justify-between">
                              <p>Vaughan</p>
                              <ChevronDownIcon
                                className="-mr-1 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="w-[280px] sm:w-[624px] xl:w-[320px] absolute right-0 z-10 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-[#090800] ",
                                        "block px-4 py-2 text-[14px]"
                                      )}
                                    >
                                      Vaughan
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-[#090800] ",
                                        "block px-4 py-2 text-[14px]"
                                      )}
                                    >
                                      Vaughan2
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-[#090800] ",
                                        "block px-4 py-2 text-[14px]"
                                      )}
                                    >
                                      Vaughan3
                                    </a>
                                  )}
                                </Menu.Item>
                                <form method="POST" action="#">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        type="submit"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-[#090800] ",
                                          "block w-full px-4 py-2 text-left text-[14px]"
                                        )}
                                      >
                                        Vaughan4
                                      </button>
                                    )}
                                  </Menu.Item>
                                </form>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div> */}
                    {/* state / province */}
                    {/* <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                      <label
                        htmlFor="State/Province"
                        className="block text-[14px] font-bold text-[#090800]  sm:w-[77px] sm:h-[21px] text-nowrap"
                      >
                        State/Province
                      </label>
                      <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px] flex flex-col gap-[10px]">
                        <Menu
                          as="div"
                          className="relative inline-block text-left w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px]"
                        >
                          <div>
                            <Menu.Button className="text-[14px] text-[#9D9C99] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px] inline-flex gap-x-1.5 rounded bg-white px-3 py-2 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 justify-between">
                              <p>Ontario</p>
                              <ChevronDownIcon
                                className="-mr-1 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"

                          >
                            <Menu.Items className="w-[280px] sm:w-[624px] xl:w-[320px] absolute right-0 z-10 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-[#090800] ",
                                        "block px-4 py-2 text-[14px]"
                                      )}
                                    >
                                      Ontario
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-[#090800] ",
                                        "block px-4 py-2 text-[14px]"
                                      )}
                                    >
                                      Ontario2
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-[#090800] ",
                                        "block px-4 py-2 text-[14px]"
                                      )}
                                    >
                                      Ontario3
                                    </a>
                                  )}
                                </Menu.Item>
                                <form method="POST" action="#">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        type="submit"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-[#090800] ",
                                          "block w-full px-4 py-2 text-left text-[14px]"
                                        )}
                                      >
                                        Ontario4
                                      </button>
                                    )}
                                  </Menu.Item>
                                </form>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div> */}
                    {/* phone */}
                    <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                      <label
                        htmlFor="phone"
                        className="block text-[14px] font-bold text-[#090800]  sm:w-[77px] sm:h-[21px] text-nowrap"
                      >
                        Phone
                      </label>
                      <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                        <input
                          id="phone"
                          name="phone"
                          type="number"
                          {...register('phone')}
                          className="appearance-none  relative block px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-non focus:z-10 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                          placeholder=""
                          disabled
                        />
                      </div>
                    </div>
                    {/* cell */}
                    <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                      <label
                        htmlFor="cell"
                        className="block text-[14px] font-bold text-[#090800]  sm:w-[77px] sm:h-[21px] text-nowrap"
                      >
                        Cell
                      </label>
                      <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                        <input
                          id="cell"
                          name="cell"
                          type="text"
                          {...register('cellPhone')}
                          className="appearance-none  relative block px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-non focus:z-10 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                          placeholder=""
                        />
                      </div>
                    </div>
                    {/* email */}
                    <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                      <label
                        htmlFor="email"
                        className="block text-[14px] font-bold text-[#090800]  sm:w-[77px] sm:h-[21px] text-nowrap"
                      >
                        Email
                      </label>
                      <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          {...register('storeEmail')}
                          className="appearance-none  relative block px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-non focus:z-10 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                          placeholder=""
                        />
                      </div>
                    </div>
                    {/* website */}
                    <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                      <label
                        htmlFor="website"
                        className="block text-[14px] font-bold text-[#090800]  sm:w-[77px] sm:h-[21px] text-nowrap"
                      >
                        Website
                      </label>
                      <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                        <input
                          id="website"
                          name="website"
                          type="text"
                          {...register('storeWebsite')}
                          className="appearance-none  relative block px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-non focus:z-10 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  {/* buttons */}
                  <div className="h-[49px] flex items-center justify-center sm:justify-start">
                    <div className="w-[205px] h-[49px] flex items-center justify-between">
                      <button
                        disabled={isLoading}
                        className={`w-[100px] h-[40px] rounded text-center text-[16px] font-semibold text-[#FFFFFF] hover:bg-black ${isLoading ? "bg-[#EECB9A]" : "bg-[#E2A856] cursor-pointer"} `}
                        type="submit"
                      >
                        {isLoading ? "loading..." : "UPDATE"}
                      </button>
                      {/* </Link> */}
                      <Link to={"/customer-profile"}>
                        <button
                          className="underline w-[67px] h-[24px] text-center text-[16px] text-[#E2A856] font-semibold bg-white text-nowrap hover:text-black"
                          type="button"
                        >
                          Go Back
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditStoreInfo;
