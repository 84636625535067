
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UserContext } from "../../states/contexts/UserContext";
import { useForm } from "react-hook-form";
import ApiService from "../../helpers/ApiServices";
import { getModelRoute, showMessage } from "../../helpers/Utils";
import { useAlert } from 'react-alert'


const ResetPassword = () => {
  const [isValidToken, setIsValidToken] = useState(false)
  const [isInvalidValidToken, setIsInvalidValidToken] = useState(false)
  const [model, setModel] = useState('')

  const { user } = useContext(UserContext)

  const { register, control, reset, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const { token } = useParams()
  const [searchParams] = useSearchParams();
  const alert = useAlert()


  const onSubmit = (formData) => {
    return resetPasswordHander(formData);
  }

  const resetPasswordHander = (data) => {
    console.log(data)
    console.log(getModelRoute(user))
    console.log(searchParams.get('model'))

    if (data.passwordConfirm == data.password) {
      ApiService.setHeader()
      ApiService.patch(`shop/user/resetPassword/${token}?model=${searchParams.get('model') ? searchParams.get('model') : model}`, data).then(response => {
        console.log(response);

        if (response.data.isSuccess) {
          navigate('/login')
          showMessage("Password reset successfull.", "success")
          alert.success("Password reset successfull.")
        } else {
          // console.log(response?.data.message)
          // showMessage(response?.data.message, "error")
        }

      }).catch(err => {
        showMessage(err.response.data.message, "error")
        alert.error(err.response?.data?.message)
      })
    } else {
      showMessage("Passwords are not the same!", "warning")
      alert.info("Passwords are not the same!")
    }
  }

  const checkResetTokenValidity = () => {
    console.log(searchParams.get('model'))
    console.log()
    setModel(searchParams.get('model'))

    ApiService.setHeader()
    ApiService.patch(`shop/user/checkResetPassword/${token}?model=${searchParams.get('model')}`).then(response => {
      console.log(response);

      if (response.data.isSuccess) {
        setIsValidToken(true)

      } else {
        // console.log(response?.data.message)
        // showMessage(response?.data.message, "error")
        setIsInvalidValidToken(true)
      }

    }).catch(err => {
      setIsInvalidValidToken(true)
      // showMessage(err.response.data.message, "error")
    })
  }


  useEffect(() => {
    checkResetTokenValidity()
  }, []);




  return (
    <div
      style={{ fontFamily: "Open Sans" }}
      className="flex items-center justify-center"
    >
      <div className="w-[280px] h-[428px] sm:w-[624px] sm:h-[396px] xl:w-[1160px] my-[80px] sm:my-[100px] flex flex-col items-center justify-center gap-10">
        {/* top heading */}
        <div className="w-[280px] h-[104px] sm:w-[624px] sm:h-[72px] xl:w-[1160px] flex flex-col gap-[10px] items-center justify-center">
          <h2 className="w-[280px] h-[76px] sm:w-[624px] sm:h-[44px] xl:w-[1160px] text-center text-[28px] sm:text-[32px] font-bold text-black">
            Enter New Password
          </h2>
          <p className="w-[280px] h-[18px] sm:w-[624px] sm:h-[18px] xl:w-[1160px] text-[#090800] text-center text-[12px]">
            Please enter a new password for your account.
          </p>
        </div>

        {/* input and submit button */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[280px] h-[284px] sm:w-[320px] flex flex-col justify-between items-center">
            {/* input fileds*/}
            <div className="w-[280px] h-[172px] sm:w-[320px] sm:h-[172px] flex flex-col items-center justify-between">
              <div className="w-[280px] h-[76px] sm:w-[320px]">
                <label
                  htmlFor="password"
                  className="block text-[14px] font-bold text-black "
                >
                  New Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    {...register("password")}
                    required
                    className="appearance-none  relative block w-full px-3 py-3 border border-gray-300 placeholder-[#9D9C99] text-[#090800] focus:outline-non focus:z-10 text-[14px]"
                    placeholder="Enter your new password"
                  />
                </div>
              </div>
              <div className="w-[280px] h-[76px] sm:w-[320px]">
                <label
                  htmlFor="confirmPassword"
                  className="block text-[14px] font-bold text-black "
                >
                  Confirm New Password
                </label>
                <div className="mt-1">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    {...register("passwordConfirm")}
                    required
                    className="appearance-none  relative block w-full px-3 py-3 border border-gray-300 placeholder-[#9D9C99] text-[#090800] focus:outline-non focus:z-10 text-[14px]"
                    placeholder="Re-enter your new password"
                  />
                </div>
              </div>
            </div>
            {/* submit button and footer */}
            <div className="w-[280px] h-[82px] sm:w-[320px] flex flex-col justify-between items-center">
              {/* button */}

              {/* <Link to={"/login"}> */}
              <button
                type="submit"
                className="w-[280px] h-[49px] sm:w-[320px] flex justify-center items-center font-semibold px-2 border border-transparent text-base rounded-md text-white bg-[#E2A856] focus:outline-none focus:ring-2 focus:ring-offset-2 text-center hover:bg-black hover:border-black"
              >
                SUBMIT
              </button>
              {/* </Link> */}

              {/* return to sign in */}
              <div className="flex justify-center items-center h-[18px]">
                <span className="text-[12px] text-[#6B6B66] cursor-pointer hover:text-[#E2A856]">
                  <Link to={"/login"}>Return to sign in</Link>
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
