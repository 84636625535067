import { useContext, useEffect, useState } from "react";
import CartProduct from "../../assets/ShoppingCart/CartProduct.svg";
import calendar_month from "../../assets/ShoppingCart/calendar_month.svg";
import moment from "moment";
import { getModelRoute, showMessage } from "../../helpers/Utils";
import ApiService from "../../helpers/ApiServices";
import { CustomerContext } from "../../states/contexts/CustomerContext";
import { BrandIconContext } from "../../states/contexts/BrandIconContext";
import { UserContext } from "../../states/contexts/UserContext";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

const MyOrders = () => {
  const [state, setState] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const rootPath = location?.pathname?.split('/')[1];

  const { dispatch: customerDispatch, customer } = useContext(CustomerContext)
  // const { division } = useContext(BrandIconContext)
  const { dispatch, user } = useContext(UserContext)

  const [searchParams, setSearchParams] = useSearchParams()

  let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"

  let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))
  let headerColor;



  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const handleSearch = (e) => {
    gridApi.setQuickFilter(e.target.value);
  }

  const handleExportAsCsv = (e) => {
    gridApi.exportDataAsCsv();
  }

  const getOrders = async () => {
    console.log(customers[customers?.length - 1]?._id);
    setIsLoading(true)
    try {
      ApiService.setHeader();
      const response = await ApiService.get(`admin/order/orderByCustomer/${customers[customers?.length - 1]?._id}?model=${getModelRoute(user).model}`);
      if (response?.data.isSuccess) {
        console.log(response?.data.documents);
        setState(response?.data.documents)
        setFilterData(response?.data.documents)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error.response?.data)
      // console.log("Else Catch")
      showMessage(error.response?.data.message, "error")
    }
  }

  const goToOrderDetailPage = (id) => {
    navigate(`/order/${id}`)
  }


  const columns = [
    { headerName: 'ORDER DATE', field: 'orderDate', width: 160, valueGetter: (params) => params.data?.orderDate ? moment(params.data?.orderDate).format("MM/DD/YYYY ") : "Not Available" },
    { headerName: 'SHIPPING DATE', field: 'shippingDate', width: 160, valueGetter: (params) => params.data?.shippingDate ? moment(params.data?.shippingDate).format("MM/DD/YYYY ") : "Not Available" },
    // { headerName: 'ORDER NUMBER', field: 'orderNumber', width: 300, cellRendererFramework: (params) => <span style={{ cursor: "pointer" }} onClick={() => { goToOrderDetailPage(params.data._id) }}>{params.data.orderNumber}</span> },
    { headerName: 'ORDER NUMBER', field: 'orderNumber', width: 300, cellRendererFramework: (params) => <Link style={{ cursor: "pointer", color: "black" }} to={`/order/${params.data._id}`}>{params.data.orderNumber}</Link> },
    { headerName: 'PO NUMBER', field: 'customerPONumber', width: 200 },
    { headerName: 'TOTAL', field: 'total', width: 240, valueGetter: (params) => params.data?.total.toFixed(2) },
    {
      headerName: 'STATUS', field: 'status', width: 258,
      // cellRendererFramework: (params) => (customer?.isSOApprovalNeeded && (params.data?.status?.toUpperCase() === "PROCESSING" || params.data?.status?.toUpperCase() === "PENDING APPROVAL")) ?
      //     <p style={{ display: "flex", gap: "10px" }}>
      //         <span>{params.data?.status?.toUpperCase()}</span>
      //         <span><BsFlagFill style={{ color: "red" }} /></span>
      //     </p>

      //     : params.data?.status?.toUpperCase()
    },
    // { headerName: 'DETAIL', field: 'customerPONumber', width: 80, cellRendererFramework: (params) => <FcSearch style={{ cursor: "pointer" }} onClick={() => { goToOrderDetailPage(params.data._id) }} /> }
  ]




  useEffect(() => {
    getOrders()
  }, []);

  useEffect(() => {
    console.log("state", state);
    const result = state?.filter(data => {

      return data.status?.toLowerCase().match(search.toLowerCase())
    })

    setFilterData(result)
  }, [search]);

  console.log("state", state);


  return (
    <>
      <div className="flex flex-col items-center justify-center my-[80px] sm:my-[100px] gap-y-[60px]">
        {/*top heading*/}
        <div className="w-[320px] h-[38px] sm:w-[744px] sm:h-[44px] flex xl:w-[1160px] 2xl:w-[1320px] items-center justify-center">
          <h1 className="text-[28px] xl:text-[32px] font-bold mx-auto text-center">
            Order History
          </h1>
        </div>
        {/* table */}
        <div className="w-[280px] sm:w-[664px] xl:w-[1160px] flex items-center xl:justify-center border border-[#CECECC] overflow-auto">
          <table className="w-[1120px] flex flex-col items-center justify-center gap-y-[20px] py-[20px]">
            <thead className="w-[1120px] h-[28px] flex justify-center border-b border-[#CECECC]">
              <tr className="w-[1120px] h-[28px] flex justify-between items-center">
                <th className="w-[100px] h-[18px] text-[12px] text-[#6B6B66]">
                  Order Date
                </th>
                <th className="w-[200px] h-[18px] text-[12px] text-[#6B6B66]">
                  Web Order #
                </th>
                <th className="w-[100px] h-[18px] text-[12px] text-[#6B6B66]">
                  Sales Order #
                </th>
                <th className="w-[250px] h-[18px] text-[12px] text-[#6B6B66]">
                  Order Status
                </th>
                <th className="w-[100px] h-[18px] text-[12px] text-[#6B6B66]">
                  Order By
                </th>
                <th className="w-[100px] h-[18px] text-[12px] text-[#6B6B66]">
                  Order Amount
                </th>
              </tr>
            </thead>
            <tbody className="w-[1120px] flex flex-col items-center justify-center divide-y divide-[#CECECC]">
              {state?.length ? state?.map((item, index) => (
                <tr
                  key={index}
                  className="ms-[10px] w-[1120px] h-[41px] flex justify-between items-center font-thin"
                >
                  <th className="w-[100px] h-[21px] text-[14px] text-black">
                    {item?.orderDate ? moment(item?.orderDate).format("MM/DD/YYYY ") : "Not Available"}
                  </th>
                  <th className="w-[200px] h-[21px] text-[14px] text-black">
                    <Link style={{ cursor: "pointer", color: "black" }} to={`/order/${item?._id}?division=${division}`}>{item?.orderNumber}</Link>
                  </th>
                  <th className="w-[100px] h-[21px] text-[14px] text-black">
                    {item?.proOrderNumber}
                  </th>
                  <th className="w-[250px] h-[21px] text-[14px] text-black">
                    {item?.status}
                  </th>
                  <th className="w-[115px] h-[21px] text-[14px] text-black text-nowrap">
                    {item?.orderBy?.text}
                  </th>
                  <th className="w-[100px] h-[21px] text-[14px] text-black">
                    $ {item?.total.toFixed(2)}
                  </th>
                </tr>
              )) : ""}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MyOrders;
