import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import CartProduct from "../../assets/ShoppingCart/CartProduct.svg";
import calendar_month from "../../assets/ShoppingCart/calendar_month.svg";
import { formatAddressForSaveInDB, formatAddressNew, getModelRoute, showMessage } from "../../helpers/Utils";
import ApiService from "../../helpers/ApiServices";
import { useForm } from "react-hook-form";
import { BrandIconContext } from "../../states/contexts/BrandIconContext";
import { CartContext } from "../../states/contexts/CartContext";
import { UserContext } from "../../states/contexts/UserContext";
import { CustomerContext } from "../../states/contexts/CustomerContext";
import moment from "moment";
import DisplayOderItems from "../../components/elements/components/DisplayOderItems";
import { useAlert } from 'react-alert'


const Order = () => {
  const [state, setState] = useState([]);
  const [carts, setCarts] = useState([]);
  const [shipDates, setShipDates] = useState([]);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [finalShippingAddresses, setFinalShippingAddresses] = useState([]);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState('');
  const [total, setTotal] = useState(0);
  const [collectionSubTotal, setCollectionSubTotal] = useState(0);
  const [collectionTotal, setCollectionTotal] = useState(0);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [shippingtype, setShipingType] = useState('');
  const [totalDiscount, setTotalDiscount] = useState(0.00);
  const [actualTotal, setActualTotal] = useState(0.00);
  const [ordersLength, setOrderslength] = useState(null);

  // All reducers
  const { dispatch: customerDispatch, customer } = useContext(CustomerContext)
  const { dispatch, user } = useContext(UserContext)
  const { dispatch: cartDispatch, quantity } = useContext(CartContext)
  const { color, backgroundColor } = useContext(BrandIconContext)

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const { shipType } = useParams();
  const alert = useAlert()

  // All local storage data
  let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"
  let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))
  let uniteType;
  let orderNumber = ''

  // All useForms
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {

    }
  });

  console.log("shipType: ", shipType);
  // All functions
  const onSubmit = (data) => {
    setLoadingStatus(true)

    let arr = []
    console.log("state: ", state);
    console.log("finalShippingAddresses: ", finalShippingAddresses);
    // data.cartItems = state

    try {
      ApiService.setHeader();
      // ApiService.get(`admin/order?model=${getModelRoute(user).model}`).then(response => {
      ApiService.get(`shop/order/orderByCustomer/${customers[customers?.length - 1]?._id}?model=${getModelRoute(user).model}`).then(response => {

        if (response?.data.isSuccess) {
          console.log(response?.data.documents);

          if (shipType === "orderComplete") {
            data.cartItems = formatCartItemForShipComplete(state)
            data.shippingDate = searchParams.get("shippingDate") !== 'null' ? new Date(searchParams.get("shippingDate"))?.toISOString() : moment.max(shipDates)._i
            // data.orderNumber = `N${nanoid(9)}`
            // data.orderNumber = `${formatOrderNumber()}${response?.data.documents?.length + 1}`
            data.orderNumber = `${formatOrderNumber()}${ordersLength + 1}`
            data.status = customer?.isPFApprovalNeeded ? "Credit approval" : customer?.isSOApprovalNeeded ? "Credit hold" : "Pending Fulfillment"
            data.total = total
            data.orderBy = {
              id: user?._id,
              text: user?.name,
              value: user?.netsuiteId
            }
            data.onBehalfOf = {
              id: customers[customers?.length - 1]?._id,
              text: customers[customers?.length - 1]?.name,
              value: customers[customers?.length - 1]?.netsuiteId
            }
            data.billTo = customer?.addresses.length ? formatAddressForSaveInDB(customer?.addresses.filter(address => address.isDefaultBilling)[0]) : ""
            data.shipTo = customer?.addresses.length ? formatAddressForSaveInDB(finalShippingAddresses?.length == 1 ? finalShippingAddresses[0] : finalShippingAddresses.filter(address => address.address1 == selectedShippingAddress)[0]) : ""
            // data.subsidiary = customer?.addresses.filter(address => address.isDefaultBilling)[0]?.country.text === 'CANADA' ? "C" : "U"
            data.subsidiary = customer?.subsidiary?.text?.toUpperCase()?.includes("CANADA") ? "C" : "U"
            // data.division = customer.brandTypes[0]?.text === "NORTHCOTT" ? "Northcott" : "Figo"
            data.division = 'Northcott'
            data.comesFrom = 'NEW WEBSITE'

          } else if (shipType === "orderByCollection") {
            let orderCount = ordersLength + 1;
            carts.forEach((ele, index) => {
              let obj = {}
              let items = formatCartItemForShipComplete(ele[1])

              obj.cartItems = formatCartItemForShipComplete(ele[1])
              obj.shippingDate = ele[1][0].shipDate
              obj.specialInstruction = ele[1][0].specialInstruction
              // obj.orderNumber = `N${nanoid(9)}`
              // obj.orderNumber = `${formatOrderNumber()}${response?.data.documents?.length + index + 1}`
              obj.orderNumber = `${formatOrderNumber()}${orderCount}`
              obj.status = customer?.isPFApprovalNeeded ? "Credit approval" : customer?.isSOApprovalNeeded ? "Credit hold" : "Pending Fulfillment"
              obj.total = items?.reduce(function getSum(total, item) {
                return (parseFloat(total) + parseFloat(item?.subTotal)).toFixed(2);
              }, 0)
              obj.orderBy = {
                id: user?._id,
                text: user?.name,
                value: user?.netsuiteId
              }
              obj.onBehalfOf = {
                id: customers[customers?.length - 1]?._id,
                text: customers[customers?.length - 1]?.name,
                value: customers[customers?.length - 1]?.netsuiteId
              }
              obj.billTo = customer?.addresses.length ? formatAddressForSaveInDB(customer?.addresses.filter(address => address.isDefaultBilling)[0]) : ""
              // obj.shipTo = customer?.addresses.length ? formatAddressForSaveInDB(customer?.addresses.filter(address => address.address1 == selectedShippingAddress)[0]) : ""
              obj.shipTo = customer?.addresses.length ? formatAddressForSaveInDB(finalShippingAddresses?.length == 1 ? finalShippingAddresses[0] : finalShippingAddresses.filter(address => address.address1 == selectedShippingAddress)[0]) : ""
              // obj.subsidiary = customer?.addresses.filter(address => address.isDefaultBilling)[0]?.country.text === 'CANADA' ? "C" : "U"
              obj.subsidiary = customer?.subsidiary?.text?.toUpperCase()?.includes("CANADA") ? "C" : "U"
              // obj.division = customer.brandTypes[0]?.text === "NORTHCOTT" ? "Northcott" : "Figo"
              obj.division = 'Northcott'
              obj.comesFrom = 'NEW WEBSITE'

              arr.push(obj)
              orderCount++;
            })
          }

          console.log(data);
          console.log(arr);

          if (arr.length) {
            if (arr?.length == 1) {
              return createOrder(arr[0])
            } else if (arr?.length > 1) {
              return createMultipleOrder(arr)
            }
          } else {
            data.specialInstruction = searchParams.get("shippingInstruction")

            console.log(data);
            return createOrder(data)
          }
        }
      })
    } catch (error) {
      console.log(error.response)
    }
  }

  const formatOrderNumber = () => {
    let country = null
    if (customers !== null) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        country = "UNITED STATES"
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
        country = "CANADA"
    }

    const countryCode = country?.toUpperCase() === "CANADA" ? 'C' : 'U'
    const customerNumber = customers[customers?.length - 1]?.customerId

    return `${customerNumber}-N`
  }

  const getOrdersLength = () => {
    ApiService.setHeader();
    ApiService.get(`shop/order/orderByCustomer/${customers[customers?.length - 1]?._id}?model=${getModelRoute(user).model}`).then(response => {
      if (response?.data.isSuccess) {
        console.log("ele", response?.data.documents?.length);
        setOrderslength(response?.data.documents?.length)
      }
    }).catch(error => {
      console.log(error.response?.data)
      showMessage(error.response?.data.message, "error")
    })
  }

  const createOrder = (data) => {
    console.log('one order means combine shipment')
    console.log('create order data: ', data)
    let orderData

    return ApiService.post(`shop/order?protectModel=${getModelRoute(user).model}`, data).then(async response => {
      if (response?.data.isSuccess) {

        const order = response.data.document
        console.log(order);

        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
        let initials = [...order.orderBy.text.matchAll(rgx)] || [];

        order.customerId = order.onBehalfOf.id.customerId;
        order.customerNetsuiteId = order.onBehalfOf.id.customerNetsuiteId;
        order.shippingDate = moment(order.shippingDate).format("MM/DD/YYYY")
        order.orderBy = initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
        console.log("order: ", order);
        orderData = order

        // Push the order to netsuite
        ApiService.setHeader();
        // const res = await ApiService.post(`admin/nsOrder`, order)
        const res = await ApiService.post(`shop/nsOrder?protectModel=${getModelRoute(user).model}`, order)
        console.log(res);
        if (res.data.isSuccess) {

          console.log("success");
          const resp = await ApiService.patch(`shop/order/${order._id}?protectModel=${getModelRoute(user).model}`, { netsuiteId: res.data.document.id })
          if (resp.data.isSuccess) {
            console.log("order after so create: ", resp.data.document)
            setState(resp.data.document)
            setLoadingStatus(false)
            // navigate(`/${rootPath}/order/edit/${id}`)
            showMessage("Your order has been Placed successfully!", 'info')
            // alert.success("Your order has been Placed successfully!")

            // Update customer if order is placed by an unapproved address
            console.log("selected shipping addr: ", selectedShippingAddress)

            ApiService.setHeader()
            ApiService.get(`/admin/customer/${customer?._id}`).then(response => {
              let customerDoc = response?.data.document

              const isApprovredAddr = shippingAddresses?.filter(address => address.address1 == selectedShippingAddress)[0]?.isapproved
              if (!isApprovredAddr) {
                customerDoc?.customerUpdate.map(ele => {
                  if (ele?.fieldName == "addresses" && ele?.new?.address1?.trim() == selectedShippingAddress?.trim()) {
                    // update customerUpdate
                    ele['note'] = `A Sales Order (${resp.data.document?.proOrderNumber}) is created with this unapproved address!`
                  }
                })

                console.log("customerUpdate: ", customerDoc?.customerUpdate)

                ApiService.setHeader()
                ApiService.patch(`/admin/customer/${customer?._id}?updatedFrom=admin&protectModel=${getModelRoute(user).model}`, { customerUpdate: customerDoc?.customerUpdate }).then(res => {
                  console.log("response after customer update: ", res?.data?.document)
                }).catch(err => {
                  console.log(err)
                })

              }
            })
          }
        } else {
          setLoadingStatus(false)
        }
        navigate(`/my-orders?division=${division}`)
        // navigate(`/order/${order?._id}`)
        getCartDetail()
      }
    }).catch(error => {
      setLoadingStatus(false)
      console.log(error)
      showMessage(error.response.data.message, "error")
      alert.error(error.response?.data?.message)

      // Set order status t0 processing
      ApiService.patch(`shop/order/${orderData._id}?protectModel=${getModelRoute(user).model}`, { status: "PROCESSING" }).then(resp => {
        if (resp.data.isSuccess) {
          setState(resp.data.document)
          setLoadingStatus(false)
          // navigate(`/${rootPath}/order/edit/${id}`)
          showMessage("Your order has been Placed successfully!", 'info')
          // alert.success("Your order has been Placed successfully!")
        }


      }).catch(err => {
        console.log(error)
      })
    })
  }

  const createMultipleOrder = (dataArr) => {
    console.log('multiple order means ship complete')
    console.log('create multiple order data: ', dataArr)
    let orderData

    for (let [index, data] of dataArr.entries()) {
      // dataArr.forEach(data => {

      ApiService.post(`shop/order?protectModel=${getModelRoute(user).model}&index=${index + 1}`, data).then(async response => {
        if (response?.data.isSuccess) {
          const order = response.data.document

          let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
          let initials = [...order.orderBy.text.matchAll(rgx)] || [];

          order.customerId = order.onBehalfOf.id.customerId;
          order.customerNetsuiteId = order.onBehalfOf.id.customerNetsuiteId;
          order.shippingDate = moment(order.shippingDate).format("MM/DD/YYYY")
          order.orderBy = initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
          console.log("order: ", order);
          orderData = order

          // Push the order to netsuite
          ApiService.setHeader();
          // const res = await ApiService.post(`admin/nsOrder`, order)
          const res = await ApiService.post(`shop/nsOrder?protectModel=${getModelRoute(user).model}`, order)
          console.log(res);
          if (res.data.isSuccess) {

            console.log("success");
            const resp = await ApiService.patch(`shop/order/${order._id}?protectModel=${getModelRoute(user).model}`, { netsuiteId: res.data.document.id })
            if (resp.data.isSuccess) {
              setState(resp.data.document)
              setLoadingStatus(false)
              // navigate(`/${rootPath}/order/edit/${id}`)
              showMessage("Your order has been Placed successfully!", 'info')
              // Update customer if order is placed by an unapproved address
              console.log("selected shipping addr: ", selectedShippingAddress)

              ApiService.setHeader()
              ApiService.get(`/admin/customer/${customer?._id}`).then(response => {
                let customerDoc = response?.data.document

                const isApprovredAddr = shippingAddresses?.filter(address => address.address1 == selectedShippingAddress)[0]?.isapproved
                if (!isApprovredAddr) {
                  customerDoc?.customerUpdate.map(ele => {
                    if (ele?.fieldName == "addresses" && ele?.new?.address1?.trim() == selectedShippingAddress?.trim()) {
                      // update customerUpdate
                      ele['note'] = `A Sales Order (${resp.data.document?.proOrderNumber}) is created with this unapproved address!`
                    }
                  })

                  console.log("customerUpdate: ", customerDoc?.customerUpdate)

                  ApiService.setHeader()
                  ApiService.patch(`/admin/customer/${customer?._id}?updatedFrom=admin&protectModel=${getModelRoute(user).model}`, { customerUpdate: customerDoc?.customerUpdate }).then(res => {
                    console.log("response after customer update: ", res?.data?.document)
                  }).catch(err => {
                    console.log(err)
                  })

                }
              })
            }
          } else {
            setLoadingStatus(false)
          }

          navigate(`/my-orders?division=${division}`)
          getCartDetail()
        }
      }).catch(error => {
        setLoadingStatus(false)
        console.log(error)
        showMessage(error.response.data.message, "error")
      })
      // })
    }
  }

  const formatArray = (multiplier, calledFrom) => {
    let array = []
    for (let i = 1; i <= 10; i++) {
      array.push((i * parseInt(multiplier)))
    }

    return array
  }

  const formatCartItemForShipComplete = (state) => {
    let array = []
    let country = ''

    if (customers !== null) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        country = "UNITED STATES"
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
        country = "CANADA"
    }

    state.forEach(ele => {
      let obj = {}
      console.log("formatCartItemForShipComplete", ele);
      obj.collectionName = ele.collectionName
      obj.id = ele.id
      obj.image = ele.image
      if (ele?.isAddedBySelectFullCollectionHalfPiece) {
        if (country == 'UNITED STATES') {
          obj.multipliers = formatArray(ele.productId?.usdHalfMultiplier)
        } else {
          obj.multipliers = formatArray(ele.productId?.cadHalfMultiplier)
        }
      } else {
        obj.multipliers = ele.multipliers
      }
      // obj.multipliers = ele.multipliers
      obj.price = ele.price.split("/")[0].replace("$", "")
      obj.discount = ele.discount == "Free Freight" ? "N/A" : ele.discount
      obj.appliedPromo = ele.appliedPromo
      if (ele?.isAddedBySelectFullCollectionHalfPiece) {
        if (country == 'UNITED STATES') {
          obj.boltQuantity = ele.productQuantity.split("/")[0] / formatArray(ele.productId?.usdHalfMultiplier)[0]
        } else {
          obj.boltQuantity = ele.productQuantity.split("/")[0] / formatArray(ele.productId?.cadHalfMultiplier)[0]
        }
      } else {
        obj.boltQuantity = ele.productQuantity.split("/")[0] / ele.multipliers[0]
      }
      obj.productId = ele.productId
      obj.productQuantity = ele.productQuantity.split("/")[0]
      obj.shipDate = ele.shipDate
      obj.specialInstruction = ele?.specialInstruction
      obj.subTotal = ele.subTotal.replace("$", '')
      obj.userId = ele.userId
      obj._id = ele._id

      array.push(obj)
    })

    return array
  }



  const getCartDetail = () => {
    let array = []

    ApiService.setHeader()

    ApiService.get(`shop/cart/${customers[customers?.length - 1]?._id}?protectModel=${getModelRoute(user).model}`).then(response => {
      console.log("ele", customers[customers?.length - 1]?._id, getModelRoute(user).model);
      if (response?.data.isSuccess) {
        getCustomerShippingAddresses()

        setTotal(response?.data.documents?.reduce(function getSum(total, cart) {
          // return total + cart?.price;
          return (parseFloat(total) + parseFloat(cart?.subTotal)).toFixed(2);
        }, 0))

        const tot = response?.data.documents?.reduce(function getSum(total, cart) {
          // return total + cart?.price;
          return (parseFloat(total) + parseFloat(cart?.subTotal)).toFixed(2);
        }, 0)

        const actualTot = response?.data.documents?.reduce(function getSum(total, cart) {
          // return total + cart?.price;
          return (parseFloat(total) + (parseFloat(cart?.productQuantity) * parseFloat(cart?.price))).toFixed(2);
        }, 0)

        setActualTotal(actualTot)

        setTotalDiscount(parseFloat(parseFloat(actualTot) - parseFloat(tot))?.toFixed(2))

        // Set cart qty
        cartDispatch({ type: "ADD_TO_CART_QUANTITY", payload: response?.data.documents?.length });

        // Set all cart Id's into an array
        response?.data.documents.map(cart => array.push(moment(cart?.shipDate)))
        setShipDates(array)

        // Format cart items
        response?.data.documents.map(ele => {

          ele.productQuantity = `${ele.productQuantity}/ ${ele?.productId?.primaryUnitsType == "Each" ? "e" : uniteType == 'm' ? 'Metre(s)' : 'Yard(s)'}`
          ele.price = `$${ele.price}/ ${ele?.productId?.primaryUnitsType == "Each" ? "e" : uniteType}`
          ele.subTotal = `$${ele.subTotal}`
        })


        if (searchParams.get('orderByCollection') == 'true' && searchParams.get('orderComplete') == "false") {
          console.log("by coll")
          formatDataToShowInCart(response?.data.documents)
          setShipingType("Ship Order Complete")
        } else if ((searchParams.get('orderComplete') == "false" && searchParams.get('orderByCollection') == "false")) {
          console.log("by coll")
          formatDataToShowInCart(response?.data.documents)
          setShipingType("")
        } else if (searchParams.get('orderComplete') == 'true' && searchParams.get('orderByCollection') == "false") {
          console.log("complete")
          setState(response?.data.documents)
          setShipingType("Combine shipment")
        } else if ((searchParams.get('orderComplete') == "true" && searchParams.get('orderByCollection') == "true")) {
          console.log("complete")
          setState(response?.data.documents)
          setShipingType("Ship Order Complete")
        } else {
          formatDataToShowInCart(response?.data.documents)
          setShipingType("")
        }


      }
    }).catch(error => {
      console.log(error.response.data)
      // console.log("Else Catch")
      showMessage(error.response.data.message, "error")
    })
  }

  const formatDataToShowInCart = (cartDetails) => {

    // Group all cart items by collection name
    const groupBycollectionName = cartDetails.reduce((group, cartItem) => {
      const { collectionName } = cartItem;

      group[collectionName] = group[collectionName] ?? [];
      group[collectionName].push(cartItem);

      return group;
    }, {});

    console.log(Object.entries(groupBycollectionName))
    setCarts(Object.entries(groupBycollectionName))
  }

  const getCustomerShippingAddresses = () => {
    console.log("getCustomerShippingAddresses")
    console.log("customer", customer)
    console.log("customers", customers[customers?.length - 1])
    let array = []
    let finalArray = []

    if (customers[customers?.length - 1]?._id) {
      ApiService.setHeader();
      // return ApiService.get(`/admin/user/${id}`).then(response => {
      ApiService.get(`/admin/customer/${customers[customers?.length - 1]?._id}`).then(response => {
        console.log("cust res", response)
        const document = response?.data.document;
        console.log(document);
        console.log("getCustomerShippingAddresses: ", document);
        array = document?.addresses

        for (let ele of document?.customerUpdate) {
          if (ele?.fieldName == 'addresses') {
            array.push(ele?.new)
          }
        }

        // Need to remove duplicate address

        console.log("array", array)
        setShippingAddresses(array)

        for (let ele of array) {
          if (ele?.isDefaultShipping) {
            finalArray.push(ele)
          }
        }

        console.log("finalArray", finalArray)
        setFinalShippingAddresses(finalArray)

      }).catch(e => {
        console.log(e);
      })
    }

  }

  console.log(shippingAddresses);

  if (customers !== null) {
    if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
      uniteType = 'y'
    } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
      uniteType = 'm'
  }


  useEffect(() => {
    // console.log(customer);
    if (customers != null || customer) {
      getOrdersLength()
      // getCustomerShippingAddresses()
      getCartDetail()
    }
  }, []);

  console.log("ordersLength", ordersLength)

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className="flex items-center justify-center my-[80px] sm:my-[100px]"
        style={{ fontFamily: "Open Sans" }}
      >
        <div className="w-[320px] sm:w-[744px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col items-center justify-center gap-[60px]">
          {/* checkout heading*/}
          <div className="w-[320px] h-[38px] sm:w-[744px] sm:h-[44px] flex xl:w-[1160px] 2xl:w-[1320px] items-center justify-center">
            <div>
              <p className="text-[28px] xl:text-[32px] font-bold mx-auto text-center">
                Checkout
              </p>
            </div>
          </div>

          {/*top buttons , orderdetail and summary */}
          <div className="flex flex-col items-center justify-center gap-5">
            {/* top section */}
            <div className="w-[280px] h-[65px] sm:w-[640px] sm:h-[34px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col items-center justify-between sm:flex-row">
              {/* back to cart */}
              <Link to={"/cart-detail"}>
                <div className="h-[21px] sm:h-[34px] flex items-center cursor-pointer justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-4 h-4 text-[#6B6B66]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 19.5 8.25 12l7.5-7.5"
                    />
                  </svg>
                  <p className="text-[14px] text-[#6B6B66] hover:text-[#E2A856]">
                    Back to Shopping Cart
                  </p>
                </div>
              </Link>
              {/* button */}
              <div>
                <Link to={"/"}>
                  <button
                    type="button"
                    className="w-[154px] h-[34px] text-white bg-[#E2A856] focus:ring-4 focus:ring-[#E2A856] font-medium rounded text-[12px] hover:bg-black"
                  >
                    CONTINUE SHOPPING
                  </button>
                </Link>
              </div>
            </div>
            {/* Order details , order summary and shipping details */}
            <div className="w-[280px] sm:w-[640px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col xl:flex-row items-center justify-center xl:items-start xl:justify-between">
              {/* orders details */}

              {carts?.length ? <div className="w-[280px] sm:w-[640px] xl:w-[685px] flex flex-col items-center justify-center border-2 gap-[30px]">
                {
                  carts?.length ? carts.map((cart, idx) => {
                    return (
                      <>
                        {/* shipping date */}
                        <div className="w-[240px] h-[137px] sm:w-[600px] sm:h-[59px] xl:w-[645px] flex flex-col items-center justify-between sm:flex-row">
                          {/* col 1 */}
                          <div className="w-[240px] h-[72px] font-bold sm:w-[277px] sm:h-[59px] flex flex-col items-center justify-center sm:items-start border-b border-gray-500 sm:border-b-0">
                            <div className="sm:h-[44px] flex flex-col sm:flex-row gap-3 sm:gap-5">
                              <div className="h-[32px] text-[16px] sm:h-[44px] text-nowrap flex items-end sm:items-center justify-center sm:justify-start">
                                Shipping Date:
                              </div>
                              <div className="h-[41px] sm:h-[44px] flex items-center justify-center gap-1 sm:gap-0">
                                <img src={calendar_month} alt="Calender" />
                                <span className="text-nowrap text-[16px]">
                                  {/* 15 September 2024 */}
                                  {moment(cart[1][0]?.shipDate).format('DD-MMM-YYYY').replaceAll("-", " ")}

                                </span>
                              </div>
                            </div>
                            <div className="hidden sm:flex sm:items-center sm:gap-1">
                              <p className="text-gray-500 text-[10px] sm:text-[12px] text-nowrap">
                                Instruction:
                              </p>
                              <p className="text-gray-500 text-[10px] sm:text-[12px] text-nowrap">
                                {cart[1][0]?.specialInstruction}
                              </p>
                            </div>
                          </div>
                          {/* col 2 */}
                          <div className=" flex w-[240px] h-[45px] sm:w-[109px] sm:h-[59px] text-[10px] text-gray-700 gap-2 flex-col items-center justify-center">
                            <div className="flex items-center justify-center sm:hidden">
                              <p className="w-[54px] h-[15px]">Instruction: </p>
                              <p className="w-[132px] sm:w-[586px] h-[15px]">
                                Lorem ipsum dolor sit amet.
                              </p>
                            </div>

                            <div>
                              {" "}
                              <button
                                type="button"
                                className="w-[109px] h-[25px] text-black bg-[#F0EFEB] focus:ring-4 focus:ring-[#F0EFEB] font-medium text-[10px]  focus:outline-none"
                              >
                                {/* Ship Order Complete */}
                                {shippingtype}
                              </button>
                            </div>
                          </div>
                        </div>

                        <table className="w-[240px] sm:w-[600px] xl:w-[645px] text-[14px] text-left rtl:text-right text-gray-500 flex flex-col">
                          <thead className="text-[12px] text-gray-500 sm:w-[600px] xl:w-[645px] sm:h-[28px] sm:items-center sm:justify-center">
                            <tr className="hidden sm:border-b-2 w-[240px] h-[18px] sm:w-[600px] xl:w-[645px] sm:h-[28px] sm:flex flex-row sm:gap-[10px]">
                              <th
                                scope="col"
                                className="sm:w-[55px] h-[18px] hidden sm:flex items-center justify-center me-[10px]"
                              >
                                <span className="">Image</span>
                              </th>
                              <th
                                scope="col"
                                className="sm:w-[180px] xl:w-[200px] h-[18px] hidden sm:flex items-center justify-center px-4"
                              >
                                Product Details
                              </th>
                              <th
                                scope="col"
                                className="sm:w-[80px] xl:w-[90px] h-[18px] hidden sm:flex items-center sm:ms-5 xl:ms-0"
                              >
                                Price
                              </th>
                              <th
                                scope="col"
                                className="sm:w-[80px] xl:w-[90px] h-[18px] hidden sm:flex items-center xl:me-0"
                              >
                                Qty
                              </th>
                              <th
                                scope="col"
                                className="sm:w-[80px] xl:w-[90px] h-[18px] hidden sm:flex items-center"
                              >
                                Discount
                              </th>
                              <th
                                scope="col"
                                className="sm:w-[80px] xl:w-[90px] h-[18px] hidden sm:flex items-center justify-center"
                              >
                                Subtotal
                              </th>
                            </tr>
                          </thead>
                          <tbody className=" border-gray-400">
                            {/* tr-1 */}
                            {
                              cart[1]?.length ? cart[1]?.map((ele, index) => {
                                // console.log("ele", ele);
                                return (
                                  <DisplayOderItems key={ele?._id} ele={ele} CartProduct={CartProduct} />
                                )
                              }) : ""
                            }
                          </tbody>
                        </table>

                        {/* coupon code and instructor */}
                        <div className="my-3 -mt-2 w-[240px] h-[74px] sm:w-[600px] sm:h-[86px] xl:w-[645px] xl:h-[86px] bg-white">
                          {/* collection total */}
                          <div className="w-[240px] h-[44px] sm:w-[600px] xl:w-[645px] sm:h-[50px] text-[10px] sm:text-[12px] sm:flex sm:flex-col sm:items-end">
                            <div className="flex justify-between sm:w-[314px] sm:h-[18px]">
                              <p>Collection Subtotal:</p>
                              <p>$ {cart[1]?.reduce(function getSum(total, item) {
                                console.log(item?.subTotal)
                                return (parseFloat(total) + parseFloat(parseFloat(parseFloat(item?.price?.replace("$", "")?.split("/")[0]) * parseFloat(item?.productQuantity?.split("/")[0])))).toFixed(2);
                              }, 0)}</p>

                            </div>
                            <div className="flex justify-between text-red-400 sm:w-[314px] sm:h-[18px]">
                              <p>Discount applied:</p>
                              <p>-$


                                {
                                  parseFloat(cart[1]?.reduce(function getSum(total, item) {
                                    console.log(item?.subTotal)
                                    return (parseFloat(total) + parseFloat(parseFloat(parseFloat(item?.price?.replace("$", "")?.split("/")[0]) * parseFloat(item?.productQuantity?.split("/")[0])))).toFixed(2);
                                  }, 0) - cart[1]?.reduce(function getSum(total, item) {
                                    console.log(item?.subTotal)
                                    return (parseFloat(total) + parseFloat(item?.subTotal?.replace("$", ""))).toFixed(2);
                                  }, 0))?.toFixed(2)
                                }
                              </p>
                            </div>
                            <div className="flex justify-between text-red-400 sm:w-[314px] sm:h-[18px]">
                              {/* <p>Discount (Promo name) applied:</p>
                            <p>-$ 00.00</p> */}
                            </div>
                            <div className="mt-2 flex justify-between font-bold text-[14px] sm:w-[314px] sm:h-[24px]">
                              <p>Collection Total:</p>
                              <p>$ {cart[1]?.reduce(function getSum(total, item) {
                                console.log(item?.subTotal)
                                return (parseFloat(total) + parseFloat(item?.subTotal?.replace("$", ""))).toFixed(2);
                              }, 0)}</p>

                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }) : ""

                }

              </div> : ""}

              {
                state?.length ?
                  <div className="w-[280px] sm:w-[640px] xl:w-[685px] flex flex-col items-center justify-center border-2 gap-[30px]">
                    {
                      state?.length ? state?.map((cart, index) => {
                        return (
                          <>
                            {/* shipping date */}
                            {index == 0 ? <div className="w-[240px] h-[137px] sm:w-[600px] sm:h-[59px] xl:w-[645px] flex flex-col items-center justify-between sm:flex-row">
                              {/* col 1 */}
                              <div className="w-[240px] h-[72px] font-bold sm:w-[277px] sm:h-[59px] flex flex-col items-center justify-center sm:items-start border-b border-gray-500 sm:border-b-0">
                                <div className="sm:h-[44px] flex flex-col sm:flex-row gap-3 sm:gap-5">
                                  <div className="h-[32px] text-[16px] sm:h-[44px] text-nowrap flex items-end sm:items-center justify-center sm:justify-start">
                                    Shipping Date:
                                  </div>
                                  <div className="h-[41px] sm:h-[44px] flex items-center justify-center gap-1 sm:gap-0">
                                    <img src={calendar_month} alt="Calender" />
                                    <span className="text-nowrap text-[16px]">
                                      {moment(moment.max(shipDates)._i).format('DD-MMM-YYYY').replaceAll("-", " ")}
                                    </span>
                                  </div>
                                </div>
                                <div className="hidden sm:flex sm:items-center sm:gap-1">
                                  <p className="text-gray-500 text-[10px] sm:text-[12px] text-nowrap">
                                    Instruction:
                                  </p>
                                  <p className="text-gray-500 text-[10px] sm:text-[12px] text-nowrap">
                                    {/* Lorem ipsum dolor sit amet. */}
                                  </p>
                                </div>
                              </div>
                              {/* col 2 */}
                              <div className=" flex w-[240px] h-[45px] sm:w-[109px] sm:h-[59px] text-[10px] text-gray-700 gap-2 flex-col items-center justify-center">
                                <div className="flex items-center justify-center sm:hidden">
                                  <p className="w-[54px] h-[15px]">Instruction: </p>
                                  <p className="w-[132px] sm:w-[586px] h-[15px]">
                                    Lorem ipsum dolor sit amet.
                                  </p>
                                </div>

                                <div>
                                  {" "}
                                  <button
                                    type="button"
                                    className="w-[109px] h-[25px] text-black bg-[#F0EFEB] focus:ring-4 focus:ring-[#F0EFEB] font-medium text-[10px]  focus:outline-none"
                                  >
                                    {/* Ship Order Complete */}
                                    {/* {shipType == "orderByCollection" ? "Ship Order Complete" : "Combine Shipment"} */}
                                    {shippingtype}
                                  </button>
                                </div>
                              </div>
                            </div> : ""}

                            {/* table */}
                            <table className="w-[240px] sm:w-[600px] xl:w-[645px] text-[14px] text-left rtl:text-right text-gray-500 flex flex-col">
                              <thead className="text-[12px] text-gray-500 sm:w-[600px] xl:w-[645px] sm:h-[28px] sm:items-center sm:justify-center">
                                <tr className="hidden sm:border-b-2 w-[240px] h-[18px] sm:w-[600px] xl:w-[645px] sm:h-[28px] sm:flex flex-row sm:gap-[10px]">
                                  <th
                                    scope="col"
                                    className="sm:w-[55px] h-[18px] hidden sm:flex items-center justify-center me-[10px]"
                                  >
                                    <span className="">Image</span>
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm:w-[180px] xl:w-[200px] h-[18px] hidden sm:flex items-center justify-center px-4"
                                  >
                                    Product Details
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm:w-[80px] xl:w-[90px] h-[18px] hidden sm:flex items-center sm:ms-5 xl:ms-0"
                                  >
                                    Price
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm:w-[80px] xl:w-[90px] h-[18px] hidden sm:flex items-center xl:me-0"
                                  >
                                    Qty
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm:w-[80px] xl:w-[90px] h-[18px] hidden sm:flex items-center"
                                  >
                                    Discount
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm:w-[80px] xl:w-[90px] h-[18px] hidden sm:flex items-center justify-center"
                                  >
                                    Subtotal
                                  </th>
                                </tr>
                              </thead>
                              <tbody className=" border-gray-400">
                                {/* tr-1 */}

                                <DisplayOderItems key={cart?._id} ele={cart} CartProduct={CartProduct} />
                              </tbody>
                            </table>
                          </>
                        )
                      }) : ""
                    }
                    {/* coupon code and instructor */}
                    <div className="my-3 -mt-2 w-[240px] h-[74px] sm:w-[600px] sm:h-[86px] xl:w-[645px] xl:h-[86px] bg-white">
                      {/* collection total */}
                      <div className="w-[240px] h-[44px] sm:w-[600px] xl:w-[645px] sm:h-[50px] text-[10px] sm:text-[12px] sm:flex sm:flex-col sm:items-end">
                        <div className="flex justify-between sm:w-[314px] sm:h-[18px]">
                          <p>Collection Subtotal:</p>
                          <p>$ {state?.reduce(function getSum(total, item) {
                            console.log(item?.subTotal)
                            return (parseFloat(total) + parseFloat(parseFloat(parseFloat(item?.price?.replace("$", "")?.split("/")[0]) * parseFloat(item?.productQuantity?.split("/")[0])))).toFixed(2);
                          }, 0)}</p>
                        </div>
                        <div className="flex justify-between text-red-400 sm:w-[314px] sm:h-[18px]">
                          <p>Discount applied:</p>
                          <p>-$
                            {
                              state?.reduce(function getSum(total, item) {
                                console.log(item?.subTotal)
                                return (parseFloat(total) + parseFloat(parseFloat(parseFloat(item?.price?.replace("$", "")?.split("/")[0]) * parseFloat(item?.productQuantity?.split("/")[0])))).toFixed(2);
                              }, 0) - state?.reduce(function getSum(total, item) {
                                console.log(item?.subTotal)
                                return (parseFloat(total) + parseFloat(item?.subTotal?.replace("$", ""))).toFixed(2);
                              }, 0)
                            }
                          </p>
                        </div>
                        <div className="flex justify-between text-red-400 sm:w-[314px] sm:h-[18px]">
                          {/* <p>Discount (Promo name) applied:</p>
                    <p>-$ 00.00</p> */}
                        </div>
                        <div className="mt-2 flex justify-between font-bold text-[14px] sm:w-[314px] sm:h-[24px]">
                          <p>Total:</p>
                          {/* <p>$ {total}</p> */}
                          <p>$ {state?.reduce(function getSum(total, item) {
                            console.log(item?.subTotal)
                            return (parseFloat(total) + parseFloat(item?.subTotal?.replace("$", ""))).toFixed(2);
                          }, 0)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  : ""
              }


              {/* order summary and shipping details */}
              <div className="w-[280px] sm:w-[500px] xl:w-[435px] 2xl:w-[555px] flex flex-col gap-10 items-center justify-center">
                {/* order summary */}
                <div className="w-[280px] h-[291px] sm:w-[500px] sm:h-[283px] xl:w-[435px] xl:h-[283px] 2xl:w-[555px] mt-10 xl:mt-0">
                  <div className="mx-auto w-[280px] h-[291px] sm:w-[500px] sm:h-[283px] xl:w-[435px] xl:h-[283px] 2xl:w-[555px] xl:mb-[89px] border-2 mb-20 flex flex-col">
                    <div className="flex flex-col items-center justify-center gap-10 my-5 sm:my-0 xl:w-[435px] 2xl:w-[555px] xl:h-[283px]">
                      {/* row1 */}
                      <div className="sm:mt-5 xl:mt-0 w-[240px] h-[57px] sm:w-[460px] xl:w-[395px] xl:h-[57px] 2xl:w-[515px] flex items-center justify-center sm:justify-start uppercase font-bold text-[18px] border-b">
                        Order Summary
                      </div>
                      {/* row2 */}
                      <div className="w-[240px] h-[154px] sm:w-[460px] sm:h-[136px] xl:w-[395px] xl:h-[156px] 2xl:w-[515px]">
                        <div className="flex justify-between text-base">
                          <p className="">Subtotal:</p>
                          <p>${actualTotal}</p>
                        </div>
                        <div className="flex justify-between text-red-400">
                          <p className="">Discount:</p>
                          <p>${totalDiscount}</p>
                        </div>
                        <div className="mt-1 font-bold flex justify-between">
                          <p className="">Total:</p>
                          <p>${total}</p>
                        </div>
                        <div className="w-[240px] sm:w-[460px] sm:h-[36px] xl:w-[395px] h-[36px] 2xl:w-[515px] mt-5 text-gray-400 text-[12px]">
                          <ul className="ms-5">
                            <li className="list-disc">
                              Total does not include applicable taxes or delivery.
                              All prices and product availability are subject to
                              change.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* shipping details */}
                <div className="w-[320px] sm:w-[740px] xl:w-[435px] 2xl:w-[555px] flex items-center justify-center">
                  <div className="mx-auto w-[280px] sm:w-[500px] xl:w-[435px] 2xl:w-[555px] border-2 mb-20 flex flex-col items-center justify-center">
                    <div className="flex flex-col items-center justify-center gap-5 my-5 sm:my-0 xl:w-[435px] 2xl:w-[555px]">
                      {/* row1 name only*/}
                      <div className="sm:mt-5 xl:mt-0 w-[240px] h-[57px] sm:w-[460px] xl:w-[395px] xl:h-[57px] 2xl:w-[515px] flex items-center justify-center sm:justify-start uppercase font-bold text-[18px] border-b">
                        Shipping Details
                      </div>
                      {/* row2 */}
                      <div className="w-[240px] h-[66px] sm:w-[460px] sm:h-[51px] xl:w-[395px] xl:h-[51px] 2xl:w-[515px] flex text-base border-b gap-1">
                        <p className="w-[65px] h-[36px] sm:w-[125px] font-bold sm:h-[21px] text-[12px]">
                          Web Order Number:
                        </p>
                        {/* <p className="h-[18px] sm:h-[21px] text-[12px]">{formatOrderNumber()}</p> */}
                        <p className="h-[18px] sm:h-[21px] text-[12px]">{`${formatOrderNumber()}${ordersLength + 1}`}</p>
                      </div>
                      {/* row3 */}
                      <div className="w-[240px] h-[66px] sm:w-[460px] sm:h-[72px] xl:w-[395px] xl:h-[72px] 2xl:w-[515px] flex items-center text-base border-b gap-1">
                        <p className="w-[65px] h-[36px] sm:w-[125px] sm:h-[42px] text-[12px] font-bold">
                          Sales Order Number:
                        </p>
                        <p className="w-[170px] h-[36px] sm:w-[236px] sm:h-[21px] text-[12px]"></p>
                      </div>
                      {/* row4 */}
                      <div className="w-[240px] h-[66px] sm:w-[460px] sm:h-[72px] xl:w-[395px] xl:h-[72px] 2xl:w-[515px] flex items-center text-base border-b gap-1">
                        <p className="w-[65px] h-[36px] sm:w-[125px] sm:h-[42px] text-[12px] font-bold flex items-center">
                          Order By:
                        </p>
                        <p className="w-[170px] h-[36px] sm:w-[236px] sm:h-[21px] text-[12px]">
                          {/* STEVE WEISSMAN */}
                          {customers[customers?.length - 1]?.name}
                        </p>
                      </div>
                      {/* row5 */}
                      <div className="w-[240px] h-[140px] sm:w-[460px] sm:h-[155px] xl:w-[395px] 2xl:w-[515px] flex text-base border-b">
                        <p className="w-[50px] h-[18px] sm:w-[125px] font-bold sm:h-[21px] text-[12px]">
                          Bill To:
                        </p>
                        <p className="w-[170px] h-[110px] sm:w-[330px] xl:w-[265px] 2xl:w-[385px] sm:h-[125px] text-[12px]">

                          {customer?.addresses?.length ? formatAddressNew(customer?.addresses.filter(address => address.isDefaultBilling)[0]) : ""}
                        </p>
                      </div>
                      {/* row6 */}
                      {/* <div className="w-[240px] h-[75px] sm:w-[460px] sm:h-[77px] xl:w-[395px] 2xl:w-[515px] flex text-base border-b gap-1">
                        <p className="w-[50px] h-[18px] sm:w-[125px] font-bold sm:h-[21px] text-[12px]"> */}
                      <div className="w-[240px] h-[140px] sm:w-[460px] sm:h-[155px] xl:w-[395px] 2xl:w-[515px] flex text-base border-b">
                        <p className="w-[50px] h-[18px] sm:w-[125px] font-bold sm:h-[21px] text-[12px]">
                          Ship To:
                        </p>
                        {/* <p className="w-[170px] h-[170px] sm:w-[330px] sm:h-[125px] text-[12px]"> */}
                        <p className="w-[170px] h-[110px] sm:w-[330px] xl:w-[265px] 2xl:w-[385px] sm:h-[125px] text-[12px]">

                          <div>
                            {
                              finalShippingAddresses?.length == 1 ? formatAddressNew(finalShippingAddresses[0]) :
                                <select size='sm' aria-label="Default select example" style={{ width: "250px" }}
                                  {...register("shippingAddress", { required: true })}
                                  required={true}
                                  // defaultValue={industryCode}
                                  onChange={e => {
                                    // console.log(e.target.value);
                                    setSelectedShippingAddress(e.target.value)
                                  }}>
                                  <option value={""}>{'Select shipping address...'}</option>
                                  {
                                    // shippingAddresses?.length && shippingAddresses.map(address => {
                                    finalShippingAddresses?.length && finalShippingAddresses.map(address => {
                                      return <option key={address?._id} value={address?.address1}>{address?.address1}</option>
                                    })
                                  }
                                </select>
                              // {errors?.shippingAddress && <span>Please select shipping address!!</span>}
                            }
                          </div>

                        </p>
                      </div>
                      {/* row7 */}
                      <div className="w-[240px] h-[102px] sm:w-[460px] sm:h-[72px] xl:w-[395px] 2xl:w-[515px] flex text-base border-b gap-1">
                        <p className="w-[65px] h-[72px] sm:w-[125px] font-bold sm:h-[42px] text-[12px] gap-1">
                          Optional Order Processing:
                        </p>
                        <p className="w-[170px] h-[36px] sm:w-[260px] sm:h-[21px] text-[12px]">
                          Combine Delivery, Ship Order Complete
                        </p>
                      </div>
                    </div>
                    {/* row last */}
                    <div>
                      <ul className="ms-5 px-1 w-[240px] h-[115px] sm:w-[460px] sm:h-[79px] xl:w-[395px] 2xl:w-[515px] text-gray-500 text-[12px] flex flex-col justify-center gap-2">
                        <li className="list-disc">
                          NOTE: If the address we have on file is incorrect,
                          please contact us at 1-800-268-1466
                        </li>
                        <li className="list-disc">
                          Click here to read through our Sales Terms and
                          Conditions.
                        </li>
                      </ul>
                    </div>
                    {/* place order button */}
                    <div className="mb-4 mt-2">
                      {/* <Link to={"/my-orders"} className="cursor-pointer"> */}
                      <button
                        type="submit"
                        disabled={loadingStatus}
                        style={{ cursor: loadingStatus ? 'progress' : 'pointer' }}
                        className="w-[240px] h-[49px] sm:w-[460px] xl:w-[395px] 2xl:w-[515px] text-white bg-[#E2A856] focus:ring-[#E2A856] font-medium rounded-[4px] focus:outline-none uppercase hover:bg-black"
                      >
                        {loadingStatus ? 'PLACING ORDER...' : "PLACE ORDER"}
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </form>
  );
};

export default Order;
