import { Fragment, useContext, useState, useEffect, useRef } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import shopping from "../../../assets/HeaderLogo/CartLight.svg";
import mobileCartHover from "../../../assets/HeaderLogo/mobileCartHover.svg";
import mobileMenuHover from "../../../assets/HeaderLogo/mobileMenuHover.svg";
import shoppingMoblie from "../../../assets/HeaderLogo/CartMobile.svg";
import BurgerIconMobile from "../../../assets/HeaderLogo/BurgerIconMobile.svg";
import BanyanLogo from "../../../assets/HeaderLogo/BrandLogo/BanyanLogo.svg";
import PatrickLogo from "../../../assets/HeaderLogo/BrandLogo/PatrickLogo.svg";
import Northcott from "../../../assets/HeaderLogo/BrandLogo/Northcott.svg";
import NorthcottMainLogo from "../../../assets/HeaderLogo/BrandLogo/NorthcottMainLogo.svg";
import BanyanMainLogo from "../../../assets/HeaderLogo/BrandLogo/BanyanMainLogo.svg";
import PatrickMainLogo from "../../../assets/HeaderLogo/BrandLogo/PatrickMainLogo.svg";
import BanyanOnClick from "../../../assets/HeaderLogo/OnclickLogo/BanyanOnClick.svg";
import PatrickOnClick from "../../../assets/HeaderLogo/OnclickLogo/PatrickOnClick.svg";
import NorthcottOnClick from "../../../assets/HeaderLogo/OnclickLogo/NorthcottOnClick.svg";
import search from "../../../assets/HeaderLogo/search.svg";
import { links } from "./HomePageContent/Link";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from "../../../states/contexts/UserContext";
import { CustomerContext } from "../../../states/contexts/CustomerContext";
import { CartContext } from "../../../states/contexts/CartContext";
import { BrandIconContext } from "../../../states/contexts/BrandIconContext";
import { getModelRoute, logout, showMessage } from "../../../helpers/Utils";
import ApiService from "../../../helpers/ApiServices";
import "../TopHeader.css"

export default function TopHeader() {
  const [open, setOpen] = useState(false);
  // Define a state to hold the open/closed state for each submenu
  const [openSubmenus, setOpenSubmenus] = useState({});
  const [openSubsubmenus, setOpenSubsubmenus] = useState(false);
  // for top header brand selection
  const [selectedBrand, setSelectedBrand] = useState("northcott");
  // login logout
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [categories, setCategories] = useState([]);
  const [userId, setUserId] = useState();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const inputRef = useRef(null);


  const { dispatch, user } = useContext(UserContext);
  const { dispatch: customerDispatch, customer } = useContext(CustomerContext);
  const { dispatch: cartDispatch, quantity } = useContext(CartContext)
  const { dispatch: iconDispatch, isNorthchott,
    isBanyan,
    isPatrick,
    isDefault,
    isFigo,
    color,
    textColor, headerColor,
    divisionType,
    backgroundColor,
    darkBackgroundColor } = useContext(BrandIconContext)

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()

  let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"

  let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))
  let cancelToken;
  let loggedInUser
  let cust = {
    name: "Customer",
    submenu: true,
    sublinks: [
      {
        Head: "",
        sublink: [
          {
            name: "Personal Info",
            href: "#",
          },
          {
            name: "Order History",
            href: "#",
          },
          {
            name: "Invoice History",
            href: "#",
          },
          {
            name: "Statement",
            href: "#",
          },
          {
            name: "Dashboard",
            href: "#",
          },
        ],
      },
    ],
  }
  let ro = {
    name: "Rapid Order",
    submenu: false,
    href: "/coming-soon",
  }

  // Function to toggle the submenu for a specific link
  const toggleSubmenu = (index) => {
    setOpenSubmenus((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
    setOpenSubsubmenus(!openSubsubmenus);
  };

  const closeMenu = () => {
    setOpen(!open);
  };

  const handleImageClick = (imageName) => {
    setSelectedBrand(imageName);
  };

  const changeToNortncott = () => {
    iconDispatch({ type: "IS_NORTHCOTT" })
    navigate(`?division=NORTHCOTT`)
  }
  const changeToBanyan = () => {
    iconDispatch({ type: "IS_BANYAN" })
    navigate(`?division=BANYAN BATIKS`)
  }
  const changeToPatrick = () => {
    iconDispatch({ type: "IS_PATRICK" })
    navigate(`?division=PATRICK LOSE`)
  }
  const changeToFigo = () => {
    iconDispatch({ type: "IS_FIGO" })
    navigate(`?division=FIGO`)
  }

  const handleLogout = async () => {
    try {
      links?.map(link => {
        if (link?.name == "Customer")
          link['isVisible'] = false

        if (link?.name == "Rapid Order")
          link['isVisible'] = false
      })

      await logout()
      dispatch({ type: "LOGOUT_SUCCESS" });
      customerDispatch({ type: "SELECT_CUSTOMER", payload: null })
    } catch (error) {
      dispatch({ type: "LOGIN_FAILURE" });
    }
    navigate(`/`)
  }

  const setModelAndRoute = (user) => {
    if (user?.userTypes.includes("CUSTOMER")) {
      localStorage.setItem("PCTeRP.MODEL", "Customer")
      localStorage.setItem("PCTeRP.ROUTE", "Customer")
    } else {
      localStorage.setItem("PCTeRP.MODEL", "User")
      localStorage.setItem("PCTeRP.ROUTE", "user")
    }
  }

  const isLoggedInUser = async () => {
    ApiService.setHeader()
    ApiService.get(`shop/user/loggedIn?model=${getModelRoute(user).model}`).then(async response => {

      dispatch({ type: "LOGIN_SUCCESS", payload: response.data.document });
      loggedInUser = response.data.document | user

      setModelAndRoute(response.data.document)

      if (!response?.data.document.savePassword && window.performance.getEntriesByType("navigation")[0].type == "navigate") {
        dispatch({ type: "LOGIN_SUCCESS", payload: response.data.document });
      }

      if (response?.data.document.savePassword && window.performance.getEntriesByType("navigation")[0].type == "navigate") {
        if ((new Date().getTime() - response?.data.document.browserClosingTime) / 60000 > 60) {
          customerDispatch({ type: "SELECT_CUSTOMER", payload: customers[0] });
        } else {
          customerDispatch({ type: "SELECT_CUSTOMER", payload: customers[customers?.length - 1] });

        }
      }


    }).catch(async error => {
      try {

        await logout()
        dispatch({ type: "LOGOUT_SUCCESS" });
        customerDispatch({ type: "SELECT_CUSTOMER", payload: null })
      } catch (error) {
        dispatch({ type: "LOGIN_FAILURE" });
      }
    })

  }

  const isLoggedInCheck = async () => {
    ApiService.setHeader();
    ApiService.get(`shop/user/loggedIn?model=${getModelRoute(user).model}`)
      .then(async (response) => {

        dispatch({ type: "LOGIN_SUCCESS", payload: response.data.document });
        setUserId(response.data.document._id);

        setModelAndRoute(response.data.document);

        if (
          !response?.data.document.savePassword &&
          window.performance.getEntriesByType("navigation")[0].type ==
          "navigate"
        ) {
          dispatch({ type: "LOGIN_SUCCESS", payload: response.data.document });
        }

        if (
          response?.data.document.savePassword &&
          window.performance.getEntriesByType("navigation")[0].type ==
          "navigate"
        ) {
          if (
            (new Date().getTime() -
              response?.data.document.browserClosingTime) /
            60000 >
            60
          ) {
            customerDispatch({
              type: "SELECT_CUSTOMER",
              payload: customers[0],
            });
          } else {
            customerDispatch({
              type: "SELECT_CUSTOMER",
              payload: customers[customers?.length - 1],
            });
          }
        }
      })
      .catch(async (error) => {
        try {
          await logout();
          dispatch({ type: "LOGOUT_SUCCESS" });
          customerDispatch({ type: "SELECT_CUSTOMER", payload: null });
        } catch (error) {
          dispatch({ type: "LOGIN_FAILURE" });
        }
      });
  };

  const getcategoryByDivision = () => {
    let isLoggedInUser, country

    if (customers != null || customer) {
      isLoggedInUser = true
    } else {
      isLoggedInUser = false
    }

    if (customers !== null) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        country = "UNITED STATES"
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA")) {
        country = "CANADA"
      }
    }

    ApiService.setHeader()
    ApiService.get(`shop/category/getbyDivision/${division}?protectModel=${getModelRoute(user).model}&brandType=NORTHCOTT&isLoggedInUser=${isLoggedInUser}&country=${country}`).then(response => {
      if (response?.data.isSuccess) {
        setCategories(response?.data?.documents)

        response?.data?.documents?.length && response?.data?.documents?.map(ele => {
          ele['href'] = `/product-collection/${ele?._id}`
        })

        for (let link of links) {
          if (link?.name == "Products") {
            link.sublinks[0]['sublink'] = response?.data?.documents
          }
        }
      }
    }).catch(error => {
      showMessage(error?.response?.data?.message, "error")
    })
  }

  const GlobalSearch = async (query) => {
    setActive(false)
    ApiService.setHeader()
    await ApiService.get(`shop/search?searchText=${query}`)
      .then(response => {
        setSearchResult(response?.data.documents)
        setActive(true)
      }).catch(e => {
        console.log(e.response?.data.message);
      })
  }


  const clearSearch = () => {
    setActive(false);
    inputRef.current.value = '';
  };


  const goto = (position) => {
    window.scroll({ top: position, behavior: "smooth" })
  }



  useEffect(() => {
    isLoggedInCheck()
    isLoggedInUser()

    if (user && (user?.userTypes?.includes("CUSTOMER") || customer)) {
      links?.map(link => {
        if (link?.name == "Customer")
          link['isVisible'] = true
      })
    }
    if (user && (!user?.userTypes?.includes("CUSTOMER") || !user?.userTypes?.includes("SALES_REP"))) {
      links?.map(link => {
        if (link?.name == "Rapid Order")
          link['isVisible'] = true
      })
    }
    if (user && (user?.userTypes?.includes("CUSTOMER"))) {
      links?.map(link => {
        if (link?.name == "Rapid Order")
          link['isVisible'] = false
      })
    }


    goto(0)
  }, []);

  useEffect(() => {
    getcategoryByDivision()

    if (division == "NORTHCOTT")
      changeToNortncott()

    if (division == "BANYAN BATIKS")
      changeToBanyan()

    if (division == "PATRICK LOSE")
      changeToPatrick()
    if (division == "FIGO")
      changeToFigo()

    if (user && (user?.userTypes?.includes("CUSTOMER") || customer)) {
      links?.map(link => {
        if (link?.name == "Customer")
          link['isVisible'] = true
      })
    }
    if (user && (!user?.userTypes?.includes("CUSTOMER") || !user?.userTypes?.includes("SALES_REP"))) {
      links?.map(link => {
        if (link?.name == "Rapid Order")
          link['isVisible'] = true
      })
    }
    if (user && (user?.userTypes?.includes("CUSTOMER"))) {
      links?.map(link => {
        if (link?.name == "Rapid Order")
          link['isVisible'] = false
      })
    }


    goto(0)
  }, [division]);

  useEffect(() => {

    if (user && (user?.userTypes?.includes("CUSTOMER") || customer)) {
      links?.map(link => {
        if (link?.name == "Customer")
          link['isVisible'] = true
      })
    }
    if (user && (!user?.userTypes?.includes("CUSTOMER") || !user?.userTypes?.includes("SALES_REP"))) {
      links?.map(link => {
        if (link?.name == "Rapid Order")
          link['isVisible'] = true
      })
    }
    if (user && (user?.userTypes?.includes("CUSTOMER"))) {
      links?.map(link => {
        if (link?.name == "Rapid Order")
          link['isVisible'] = false
      })
    }

    getcategoryByDivision()

    goto(0)
  }, [customer, user]);



  return (
    <>
      {/* first header starts here */}
      <header className={` dark:bg-[#3A3933] h-[35px] w-full xl:fixed z-[100]`} onClick={clearSearch} style={{ backgroundColor: headerColor || '#686661' }} >
        <nav
          className="flex mx-4 sm:mx-10 items-center justify-center"
          aria-label="Global"
        >
          <Popover.Group className="flex w-[320px] sm:w-[345.07px]">
            <Link to="/?division=NORTHCOTT" onClick={() => {
              handleImageClick("northcott")
              changeToNortncott()
            }}>
              {selectedBrand === "northcott" ? (
                <img
                  className="py-2 h-[35px] w-[49.38px] sm:py-0 sm:h-[35px] sm:w-[58.2px] object-cover bg-white"
                  src={NorthcottOnClick}
                  alt="Northcott"
                />
              ) : (
                <img
                  className="py-2 h-[35px] w-[49.38px] sm:py-0 sm:h-[35px] sm:w-[58.2px] hover:bg-[#6B6B66] object-cover"
                  src={Northcott}
                  alt="Northcott"
                />
              )}
            </Link>
            <Link to="/?division=BANYAN BATIKS" onClick={() => {
              handleImageClick("banyan")
              changeToBanyan()
            }}>
              {selectedBrand === "banyan" ? (
                <img
                  className="h-[35px] w-[76.3px] sm:w-[88.11px] object-cover bg-white"
                  src={BanyanOnClick}
                  alt="BanyanLogo"
                />
              ) : (
                <img
                  className="h-[35px] w-[76.3px] sm:w-[88.11px] hover:bg-[#6B6B66]"
                  src={BanyanLogo}
                  alt="Banyan"
                />
              )}
            </Link>
            <Link to="/?division=PATRICK LOSE" onClick={() => {
              handleImageClick("patrick")
              changeToPatrick()
            }}>
              {selectedBrand === "patrick" ? (
                <img
                  className="h-[35px] w-[97.6px] sm:w-[122px] object-cover bg-white"
                  src={PatrickOnClick}
                  alt="PatrickLogo"
                />
              ) : (
                <img
                  className="h-[35px] w-[97.6px] sm:w-[122px] hover:bg-[#6B6B66]"
                  src={PatrickLogo}
                  alt="PatrickLogo"
                />
              )}
            </Link>
          </Popover.Group>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:items-center">
            {!user ? (
              <div>
                <Link
                  to={`/login?division=${division}`}
                  className="text-sm text-white font-semibold hover:text-[#A4A3A0]"
                >
                  Log In
                </Link>{" "}
              </div>
            ) : (
              <div className="text-sm text-white font-semibold hover:text-[#A4A3A0]">
                <div className="flex items-center justify-end text-end sm:mx-2">
                  {user?.userTypes?.includes("CUSTOMER") && <div className=" h-[20px]">
                    <p className="text-white text-[8px] leading-3">
                      You're logged in as {user?.companyName} {user?.customerId} {user?.city} {user?.state}{" "}

                    </p>
                  </div>}

                  <div className=" h-[20px]">
                    <p className="text-white text-[8px] leading-3">
                      {(customer && !user?.userTypes?.includes("CUSTOMER")) && `You're logged in as ${customer?.companyName} ${customer?.customerId} ${customer?.city} ${(customer.isExport && customer.exportUSA?.length) ? customer?.exportUSA.map(ele => ele.text) : (customer.isExport && customer.exportCAD?.length) ? customer?.exportCAD.map(ele => ele.text) : customer?.state}`}{" "}
                      {user?.userTypes?.includes("CUSTOMER") ? "" : <Link to={`/region-customer?division=${division}`} className="underline font-semibold">
                        To change, click here.
                      </Link>}
                    </p>
                  </div>

                  <div className=" mx-6 h-[23px] text-white">{` | `}</div>
                  <a
                    href="#"
                    onClick={handleLogout}
                    className="text-sm text-white font-semibold text-center text-nowrap"
                  >
                    Log Out
                  </a>{" "}
                </div>
              </div>
            )}
            {(user || customer) ? <div className="mx-6 h-[30px] text-white">{` | `}</div> : ""}

            <div className="relative">
              {(user || customer) ? <Link to={"/cart-detail"}>
                <img className=" h-8 w-auto" src={shopping} alt="shopping" />
                {quantity > 0 ? <span
                  className="px-[4px] h-[18px] pt-[1px] flex items-center justify-center rounded-full bg-white text-[10px] font-normal text-black absolute left-[20px] -top-[1px]"
                >
                  {quantity <= 1000 ? quantity : "1000+"}
                </span> : ""}
              </Link> : ""}
            </div>

          </div>
        </nav>
      </header >
      {/* first header ends here */}
      {/* Second Header start here */}
      <div className="bg-white p-2 sm:pt-3 xl:pt-9">
        {/* <div className="flex flex-row"> */}
        <div className="flex xl:flex-row-reverse relative">
          {/* mobile view hamburger menu */}
          <div className="basis-1/3 h-[50px] w-[200px] sm:w-[664px] ms-[10px] sm:ms-[45px]" onClick={clearSearch}>
            <div
              className="flex mt-4 lg:hidden hover:text-[#E2A856]"
              onClick={() => setOpen(!open)}
            >
              <div className="relative">
                <img
                  className="absolute top-0 left-0 hover:hidden h-[24px] w-[18px]"
                  src={BurgerIconMobile}
                  alt="Menu"
                />
                <img
                  className="block h-[24px] w-[18px]"
                  src={mobileMenuHover}
                  alt="Menu"
                />
              </div>
            </div>
          </div>
          {division === "NORTHCOTT" ? <div className="basis-2/3 sm:basis-1/3 flex items-center justify-center -ms-10 -me-4" onClick={clearSearch}>
            {" "}
            <Link to={"/?division=NORTHCOTT"} className="cursor-pointer">
              <img
                className="h-[37.25px] w-[170px] sm:h-[50px] sm:w-[228px] mt-2"
                src={NorthcottMainLogo}
                alt="Northcott"
              />
            </Link>
          </div> : ""}

          {division === "BANYAN BATIKS" ? <div className="basis-2/3 sm:basis-1/3 flex items-center justify-center -ms-10 -me-4" onClick={clearSearch}>
            {" "}
            <Link to={"/?division=BANYAN BATIKS"} className="cursor-pointer">
              <img
                className="h-[37.25px] w-[170px] sm:h-[50px] sm:w-[228px] mt-2"
                src={BanyanMainLogo}
                alt="BANYAN BATIKS"
              />
            </Link>
          </div> : ""}

          {division === "PATRICK LOSE" ? <div className="basis-2/3 sm:basis-1/3 flex items-center justify-center -ms-10 -me-4" onClick={clearSearch}>
            {" "}
            <Link to={"/?division=PATRICK LOSE"} className="cursor-pointer">
              <img
                className="h-[37.25px] w-[170px] sm:h-[50px] sm:w-[228px] mt-2"
                src={PatrickMainLogo}
                alt="Northcott"
              />
            </Link>
          </div> : ""}
          <div className="basis-1/3 absolute z-50">
            {" "}
            {/* desktop view input search */}
            <form className="sm:mx-5 mt-2 hidden lg:flex justify-end">
              <div className="flex mt-2">
                <div className="relative w-[250px] bg-white">
                  <label
                    htmlFor="default-search"
                    className="mb-2 text- font-medium text-gray-900 sr-only"
                  >
                    Search
                  </label>
                  <div className="relative">
                    {active && <div className="z-50 absolute inset-y-0 end-0 mt-[12px] px-3 " style={{ cursor: "pointer" }} onClick={clearSearch}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="size-5 text-[#9D9C99]">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                      </svg>
                    </div>}
                    <input
                      type="search"
                      id="default-search"
                      className="block w-full p-2 text-gray-900 border border-gray-300 bg-white focus:ring-gray-500 focus:border-gray-500"
                      placeholder="Search"
                      ref={inputRef}
                      onChange={(e) => {
                        GlobalSearch(e.target.value)
                      }}
                      onFocus={() => setActive(true)}
                    />
                    {/* search result dropdown */}
                    {active &&
                      < div
                        className="styled-scrollbar w-[250px] h-[600px] bg-white flex flex-col items-center justify-start ps-1 sm:px-0 xl:px-2 drop-shadow-xl overflow-scroll" style={{ fontFamily: "Open Sans" }}
                      >
                        {/* Collection data for global search */}
                        <div className="w-[230px] flex flex-col items-center justify-center">
                          <h2 className="h-[45px] text-black font-bold flex items-center text-[14px] border-b-[1px] border-[#CECECC] w-[230px] ps-[20px]">Collections</h2>

                          <div>  {searchResult?.collections?.docs.map((collection, index) => {
                            return (
                              <Link key={index} to={`/product-detail/${collection?._id}?division=${division}&categoryName=${collection?.categories[0]?.text}&categoryId=${collection?.categories[0]?.id}`} onClick={clearSearch}>
                                <h4 className="text-[14px] cursor-pointer text-black flex items-center text-left w-[230px] hover:bg-[#F0EFEB] h-[45px] ps-[20px]">
                                  {collection?.name}
                                </h4>
                              </Link>
                            )
                          })}
                          </div>
                        </div>
                        {/* Product data for global search*/}
                        <div className="w-[230px] flex flex-col items-center justify-center">
                          <h2 className="h-[45px] text-black font-bold flex items-center text-[14px] border-b-[1px] border-[#CECECC] w-[230px] ps-[20px]">Products</h2>

                          <div>  {searchResult?.products?.docs.map((product, index) => {
                            console.log(product);
                            return (
                              <Link to={`/product-detail/${product?.collectionDoc?._id}?division=${division}`} key={index} onClick={clearSearch}>
                                <h4 className="text-[14px] cursor-pointer text-black flex items-center text-left w-[230px] hover:bg-[#F0EFEB] h-[45px] ps-[20px] text-nowrap">
                                  {product?.name} - {product?.displayName}
                                </h4>
                                <div className="w-[230px] flex justify-end">
                                  <hr className="border-b-[0.4px] border-gray-200 w-[200px] h-[1px]" />
                                </div>
                              </Link>
                            )
                          })}
                          </div>
                        </div>
                        {/* Categories data for global search*/}
                        <div className="w-[230px] flex flex-col items-center justify-center">
                          <h2 className="h-[45px] text-black font-bold flex items-center text-[14px] border-b-[1px] border-[#CECECC] w-[230px] ps-[20px]">Categories</h2>

                          <div>  {searchResult?.categories?.docs.map((category, index) => (
                            <Link to={`/product-collection/${category?._id}?division=${division}`} key={index} onClick={clearSearch}>
                              <h4 className="text-[14px] cursor-pointer text-black flex items-center text-left w-[250px] hover:bg-[#F0EFEB] h-[45px] ps-[20px]">
                                {category?.name}
                              </h4>
                            </Link>
                          ))}
                          </div>
                        </div>
                        {/* Designers data for global search*/}
                        <div className="w-[230px] flex flex-col items-center justify-center">
                          <h2 className="h-[45px] text-black font-bold flex items-center text-[14px] border-b-[1px] border-[#CECECC] w-[230px] ps-[20px]">Designers</h2>

                          <div>  {searchResult?.designers?.docs.map((designer, index) => (
                            <Link to={`/coming-soon`} onClick={clearSearch} key={index}>
                              <h4 className="text-[14px] cursor-pointer text-black flex items-center text-left w-[230px] hover:bg-[#F0EFEB] h-[45px] ps-[20px]">
                                {designer?.name}
                              </h4>
                            </Link>
                          ))}
                          </div>
                        </div>
                      </div>}
                  </div>
                </div>
              </div>
            </form>
            {/* mobile view shopping icon menu */}
            <div className="flex lg:hidden justify-end mt-4 me-[13px] sm:me-[35px]">
              {(user || customer) ? <Link to={"/cart-detail"}>
                <div className="relative">
                  <img
                    className="absolute top-0 left-0 hover:hidden h-[24px] w-[16px] hover:text-[#E2A856]"
                    src={shoppingMoblie}
                    alt="Cart"
                  />
                  <img
                    className="block h-[24px] w-[16px] hover:text-[#E2A856]"
                    src={mobileCartHover}
                    alt="Cart"
                  />
                </div>
              </Link> : ""}
            </div>
          </div>
        </div >
      </div >
      {/* mobile view search input box */}
      < form className=" mt-5 mb-7 flex lg:hidden justify-center bg-white" >
        <div className="flex">
          <div className="relative">
            <div className="w-[280px] h-[40px] sm:w-[664px] sm:h-[40px] mx-auto">
              <label
                htmlFor="default-search"
                className="mb-2 font-medium text-gray-900 sr-only"
              >
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 end-0 flex items-center px-3 pointer-events-none">
                  <img src={search} alt="search" />
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block w-full p-2 text-gray-900 border border-gray-300 bg-white focus:ring-gray-500 focus:border-gray-500"
                  placeholder="Search"
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </form >
      {/* header second ends here */}
      {/* Third Header start here */}

      {/* desktop view links and dropdown links */}
      <div className="hidden bg-white pt-3 lg:mx-auto lg:flex lg:justify-center" onClick={clearSearch}>
        <div
          className="h-[44px] sm:min-w-[915px] xl:w-[1092px] grid grid-flow-col items-center justify-evenly"
          style={{ fontFamily: "Open Sans", fontWeight: "400" }}
        >
          {links.map((item, index) =>
            <div key={index}>
              {
                item?.isVisible ? (<div key={index}>
                  {!item.submenu ? (
                    <div>
                      <Link
                        to={item?.href ? `${item?.href}?division=${division}` : `/?division=${division}`}
                        className={`block uppercase rounded-lg py-2 font-normal text-xs text-gray-900 ${division === "NORTHCOTT" ? "hover:text-[#E2A856]" : `${division === "PATRICK LOSE" ? "hover:text-[#E2A856]" : "hover:text-[#006371]"}`}`}
                      >
                        {item?.name}
                      </Link>
                    </div>
                  ) : (
                    <div>
                      <Popover.Group>
                        <Popover className="relative group">
                          <Popover.Button
                            className={`flex uppercase font-normal mt-[0.3rem] text-xs text-gray-90 group ${division === "BANYAN BATIKS" ? "group-hover:text-[#006371]" : "group-hover:text-[#E2A856]"}`}
                            onClick={() => {
                              setPopoverOpen(true);
                            }}
                          >
                            {item?.name}
                            <ChevronDownIcon
                              className={`h-5 w-5 text-gray-400 ${division === "BANYAN BATIKS" ? "group-hover:text-[#006371]" : "group-hover:text-[#E2A856]"}`}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-1"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel
                              className="absolute grid -left-8 w-max z-10 mt-3 bg-white shadow-lg"
                            >
                              {popoverOpen && <div
                                className={`${item.sublinks.length > 1
                                  ? " grid grid-cols-3 gap-10 shadow-md border-t"
                                  : " grid grid-cols-1 w-64 shadow-md border-t"
                                  }`}
                              >
                                {item?.sublinks &&
                                  item?.sublinks?.map((mysublink, index) => (
                                    <div key={index}>
                                      {mysublink?.Head !== "a" ? (
                                        <div className=" font-bold text-xs">
                                          {mysublink?.Head !== "" ? (
                                            <div className="p-3">
                                              {mysublink?.Head}
                                            </div>
                                          ) : (
                                            <div className="hidden"></div>
                                          )}
                                        </div>
                                      ) : (
                                        <div className="text-white p-2">a</div>
                                      )}

                                      {mysublink?.sublink?.map(
                                        (finallink, index) => {

                                          return (
                                            <div
                                              key={index}
                                              className="p-3 text-sm leading-6 hover:bg-[#F5F4F1]"
                                            >
                                              <div>
                                                <div className="col-span-2">
                                                  <Link
                                                    to={`${finallink?.href}?division=${division}`}
                                                    className="block text-xs text-gray-900"
                                                    onClick={() =>
                                                      setPopoverOpen(false)
                                                    }
                                                  >
                                                    {finallink?.name}
                                                  </Link>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        }
                                      )}
                                    </div>
                                  ))}
                              </div>}
                            </Popover.Panel>
                          </Transition>
                        </Popover>
                      </Popover.Group>
                    </div>
                  )}
                </div>) : ""
              }
            </div>
          )}
        </div>
      </div >
      <div className="-mt-2">
        <hr />
      </div>
      {/* Third header ends here */}

      {/* mobile view nav link and nav menu */}
      <ul
        className={`
        lg:hidden bg-[#686661] fixed w-full top-0 overflow-y-auto bottom-0
        duration-500 ${open ? "left-0" : "left-[-100%]"}
        `}
        style={{ zIndex: 9999, fontFamily: "Open Sans", fontWeight: "400" }}
      >
        <li className="flex m-2 items-center justify-between text-2xl h-16">
          {!user ? (
            <div>
              <Link
                to="/login"
                className="text-sm text-white font-semibold mx-2 text-center hover:text-[#A4A3A0]"
              >
                Log In
              </Link>{" "}
            </div>
          ) : (
            <div className="flex items-center justify-center gap-2 sm:mx-2">
              <a
                href="#"
                className="text-sm text-white font-semibold text-center text-nowrap"
                onClick={handleLogout}
              >
                Log Out
              </a>{" "}
              <div className=" h-[35px] text-white">{` | `}</div>

              {user?.userTypes?.includes("CUSTOMER") &&
                <div className="">
                  <p className="text-white text-[10px] sm:text-sm leading-4 ms-2 sm:ms-2   text-start">
                    You're logged in as {user?.companyName} {user?.customerId} {user?.city} {user?.state}{" "}
                  </p>
                </div>
              }
              <div className="">
                <p className="text-white text-[10px] sm:text-sm leading-4 ms-2 sm:ms-2   text-start">
                  {(customer && !user?.userTypes?.includes("CUSTOMER")) && `You're logged in as ${customer?.companyName} ${customer?.customerId} ${customer?.city} ${(customer.isExport && customer.exportUSA?.length) ? customer?.exportUSA.map(ele => ele.text) : (customer.isExport && customer.exportCAD?.length) ? customer?.exportCAD.map(ele => ele.text) : customer?.state}`}{" "}
                  {user?.userTypes?.includes("CUSTOMER") ? "" : <Link to={`/region-customer?division=${division}`} className="underline font-semibold">
                    To change, click here.
                  </Link>}
                </p>
              </div>
            </div>
          )}
          <div className="text-white" onClick={closeMenu}>
            {" "}
            <ion-icon name="close"></ion-icon>
          </div>
        </li>
        <div>
          {links.map((link, index) => (
            <div className="bg-white" key={index}>
              <div className="px-3 text-left md:cursor-pointer group">
                {!link.submenu ? (
                  <Link
                    to={"/"}
                    className="py-3 flex justify-between items-center pr-5 group"
                  >
                    {" "}
                    <div className="uppercase text-black hover:text-[#E2A856] font-normal text-xs">
                      {link.name}
                    </div>
                  </Link>
                ) : (
                  <div
                    className="py-3 flex justify-between items-center pr-5 group"
                    onClick={() => {
                      toggleSubmenu(index);
                    }}
                  >
                    <div className="uppercase text-black hover:text-[#E2A856] font-normal text-xs">
                      {link.name}
                    </div>
                    <span className="lg:hidden inline">
                      {link.submenu && (
                        <div>
                          {!openSubsubmenus && (
                            <div className="text-black hover:text-[#E2A856]">
                              <ion-icon name="chevron-forward"></ion-icon>
                            </div>
                          )}{" "}
                          {
                            <ul
                              className={`
                              lg:hidden bg-white fixed w-full top-0 overflow-y-auto bottom-0
                              duration-500 ${openSubmenus[index] ? "left-0" : "left-[-100%]"
                                }
                              `}
                            >
                              {/* Render submenu content */}
                              {openSubsubmenus && (
                                <ul>
                                  <li className="bg-[#686661] flex justify-between text-2xl p-2">
                                    {!isLoggedIn ? (
                                      <div>
                                        <Link
                                          to="/login"
                                          className="text-sm text-white font-semibold mx-2 text-center hover:text-[#A4A3A0]"
                                        >
                                          Log In
                                        </Link>{" "}
                                      </div>
                                    ) : (
                                      <div className="flex items-center justify-center gap-2 sm:mx-2">
                                        <a
                                          href="#"
                                          className="text-sm text-white font-semibold text-center text-nowrap"
                                          onClick={handleLogout}
                                        >
                                          Log Out
                                        </a>{" "}
                                        <div className=" h-[35px] text-white">{` | `}</div>
                                        <div className="">
                                          <p className="text-white text-[10px] sm:text-sm leading-4 ms-2 sm:ms-2 me-6 sm:me-56 text-start">
                                            You're logged in as CHRISTINA'S
                                            NORTHCOTT SHOP, Vaughan{" "}
                                            <Link
                                              to={"/"}
                                              className="underline font-semibold"
                                            >
                                              To change, click here.
                                            </Link>
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                    <div
                                      className="text-white"
                                      onClick={closeMenu}
                                    >
                                      {" "}
                                      <ion-icon name="close"></ion-icon>
                                    </div>
                                  </li>
                                  <li className="flex bg-white items-end mt-3 py-3 ">
                                    <div className="text-center ps-3 mb-[11px]">
                                      <ion-icon name="chevron-back-outline"></ion-icon>
                                    </div>
                                    <h1 className=" bg-white font-bold uppercase text-xs ms-2 mb-3 hover:text-[#E2A856]">
                                      {link.name}
                                    </h1>
                                  </li>
                                  {link.sublinks.map((mysublinks, index) => (
                                    <div
                                      key={index + 100}
                                      className="bg-white ps-4"
                                    >
                                      {mysublinks?.Head === "a" ? (
                                        <div className="text-white hidden"></div>
                                      ) : (
                                        <div className="font-bold text-xs">
                                          {mysublinks.Head}
                                          {mysublinks.Head === "" ? null : (
                                            <div className="mt-3 w-[90vw] sm:w-[95vw]">
                                              <hr />
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      {mysublinks.sublink.map(
                                        (finallink, index) => (
                                          <div
                                            key={index}
                                            className="bg-white py-3 text-xs text-black hover:bg-[#F5F4F1]"
                                          >
                                            <Link
                                              to={`/product-collection/${finallink?._id?.$oid}`}
                                            >
                                              {finallink.name}
                                            </Link>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ))}
                                </ul>
                              )}
                            </ul>
                          }
                        </div>
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="bg-white h-[10vh]"></div>
      </ul >
    </>
  );
}





