import BecomeADesigner2 from "../../assets/Designer/BecomeADesigner2.svg";
import BecomeADesigner1 from "../../assets/Designer/BecomeADesigner1.svg";
import { Link, useSearchParams } from "react-router-dom";

const BecomeANorthcottDesigner = () => {

    const [searchParams, setSearchParams] = useSearchParams()

    let division = searchParams.get('division')?.toUpperCase() || "NORTHCOTT";
    return (
        <div
            className="my-[80px] sm:my-[100px] flex items-center justify-center"
            style={{ fontFamily: "Open Sans" }}
        >
            <div className="flex flex-col items-center justify-center gap-[60px]">
                {/*row 1 back button */}
                <div className="w-[280px] h-[21px] sm:w-[624px]  xl:w-[1160px] 2xl:w-[1320px] flex justify-start">
                    {/* back to cart */}
                    <Link to={`/designers-list?division=${division}`}>
                        <div className="h-[21px] sm:h-[34px] flex items-center cursor-pointer justify-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-[14px] h-[14px] text-[#6B6B66]"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15.75 19.5 8.25 12l7.5-7.5"
                                />
                            </svg>
                            <p className="text-[14px] text-[#6B6B66] hover:text-[#E2A856]">
                                View all designers
                            </p>
                        </div>
                    </Link>
                </div>
                {/* row 2 content*/}
                <div className="w-[320px] sm:w-[624px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col items-center justify-center gap-[100px] xl:gap-[60px]">
                    {/*become a fabric designer */}
                    <div className="w-[320px] sm:w-[624px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col xl:flex-row items-center justify-center xl:justify-start gap-[40px] xl:gap-[100px]">
                        <img
                            src={BecomeADesigner1}
                            alt="USA Map"
                            className="w-[280px] h-[280px] sm:w-[500px] sm:h-[500px] object-cover flex items-center justify-center"
                        />
                        <div className="w-[280px] sm:w-[624px] xl:w-[500px] 2xl:w-[660px] flex flex-col items-center justify-center gap-[20px]">
                            <h1 className="w-[280px] h-[76px] sm:w-[624px] sm:h-[44px] xl:w-[500px] 2xl:w-[660px] text-center xl:text-start text-[28px] sm:text-[32px] font-bold text-black">
                                Become a Fabric Designer
                            </h1>
                            <p className="w-[280px] sm:w-[624px] xl:w-[500px] 2xl:w-[660px] text-center xl:text-start text-[14px] sm:text-[16px] text-black">
                                Designers interested in collaborating with Northcott should
                                submit concepts or finished collections to{" "}
                                <span>
                                    <a className="hover:underline hover:text-[#E2A856] cursor-pointer" target="_blank" href='mailto:design@northcott.com'>
                                        design@northcott.com .{" "}
                                    </a>
                                </span>
                                Please include your contact information, a brief outline for
                                the collection and jpeg images for consideration. Files cannot
                                exceed 10MB. We promise to reply within a few days.
                            </p>
                        </div>
                    </div>
                    {/*become a pattern designer */}
                    <div className="w-[320px] sm:w-[624px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col xl:flex-row items-center justify-center xl:justify-end gap-y-[40px] gap-x-0 xl:gap-x-[100px] xl:gap-y-0">
                        <img
                            src={BecomeADesigner2}
                            alt="USA Map"
                            className="w-[280px] h-[280px] sm:w-[500px] sm:h-[500px] object-cover flex items-center justify-center order-1 xl:order-2"
                        />
                        <div className="w-[280px] sm:w-[624px] xl:w-[500px] 2xl:w-[660px] flex flex-col items-center justify-center gap-[20px] order-2 xl:order-1">
                            <h1 className="w-[280px] h-[76px] sm:w-[624px] sm:h-[44px] xl:w-[500px] 2xl:w-[660px] text-center xl:text-end text-[28px] sm:text-[32px] font-bold text-black">
                                Become a Pattern Designer
                            </h1>
                            <p className="w-[280px] sm:w-[624px] xl:w-[500px] 2xl:w-[660px] text-center xl:text-end text-[14px] sm:text-[16px] text-black">
                                Designers interested in collaborating with Northcott should
                                submit concepts or finished collections to{" "}
                                <span>
                                    <a className="hover:underline hover:text-[#E2A856] cursor-pointer" target="_blank" href='mailto:marketing@northcott.com'>
                                        marketing@northcott.com .
                                    </a>
                                </span>
                                Please include your contact information, a brief outline for
                                the collection and jpeg images for consideration. Files cannot
                                exceed 10MB. We promise to reply within a few days.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default BecomeANorthcottDesigner;