
import React from 'react'

const ComingSoon = () => {
    return (

        <>
            {/* component */}
            <div
                className="w-full h-screen bg-white"
                style={{
                    fontFamily: "Open sans"
                    // backgroundImage:
                    //     'url("https://vojislavd.com/ta-template-demo/assets/img/coming-soon.jpg")'
                }}
            >
                <div className="w-full h-screen flex flex-col items-center justify-between py-8">
                    <div className="flex-1 flex flex-col items-center justify-center">

                        <h1 className="text-6xl lg:text-7xl xl:text-8xl text-black tracking-wider font-serif mt-12 text-center">
                            Coming Soon...
                        </h1>

                    </div>

                </div>
            </div>
        </>

    )
}

export default ComingSoon