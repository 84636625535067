

import Breadcrum from "../product/Breadcrum";
import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ApiService from "../../helpers/ApiServices";
import { BrandIconContext } from "../../states/contexts/BrandIconContext";
import { UserContext } from "../../states/contexts/UserContext";
import { getModelRoute } from "../../helpers/Utils";
import axios from "axios";
import { CustomerContext } from "../../states/contexts/CustomerContext";
import moment from "moment";

const filters = [
  {
    id: "Categories",
    name: "Categories",
    options: [
      { value: "Option1", label: "Option1", checked: false, quantity: 15 },
      { value: "Option22", label: "Option22", checked: false, quantity: 15 },
      { value: "Option333", label: "Option333", checked: true, quantity: 15 },
      {
        value: "Option4444",
        label: "Option4444",
        checked: false,
        quantity: 15,
      },
      {
        value: "Option55555",
        label: "Option55555",
        checked: false,
        quantity: 15,
      },
      {
        value: "Option666666",
        label: "Option666666",
        checked: false,
        quantity: 15,
      },
    ],
  },
  {
    id: "Division",
    name: "Division",
    options: [
      { value: "Option7", label: "Option7", checked: false, quantity: 15 },
      { value: "Option8", label: "Option8", checked: false, quantity: 15 },
      { value: "Option9", label: "Option9", checked: true, quantity: 15 },
      { value: "Option10", label: "Option10", checked: false, quantity: 15 },
      { value: "Option11", label: "Option11", checked: false, quantity: 15 },
      { value: "Option12", label: "Option12", checked: false, quantity: 15 },
    ],
  },
  {
    id: "Status",
    name: "Status",
    options: [
      { value: "Option13", label: "Option13", checked: false, quantity: 15 },
      { value: "Option14", label: "Option14", checked: false, quantity: 15 },
      { value: "Option15", label: "Option15", checked: true, quantity: 15 },
      { value: "Option16", label: "Option16", checked: false, quantity: 15 },
      { value: "Option17", label: "Option17", checked: false, quantity: 15 },
      { value: "Option18", label: "Option18", checked: false, quantity: 15 },
    ],
  },
];

const productItems = [
  {
    name: 0,
    href: "allThatGlitters",
    by: "Simon Treadwell",
    detail: "All That Glitters",
    date: "In stores in June, 2024",
  },
  {
    name: 1,
    href: "autumnGathering",
    by: "Jennifer Nilsson",
    detail: "Autumn Gathering",
    date: "In stores in April, 2024",
  },
  {
    name: 2,
    href: "bearyMerryChristmas",
    by: "Deborah Edwards Nort...",
    detail: "Beary Merry Christmas",
    date: "In stores in May, 2024",
  },
  {
    name: 3,
    href: "doubleBubbleKittyT",
    by: "Fernanda Motta",
    detail: "Double Bubble Kitty T...",
    date: "In stores in April, 2024",
  },
  {
    name: 4,
    href: "farmsteadFriends",
    by: "Simon Treadwell",
    detail: "Farmstead Friends",
    date: "In stores in June, 2024",
  },
  {
    name: 5,
    href: "hallow'sEve",
    by: "Cerrito Creek Studio",
    detail: "Hallow's Eve",
    date: "In stores in April, 2024",
  },
  {
    name: 6,
    href: "lettersToSanta",
    by: "Simon Treadwell",
    detail: "Letters To Santa",
    date: "In stores in May, 2024",
  },
  {
    name: 7,
    href: "merryAndBright",
    by: "Michael Zindell Designs",
    detail: "Merry and Bright",
    date: "In stores in May, 2024",
  },
  {
    name: 8,
    href: "shimmerSparkle",
    by: "Deborah Edwards Nort...",
    detail: "Shimmer Sparkle",
    date: "In stores in May, 2024",
  },
  {
    name: 9,
    href: "snowMuchFun",
    by: "Simon Treadwell",
    detail: "Snow Much Fun",
    date: "In stores in May, 2024",
  },
  {
    name: 10,
    href: "sugarCoated",
    by: "Deborah Edwards Nort...",
    detail: "Sugar Coated",
    date: "In stores in April, 2024",
  },
  {
    name: 12,
    href: "twasTheNightBeforeChristmas",
    by: "Simon Treadwell",
    detail: "'Twas The Night Before Christmas",
    date: "In stores in June, 2024",
  },
  {
    name: 11,
    href: "yuletideTraditions",
    by: "Simon Treadwell",
    detail: "Yuletide Traditions",
    date: "In stores in June, 2024",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SearchDetail = () => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [state, setState] = useState([]);
  const [tabKey, setTabKey] = useState();

  const { dispatch: iconDispatch, ...brandIconObject } = useContext(BrandIconContext)
  const { user } = useContext(UserContext)
  const { customer } = useContext(CustomerContext)

  const { searchItem } = useParams()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  let cancelToken, to;

  let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"

  const handleFilterSelection = (sectionId, optionValue) => {
    const updatedFilters = [...selectedFilters];
    const index = updatedFilters.findIndex(
      (filter) => filter.sectionId === sectionId
    );

    if (index === -1) {
      updatedFilters.push({ sectionId, optionValues: [optionValue] });
    } else {
      const options = updatedFilters[index].optionValues;
      const optionIndex = options.indexOf(optionValue);
      if (optionIndex === -1) {
        options.push(optionValue);
      } else {
        options.splice(optionIndex, 1);
      }
    }

    setSelectedFilters(updatedFilters);
  };

  const removeOptionValue = (sectionId, optionValue) => {
    const updatedFilters = selectedFilters.map((filter) => {
      if (filter.sectionId === sectionId) {
        filter.optionValues = filter.optionValues.filter(
          (value) => value !== optionValue
        );
      }
      return filter;
    });

    setSelectedFilters(updatedFilters);

    // Uncheck the corresponding checkbox
    const checkbox = document.getElementById(
      `filter-${sectionId}-${optionValue}`
    );
    if (checkbox) {
      checkbox.checked = false;
    }
  };

  const clearAllFilters = () => {
    setSelectedFilters([]);
    // Uncheck all checkboxes
    filters.forEach((section) => {
      section.options.forEach((option) => {
        const checkbox = document.getElementById(
          `filter-${section.id}-${option.value}`
        );
        if (checkbox) {
          checkbox.checked = false;
        }
      });
    });
  };

  const GlobalSearch = async (searchData) => {
    console.log("GlobalSearch")
    const controller = new AbortController();
    let data, array = [];

    cancelToken = axios.CancelToken.source

    ApiService.setHeader()
    await ApiService.get(`shop/collection/globalSearch/${searchItem}?model=${getModelRoute(user).model}`, { signal: controller.signal }).then(response => {
      console.log(response?.data);

      for (let ele of response?.data.data) {
        let obj = {}
        let arr = ele

        obj.header = ele[0]
        obj.data = arr?.slice(1)

        array?.push(obj)
      }

      console.log("array", array)
      setState(array)
      // data = response?.data.documents

    }).catch(e => {
      console.log(e.response?.data.message);
      // showMessage(e?.response?.data.message, "error")
    })

    // return data;
    controller.abort()

  }



  useEffect(() => {
    GlobalSearch()
  }, [searchItem])
  // console.log(state?.shift())


  return (
    <>
      <div
        className="flex items-center justify-center my-[80px] sm:my-[100px]"
        style={{ fontFamily: "Open Sans" }}
      >
        <div
          style={{ fontFamily: "Open Sans" }}
          className="w-full flex flex-col items-center justify-center gap-[80px] sm:gap-[60px] xl:gap-[100px]"
        >
          {/* heading and breadcrums */}
          <div className="w-[320px] h-[114px] sm:w-[644px] sm:h-[82px] xl:w-[1280px] 2xl:w-[1320px] flex items-center">
            <div className="flex flex-col items-center justify-center gap-[20px] mx-[20px] sm:mx-0 xl:mx-[60px] 2xl:mx-0">
              {/* Heading  */}
              <div className="w-[280px] h-[76px] sm:w-[664px] sm:h-[44px] xl:w-[1160px] 2xl:w-[1320px]">
                <p className="text-[28px] sm:text-[32px] font-bold wrap text-start leading-10">
                  Search Results for ‘{searchItem}’
                </p>
              </div>

              {/* Breadcrums */}
              <div className="w-full h-[18px]">
                <nav className="flex" aria-label="Breadcrumb">
                  <ol className="inline-flex space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex">
                      <p className=" mt-[5px] cursor-pointer inline-flex text-[12px] font-medium text-[#6B6B66] hover:text-[#090800] hover:underline"
                        onClick={() => {
                          navigate(`/?division=${division}`)
                        }}
                      >
                        Home
                      </p>
                    </li>
                    <li className="inline-flex">
                      <div className="flex text-[#6B6B66]">
                        {" / "}
                        <p className="cursor-pointer mt-[5px] ms-1 text-[12px] font-medium text-[#6B6B66] hover:text-[#090800] hover:underline md:ms-2">
                          Search
                        </p>
                      </div>
                    </li>
                    <li aria-current="page">
                      <div className="flex text-[#6B6B66]">
                        {" / "}
                        <span className="mt-[5px] ms-1 text-[12px] font-medium text-[#6B6B66] md:ms-2 flex justify-center">
                          {searchItem}
                        </span>
                      </div>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>

          {/*Main body */}
          <div className="w-[320px] sm:w-[644px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col items-center justify-center">
            {/* Mobile filter dialog */}
            <Transition.Root show={mobileFiltersOpen} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-40 lg:hidden"
                onClose={setMobileFiltersOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl items-center">
                      {/* mobile filter top header */}
                      <div className="flex flex-col w-[280px]">
                        <button
                          type="button"
                          className="-mr-2 flex h-10 w-full justify-end rounded-md bg-white p-2"
                          onClick={() => setMobileFiltersOpen(false)}
                        >
                          <span className="sr-only">Close menu</span>
                          <XMarkIcon
                            className="h-[28px] w-[28px] text-black"
                            aria-hidden="true"
                          />
                        </button>
                        <h2
                          className="text-[22px] font-bold text-black"
                          style={{ fontFamily: "Open Sans" }}
                        >
                          Filters
                        </h2>
                      </div>

                      {/* mobile Filters */}
                      <form
                        className={`${selectedFilters.length > 0
                          ? "mt-4 border-t-2 border-[#CECECC] flex flex-col items-center justify-center w-[280px]"
                          : "border-white mt-0 flex flex-col items-center justify-center w-[280px]"
                          }`}
                      >
                        {/* Clear All button mobile only */}
                        {selectedFilters.length > 0 && (
                          <div className="mb-[25px] w-[300px] flex flex-col justify-between items-start sm:block sm:justify-normal px-4 py-2 mt-[20px] sm:mt-0 gap-[10px]">
                            <h3 className="text-[16px] font-bold text-black text-nowrap">
                              Selected Filters
                            </h3>
                            <button
                              type="button"
                              onClick={() => clearAllFilters()}
                              className="text-[14px] text-black underline"
                            >
                              Clear All
                            </button>
                          </div>
                        )}
                        <ul className="w-[280px] space-y-4 pb-6 text-[14px]">
                          {/* select field occur in input by Mithun */}
                          {selectedFilters.map((filter) => (
                            <li
                              key={filter.sectionId}
                              className="flex flex-wrap"
                            >
                              {filter.optionValues.map((optionValue) => (
                                <div
                                  key={optionValue}
                                  className="flex items-center"
                                  style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                                    padding: "8px",
                                    margin: "4px",
                                    flexBasis: "calc(30% - 8px)",
                                  }}
                                >
                                  <span className="mr-1 text-black">
                                    {optionValue}
                                  </span>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      removeOptionValue(
                                        filter.sectionId,
                                        optionValue
                                      )
                                    }
                                  >
                                    <XMarkIcon className="h-4 w-4 text-black" />
                                  </button>
                                </div>
                              ))}
                            </li>
                          ))}
                        </ul>

                        <div className="bg-[#CECECC] h-[1px] w-full"></div>
                        {/* dropdown menu */}
                        {filters.map((section, index) => (
                          <Disclosure
                            as="div"
                            key={section.id}
                            className={`py-6 w-[280px] ${index !== 0 && "border-t-[1.5px] border-[#CECECC]"
                              }`}
                          >
                            {({ open }) => (
                              <>
                                <h3 className="-mx-2 -my-3 flow-root">
                                  <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3">
                                    <span className=" text-black font-bold text-[18px]">
                                      {section.name}
                                    </span>
                                    <span className="ml-6 flex items-center text-[#090800]">
                                      {open ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="2"
                                          stroke="currentColor"
                                          className="w-5 h-5"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="m4.5 15.75 7.5-7.5 7.5 7.5"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="2"
                                          stroke="currentColor"
                                          className="w-5 h-5"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="m19.5 8.25-7.5 7.5-7.5-7.5"
                                          />
                                        </svg>
                                      )}
                                    </span>
                                  </Disclosure.Button>
                                </h3>
                                <Disclosure.Panel className="pt-6">
                                  <div className="space-y-[10px]">
                                    {/* mobile view dropdown */}
                                    {section.options.map(
                                      (option, optionIdx) => (
                                        <div
                                          key={option.value}
                                          className="flex items-center justify-between"
                                        >
                                          <div className="flex">
                                            <input
                                              id={`filter-${section.id}-${option.value}`}
                                              name={`${section.id}[]`}
                                              defaultValue={option.value}
                                              type="checkbox"
                                              className="h-[17px] w-[17px] rounded-[2px] border-[#D9D9D9] text-black focus:ring-black hover:border-black checked:text-black peer relative shrink-0 appearance-none border after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-[url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==')] after:bg-[length:30px] after:bg-center after:bg-no-repeat after:content-[''] checked:bg-black focus:outline-none"
                                              onClick={() =>
                                                handleFilterSelection(
                                                  section.id,
                                                  option.value
                                                )
                                              }
                                            />
                                            <label
                                              htmlFor={`filter-${section.id}-${option.value}`}
                                              className="ml-3 text-[14px] text-[#3A3933]"
                                            >
                                              {option.label}
                                            </label>
                                          </div>
                                          <div className="text-[#3A3933] text-[14px]">
                                            ({option.quantity})
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}
                        <div className="bg-[#CECECC] h-[1px] w-full"></div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>
            {/* desktop*/}
            <main className="w-[280px] sm:w-[644px] xl:w-[1160px] 2xl:w-[1320px]">
              <div className="grid grid-cols-1 gap-x-9 sm:grid-cols-4 h-[21px] sm:h-[61px]">
                <div className="sm:w-[180px] xl:w-[180px] hidden sm:block col-span-1 text-[24px] xl:text-[22px] font-bold">
                  Filters
                </div>

                {/* top heading */}
                <div className="sm:ms-[40px] 2xl:ms-[70px] sm:col-span-3 w-[280px] h-[21px] sm:w-[444px] xl:w-[820px] 2xl:w-[920px] flex items-center justify-center sm:items-start">
                  <div className="w-[280px] h-[21px] sm:w-[444px] xl:w-[820px] 2xl:w-[920px] flex flex-col sm:flex-row items-center justify-center sm:justify-between">
                    {/* length for all */}
                    <div className="w-[280px] h-[21px] sm:w-[91px] flex items-center justify-between sm:justify-center">
                      <h1 className="w-[91px] h-[21px] text-black text-[14px] text-nowrap">
                        {/* {productItems.length} Collections */}
                      </h1>
                      {/* mobile only */}
                      <button
                        type="button"
                        className=" text-gray-700 hover:text-gray-900 sm:hidden"
                        onClick={() => setMobileFiltersOpen(true)}
                      >
                        <span className="sr-only text-[14px]">Filters</span>
                        <p className="text-black underline text-[14px]">
                          See Filters
                        </p>
                      </button>
                    </div>
                    {/* sort for all and dropdown for desktop */}
                    <div className="pt-[20px] sm:pt-0 w-[280px] xl:w-[221px] h-[21px] flex flex-col items-end">
                      <div className="sm:mt-0 w-[280px] h-[21px] sm:w-[221px] flex items-center justify-center">
                        <Menu
                          as="div"
                          className="relative inline-block text-left w-full"
                        >
                          <div className="text-[14px] text-black flex items-center justify-between">
                            <p>Sort:</p>
                            <Menu.Button className="w-[170px] h-[21px] inline-flex justify-between rounded bg-white text-[14px]">
                              Default
                              <ChevronDownIcon
                                className="h-5 w-5 text-black sm:ms-14 font-normal"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute sm:-right-1 right-0 xl:right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="">
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-[#F5F4F1] text-black"
                                          : "text-black",
                                        "block px-4 py-2 text-[14px]"
                                      )}
                                    >
                                      Account settings
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-[#F5F4F1] text-black"
                                          : "text-black",
                                        "block px-4 py-2 text-[14px]"
                                      )}
                                    >
                                      Support
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active
                                          ? "bg-[#F5F4F1] text-black"
                                          : "text-black",
                                        "block px-4 py-2 text-[14px]"
                                      )}
                                    >
                                      License
                                    </a>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* desktop filter and main content for all */}
              <section
                aria-labelledby="products-heading"
                className="pt-[20px] sm:pt-0 w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px]"
              >
                <h2 id="products-heading" className="sr-only text-[14px]">
                  Products
                </h2>
                {/* desktop filter and main content for all */}
                <div className="pt-[40px] sm:pt-0 grid grid-cols-1 gap-x-10 gap-y-10 sm:gap-0 sm:grid-cols-4">
                  {/*desktop Filters */}
                  <form className="hidden md:block col-span-1 sm:w-[160px] xl:w-[280px] border-t border-[#CECECC]">
                    <h3 className="sr-only text-[14px]">Categories</h3>
                    {/* Clear All button desktop*/}
                    {selectedFilters.length > 0 && (
                      <div className="px-1 pb-[25px] flex justify-between flex-col xl:flex-row gap-[10px] xl:gap-0 mt-[40px]">
                        <h3 className="text-[18px] sm:text-[16px] xl:text-[18px] font-bold text-nowrap">
                          Selected Filters
                        </h3>

                        <button
                          type="button"
                          onClick={() => clearAllFilters()}
                          className="text-[14px] text-black hover:text-gray-700 underline text-nowrap text-start xl:text-center"
                        >
                          Clear all
                        </button>
                      </div>
                    )}
                    <ul className="space-y-2 text-[14px] font-medium text-gray-900 border-b border-[#CECECC]">
                      {selectedFilters.map((filter) => (
                        <li
                          key={filter.sectionId}
                          className={`flex flex-wrap gap-y-[10px] mb-[25px] gap-[10px]`}
                        >
                          {filter.optionValues.map((optionValue) => (
                            <div
                              key={optionValue}
                              className="flex items-center justify-between bg-[#F0EFEB] p-[8px] m-[2px] gap-[10px]"
                              style={{
                                flexBasis: "calc(30% - 8px)",
                              }}
                            >
                              <span className="mr-1">{optionValue}</span>
                              <button
                                type="button"
                                onClick={() =>
                                  removeOptionValue(
                                    filter.sectionId,
                                    optionValue
                                  )
                                }
                              >
                                <XMarkIcon className="h-4 w-4 text-black" />
                              </button>
                            </div>
                          ))}
                        </li>
                      ))}
                    </ul>

                    {filters.map((section) => (
                      <Disclosure
                        as="div"
                        key={section.id}
                        className="border-b border-[#CECECC] py-6"
                      >
                        {({ open }) => (
                          <>
                            <h3 className="-my-3 flow-root">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-[14px]">
                                <span className="font-bold text-black text-[14px] sm:text-[18px] h-[27px]">
                                  {section.name}
                                </span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="3"
                                      stroke="currentColor"
                                      className="w-[18px] h-[14px] text-black"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m4.5 15.75 7.5-7.5 7.5 7.5"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="3"
                                      stroke="currentColor"
                                      className="w-[18px] h-[14px] text-black"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                                      />
                                    </svg>
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-[10px]">
                                {section.options.map((option, optionIdx) => (
                                  <div
                                    key={option.value}
                                    className="flex items-center justify-between"
                                  >
                                    <div className="h-[21px] flex items-center gap-[10px]">
                                      <input
                                        id={`filter-${section.id}-${option.value}`}
                                        name={`${section.id}[]`}
                                        defaultValue={option.value}
                                        type="checkbox"
                                        className="h-[17px] w-[17px] rounded-[2px] border-[#D9D9D9] text-black focus:ring-black hover:border-black checked:text-black peer relative shrink-0 appearance-none border after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-[url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==')] after:bg-[length:30px] after:bg-center after:bg-no-repeat after:content-[''] checked:bg-black focus:outline-none"
                                        onClick={() =>
                                          handleFilterSelection(
                                            section.id,
                                            option.value
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={`filter-${section.id}-${option.value}`}
                                        className="text-[14px] text-[#3A3933]"
                                      >
                                        {option.label}
                                      </label>
                                    </div>
                                    <div className="h-[21px] text-[#3A3933] text-[14px]">
                                      ({option.quantity})
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </form>
                  {/* main content */}
                  <div className="w-[280px] sm:w-[444px] xl:w-[820px] 2xl:w-[920px] sm:ms-[50px] 2xl:ms-[80px] flex flex-col items-center justify-center gap-[40px] xl:gap-[60px]">
                    {/* collection grid */}
                    {state?.length ? state?.map(ele =>
                      <div className="w-[280px] sm:w-[444px] xl:w-[820px] 2xl:w-[920px] border-t-[2px] border-[#CECECC] flex flex-col gap-[30px] sm:gap-[40px]">
                        <div className="w-full text-center text-black text-[18px] mt-[30px] sm:mt-[40px] font-bold">
                          {/* Collection */}
                          {ele?.header}
                        </div>
                        <div className="col-span-3 grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-x-[20px] sm:gap-x-[27px] xl:gap-x-[33.33px] 2xl:gap-x-[67.67px] gap-y-[40px]">
                          {ele?.data?.length ? ele?.data?.map((item, index) =>
                            <div
                              key={index}
                              className="cursor-pointer w-[130px] h-[251px] xl:w-[180px] xl:h-[329px] border border-[#CECECC] hover:border-[#6B6B66]"
                            >
                              <Link
                                // to={`/product-detail/${item?.href}`}
                                to={`/product-detail/${item?._id}?division=${division}`}
                                className="flex flex-col items-center justify-center gap-[15px] xl:gap-[20px]"
                              >
                                <img
                                  src={`${ApiService.getBaseImagePath()}products/${item?.name?.includes(":")
                                    ? item?.name?.split(":")[1].trim()
                                    : item?.name
                                    }.jpg`}
                                  alt="Image"
                                  className="w-[130px] h-[130px] xl:w-[180px] xl:h-[180px] object-cover"
                                />
                                <div className="w-[130px] h-[106px] xl:w-[180px] xl:h-[129px] flex flex-col items-center gap-[20px]">
                                  <div className="w-[120px] h-[56px] xl:w-[160px] xl:h-[68px] flex flex-col items-start justify-between ">
                                    <h4 className="text-[#9D9C99] text-[10px] xl:text-[12px] w-[120px] h-[15px] text-start overflow-hidden text-nowrap">
                                      {/* by {item?.designer[0]?.text} */}
                                    </h4>
                                    <h2 className="w-[120px] h-[36px] xl:w-[160px] xl:h-[42px] font-semibold text-[12px] xl:text-[14px] text-start text-wrap overflow-hidden">
                                      {/* {item?.detail} */}
                                    </h2>
                                  </div>
                                  <h4 className="text-[#6B6B66] text-[10px] xl:text-[12px] w-[120px] h-[15px] xl:w-[160px] xl:h-[21px] text-nowrap text-start">
                                    {/* {item?.date} */}
                                    {moment(new Date(item?.date)).format('MMM-YYYY').replaceAll("-", " ")}
                                  </h4>
                                </div>
                              </Link>
                            </div>
                          ) : ""}
                        </div>
                      </div>
                    ) : ""}
                    {/* Product grid */}
                    {/* <div className="w-[280px] sm:w-[444px] xl:w-[820px] 2xl:w-[920px] border-t-[2px] border-[#CECECC] flex flex-col gap-[30px] sm:gap-[40px]">
                      <div className="w-full text-center text-black text-[18px] mt-[30px] sm:mt-[40px] font-bold">
                        Product
                      </div>
                      <div className="col-span-3 grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-x-[20px] sm:gap-x-[27px] xl:gap-x-[33.33px] 2xl:gap-x-[67.67px] gap-y-[40px]">
                        {productItems?.map((item, index) => (
                          <div
                            key={index}
                            className="cursor-pointer w-[130px] h-[216px] xl:w-[180px] xl:h-[288px] border border-[#CECECC] hover:border-[#6B6B66]"
                          >
                            <Link
                              to={`/product-detail/${item?.href}`}
                              className="flex flex-col items-center justify-center gap-[15px] xl:gap-[20px]"
                            >
                              <img
                                src={item.name}
                                alt="Image"
                                className="w-[130px] h-[130px] xl:w-[180px] xl:h-[180px] object-cover"
                              />
                              <div className="w-[120px] h-[56px] xl:w-[160px] xl:h-[68px] flex flex-col items-center gap-[5px]">
                                <div className="text-[#9D9C99] text-[10px] xl:text-[12px] w-[120px] h-[15px] xl:w-[160px] xl:h-[18px] text-nowrap text-start flex items-center justify-between">
                                  <div className="w-[63px] h-[15px] xl:w-[77.5px] xl:h-[21px]">
                                    Design Name
                                  </div>
                                  <div className="w-[63px] h-[15px] xl:w-[77.5px] xl:h-[21px] text-end">
                                    Color
                                  </div>
                                </div>
                                <h4 className="text-black text-[12px] xl:text-[14px] w-[120px] h-[36px] xl:w-[160px] xl:h-[42px] text-start font-semibold">
                                  DP27023-44
                                </h4>
                              </div>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div> */}
                    {/* Pattern grid */}
                    {/* <div className="w-[280px] sm:w-[444px] xl:w-[820px] 2xl:w-[920px] border-t-[2px] border-[#CECECC] flex flex-col gap-[30px] sm:gap-[40px]">
                      <div className="w-full text-center text-black text-[18px] mt-[30px] sm:mt-[40px] font-bold">
                        Pattern
                      </div>
                      <div className="col-span-3 grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-x-[20px] sm:gap-x-[27px] xl:gap-x-[33.33px] 2xl:gap-x-[67.67px] gap-y-[40px]">
                        {productItems?.map((item, index) => (
                          <div
                            key={index}
                            className="cursor-pointer w-[130px] h-[258px] xl:w-[180px] xl:h-[323px] border border-[#CECECC] hover:border-[#6B6B66]"
                          >
                            <Link
                              to={`/product-detail/${item?.href}`}
                              className="flex flex-col items-center justify-center gap-[20px]"
                            >
                              <img
                                src={item.name}
                                alt="Image"
                                className="w-[130px] h-[130px] xl:w-[180px] xl:h-[180px] object-cover"
                              />
                              <div className="w-[130px] h-[108px] xl:w-[180px] xl:h-[123px] flex flex-col items-center gap-[20px]">
                                <div className="w-[110px] h-[38px] xl:w-[160px] xl:h-[47px] flex flex-col items-start justify-between">
                                  <h4 className="text-[#9D9C99] text-[10px] xl:text-[12px] w-[110px] h-[15px] xl:w-[160px] xl:h-[21px] text-start overflow-hidden text-nowrap">
                                    Collection Name
                                  </h4>
                                  <h2 className="w-[110px] h-[18px] xl:w-[160px] xl:h-[21px] font-semibold text-[12px] xl:text-[14px] text-start text-wrap overflow-hidden">
                                    DP27023-44
                                  </h2>
                                </div>
                                <h4 className="text-[#6B6B66] text-[10px] xl:text-[12px] w-[110px] h-[30px] xl:w-[160px] xl:h-[36px] text-start">
                                  Click here to view fabric requirement
                                </h4>
                              </div>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div> */}
                    {/* Resources grid */}
                    {/* <div className="w-[280px] sm:w-[444px] xl:w-[820px] 2xl:w-[920px] border-t-[2px] border-[#CECECC] flex flex-col gap-[30px] sm:gap-[40px]">
                      <div className="w-full text-center text-black text-[18px] mt-[30px] sm:mt-[40px] font-bold">
                        Resources
                      </div>
                      <div className="col-span-3 grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-x-[20px] sm:gap-x-[27px] xl:gap-x-[33.33px] 2xl:gap-x-[67.67px] gap-y-[40px]">
                        {productItems?.map((item, index) => (
                          <div
                            key={index}
                            className="cursor-pointer w-[130px] h-[261px] xl:w-[180px] xl:h-[326px] border border-[#CECECC] hover:border-[#6B6B66]"
                          >
                            <Link
                              to={`/product-detail/${item?.href}`}
                              className="flex flex-col items-center justify-center gap-[20px]"
                            >
                              <img
                                src={item.name}
                                alt="Image"
                                className="w-[130px] h-[130px] xl:w-[180px] xl:h-[180px] object-cover"
                              />
                              <div className="w-[130px] h-[111px] xl:w-[180px] xl:h-[126px] flex flex-col items-center gap-[20px]">
                                <div className="w-[110px] h-[56px] xl:w-[160px] xl:h-[68px] flex flex-col items-start justify-between">
                                  <h4 className="text-[#9D9C99] text-[10px] xl:text-[12px] w-[110px] h-[15px] xl:h-[21px] text-start overflow-hidden text-nowrap">
                                    Free Patterns
                                  </h4>
                                  <h2 className="w-[110px] h-[36px] xl:w-[160px] xl:h-[42px] font-semibold text-[12px] xl:text-[14px] text-start text-wrap overflow-hidden text-black">
                                    Patrick Lose Cake Block - FREE DO...
                                  </h2>
                                </div>
                                <h4 className="text-[#6B6B66] text-[10px] xl:text-[12px] w-[110px] h-[15px] xl:w-[160px] xl:h-[18px] text-nowrap text-start">
                                  Read More
                                </h4>
                              </div>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div> */}
                  </div>
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchDetail;
