import { Fragment, useContext, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import USflag from "../../assets/ProductFinder/USflag.svg";
import ProductImgsmall from "../../assets/ProductFinder/ProductImgsmall.svg";
import ProductImglarge from "../../assets/ProductFinder/ProductImglarge.svg";
import CNDflag from "../../assets/ProductFinder/CNDflag.svg";
import InStore from "../../assets/ProductFinder/InStore.svg";
import Online from "../../assets/ProductFinder/Online.svg";
import ProductFinderModalDetail from "./ProductFinderModalDetail";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UserContext } from "../../states/contexts/UserContext";
import { CustomerContext } from "../../states/contexts/CustomerContext";
import { BrandIconContext } from "../../states/contexts/BrandIconContext";
import ApiService from "../../helpers/ApiServices";
import TableHeaderImage from '../../components/elements/components/TableHeaderImage';
import { getModelRoute, showMessage } from "../../helpers/Utils";
import { useForm } from "react-hook-form";
import { FcCheckmark } from 'react-icons/fc';
import { AiOutlineGlobal } from 'react-icons/ai';


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Productfinder = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  const [collection, setCollection] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [selectedCustData, setSelectedCustData] = useState({});
  const [products, setProducts] = useState([]);
  const [largeImageProducts, setLargeImageProducts] = useState([]);
  const [smallImageProducts, setSmallImageProducts] = useState([]);
  const [qtys, setQtys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState([]);
  const [countries, setCountries] = useState([]);
  const [industryCodes, setIndustryCodes] = useState([]);
  const [regionCustomers, setRegionCustomers] = useState([]);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [stateName, setStateName] = useState(searchParams.get("state"));
  const [industryCode, setIndustryCode] = useState(searchParams.get("IC"));


  const { dispatch, user } = useContext(UserContext)
  const { customer } = useContext(CustomerContext)
  const { dispatch: iconDispatch, textColor, division, ...rest } = useContext(BrandIconContext)

  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
    defaultValues: {}
  });

  // Global variables //params.data.customer.custData.industryCodes?.filter(ele => ele?.text === "BRICK AND MORTAR")
  let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))
  let columns = [{
    headerName: 'CUSTOMER NAME (CITY)', field: 'customer.customer', width: 250, pinned: "left",
    cellRendererFramework: (params) => <div style={{ display: "flex", gap: "10px" }}>

      <span style={{ cursor: "pointer", fontWeight: 500 }}>{params.data.customer.customer}</span>
      {
        params.data.customer.custData.industryCodes?.filter(ele => ele?.text == "ONLINE SHOP")?.length ? <span><AiOutlineGlobal style={{ color: "blue" }} /></span> : null
      }
    </div>
  }]
  // let columns = [{ headerName: { name: 'CUSTOMER NAME (CITY)' }, field: 'customer.customer', width: 250, pinned: "left" }]


  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const handleExportAsCsv = (e) => {
    gridApi.exportDataAsCsv();
  }

  const setTestCustomer = (customers, array) => {
    if (customers?.length) {
      // array.unshift({ _id: "--SELECT A REGION--", name: "--SELECT A REGION--" })
      // array.unshift(customers[0]) // Add test account at the begening of array
      // array.unshift({ _id: "--TEST ACCOUNT--", name: "--TEST ACCOUNT--" })
    }
    return array;
  }

  const checkUSA = (ele) => {
    // console.log(ele);
    return ele.isUSA == 'true';
  }

  const checkCANADA = (ele) => {
    // console.log(ele);
    return ele.isCANADA == 'true';
  }

  const getRegion = async () => {
    let options = [];
    let regions = [];

    try {
      ApiService.setHeader()
      const response1 = await ApiService.get(`/shop/customList/title?protectModel=${getModelRoute(user).model}&title=${"Territory Name USA"}`)
      const response2 = await ApiService.get(`/shop/customList/title?protectModel=${getModelRoute(user).model}&title=${"Territory Name Canada"}`)
      // const response3 = await ApiService.get(`/shop/internationalExport`)

      Array.prototype.push.apply(regions, [{ _id: "-CANADA-", name: "-CANADA-" }])
      // Array.prototype.push.apply(regions, response3.data.documents.filter(checkCANADA))
      Array.prototype.push.apply(regions, response2?.data.document.options)

      Array.prototype.push.apply(regions, [{ _id: "-USA-", "name": "-USA-" }])
      // Array.prototype.push.apply(regions, response3.data.documents.filter(checkUSA))
      Array.prototype.push.apply(regions, response1?.data.document.options)

      // console.log("options", options)
      // console.log("user", user)

      // // Sales rep and sale amanager can see only their teritory.
      // if (user?.userTypes.includes("SALES_REP") || user?.userTypes.includes("SALES_MANAGER")) {

      //     Array.prototype.push.apply(options, [{ _id: "-CANADA-", name: "-CANADA-" }])
      //     user?.region.map(ele => {

      //         if (response2?.data.document.options.find(e => e.text == ele.text) != undefined)
      //             Array.prototype.push.apply(options, [response2?.data.document.options.find(e => e.text == ele.text)])
      //     })

      //     Array.prototype.push.apply(options, [{ _id: "-USA-", "name": "-USA-" }])
      //     user?.region.map(ele => {

      //         if (response1?.data.document.options.find(e => e.text == ele.text) != undefined)
      //             Array.prototype.push.apply(options, [response1?.data.document.options.find(e => e.text == ele.text)])
      //     })

      //     const array = setTestCustomer(customers, options)
      //     array.unshift({ _id: 'Select State / Prov', name: 'Select State / Prov' })
      //     setState(array)
      //     // setState([region])
      // } else {
      //     const array = setTestCustomer(customers, regions)
      //     array.unshift({ _id: 'Select State / Prov', name: 'Select State / Prov' })
      //     setState(array)
      // }

      const array = setTestCustomer(customers, regions)
      array.unshift({ _id: 'Select State / Prov', name: 'Select State / Prov' })
      setState(array)
    } catch (error) {
      console.log(error)
      showMessage(error.response?.data?.message, "error")
    }
  }

  const getCollection = async () => {
    // setIsLoading(true)
    // console.log(id);
    ApiService.setHeader()
    await ApiService.get(`shop/collection/${id}?protectModel=${getModelRoute(user).model}`).then(async response => {
      console.log(response.data);
      if (response?.data.isSuccess) {
        setCollection(response?.data.document)
        // getAllProductsByCollection(response?.data.document?.name)
        getAllCustomerInSelectedRegion(response?.data.document?.name)
        // setIsLoading(false)
      }
    }).catch(error => {
      console.log(error)
      // console.log("Else Catch")
      // showMessage(error.response.data.message, "error")
    })
  }

  const getAllCustomerInSelectedRegion = async (collectionName) => {
    // setIsLoading(true)
    // let array = []
    // console.log("iC: ", industryCode);

    ApiService.setHeader()
    // ApiService.get(`shop/user/buyProducts?region=${stateName}&name=${collectionName}&industryCode=${industryCode}`).then(response => {
    ApiService.get(`shop/customer/buyProducts?protectModel=${getModelRoute(user).model}&region=${stateName}&name=${collectionName}&industryCode=${industryCode}`).then(response => {
      console.log(response.data)
      // Remove logged in store from the list
      const custs = response.data.documents.filter(ele => ele.customer?.custData?.name !== customer?.name)
      console.log(custs);

      if (response?.data.isSuccess) {
        // setRegionCustomers(response?.data.document)
        setProducts(response.data.products.map(ele => {
          if (ele?.name?.includes(':') || ele?.name?.includes('PTN')) {
            // if (ele?.name?.includes(':')) {
            return ele
          }
        }))
        setRegionCustomers(custs)
        setValue('region', stateName)
        setValue('industryCode', industryCode)
        // setIsLoading(false)
      }
    }).catch(error => {
      console.log(error)
      // console.log("Else Catch")
      showMessage(error.response?.data?.message, "error")
    })
  }

  const getIndustryCodes = (data) => {
    ApiService.setHeader()
    ApiService.get(`shop/customList/title?protectModel=${getModelRoute(user).model}&title=NCS Industry Codes`, data).then(response => {
      // console.log(response.data);
      if (response?.data.isSuccess) {
        setIndustryCodes(response?.data.document.options)
      }
    }).catch(error => {
      console.log(error)
      // console.log("Else Catch")
      // showMessage(error.response.data.message, "error")
    })
  }

  const getCountryList = (data) => {
    // ApiService.setHeader()
    // ApiService.get(`shop/customList/title?model=${getModelRoute(user).model}&title=NCS COUNTRY LIST`, data).then(response => {
    //     // console.log(response.data);
    //     if (response?.data.isSuccess) {
    //         countryList.push(...response?.data.document.options)
    //     }
    // }).catch(error => {
    //     console.log(error.response.data)
    //     // console.log("Else Catch")
    //     // showMessage(error.response.data.message, "error")
    // })

    ApiService.setHeader()
    ApiService.get(`shop/product/getCountries`).then(response => {
      // console.log(response.data.documents);
      // countryList.push(...response.data.documents)
      setCountries(response.data.documents)
      // countryList.shift({ text: "Select country", value: 1 })
      setValue('region', stateName)
      setIsLoading(false)
    }).catch(error => {
      console.log(error.response.data)
      // console.log("Else Catch")
      showMessage(error.response.data.message, "error")
    })
  }

  // const openModal = (params) => {
  //     // console.log(params);
  //     if (params.column.colId === "customer.customer") {
  //         setSelectedCustData(params.data.customer)
  //         setModalShow(true)
  //     }
  // }

  const goToPrintPage = () => {
    navigate(`/print/${id}?state=${stateName}&IC=${industryCode}`)
  }

  // Format the table header
  for (const product of products) {
    // console.log(product.uploadSmallPoster);
    let obj = {}

    // obj.headerName = product?.name
    obj.headerComponent = TableHeaderImage
    obj.headerComponentParams = {
      name: product?.name,
      // image: product?.uploadSmallPoster?.url
      image: `${ApiService.getBaseImagePath()}products/${product?.name?.includes(":") ? product?.name?.split(":")[1].trim() : product?.name}.jpg`
    }
    obj.field = product?.name
    obj.width = '200'
    obj.editable = false
    obj.cellRendererFramework = (params) => params.data[product?.name] == true ? <FcCheckmark className='' style={{ width: "50px", height: "30px", textAlign: "center" }} /> : false

    columns.push(obj)
  }

  // console.log(countries);



  useEffect(() => {
    setIsLoading(true)

    getRegion()
    getIndustryCodes()
    getCollection()
    getCountryList()
  }, [id, stateName, industryCode]);


  // modalBox
  const [openModal, setOpenModal] = useState(null);
  const [rowData, setRowData] = useState([
    { make: "Tesla", model: "Model Y", price: 64950, electric: true },
    { make: "Ford", model: "F-Series", price: 33850, electric: false },
    { make: "Toyota", model: "Corolla", price: 29600, electric: false },
    { make: "Toyota", model: "Corolla", price: 29600, electric: false },
    { make: "Toyota", model: "Corolla", price: 29600, electric: false },
    { make: "Toyota", model: "Corolla", price: 29600, electric: false },
  ]);

  const [colDefs, setColDefs] = useState([
    { field: "make" },
    { field: "model" },
    { field: "price" },
    { field: "electric" },
  ]);

  return (
    <div
      className="mb-20 max-w-[1320px] min-w-[320px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col lg:flex-col lg:mx-auto items-center justify-center"
      style={{ fontFamily: "Open Sans" }}
    >
      {/*row 1- product finder heading */}
      <div className=" w-[280px] h-[76px] sm:w-[664px] sm:h-[44px] xl:w-[1160px] xl:h-[44px] 2xl:w-[1320px] flex items-center justify-center mt-20 lg:mt-[100px]">
        <div>
          <p className="text-[28px] lg:text-[32px] font-bold mx-auto text-center">
            Product Finder: Online Retailers
          </p>
        </div>
      </div>
      {/*row 2- product finder dropdown and print download  */}
      <div className="w-[280px] h-[405px] sm:w-[664px] sm:h-[244px] xl:w-[1160px] xl:h-[143px] 2xl:w-[1320px] flex flex-col items-center justify-center mt-[100px] sm:mt-[80px] lg:mt-[100px] gap-10">
        {/* row-2 row2.1 */}
        <div className="w-[280px] h-[21px] sm:w-[664px] sm:h-[21px] xl:w-[1160px] xl:h-[21px] 2xl:w-[1320px] flex items-center justify-center xl:justify-start gap-1 text-center">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-3 h-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
              />
            </svg>
          </div>
          <span className="text-sm text-nowrap text-center" style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/product-detail/${collection?._id}?division=NORTHCOTT`)
            }}
          >
            Back to {collection?.name}
          </span>
        </div>
        {/* row 2 row2.2 */}
        <div className="w-[289.4px] h-[344px] sm:w-[664px] sm:h-[183px] xl:w-[1160px] xl:h-[82px] 2xl:w-[1320px] flex flex-col xl:flex-row items-center justify-center xl:justify-start gap-10">
          {/* row 2 row2.2 col 1 */}
          <div className="w-[289.4px] h-[100px] sm:w-[664px] sm:h-[61px] xl:w-[743px] xl:h-[82px] 2xl:w-[903px] flex flex-col items-center justify-center gap-1">
            <p className="text-sm sm:text-base text-gray-900 font-bold w-[289.4px] h-[48px] sm:w-[664px] sm:h-[27px] xl:w-[743px] xl:h-[27px] 2xl:w-[903px] flex px-5 sm:px-0 text-center mx-auto">
              The following stores have purchased these products:
            </p>
            <p className="text-sm sm:text-[16px] text-gray-500 w-[289.4px] h-[42px] sm:w-[664px] sm:h-[27px] xl:w-[743px] xl:h-[27px] 2xl:w-[903px] flex text-center">
              Contact retailers directly for inventory information
            </p>
          </div>
          {/* row 2 row2.2 col 2 buttons and dropdown */}
          <div className="w-[204px] h-[204px] sm:w-[377px] sm:h-[82px] xl:w-[377px] xl:h-[82px] flex flex-col sm:flex-row items-center justify-center gap-10 sm:gap-5">
            <div className="w-[204px] h-[82px] flex flex-col gap-[15px]">
              <p className="w-[134px] h-[27px] text-sm font-bold">
                Product Finder
              </p>
              {/* dropdown menu */}
              <div className="w-[204px] h-[40px]">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="w-[204px] h-[40px] inline-flex justify-center gap-x-20 lg:gap-12 rounded bg-white py-2 text-sm text-gray-500 shadow-sm border">
                      Find Retailers{" "}
                      <ChevronDownIcon
                        className="-mr-1 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {
                          industryCodes?.length && industryCodes.map(industryCode => {
                            // console.log(industryCode);
                            // return <option key={industryCode?.value} value={industryCode?.text}>{industryCode?.text}</option>
                            return <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                  onClick={e => {
                                    // console.log(e.target.value);
                                    setIndustryCode(e.target.innerText)
                                    navigate(`/product-finder/${id}?state=${stateName}&IC=${e.target.innerText}`)
                                  }}
                                >
                                  {industryCode?.text}
                                </a>
                              )}
                            </Menu.Item>
                          })
                        }
                        {/* <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Account settings
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Support
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              License
                            </a>
                          )}
                        </Menu.Item>
                        <form method="POST" action="#">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="submit"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block w-full px-4 py-2 text-left text-sm"
                                )}
                              >
                                Sign out
                              </button>
                            )}
                          </Menu.Item>
                        </form> */}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <div className="w-[153px] h-[82px] flex flex-col gap-[15px]">
              <p className="w-[153px] h-[27px] font-bold text-sm text-center">
                Print / Download
              </p>

              <div className="w-[153px] h-[40px] font-bold flex justify-center lg:justify-start">
                <button
                  type="button"
                  className=" bg-[#E2A856] text-white hover:text-white border border-[#E2A856] hover:border-none hover:bg-black font-semibold rounded px-2 py-1 text-center w-[153px] h-[40px] text-base"
                >
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* row 3 */}
      <div className="mt-[100px] w-[280px] h-[143px] sm:w-[664px] sm:h-[82px] xl:w-[1160px] xl:h-[82px] 2xl:w-[1320px] flex flex-col sm:flex-row items-center justify-center xl:justify-between gap-10">
        {/* row 3 col 1 dropdown */}
        <div className="w-[204px] h-[27px] flex flex-col sm:flex-row items-center justify-center gap-10 sm:gap-5">
          <div className="w-[204px] h-[82px] flex flex-col gap-[15px]">
            <p className="w-[204px] h-[27px] text-sm font-bold text-center sm:text-start">
              Select State/Province
            </p>
            {/* dropdown menu */}
            <div className="w-[204px] h-[40px]">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="w-[204px] h-[40px] inline-flex justify-center gap-x-10 lg:gap-12 rounded bg-white py-2 text-sm text-gray-500 shadow-sm border">
                    State/Province
                    <ChevronDownIcon
                      className="-mr-1 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1" style={{ height: "300px", overflowY: "auto" }}>
                      {
                        state?.length && state.map(ele => {
                          // console.log(ele);
                          // return <option key={ele?._id} value={ele?.name}>{ele?.name}</option>
                          return <Menu.Item
                          >
                            {({ active }) => {
                              // console.log(active)
                              return (
                                <a
                                  href="#"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                  onClick={e => {
                                    console.log(e.target.innerText);
                                    setStateName(e.target.innerText)
                                    setSearchParams(e.target.innerText)
                                    navigate(`/product-finder/${id}?state=${e.target.innerText}&IC=${industryCode}`)
                                  }}
                                >
                                  {ele?.name}
                                </a>
                              )
                            }}
                          </Menu.Item>
                        })
                      }

                      {/* <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            Account settings
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            Support
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            License
                          </a>
                        )}
                      </Menu.Item>
                      <form method="POST" action="#">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="submit"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block w-full px-4 py-2 text-left text-sm"
                              )}
                            >
                              Sign out
                            </button>
                          )}
                        </Menu.Item>
                      </form> */}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        {/* row 3 col 2 dropdown */}
        <div className="w-[280px] h-[21px] sm:w-[222px] flex flex-col sm:flex-row gap-10 sm:gap-5 sm:mt-10">
          <div className="w-[280px] h-[21px] sm:w-[222px] flex gap-[15px] justify-between">
            <p className="w-[204px] h-[21px] sm:w-[222px] text-sm font-bold mt-0.5">
              Sort:
            </p>
            {/* dropdown menu */}
            <div className="w-[170px] h-[21px]">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="w-[170px] h-[21px] inline-flex justify-center gap-x-24 lg:gap-20 rounded bg-white text-sm text-gray-500">
                    Default
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            Account settings
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            Support
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            License
                          </a>
                        )}
                      </Menu.Item>
                      <form method="POST" action="#">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="submit"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block w-full px-4 py-2 text-left text-sm"
                              )}
                            >
                              Sign out
                            </button>
                          )}
                        </Menu.Item>
                      </form>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      {/* row 4 table */}
      <div className="mt-10 w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px] border">
        <div className="relative overflow-auto">
          {(regionCustomers && columns) ?
            <div className={`ag-theme-alpine scroll scroll4 p-0 mt-3 ${division === "NORTHCOTT" ? 'ag-theme-alpine-n scroll scroll4' : division === "BANYAN BATIKS" ? "ag-theme-alpine-b scroll scroll4" : "ag-theme-alpine-p scroll scroll4"}`} style={{ width: "100%" }}>
              <AgGridReact
                onGridReady={onGridReady}
                rowData={regionCustomers}
                columnDefs={columns}
                defaultColDef={{
                  editable: true,
                  sortable: true,
                  // flex: 1,
                  minWidth: 100,
                  filter: true,
                  resizable: true,
                  suppressNavigable: true,
                  // cellStyle: () => ({
                  //     // display: "flex",
                  //     // alignItems: "center",
                  //     // justifyContent: "center"
                  //     textAlign: 'center'
                  // }),

                }}
                headerHeight={80}
                animateRows={true}
                domLayout='autoHeight'
                rowHeight={40}
                pagination={true}
                paginationPageSize={regionCustomers?.length}
                onCellClicked={openModal}
                // overlayNoRowsTemplate="No Purchase Order found. Let's create one!"
                overlayNoRowsTemplate='<span style="color: rgb(128, 128, 128); font-size: 2rem; font-weight: 100;">No Records Found!</span>'
              />
            </div> : null
          }
        </div>
      </div>
    </div>
  );
};

export default Productfinder;
