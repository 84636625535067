import React, { useContext, useEffect, useState } from "react";
import ProductBigCardModal from "./ProductBigCardModal";
import ApiService from "../../../helpers/ApiServices";
import { CustomerContext } from "../../../states/contexts/CustomerContext";
import { BrandIconContext } from "../../../states/contexts/BrandIconContext";
import { showMessage } from "../../../helpers/Utils";
import DefaultImg from '../../../image/default_product_img.jpg'
import { UserContext } from "../../../states/contexts/UserContext";


const ProductBigCard = ({ login, index, item, data, multiBigCard, product, uniteType, setQuantity, customers, wishLists, navigate, moveToWishList, carts, addSelectedProductsToCart, getPriceByPricingMatrix, findProductMultiplierAndFormatArray, cadMultiplier, addToCart, setProductImage, setModalShow, country, collection }) => {
  const [multiBig, setmultiBig] = useState(false);
  const [isSoldOutCAD, setIsSoldOutCAD] = useState(false);
  const [isSoldOutUSA, setIsSoldOutUSA] = useState(false);

  const { dispatch: customerDispatch, customer } = useContext(CustomerContext)
  const { user } = useContext(UserContext)
  const { dispatch: iconDispatch, ...rest } = useContext(BrandIconContext)

  useEffect(() => {
    if (multiBigCard) {
      setmultiBig(true);
    } else {
      setmultiBig(false);
    }

    if (customer && country === "CANADA") {
      if (!product?.isReorderable && product?.isSoldOutCanada) {
        setIsSoldOutCAD(true)
      }
    } else if (!product?.isReorderable && product?.isSoldOutUsa) {
      setIsSoldOutUSA(true)
    }

    return () => {

    }
  }, [multiBigCard]);

  // modalBox
  const [openModal, setOpenModal] = useState(null);

  const [showDetailPopUp, setshowDetailPopUp] = useState(
    Array({ data }).fill(false)
  );

  const handleMouseEnter = (index) => {
    const updatedHoverState = [...showDetailPopUp];
    updatedHoverState[index] = true;
    setshowDetailPopUp(updatedHoverState);
  };

  const handleMouseLeave = (index) => {
    const updatedHoverState = [...showDetailPopUp];
    updatedHoverState[index] = false;
    setshowDetailPopUp(updatedHoverState);
  };


  return (
    <>
      {/* {multiBig ? ( */}
      {!multiBig ? (
        // For Big Card of Multipanel only
        <div
          key={index}
          className={`${(user || customer) ? "h-[558.51px]" : "h-[513.51px]"
            } w-[250px] border-[1px] border-[#CECECC] hover:border-[#6B6B66]`}
        >
          <div>
            <ProductBigCardModal
              login={login}
              showModal={openModal === index}
              cancelOption={() => setOpenModal(null)}
              index={index + 1}
              item={item}
              product={product}
              uniteType={uniteType}
            />
          </div>

          <div
            className={`w-[250px] ${(user || customer) ? "h-[558.51px]" : "h-[513.51px]"
              } flex flex-col gap-[20px] border`}
          >
            <div
              className="relative w-[250px] h-[400.51px] flex items-center justify-center border-b-[1px] border-[#CECECC]"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              <img
                // src={item.name}
                src={`${ApiService.getBaseImagePath()}products/${product?.name?.includes(":") ? product?.name?.split(":")[1].trim() : product?.name}.jpg`}
                alt="Product"
                className="w-[230px] h-[380.51px] object-cover"
              />
              {showDetailPopUp[index] && (
                <div
                  className="absolute w-[250px] h-[60px] text-white text-[12px] font-bold bg-black/85 flex items-center justify-center bottom-0 cursor-pointer"
                  onClick={() => {
                    setOpenModal(index);
                  }}
                >
                  <span className="text-white">View Details</span>
                </div>
              )}
            </div>
            <div
              className={`w-[250px] ${login ? "h-[138px]" : "h-[88px]"
                } flex items-start justify-center`}
            >
              <div
                className={`flex flex-col ${login ? "h-[118px]" : "h-[68px]"
                  } w-[230px] gap-[5px]`}
              >
                <div className="w-[230px] h-[21px] flex justify-between">
                  <p className="w-[112.5px] h-[21px] overflow-hidden text-nowrap text-[#9D9C99] text-[12px]">
                    {/* {product?.fabrication} */}
                    {product?.displayName}
                  </p>
                  <p className="w-[112.5px] h-[21px] text-[12px] overflow-hidden text-end text-nowrap text-[#9D9C99]">
                    {product?.colorDescription}
                  </p>
                </div>
                <p className="text-black text-[14px] font-semibold w-[230px] h-[42px]">
                  {product?.name?.includes(":") ? product?.name?.split(":")[1].trim() : product?.name}
                </p>

                {login && (
                  <div className="w-[230px] h-[45px]">
                    <div className="flex items-end justify-between">
                      {/* price */}
                      <div className="w-[71px] h-[27px] flex items-center justify-between flex-row">
                        <p className="text-[18px] font-semibold w-[47px] h-[27px] flex items-center justify-center">
                          {/* ${product?.price} */}
                          ${product?.priceFromPM ? product?.priceFromPM : product?.price}
                        </p>
                        <span className="w-[19px] h-[18px] text-[10px] sm:text-[12px] text-[#9D9C99] font-normal">
                          / {uniteType}{" "}
                        </span>
                      </div>

                      {/* add and qty button */}
                      {
                        ((country === "CANADA" && isSoldOutCAD) || (country === "UNITED STATES" && isSoldOutUSA))
                          ?
                          (<div className="w-[60px] h-[45px] text-[#ED393E] font-bold text-[14px] flex items-end justify-between">Sold Out</div>)
                          :
                          (<div className="w-[60px] h-[45px] flex flex-col justify-between">
                            {/* quantity button */}
                            <form className="w-[60px] h-[20px]">
                              <select className='' size='sm'
                                id={`product_${product?._id}`}
                              >
                                {
                                  product.multipliers?.length && product.multipliers?.map(ele => {

                                    return <option key={ele} value={ele}>{ele}</option>
                                  })
                                }

                              </select>
                            </form>
                            {/* add button */}
                            <div className="w-[60px] h-[20px]">
                              <button
                                type="button"
                                className="w-[60px] h-[20px] text-[8px] font-bold rounded-sm text-center text-white bg-black hover:bg-[#6B6B66] flex items-center justify-center"
                                onClick={() => {
                                  if (document.getElementById(`product_${product?._id}`).value !== "Select qty...") {

                                    let price = product?.price
                                    let productQuantity = product?.noPromos?.includes(collection?.name?.toUpperCase()) ? 0.00 : document.getElementById(`product_${product?._id}`).value

                                    addToCart({ productId: product?._id, userId: customers[customers?.length - 1]?._id, price: product?.priceFromPM ? product.priceFromPM : product?.price, multipliers: product.multipliers, productQuantity: productQuantity, subTotal: (parseInt(productQuantity) * parseFloat(product?.priceFromPM ? product.priceFromPM : product?.price).toFixed(2)).toFixed(2), image: product?.uploadSmallPoster?.url ? product?.uploadSmallPoster?.url : DefaultImg })
                                  } else {
                                    showMessage("Please select a quantity!", "info")
                                  }
                                }}
                              >
                                Add
                              </button>
                            </div>
                          </div>)}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        // For Big Card of BIgAndNormalPanel only
        <div
          key={index}
          className={`${(user || customer) ? "h-[408px]" : "h-[358px]"
            } w-[250px] border-[1px] border-[#CECECC] hover:border-[#6B6B66]`}
        >
          <div>
            <ProductBigCardModal
              showModal={openModal === index}
              cancelOption={() => setOpenModal(null)}
              index={index + 1}
              item={item}
              product={product}
              uniteType={uniteType}
            />
          </div>

          <div
            className={`w-[250px] ${(user || customer) ? "h-[408px]" : "h-[358px]"
              } flex flex-col gap-[20px] border`}
          >
            <div
              className="w-[250px] h-[250px] flex items-center justify-center border-b-[1px] border-[#CECECC] relative"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              <img
                src={`${ApiService.getBaseImagePath()}products/${product?.name?.includes(":") ? product?.name?.split(":")[1].trim() : product?.name}.jpg`}
                alt="Product"
                className="h-[230px] w-[230px] object-cover"

              />
              {showDetailPopUp[index] && (
                <div
                  className="absolute w-[250px] h-[60px] text-white text-[12px] font-bold bg-black/85 flex items-center justify-center bottom-0 cursor-pointer"
                  onClick={() => {
                    setOpenModal(index);
                  }}
                >
                  <span className="text-white">View Details</span>
                </div>
              )}
            </div>
            <div
              className={`w-[250px] ${login ? "h-[138px]" : "h-[88px]"
                } flex items-start justify-center`}
            >
              <div
                className={`flex flex-col ${login ? "h-[118px]" : "h-[68px]"
                  } w-[230px] gap-[5px]`}
              >
                <div className="w-[230px] h-[21px] flex justify-between">
                  <p className="w-[112.5px] h-[21px] overflow-hidden text-nowrap text-[#9D9C99] text-[12px]">
                    {product?.fabrication}
                  </p>
                  <p className="w-[112.5px] h-[21px] text-[12px] overflow-hidden text-end text-nowrap text-[#9D9C99]">
                    {product?.colorDescription}
                  </p>
                </div>
                <p className="text-black text-[14px] font-semibold w-[230px] h-[42px]">
                  {product?.name?.includes(":") ? product?.name?.split(":")[1].trim() : product?.name}
                </p>

                {(user || customer) && (
                  <div className="w-[230px] h-[45px]">
                    <div className="flex items-end justify-between">
                      <div className="w-[71px] h-[27px] flex items-center justify-between flex-row">
                        <p className="text-[18px] font-semibold w-[47px] h-[27px] flex items-center justify-center">
                          ${product?.priceFromPM ? product?.priceFromPM : product?.price}
                        </p>
                        <span className="w-[19px] h-[18px] text-[10px] sm:text-[12px] text-[#9D9C99] font-normal">
                          / {product?.primaryUnitsType == "Each" ? "e" : uniteType}{" "}
                        </span>
                      </div>
                      {
                        ((country === "CANADA" && isSoldOutCAD) || (country === "UNITED STATES" && isSoldOutUSA))
                          ?
                          (<div className="w-[60px] h-[45px] text-[#ED393E] font-bold text-[14px] flex items-end justify-between">Sold Out</div>)
                          :
                          <div className="w-[60px] h-[45px] flex flex-col justify-between">
                            {/* quantity button */}

                            <form className="w-[60px] h-[20px]">

                              <select className=" [h-11px]"
                                id={`product_${product?._id}`}
                              >
                                {
                                  product.multipliers?.length && product.multipliers?.map(ele => {

                                    return <option key={ele} value={ele}>{ele}</option>
                                  })
                                }

                              </select>
                            </form>

                            {/* add button */}
                            <div className="w-[60px] h-[20px]">
                              <button
                                type="button"
                                className="w-[60px] h-[20px] text-[8px] font-bold rounded-sm text-center text-white bg-black hover:bg-[#6B6B66] flex items-center justify-center"
                                onClick={() => {
                                  if (document.getElementById(`product_${product?._id}`).value !== "Select qty...") {

                                    let price = product?.price
                                    let productQuantity = product?.noPromos?.includes(collection?.name?.toUpperCase()) ? 0.00 : document.getElementById(`product_${product?._id}`).value

                                    addToCart({ productId: product?._id, userId: customers[customers?.length - 1]?._id, price: product?.priceFromPM ? product.priceFromPM : product?.price, multipliers: product.multipliers, productQuantity: productQuantity, subTotal: (parseInt(productQuantity) * parseFloat(product?.priceFromPM ? product.priceFromPM : product?.price).toFixed(2)).toFixed(2), image: product?.uploadSmallPoster?.url ? product?.uploadSmallPoster?.url : DefaultImg })
                                  } else {
                                    showMessage("Please select a quantity!", "info")
                                  }
                                }}
                              >
                                Add
                              </button>
                            </div>
                          </div>}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductBigCard;