
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../states/contexts/UserContext";
import { CustomerContext } from "../../states/contexts/CustomerContext";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getCountry, getModelRoute, showMessage } from "../../helpers/Utils";
import ApiService from "../../helpers/ApiServices";
import Breadcrum from "./Breadcrum";
import Pagination from "./Pagination";
import Filteration from "./Filteration";
import CollectionGrid from "./CollectionGrid";
import { Fragment } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
} from "@heroicons/react/20/solid";


const ProductCollection = () => {

  const sortOptions = [
    { no: 1, key: "date", order: "-1", current: false, label: "Newest First" },
    { no: 2, key: "date", order: "1", current: false, label: "Oldest First" },
    { no: 3, key: "name", order: "1", current: false, label: "Alphabetical (A-Z)" },
    { no: 4, key: "name", order: "-1", current: false, label: "Alphabetical (Z-A)" },
  ];

  const [collections, setCollections] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isCollectionLoading, setIsCollectionLoading] = useState(false);
  const [category, setCategory] = useState(null);
  const [page, setPage] = useState(1);
  const [sortKey, setSortKey] = useState("name");
  const [sortValue, setSortValue] = useState(1);
  const [selectedSort, setSelectedSort] = useState("Default");
  const [FilterDataFromFiltration, setFilterDataFromFiltration] = useState([]);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const { dispatch, user } = useContext(UserContext)
  const { dispatch: customerDispatch, customer } = useContext(CustomerContext)

  const [searchParams, setSearchParams] = useSearchParams()

  let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"

  let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))

  const { id } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  let type = search.split("=")[1]


  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handlePage = (page) => {
    setPage(page);
  };

  const handleSort = (e, option) => {
    setSortKey(option?.key)
    setSortValue(option?.order)
    setSelectedSort(option.label)
  };

  const collectionsAfterFilters = (updatedFilters) => {
    setFilterDataFromFiltration(updatedFilters.length > 0 ? updatedFilters : []);
  }


  const getcategory = () => {
    let isLoggedInUser
    if (customers != null || customer) {
      isLoggedInUser = true
    } else {
      isLoggedInUser = false
    }

    ApiService.setHeader()
    ApiService.get(`shop/category/${id}?protectModel=${getModelRoute(user).model}&type=${division}&isLoggedInUser=${isLoggedInUser}&country=${getCountry()}`).then(response => {
      if (response?.data.isSuccess) {
        setIsLoading(false)
        setCategory(response?.data.document)
      }
    }).catch(error => {
      setIsLoading(false)
      showMessage(error.response.data.message, "error")
    })
  }


  useEffect(() => {
    getcategory()
    setPage(page)
  }, [division, page, sortKey, sortValue, id]);


  return (
    <>
      <div className="flex items-center justify-center">
        <div
          style={{ fontFamily: "Open Sans" }}
          className="w-full flex flex-col items-center justify-center gap-[60px] sm:gap-[80px] xl:gap-[100px]"

        >
          {/* top banner  */}
          {category?.imageName ? <div className=" bg-white w-full h-[235px] sm:h-[257px] xl:h-[296px] flex items-center justify-center">
            <img
              src={`${ApiService.getBaseImagePath()}categorybanners/${category?.imageName}`}
              alt="Image"
              className="object-cover w-full h-[235px] sm:h-[257px] xl:h-[296px]"
            />
          </div>
            :
            <div className="-mt-[50px]"></div>
          }

          {/* heading and breadcrums */}
          <div className="w-[320px] h-[114px] sm:w-[744px] sm:h-[82px] xl:w-[1280px] 2xl:w-[1320px] flex items-center justify-center">
            <div className="flex flex-col items-center sm:items-start justify-center gap-[20px] mx-[20px] sm:mx-[40px] xl:mx-[60px] 2xl:mx-0">
              {/* Heading  */}
              <div className="w-[280px] h-[76px] sm:w-[664px] sm:h-[44px] xl:w-[1160px] 2xl:w-[1320px]">
                <p className="text-[28px] sm:text-[32px] font-bold wrap text-start">
                  {isLoading ? "loading ..." : category?.name}
                </p>
              </div>

              {/* Breadcrums */}
              <div className="w-full h-[18px]">
                <Breadcrum categoryName={category?.name}></Breadcrum>
              </div>
            </div>
          </div>

          {/*Main body */}
          <div className="w-[320px] sm:w-[744px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col items-center justify-center mb-[60px] sm:mb-[100px] gap-[60px]">


            {/* collection category */}
            {isCollectionLoading ? "Loading..." :
              <div>
                {/* Mobile filter dialog */}
                <Transition.Root show={mobileFiltersOpen} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-40 lg:hidden"
                    onClose={setMobileFiltersOpen}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="transition-opacity ease-linear duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-opacity ease-linear duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                      <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                      >
                        <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                          {/* mobile filter top header */}
                          <div className="flex items-center justify-between px-4">
                            <h2 className="text-2xl font-bold text-gray-900">
                              Filters
                            </h2>
                            <button
                              type="button"
                              className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                              onClick={() => setMobileFiltersOpen(false)}
                            >
                              <span className="sr-only">Close menu</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </Dialog>
                </Transition.Root>

                {/* desktop top section */}
                <main className="mx-auto w-[280px] sm:w-[715px] xl:w-[1160px] 2xl:w-[1320px] sm:mx-[40px] xl:mx-0">
                  <div className="grid grid-cols-1 gap-x-9 sm:grid-cols-4 sm:h-[61px]">
                    <div className="hidden sm:block col-span-1 text-[22px] font-bold">
                      Filters
                    </div>
                    <div className="col-span-3">
                      <div className="grid grid-cols-2 justify-between">
                        <h1 className=" text-black col-span-1 text-sm">
                          {collections?.total} Collections
                        </h1>

                        <div className="col-span-1">
                          <div className="flex justify-end">
                            <button
                              type="button"
                              className=" text-gray-700 hover:text-gray-900 md:hidden"
                              onClick={() => setMobileFiltersOpen(true)}
                            >
                              <span className="sr-only text-sm">Filters</span>
                              <p className="text-black underline text-sm">
                                See Filters
                              </p>
                            </button>
                          </div>

                          <div className="col-span-1 sm:mt-0 ">
                            <Menu
                              as="div"
                              className="relative grid grid-cols-2 mt-5 sm:mt-0"
                            >
                              <div className="flex justify-start sm:justify-end text-sm">
                                <p>Sort: </p>
                              </div>

                              <div className="w-[220px] flex justify-end">
                                <Menu.Button className="group inline-flex text-sm font-medium text-gray-700 hover:text-gray-900">
                                  <p className="text-sm w-[180px] flex items-center justify-start">
                                    {selectedSort}
                                  </p>

                                  <ChevronDownIcon
                                    className="h-5 flex-shrink-0 text-black group-hover:text-gray-900 flex justify-end"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute right-[28px] z-10 mt-6 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    {sortOptions.map((option, index) => {
                                      return (
                                        <Menu.Item key={index}>
                                          {({ active }) => (
                                            <div
                                              onClick={(e) => {
                                                handleSort(e, option);
                                              }}
                                              className={classNames(
                                                option.current
                                                  ? "font-medium text-gray-900 text-sm"
                                                  : "text-gray-500",
                                                active ? "bg-gray-100" : "",
                                                "block px-4 py-2 text-sm cursor-pointer"
                                              )}
                                            >
                                              {option?.label}
                                            </div>
                                          )}
                                        </Menu.Item>
                                      )
                                    })}
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <section aria-labelledby="products-heading" className="pt-[20px] sm:pt-0 w-[280px] sm:w-[664px] xl:w-[1160px] 2xl:w-[1320px]"
                  >
                    <h2 id="products-heading" className="sr-only text-[14px]">
                      Products
                    </h2>
                    <div className="pt-[40px] sm:pt-0 grid grid-cols-1 gap-x-10 gap-y-10 sm:grid-cols-4">
                      {/* filtration */}
                      <Filteration collectionsAfterFilters={collectionsAfterFilters} />

                      {/* Product grid */}
                      <CollectionGrid collections={collections} category={category} FilterDataFromFiltration={FilterDataFromFiltration} page={page} sortKey={sortKey} sortValue={sortValue} setCollections={setCollections} />
                    </div>
                  </section>
                </main>
              </div>
            }

            {/* pagination */}
            <div className="w-full grid grid-cols-1 md:grid-cols-4 items-center justify-center">
              <div className="col-span-1"></div>
              <div className="col-span-3 grid grid-cols-subgrid items-center justify-center">
                <div className="col-start-1 sm:col-start-2">
                  <Pagination
                    page={page}
                    setPage={setPage}
                    handlePage={handlePage}
                    totalItems={collections?.total}
                  ></Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default ProductCollection;

