import NorthcottLogoDark from "../../../assets/FooterLogo/NorthcottLogoDark.svg";
import PatrickLoseLogo from "../../../assets/FooterLogo/PatrickLoseLogo.svg";
import BanyanLogo from "../../../assets/FooterLogo/BanyanLogo.svg";
import { Link, useSearchParams } from "react-router-dom";
import { BrandIconContext } from "../../../states/contexts/BrandIconContext";
import { useContext } from "react";
import { AiFillYoutube } from "react-icons/ai";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";

const FooterNavigation = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"
  const { footerColor, footerLogoColor } = useContext(BrandIconContext)


  return (
    <>
      <footer
        className="h-[912.77px] sm:h-[778.54px] sm:w-auto lg:h-[333px] px-[20px] pt-[90px] pb-[100px] sm:p-0 text-white"
        style={{ fontFamily: "Open Sans", fontWeight: "400", backgroundColor: footerColor || "black" }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-[80px] sm:gap-[100px] sm:px-[60px] sm:pt-[90px] sm:pb-[100px] lg:gap-20 place-items-center justify-center lg:justify-between">
          {/* col 1 */}
          <div className="w-[250px] h-[113.77px] sm:w-[290px] sm:h-[126.54px] lg:w-[269.95px] lg:h-[108.14px] flex flex-col gap-[40px] lg:gap-[30px] items-center justify-center mx-auto">
            <div className="lg:mb-0 lg:flex lg:justify-start">
              <Link to={`/?division=${division}`} className="cursor-pointer">
                {division === "NORTHCOTT" && <img
                  src={NorthcottLogoDark}
                  className="h-[54.77px] w-[250px] sm:w-[290px] sm:h-[63.54px] lg:w-[269.95px] lg:h-[59.14px]"
                  alt="Northcott"
                />}
                {division === "PATRICK LOSE" && <img
                  src={PatrickLoseLogo}
                  className="h-[54.77px] w-[250px] sm:w-[290px] sm:h-[63.54px] lg:w-[269.95px] lg:h-[59.14px]"
                  alt="Patrick Lose"
                />}
                {division === "BANYAN BATIKS" && <img
                  src={BanyanLogo}
                  className="h-[54.77px] w-[250px] sm:w-[290px] sm:h-[63.54px] lg:w-[269.95px] lg:h-[59.14px]"
                  alt="Banyan Batiks"
                />}
              </Link>
            </div>
            <div className="h-[19px] w-[168.88px] sm:w-[229px] sm:h-[23px] lg:w-[123.88px] lg:h-[19px] lg:mb-0 grid grid-flow-col lg:gap-[20px] items-center justify-between lg:mr-32">
              <a href="https://www.facebook.com/NorthcottFabrics" className="cursor-pointer relative">
                <div className="absolute top-0 left-0 hover:hidden">
                  <FaFacebookF color={`${footerLogoColor}`} size={"1rem"} />
                </div>
                <div className="block">
                  <FaFacebookF size={"1rem"} />
                </div>
              </a>
              <a href="https://x.com/i/flow/login?redirect_after_login=%2FNorthcottFabric" className="cursor-pointer relative">
                <div className="absolute top-0 left-0 hover:hidden">
                  <FaTwitter color={`${footerLogoColor}`} size={"1rem"} />
                </div>
                <div className="block">
                  <FaTwitter size={"1rem"} />
                </div>
              </a>
              <a href="https://www.youtube.com/user/NorthcottFabric/feed" className="cursor-pointer relative">
                <div className="absolute top-0 left-0 hover:hidden">
                  <AiFillYoutube color={`${footerLogoColor}`} size={"1rem"} />
                </div>
                <div className="block">
                  <AiFillYoutube size={"1rem"} />
                </div>
              </a>
              <a href="https://www.instagram.com/northcottfabric/" className="cursor-pointer relative">
                <div className="absolute top-0 left-0 hover:hidden">
                  <FaInstagram color={`${footerLogoColor}`} size={"1rem"} />
                </div>
                <div className="block">
                  <FaInstagram size={"1rem"} />
                </div>
              </a>
            </div>
          </div>
          {/* col 2 */}
          <div className="h-[263px] w-[76px] sm:w-[252px] sm:h-[119px] lg:w-[252px] lg:h-[119px] text-white text-xs lg:mx-1 flex flex-col gap-[60px] lg:gap-[100px] mx-auto sm:grid sm:grid-cols-2">
            <div className="h-[119px] w-[76px] flex flex-col gap-[20px] text-center lg:text-left cursor-pointer">
              <h2 className=" text-sm font-bold cursor-default">HELP</h2>
              <a href={'https://northcott.com/help.aspx'}>
                <h3 className="hover:text-[#EECB9A]">FAQ</h3>
              </a>
              <a href={'https://northcott.com/help.aspx'}>
                <h3 className="hover:text-[#EECB9A]">Web Tour</h3>
              </a>
              <Link to={"contact-us"}>
                <h3 className="hover:text-[#EECB9A]">Contact Us</h3>
              </Link>
            </div>
            <div className="h-[84px] w-[79px] mx-auto flex flex-col gap-[20px] place-items-center lg:place-items-start lg:text-left cursor-pointer">
              <h3 className=" font-bold cursor-default">POLICIES</h3>
              <a href={'https://northcott.com/privacy-policy.aspx'}>
                <h3 className=" mx-auto hover:text-[#EECB9A] text-nowrap">
                  Privacy Policy
                </h3>
              </a>
              {/* <h3 className="w-32 hover:text-[#EECB9A] text-nowrap ">
                Terms And Conditions
              </h3> */}
            </div>
          </div>
          {/* col 3 */}
          <div>
            <ul className="h-[186px] w-[280px] sm:h-[143px] sm:w-[328px] flex flex-col gap-[30px]">
              <li className="h-[88px] w-[280px] sm:h-[64px] sm:w-[328px] flex flex-col gap-[10px]">
                <div className="uppercase font-bold	text-[22px] text-white text-center lg:text-right">
                  GET THE INSIDE SCOOP!
                </div>
                <div className="text-gray-300 italic text-center lg:text-right sm:text-nowrap text-sm lg:text-xs">
                  New collections,free patterns,quilting tips and more.
                </div>
              </li>
              <li className="flex items-center justify-center rounded">
                {/* <div className="h-[68px] w-[260px] sm:h-[49px] sm:w-[319px] lg:h-[49px] lg:w-[319px] hover:text-[#E2A856] bg-[#E2A856] hover:bg-black hover:border-2 hover:border-[#E2A856] flex items-center justify-center">
                  <p className="h-[38px] px-3 sm:px-0 py-0 w-full text-center cursor-pointer hover:text-[#E2A856] font-bold text-base uppercase leading-5 text-black sm:text-nowrap pt-0 sm:pt-[8px]">
                    subscribe to our newsletter
                  </p>
                </div> */}
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterNavigation;
