import NorthcottFabricBg from "../../../../assets/bodyFabrics/NorthcottFabricBg.svg";
import bodyDesignerLogoNorthcott from "../../../../assets/others/bodyDesignerLogoNorthcott.svg";
import bodyDesignerLogoPatrickLose from "../../../../assets/others/bodyDesignerLogoPatrickLose.svg";
import Mask from "../../../../assets/others/Mask.png";
import { FaInstagram } from "react-icons/fa";
import { AiFillYoutube } from "react-icons/ai";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";

import CarouselTop from "./BodyComponents/CarouselTop";
import CarouselBottom from "./BodyComponents/CarouselTBottom";
import { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import "../../../../pages/resources/Resource.css";
import ApiService from "../../../../helpers/ApiServices";
import { BrandIconContext } from "../../../../states/contexts/BrandIconContext";


const Body = () => {

  const [loading, setLoading] = useState(false)
  const [state, setState] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"

  const { bodyLogoColor, footerLogoColor, buttonBgColor, textColor, headerColor, textColorCommon } = useContext(BrandIconContext)

  const findFeaturedCategories = async () => {
    setLoading(true)
    ApiService.setHeader();
    ApiService.get(`shop/landingPage/featured?division=${division}`).then(response => {
      if (response.data.isSuccess) {
        setState(response.data.document)
      }
      setLoading(false)
    }).catch(error => {
      setLoading(false)
      console.log(error);
    })
  }

  useEffect(() => {
    findFeaturedCategories();
  }, [division])

  return (
    <div
      className="flex flex-col items-center justify-center"
      style={{ fontFamily: "Open Sans" }}
    >
      {
        !loading ?
          <div className="flex flex-col items-center gap-y-[80px] sm:gap-y-[100px] mb-[80px] sm:mb-[100px]">
            {/* row 1 */}
            <div className="w-[320px] sm:w-[768px] xl:w-[1440px] 2xl:w-[1520px] h-[172px] sm:h-[399px] xl:h-[687px] 2xl:h-[926px] flex items-center justify-center">
              <CarouselTop setLoading={setLoading} loading={loading} />
            </div>
            {/* 2) */}
            {/* Whats new */}
            {/* row2 */}
            <div className={`${state?.whatsNews?.length > 0 ? "w-[320px] h-[1193.73px] sm:w-[744px] sm:h-[1432px] xl:w-[1280px] xl:h-[661px] 2xl:w-[1320px] flex flex-col items-center justify-center gap-10 xl:mx-[60px]" : "hidden"} `}>
              {/* whats new heading */}
              <div
                className=" w-[280px] sm:w-[624px] xl:w-[1160px] 2xl:w-[1200px] h-[44px] sm:h-[60px] xl:h-[66px] text-[32px] sm:text-[44px] xl:text-[48px] text-center xl:text-start "
                style={{ fontFamily: "DM Serif Display" }}
              >
                What's New
              </div>
              {/* 1 and 2 card for all and 3 card for mobile only */}
              <div className={`${state?.whatsNews?.length > 0 ? "sm:w-[624px] sm:h-[1332px] xl:w-[1160px] xl:h-[555px] 2xl:w-[1200px] sm:flex sm:flex-col xl:flex-row sm:items-center sm:justify-center xl:justify-between sm:gap-[80px] xl:gap-[60px] 2xl:gap-[100px]" : "hidden"} `}>
                {/* 1st card */}
                {Array.isArray(state?.whatsNews) && state?.whatsNews.length > 0 && state?.whatsNews[0].isActive && <div className="w-[280px] h-[343.24px] sm:w-[624px] sm:h-[626px] xl:w-[550px] xl:h-[555px] flex flex-col items-center justify-between xl:items-start">
                  <img
                    src={`${ApiService.getBaseImagePath()}landingpageimages/${state?.whatsNews[0]?.imageName}` || Mask}

                    alt="whats new"
                    className="w-[280px] h-[201.24px] sm:w-[624px] sm:h-[468.56px] xl:w-[550px] xl:h-[413px] object-cover"
                  />
                  <div className="w-[250px] h-[112px] flex flex-col justify-between items-center xl:items-start">
                    <div
                      className="w-[250px] h-[33px] text-[24px] flex items-center justify-center xl:justify-start text-nowrap"
                      style={{ fontFamily: "DM Serif Display" }}
                    >
                      {state?.whatsNews[0]?.title}
                    </div>
                    <div className="w-[148px] h-[49px] flex justify-center items-center xl:justify-start">
                      <a href={state?.whatsNews[0]?.buttonLink} >
                        <button
                          type="button"
                          className={`border-[1px] font-semibold rounded text-center w-[148px] h-[49px] text-[16px] `}
                          style={{ fontFamily: "Open Sans", color: buttonBgColor, borderWidth: "1px", borderColor: buttonBgColor }}
                        >
                          {state?.whatsNews[0]?.buttonLabel}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>}
                {/* 2st card */}
                {Array.isArray(state?.whatsNews) && state?.whatsNews.length > 1 && state?.whatsNews[1].isActive && <div className="w-[280px] h-[343.24px] sm:w-[624px] sm:h-[626px] xl:w-[550px] xl:h-[555px] flex flex-col items-center justify-between xl:items-start">
                  <img
                    src={`${ApiService.getBaseImagePath()}landingpageimages/${state?.whatsNews[1]?.imageName}` || Mask}

                    alt="whats new"
                    className="w-[280px] h-[201.24px] sm:w-[624px] sm:h-[468.56px] xl:w-[550px] xl:h-[413px] object-cover"
                  />
                  <div className="w-[250px] h-[112px] flex flex-col justify-between items-center xl:items-start">
                    <div
                      className="w-[250px] h-[33px] text-[24px] flex items-center justify-center xl:justify-start text-nowrap"
                      style={{ fontFamily: "DM Serif Display" }}
                    >
                      {state?.whatsNews[1]?.title}
                    </div>
                    <div className="w-[148px] h-[49px] flex justify-center items-center xl:justify-start">
                      <a href={state?.whatsNews[1]?.buttonLink} >

                        <button
                          type="button"
                          className=" hover:text-white font-semibold rounded text-center w-[148px] h-[49px] text-[16px]"
                          style={{ fontFamily: "Open Sans", color: buttonBgColor, borderWidth: "1px", borderColor: buttonBgColor }}
                        >
                          {state?.whatsNews[1]?.buttonLabel}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>}
              </div>
            </div>

            {/* 3rd row */}
            {/* 3rd card */}
            <div
              className={`${state?.whatsNews?.length > 2 && state?.whatsNews[2].isActive ? "w-[320px] h-[499px] sm:w-[768px] sm:h-[560.77px] xl:w-[1280px] xl:h-[745px] 2xl:w-[1320px] 2xl:h-[812.61px] flex flex-col items-center justify-center gap-[40px]" : "hidden"}`}
              style={{ fontFamily: "DM Serif Display" }}
            >
              {/* heading */}
              <div className="text-[32px] sm:text-[44px] xl:text-[48px] w-[164px] h-[44px] sm:w-[225px] sm:h-[60px] xl:w-[246px] xl:h-[66px]">
                What's New
              </div>
              {/* card 3 */}
              {
                Array.isArray(state?.whatsNews) && state?.whatsNews.length > 2 && state?.whatsNews[2].isActive && < div className="w-[280px] h-[415px] sm:w-[768px] sm:h-[460.77px] xl:w-[1160px] xl:h-[639px] 2xl:w-[1200px] 2xl:h-[706.61px] flex flex-col items-center justify-between">
                  <img
                    src={`${ApiService.getBaseImagePath()}landingpageimages/${state?.whatsNews[2]?.imageName}`}
                    alt="Images"
                    className="w-full h-[240px] sm:h-[318.77px] xl:w-[1160px] xl:h-[497px] 2xl:w-[1200px] 2xl:h-[564.61px] object-cover"
                  />
                  <div className="w-[240px] h-[145px] sm:w-[324px] sm:h-[112px] flex flex-col justify-between items-center">
                    <div
                      className="w-[240px] h-[66px] sm:text-nowrap text-[24px] sm:w-[324px] sm:h-[33px] flex items-center justify-center text-center"
                      style={{ fontFamily: "DM Serif Display" }}
                    >
                      {state?.whatsNews[2]?.title}
                    </div>
                    <a href={state?.whatsNews[2]?.buttonLink} className="w-[240px] h-[49px] flex justify-center items-center">
                      <button
                        type="button"
                        className=" hover:text-white font-semibold rounded text-center w-[148px] h-[49px] text-[16px]"
                        style={{ fontFamily: "Open Sans", color: buttonBgColor, borderWidth: "1px", borderColor: buttonBgColor }}
                      >
                        {state?.whatsNews[2]?.buttonLabel}
                      </button>
                    </a>
                  </div>
                </div>

              }

            </div>

            {/* 3) */}
            {/* 4) */}
            {/* Northcott Fabrics */}
            <div className="w-[320px] sm:w-[768px] xl:w-screen max-w-[1520px] h-[664px] sm:h-[390px] xl:h-[369px] flex flex-col items-center justify-center text-white bg-black relative">
              <img
                src={NorthcottFabricBg}
                alt="Fabrics"
                className="h-[664px] sm:h-[390px] xl:h-[369px] w-[320px] sm:w-[768px] xl:w-full object-cover"
              />

              <div className="w-[200px] h-[544px] sm:w-[624px] sm:h-[270px] xl:w-[800px] xl:h-[249px] flex flex-col items-center justify-center text-center text-white absolute">
                <div
                  className="w-[200px] h-[88px] sm:w-[624px] sm:h-[66px] xl:w-[800px] flex justify-center text-[32px] sm:text-[48px] text-center items-center"
                  style={{ fontFamily: "DM Serif Display" }}
                >
                  Northcott Fabrics
                </div>
                <div className="mt-[20px] mb-[30px] w-[200px] h-[357px] sm:w-[624px] sm:h-[105px] xl:w-[800px] xl:h-[84px] text-[14px]">
                  We are the home of “cottons that feel like silk”. Our fine cotton
                  prints with a silky hand can be found in quilt and craft shops
                  around the world. We are known for our superior quality and
                  admirable customer service. The quilting industry is where we have
                  built a name for ourselves and a community of great customers,
                  distributors and partners. We design and partner with the best
                  designers to produce fabrics to sell wholesale to your local shops
                  and distributors.
                </div>
                <div className="mx-auto h-[49px] w-[162px] flex justify-center">
                  <Link to={"about-us?division=NORTHCOTT"} className="cursor-pointer">
                    <button
                      type="button"
                      className=" text-black font-medium rounded-sm text-base px-5 lg:px-1 py-2.5 text-center w-44" style={{ backgroundColor: footerLogoColor, color: textColorCommon }}
                    >
                      LEARN MORE
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            {/* 5) */}
            {/* Featured Categories */}
            <div className={`flex items-center justify-center ${state?.featuredCategories?.length > 0 ? "flex" : "hidden"}`}>
              <div className="w-[320px] sm:w-[744px] xl:w-[1280px] 2xl:w-[1320px] flex flex-col items-center justify-center gap-[40px] xl:mx-[60px]">
                {/* heading */}
                <div
                  className="w-[280px] h-[64px] sm:w-[664px] sm:h-[80px] xl:w-[1160px] xl:h-[86px] 2xl:w-[1200px] text-center text-[32px] sm:text-[44px] xl:text-[48px] pb-[20px] text-nowrap"
                  style={{ fontFamily: "DM Serif Display" }}
                >
                  Featured Categories
                </div>
                <div className={`grid grid-rows-6 ${state?.featuredCategories?.length > 3 ? "xl:grid-rows-2" : "xl:grid-rows-1"} grid-flow-col gap-y-[40px] xl:gap-x-[62.5px] 2xl:gap-x-[82.5px]`}>
                  {state?.featuredCategories?.map((item, index) => {
                    if (!item.isActive) return;
                    return (
                      <div
                        key={index}
                        className="col-span-1 w-[260px] h-[412px] sm:w-[345px] sm:h-[503px] flex flex-col items-center justify-center bg-white gap-[30px] border-[1px] border-[#CECECC] group"
                      >
                        <div className="relative overflow-hidden w-[260px] h-[260px] sm:w-[345px] sm:h-[345px]">
                          <img
                            src={`${ApiService.getBaseImagePath()}landingpageimages/${item?.imageName}`}
                            alt="Product"
                            className="w-full h-full group:transition-transform group:duration-300 group:ease-in-out group:transform group-hover:scale-110 object-cover"
                          />
                        </div>
                        <div className="w-[260px] h-[122px] sm:w-[345px] sm:h-[128px] flex flex-col justify-center gap-[20px]">
                          <div className="w-[220px] h-[48px] sm:w-[305px] sm:h-[54px] flex  font-bold text-[16px] sm:text-[18px] mx-[20px]">
                            {item?.title}
                          </div>
                          <a className="cursor-pointer"
                            href={`${item?.buttonLink}`}

                          >
                            <button className="read-more-btn relative text-[14px] sm:text-[16px] flex items-center font-bold gap-[5px] ms-[20px] mb-[30px] cursor-pointer" style={{ color: buttonBgColor }}>
                              {item?.buttonLabel ? item?.buttonLabel : ""}
                            </button>
                          </a>
                        </div>
                      </div>
                    )
                  }
                  )}
                </div>
              </div>
            </div>
            {/* 5) */}
            {/* featured designer */}
            <div className="mt-[120px] lg:mt-[100px] h-[892px] lg:h-[570px] flex flex-col lg:flex-row lg:gap-14 xl:gap-20 2xl:gap-20 lg:items-center justify-center lg:justify-between text-white bg-[#CECECC]">
              <div className=" w-full lg:w-1/2 flex justify-center xl:justify-end">
                {division === "NORTHCOTT" && < img
                  src={bodyDesignerLogoNorthcott}
                  alt="Image"
                  className="h-[260px] w-[260px] rounded-full lg:h-[450px] lg:w-[450px]"
                />}
                {division === "BANYAN BATIKS" && < img
                  src={bodyDesignerLogoNorthcott}
                  alt="Image"
                  className="h-[260px] w-[260px] rounded-full lg:h-[450px] lg:w-[450px]"
                />}
                {division === "PATRICK LOSE" && < img
                  src={bodyDesignerLogoPatrickLose}
                  alt="Image"
                  className="h-[260px] w-[260px] rounded-full lg:h-[450px] lg:w-[450px]"
                />}
              </div>
              <div className="w-full lg:w-1/2 mt-16">
                <div className="lg:h-[307px] flex flex-col gap-5 justify-center text-center lg:text-left text-black">
                  <h1
                    className="italic text-xl mt-10 lg:mt-0"
                    style={{ fontFamily: "DM Serif Display" }}
                  >
                    Featured Designer
                  </h1>
                  <h1 className="text-3xl" style={{ fontFamily: "DM Serif Display" }}>
                    Designer Name
                  </h1>
                  <p className="text-sm lg:text-xs lg:pe-20 px-10 lg:px-0 xl:pe-52 lg:h-[105px]">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                    enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse et dolore magna aliqua
                    cillum dolore eu fugiat nulla pariatur.
                  </p>
                  <Link to={`/designers-list?division=${division}`} className="mt-3 sm:mt-10 lg:mt-7 flex items-center justify-center lg:justify-start">
                    <button
                      type="button"
                      // className={`uppercase font-semibold rounded-sm text-base py-2.5 px-2 text-center mb-10 w-[280px] h-[68px] sm:h-[49px] md:w-96 bg-[${buttonBgColor}] text-${textColor} hover:text-[${buttonBgColor}] hover:bg-[${textColor}]`}
                      className={`uppercase font-semibold rounded-sm text-base py-2.5 px-2 text-center mb-10 w-[280px] h-[68px] sm:h-[49px] md:w-96`} style={{ backgroundColor: buttonBgColor, color: textColor }}
                    >
                      Shop designer name’s collection
                    </button>
                  </Link>
                </div>
              </div>
            </div>


            {/* 6) */}
            {/* Resources */}
            {/* <div className="mx-[20px] lg:mx-[60px] py-5 sm:mb-[60vh] lg:mb-[20vh] 2xl:w-[1160px] 2xl:h-[667px] 2xl:mb-[80vh] 2xl:mx-auto">
        <div>
          <div className="mx-auto mt-16">
            <div className="flex flex-col lg:flex-row justify-between gap-10 lg:ms-10 xl:gap-0">
              <div
                className="text-center lg:text-left text-5xl"
                style={{ fontFamily: "DM Serif Display" }}
              >
                Resources
              </div>
              <Link to={"/"} className="flex justify-center mx-5 lg:mx-0 lg:justify-start xl:me-20 2xl:ms-[25vw]">
                <button
                  type="button"
                  className=" text-[#E2A856] hover:text-white border border-[#E2A856] hover:border-none hover:bg-black font-semibold rounded px-4 py-2.5 text-center me-2 mb-10 w-[132px] h-[49px] text-base"
                >
                  VIEW ALL
                </button>{" "}
              </Link>
            </div>
          </div>
          <div className="xl:w-[1160px] xl:h-[667px] lg:w-auto lg:h-[667px] lg:mt-14 flex flex-col lg:flex-row items-center xl:items-start justify-center lg:justify-between lg:gap-24 xl:gap-20 lg:mx-10">
            <div className=" lg:w-1/2 md:w-fit bg-white flex justify-center mx-5 lg:mx-0 border">
              <a
                href="#"
                className="sm:w-[624px] sm:h-[779px] lg:w-[515px] lg:h-[667px]"
              >
                <img
                  src={Property1}
                  alt="Image"
                  className="w-[217px] h-[280px] sm:w-[624px] sm:h-[624px] lg:w-auto lg:h-[515px] object-cover"
                />
                <div className="w-[217px] h-[182px] sm:w-[320px] sm:h-[129px] flex flex-col gap-3 sm:gap-1 sm:mt-5 items-start justify-center sm:justify-start px-7 sm:px-5">
                  <h4 className="text-gray-400">Editorial Features</h4>
                  <h2 className="font-bold w-56 sm:w-full sm:text-nowrap text-lg text-start">
                    Follow The Star by TeriAnn Harms{" "}
                  </h2>
                  <img className="w-24" src={frame} alt="Image" />
                </div>
              </a>
            </div>
            <div className="w-[260px] sm:w-[624px] sm:h-[665px] lg:w-1/2 flex flex-col items-center gap-10 lg:gap-4 mt-10 lg:mt-0 me-0 md:me-28 lg:me-0">
              <a href="#" className="sm:max-w-[550px] sm:h-[140px]">
                <div className="flex flex-col md:flex-row gap-5 sm:gap-10">
                  <img
                    className="w-[260px] sm:w-[140px] sm:h-[140px] lg:h-32"
                    src={Mask1}
                    alt="Image"
                  />
                  <div className="sm:w-[320px] sm:h-[129px] flex flex-col gap-3 items-center justify-center sm:items-start sm:justify-start">
                    <h4 className="text-gray-400">Free Patterns</h4>
                    <h2 className="font-bold w-60 lg:w-52 lg:text-sm text-lg text-center sm:text-start">
                      Patrick Lose Cake Block - FREE DOWNLOAD
                    </h2>
                    <img className="w-24" src={frame} alt="Image" />
                  </div>
                </div>
              </a>
              <hr className=" text-black w-4/5 lg:w-full sm:me-12" />
              <a href="#" className="sm:max-w-[550px] sm:h-[140px]">
                <div className="flex flex-col md:flex-row gap-5 sm:gap-10">
                  <img
                    className="w-[260px] sm:w-[140px] sm:h-[140px] lg:h-32"
                    src={Mask2}
                    alt="Image"
                  />
                  <div className="sm:w-[320px] sm:h-[129px] flex flex-col gap-3 items-center justify-center sm:items-start sm:justify-start">
                    <h4 className="text-gray-400">Editorial Features</h4>
                    <h2 className="font-bold w-60 lg:w-52 lg:text-sm text-lg text-center sm:text-start">
                      Lemon Sorbet by TeriAnn Harms{" "}
                    </h2>
                    <img className="w-24" src={frame} alt="Image" />
                  </div>
                </div>
              </a>
              <hr className=" text-black w-4/5 lg:w-full sm:me-12" />
              <a href="#" className="sm:max-w-[550px] sm:h-[140px]">
                <div className="flex flex-col md:flex-row gap-5 sm:gap-10">
                  <img
                    className="w-[260px] sm:w-[140px] sm:h-[140px] lg:h-32"
                    src={Mask3}
                    alt="Image"
                  />
                  <div className="sm:w-[320px] sm:h-[129px] flex flex-col gap-3 items-center justify-center sm:items-start sm:justify-start">
                    <h4 className="text-gray-400">Free Patterns</h4>
                    <h2 className="font-bold w-60 lg:w-52 lg:text-sm text-lg text-center sm:text-start">
                      Beach Blanket - FREE DOWNLOAD{" "}
                    </h2>
                    <img className="w-24" src={frame} alt="Image" />
                  </div>
                </div>
              </a>
              <hr className=" text-black w-4/5 lg:w-full sm:me-12" />
              <a href="#" className="sm:max-w-[550px] sm:h-[140px]">
                <div className="flex flex-col md:flex-row gap-5 sm:gap-10">
                  <img
                    className="w-[260px] sm:w-[140px] sm:h-[140px] lg:h-32"
                    src={Mask4}
                    alt="Image"
                  />
                  <div className="sm:w-[320px] sm:h-[129px] flex flex-col gap-3 items-center justify-center sm:items-start sm:justify-start">
                    <h4 className="text-gray-400">Editorial Features</h4>
                    <h2 className="font-bold w-60 lg:w-52 lg:text-sm text-lg text-center sm:text-start">
                      Lemon Sorbet by TeriAnn Harms{" "}
                    </h2>
                    <img className="w-24" src={frame} alt="Image" />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div> */}
            {/* 7) */}
            <div className="mx-[20px] sm:mx-[60px] w-[260px] sm:w-full h-[108px] flex items-center justify-center my-10 ms-7 sm:ms-0">
              <div className="font-bold text-[28px] lg:min-w-96 max-w-2xl text-center">
                <p className="text-center sm:w-[624px] sm:h-[66px] lg:w-[800px] lg:h-[84px]">
                  Follow us on social media as we always have something exciting to
                  share <span className="" style={{ color: bodyLogoColor }}>#NorthcottFabrics</span>
                </p>
              </div>
            </div>
            {/* 8) */}
            {/* <Carousel /> */}
            <div className="w-[280px] h-[130px] sm:w-[624px] sm:h-[150px] xl:w-[1160px] xl:h-[260px] 2xl:w-[1320px]">
              <CarouselBottom />
            </div>
            {/* 9) */}
            {/* body footer */}
            <div className="flex flex-row items-center justify-center mt-[40px]">
              <div className="w-[102px] h-[24px] xl:w-[115px] xl:h-[27px] text-[16px] sm:text-[18px] text-nowrap">
                Follow us on :
              </div>
              <div className="w-[124px] h-[20px] sm:w-[174px] sm:h-[25px] flex items-center justify-evenly">
                <a href="https://www.facebook.com/NorthcottFabrics" className="cursor-pointer relative">
                  <div className="absolute top-0 left-0 hover:hidden">
                    <FaFacebookF color={`${bodyLogoColor}`} size={"1.5rem"} />
                  </div>
                  <div className="block">
                    <FaFacebookF size={"1.5rem"} />
                  </div>
                </a>
                <a href="https://x.com/i/flow/login?redirect_after_login=%2FNorthcottFabric" className="cursor-pointer relative">
                  <div className="absolute top-0 left-0 hover:hidden">
                    <FaTwitter color={`${bodyLogoColor}`} size={"1.5rem"} />
                  </div>
                  <div className="block">
                    <FaTwitter size={"1.5rem"} />
                  </div>
                </a>
                <a href="https://www.youtube.com/user/NorthcottFabric/feed" className="cursor-pointer relative">
                  <div className="absolute top-0 left-0 hover:hidden">
                    <AiFillYoutube color={`${bodyLogoColor}`} size={"1.5rem"} />
                  </div>
                  <div className="block">
                    <AiFillYoutube size={"1.5rem"} />
                  </div>
                </a>
                <a href="https://www.instagram.com/northcottfabric/" className="cursor-pointer relative">
                  <div className="absolute top-0 left-0 hover:hidden">
                    <FaInstagram color={`${bodyLogoColor}`} size={"1.5rem"} />
                  </div>
                  <div className="block">
                    <FaInstagram size={"1.5rem"} />
                  </div>
                </a>
              </div>
            </div >
          </div >
          :
          <div className="h-screen flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2400 2400" width="24" height="24"><g strokeWidth="200" strokeLinecap="round" stroke="#000" fill="none"><path d="M1200 600V100" /><path opacity=".5" d="M1200 2300v-500" /><path opacity=".917" d="M900 680.4l-250-433" /><path opacity=".417" d="M1750 2152.6l-250-433" /><path opacity=".833" d="M680.4 900l-433-250" /><path opacity=".333" d="M2152.6 1750l-433-250" /><path opacity=".75" d="M600 1200H100" /><path opacity=".25" d="M2300 1200h-500" /><path opacity=".667" d="M680.4 1500l-433 250" /><path opacity=".167" d="M2152.6 650l-433 250" /><path opacity=".583" d="M900 1719.6l-250 433" /><path opacity=".083" d="M1750 247.4l-250 433" /><animateTransform attributeName="transform" attributeType="XML" type="rotate" keyTimes="0;0.08333;0.16667;0.25;0.33333;0.41667;0.5;0.58333;0.66667;0.75;0.83333;0.91667" values="0 1199 1199;30 1199 1199;60 1199 1199;90 1199 1199;120 1199 1199;150 1199 1199;180 1199 1199;210 1199 1199;240 1199 1199;270 1199 1199;300 1199 1199;330 1199 1199" dur="0.83333s" begin="0s" repeatCount="indefinite" calcMode="discrete" /></g></svg>
          </div>
      }
    </div >
  );
};

export default Body;