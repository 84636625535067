import React, { useContext, useEffect, useState } from "react";
import Modal from "./Modal";
import ApiService from "../../../helpers/ApiServices";
import { showMessage } from "../../../helpers/Utils";
import DefaultImg from '../../../image/default_product_img.jpg'
import { CustomerContext } from "../../../states/contexts/CustomerContext";


const ProductNormalCard = ({ login, index, item, data, product, uniteType, setQuantity, customers, wishLists, navigate, moveToWishList, carts, addSelectedProductsToCart, getPriceByPricingMatrix, findProductMultiplierAndFormatArray, cadMultiplier, addToCart, setProductImage, setModalShow, country, collection }) => {
  // modalBox
  const [openModal, setOpenModal] = useState(null);
  const [isSoldOutCAD, setIsSoldOutCAD] = useState(false);
  const [isSoldOutUSA, setIsSoldOutUSA] = useState(false);


  const [showDetailPopUp, setshowDetailPopUp] = useState(
    Array({ data }).fill(false)
  );

  const handleMouseEnter = (index) => {
    const updatedHoverState = [...showDetailPopUp];
    updatedHoverState[index] = true;
    setshowDetailPopUp(updatedHoverState);
  };

  const handleMouseLeave = (index) => {
    const updatedHoverState = [...showDetailPopUp];
    updatedHoverState[index] = false;
    setshowDetailPopUp(updatedHoverState);
  };

  const { dispatch: customerDispatch, customer } = useContext(CustomerContext)



  useEffect(() => {

    if (customer && country === "CANADA") {
      if (!product?.isReorderable && product?.isSoldOutCanada) {
        setIsSoldOutCAD(true)
      }
    } else if (!product?.isReorderable && product?.isSoldOutUsa) {
      setIsSoldOutUSA(true)
    }
  }, [])


  return (
    <div>
      <div
        key={index}
        className={`w-[130px] ${login ? "h-[266px] sm:h-[326px]" : "h-[216px] sm:h-[288px]"
          }   sm:w-[180px] border-[1px] border-[#CECECC] hover:border-[#6B6B66]`}
      >
        {/* modal */}
        <div>
          <Modal
            login={login}
            showModal={openModal === index}
            cancelOption={() => setOpenModal(null)}
            index={index + 1}
            product={product}
            uniteType={uniteType}
          />
        </div>

        <div className="flex flex-col gap-[15px] sm:gap-[20px]">
          <div
            className="relative"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
          >
            <img

              src={`${ApiService.getBaseImagePath()}products/${product?.name?.includes(":") ? product?.name?.split(":")[1].trim() : product?.name}.jpg`}
              alt="Product"
              className="h-[130px] w-[130px] sm:h-[180px] sm:w-[180px] object-cover"
            />

            {showDetailPopUp[index] && (
              <div
                className="absolute w-[130px] h-[43.33px] sm:w-[180px] sm:h-[60px] text-white text-[12px] font-bold bg-black/85 flex items-center justify-center bottom-0 cursor-pointer"
                onClick={() => {
                  setOpenModal(index);
                }}
              >
                <span className="text-white">View Details</span>
              </div>
            )}
          </div>

          <div
            className={`${login ? "h-[121px] sm:h-[126px]" : "h-[71px] sm:h-[88px]"
              } w-[130px]  sm:w-[180px] flex items-start justify-center`}
          >
            <div
              className={`flex flex-col ${login ? "h-[106px] sm:h-[126px]" : "h-[56px] sm:h-[68px]"
                } w-[120px] sm:w-[180px] gap-[5px] sm:gap-[14px] items-center`}
            >
              <div className="w-[120px] h-[56px] sm:w-[160px] sm:h-[47px] flex flex-col items-center justify-between">
                <div className="w-[120px] h-[15px] sm:h-[21px] sm:w-[160px] overflow-hidden text-nowrap flex items-center justify-between">
                  <p className="w-[63px] h-[15px] sm:w-[90px] sm:h-[21px] text-[10px] sm:text-[12px] text-[#9D9C99]">
                    {product?.displayName}
                  </p>
                  <p className="w-[25px] h-[15px] sm:w-[55px] sm:h-[21px] text-[10px] sm:text-[12px] text-[#9D9C99] text-end">
                    {product?.colorDescription}
                  </p>
                </div>
                <p className="font-semibold w-[120px] h-[36px] sm:h-[21px] sm:w-[160px] text-[12px] sm:text-[14px]">
                  {product?.name?.includes(":") ? product?.name?.split(":")[1].trim() : product?.name}
                </p>
              </div>
              {/* for log in user */}
              {login && (
                <div className="w-[120px] h-[45px] sm:w-[160px]">
                  <div className="flex items-end justify-between">
                    <div className="w-[42px] h-[39px] sm:w-[71px] sm:h-[27px] flex flex-col items-center justify-between sm:flex-row">
                      <p className="text-[16px] sm:text-[18px] font-semibold w-[42px] h-[24px] sm:w-[47px] sm:h-[27px] flex items-center justify-center">
                        ${product?.priceFromPM ? product?.priceFromPM : product?.price}
                      </p>
                      <span className="w-[16px] h-[15px] sm:w-[19px] sm:h-[18px] text-[10px] sm:text-[12px] text-[#9D9C99] font-normal">
                        / {product?.primaryUnitsType == "Each" ? "e" : uniteType}{" "}
                      </span>
                    </div>
                    {/* Add and quantity button */}
                    {((country === "CANADA" && isSoldOutCAD) || (country === "UNITED STATES" && isSoldOutUSA))

                      ?
                      (<div className="w-[60px] h-[45px] text-[#ED393E] font-bold text-[14px] flex items-end justify-between">Sold Out</div>)
                      :
                      (< div className="w-[60px] h-[45px] flex flex-col justify-between">
                        {/* quantity button */}
                        <form className="w-[60px] h-[20px]">
                          <select className=" [h-11px]"
                            id={`product_${product?._id}`}
                          >
                            {
                              product.multipliers?.length && product.multipliers?.map(ele => {

                                return <option key={ele} value={ele}>{ele}</option>
                              })
                            }

                          </select>
                        </form>
                        {/* add button */}
                        <div className="w-[60px] h-[20px]">
                          <button
                            type="button"
                            className="w-[60px] h-[20px] text-[8px] font-bold rounded-sm text-center text-white bg-black hover:bg-[#6B6B66] flex items-center justify-center"
                            onClick={() => {
                              if (document.getElementById(`product_${product?._id}`).value !== "Select qty...") {

                                let price = product?.price
                                let productQuantity = product?.noPromos?.includes(collection?.name?.toUpperCase()) ? 0.00 : document.getElementById(`product_${product?._id}`).value

                                addToCart({ productId: product?._id, userId: customers[customers?.length - 1]?._id, price: product?.priceFromPM ? product.priceFromPM : product?.price, multipliers: product.multipliers, productQuantity: productQuantity, subTotal: (parseInt(productQuantity) * parseFloat(product?.priceFromPM ? product.priceFromPM : product?.price).toFixed(2)).toFixed(2), image: product?.uploadSmallPoster?.url ? product?.uploadSmallPoster?.url : DefaultImg })
                              } else {
                                showMessage("Please select a quantity!", "info")
                              }
                            }}
                          >
                            Add
                          </button>
                        </div>
                      </div>)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ProductNormalCard;
