import { useNavigate, useSearchParams } from "react-router-dom";

const Breadcrum = ({ categoryName }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()

  let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"



  return (
    <div className="">
      <nav
        className="flex mt-5 mb-14 max-w-7xl mx-5 sm:mx-0"
        aria-label="Breadcrumb"
      >
        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
          <li className="inline-flex items-center">
            <p className="inline-flex cursor-pointer items-center text-sm font-medium text-[#6B6B66] hover:text-[#090800] hover:underline"
              onClick={() => {
                navigate(`/?division=${division}`)
              }}
            >
              Home
            </p>
          </li>

          <li aria-current="page">
            <div className="flex items-center">
              {" / "}
              <span className="ms-1 text-sm font-medium text-[#6B6B66] md:ms-2">
                {categoryName}
              </span>
            </div>
          </li>
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrum;
