import React, { useContext, useState } from "react";
import search from "../../assets/HeaderLogo/search.svg";
import DeleteIcon from "../../assets/ShoppingCart/DeleteIcon.svg";
import "./rapidOrder.css";
import CartProduct from "../../assets/ShoppingCart/CartProduct.svg";
import calendar_month from "../../assets/ShoppingCart/calendar_month.svg";
import { useNavigate } from "react-router-dom";
import { BrandIconContext } from "../../states/contexts/BrandIconContext";
import { CustomerContext } from "../../states/contexts/CustomerContext";
import { CartContext } from "../../states/contexts/CartContext";
import { UserContext } from "../../states/contexts/UserContext";
import { useFieldArray, useForm } from "react-hook-form";


const RapidOrder = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [carts, setCarts] = useState([])
  // const [unformatedCartData, setUnformatedCartData] = useState([])
  const [state, setState] = useState([]);
  const [total, setTotal] = useState(null);
  const [isEditCart, setIsEditCart] = useState(false);

  const navigate = useNavigate()

  const { dispatch: iconDispatch, textColor, division, ...rest } = useContext(BrandIconContext)
  const { dispatch: customerDispatch, customer } = useContext(CustomerContext)
  const { dispatch: cartDispatch, quantity } = useContext(CartContext)
  const { user } = useContext(UserContext)

  let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))
  let uniteType;


  const { register, handleSubmit, control, setValue, reset, getValues, formState: { errors } } = useForm({
    defaultValues: {
      cartItems: [
        {
          sku: ""
        }
      ]
    }
  });

  const { append: cartItemsAppend, remove: cartItemsRemove, fields: cartItemsFields, update: cartItemsUpdate, insert: cartItemsInsert } = useFieldArray({
    control, name: "cartItems",
  });
  const { append: showCartItemsAppend, remove: showCartItemsRemove, fields: showCartItemsFields, update: showCartItemsUpdate, insert: showCartItemsInsert } = useFieldArray({ control, name: "showCartItems" });

  let updateCartData = { quantity: "", cartId: "", price: "", subTotal: "" }


  const cartData = [
    {
      no: "collection3",
      calenderImg: calendar_month,
      items: [
        {
          img: CartProduct,
          productDetail: [
            {
              id: "00000-00",
              design: "Design Name",
              color: "Color",
              collection: "Collection Name",
            },
          ],
          price: 50,
          qty: 10,
          discount: "N/A",
          subTotal: 100,
        },
        {
          img: CartProduct,
          productDetail: [
            {
              id: "00000-00",
              design: "Design Name",
              color: "Color",
              collection: "Collection Name",
            },
          ],
          price: 40,
          qty: 11,
          discount: "N/A",
          subTotal: 120,
        },
        {
          img: CartProduct,
          productDetail: [
            {
              id: "00000-00",
              design: "Design Name",
              color: "Color",
              collection: "Collection Name",
            },
          ],
          price: 30,
          qty: 12,
          discount: "N/A",
          subTotal: 130,
        },
        {
          img: CartProduct,
          productDetail: [
            {
              id: "00000-00",
              design: "Design Name",
              color: "Color",
              collection: "Collection Name",
            },
          ],
          price: 20,
          qty: 13,
          discount: "N/A",
          subTotal: 140,
        },
        {
          img: CartProduct,
          productDetail: [
            {
              id: "00000-00",
              design: "Design Name",
              color: "Color",
              collection: "Collection Name",
            },
          ],
          price: 10,
          qty: 14,
          discount: "N/A",
          subTotal: 150,
        },
        {
          img: CartProduct,
          productDetail: [
            {
              id: "00000-00",
              design: "Design Name",
              color: "Color",
              collection: "Collection Name",
            },
          ],
          price: 0,
          qty: 15,
          discount: "N/A",
          subTotal: 200,
        },
      ],
      discount1: true,
      discount2: true,
    },
  ];
  const SKUTable = [
    { name: "SKU1" },
    { name: "SKU2" },
    { name: "SKU3" },
    { name: "SKU4" },
    { name: "SKU5" },
    { name: "SKU6" },
    { name: "SKU7" },
    { name: "SKU8" },
    { name: "SKU9" },
    { name: "SKU10" },
  ];
  const searchResult = [
    {
      id: "0000-00",
      image: CartProduct,
      design: "Design Name",
      color: "Color",
      collection: "Collection Name",
    },
    {
      id: "0000-00",
      image: CartProduct,
      design: "Design Name",
      color: "Color",
      collection: "Collection Name",
    },
    {
      id: "0000-00",
      image: CartProduct,
      design: "Design Name",
      color: "Color",
      collection: "Collection Name",
    },
    {
      id: "0000-00",
      image: CartProduct,
      design: "Design Name",
      color: "Color",
      collection: "Collection Name",
    },
    {
      id: "0000-00",
      image: CartProduct,
      design: "Design Name",
      color: "Color",
      collection: "Collection Name",
    },
    {
      id: "0000-00",
      image: CartProduct,
      design: "Design Name",
      color: "Color",
      collection: "Collection Name",
    },
    {
      id: "0000-00",
      image: CartProduct,
      design: "Design Name",
      color: "Color",
      collection: "Collection Name",
    },
    {
      id: "0000-00",
      image: CartProduct,
      design: "Design Name",
      color: "Color",
      collection: "Collection Name",
    },
    {
      id: "0000-00",
      image: CartProduct,
      design: "Design Name",
      color: "Color",
      collection: "Collection Name",
    },
  ];


  const toggleDropdown = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };


  return (
    <div
      style={{ fontFamily: "Open Sans" }}
      className="flex items-center justify-center"
    >
      <div className="my-[80px] sm:my-[100px] w-[320px] sm:w-[744px] xl:w-[1280px] 2xl:w-[1728px] flex flex-col items-center justify-center gap-[60px]">
        {/* top heading */}
        <div className="w-[320px] h-[38px] sm:w-[744px] sm:h-[44px] xl:w-[1160px] 2xl:w-[1320px] flex items-center justify-center">
          <h1 className="font-bold text-[28px] sm:text-[32px]">Rapid Order</h1>
        </div>
        {/* search table and product table */}
        <div className=" w-[320px] sm:w-[744px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col items-center justify-center xl:flex-row gap-[60px] xl:gap-[40px] xl:items-start">
          {/* search table */}
          <div className="w-[280px] sm:w-[640px] xl:w-[340px] 2xl:w-[500px] flex flex-col items-center gap-[20px]">
            {/* heading */}
            <div className="w-[280px] h-[68px] sm:w-[640px] sm:h-[53px] xl:w-[340px] xl:h-[74px] 2xl:w-[500px] flex flex-col gap-[5px]  text-center sm:text-start">
              <p className="w-[280px] h-[27px] sm:w-[640px] xl:w-[340px] 2xl:w-[500px] font-bold text-[18px] text-black">
                Please enter the SKUs:
              </p>
              <p className="w-[280px] h-[36px] sm:w-[640px] sm:h-[21px] xl:w-[340px] xl:h-[42px] 2xl:w-[500px] text-[12px] sm:text-[14px] text-[#6B6B66]">
                If SKU belongs to more than one collection, please select the
                appropriate collection
              </p>
            </div>

            {/* search table */}
            <div className="w-[280px] sm:w-[640px] xl:w-[340px] 2xl:w-[500px] grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 items-center justify-center gap-[20px]">
              {SKUTable?.map((item, index) => (
                <div key={index} className="col-span-1 flex justify-between">
                  {/* name */}
                  <div className="w-[50px] h-[40px] text-[14px] text-[#9D9C99] flex items-center justify-center">
                    {item?.name}:
                  </div>
                  {/* input field */}
                  <div className="w-[225px] sm:w-[255px] xl:w-[280px] 2xl:w-[440px]">
                    <div className="flex w-[225px] sm:w-[255px] xl:w-[280px] 2xl:w-[440px]">
                      <div className="relative w-[225px] sm:w-[255px] xl:w-[280px] 2xl:w-[440px] bg-white">
                        <label
                          htmlFor="default-search"
                          className="mb-2 text- font-medium text-[#9D9C99] sr-only"
                        >
                          Enter SKU
                        </label>
                        <div className="relative">
                          <div className="absolute inset-y-0 end-0 flex items-center px-3 cursor-pointer">
                            {activeIndex === index ? (
                              <div
                                className="text-[#9D9C99]"
                                onClick={() => toggleDropdown(index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  class="w-5 h-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6 18 18 6M6 6l12 12"
                                  />
                                </svg>
                              </div>
                            ) : (
                              <img
                                src={search}
                                alt="search"
                                onClick={() => toggleDropdown(index)}
                              />
                            )}
                          </div>
                          <input
                            type="search"
                            id={`default-search-${index}`}
                            className="block w-full p-2 text-black border border-[#CECECC] bg-white focus:ring-[#3A3933] focus:border-black placeholder:text-[#9D9C99] text-[14px]"
                            placeholder="Enter SKU"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    {activeIndex === index &&
                      searchResult.map((data, index) => (
                        <div
                          key={index}
                          className="w-[225px] sm:w-[255px] xl:w-[280px] 2xl:w-[440px] h-[81px] bg-white flex items-center justify-center xl:justify-start ps-1 cursor-pointer sm:px-0 xl:px-2 gap-[15px] border hover:bg-[#F0EFEB] border-[#CECECC]"
                        >
                          <img
                            src={data.image}
                            alt="Image"
                            className="w-[65px] h-[65px] object-cover"
                          />
                          <div className="w-[130px] sm:w-[140px] h-[65px] flex flex-col items-center justify-between">
                            <div className="w-[140px] h-[18px] flex items-center justify-start text-[12px]">
                              {data.id}
                            </div>
                            <div className="w-[140px] h-[42px] flex flex-col">
                              <div className="w-[140px] h-[14px] text-[10px] text-[#9D9C99] flex gap-[5px]">
                                <p>Design:</p>
                                <p>{data.design}</p>
                              </div>
                              <div className="w-[140px] h-[14px] text-[10px] text-[#9D9C99] flex gap-[5px]">
                                <p>Color:</p>
                                <p>{data.color}</p>
                              </div>
                              <div className="w-[140px] h-[14px] text-[10px] text-[#9D9C99] flex gap-[5px]">
                                <p>Collection:</p>
                                <p>{data.collection}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
              {/* button */}
              <div className="w-[280px] h-[34px] sm:w-[640px] xl:w-[340px] 2xl:w-[500px] flex justify-end">
                <button className="text-white bg-[#E2A856] rounded text-[12px] uppercase w-[101px] h-[34px] text-center hover:bg-black">
                  ADD TO LIST
                </button>
              </div>
            </div>
          </div>
          {/* product table */}
          <div className="w-[280px] sm:w-[640px] xl:w-[780px] flex flex-col justify-center items-center gap-[20px]">
            {/* table */}
            <div className="w-[280px] sm:w-[640px] xl:w-[780px] flex flex-col items-center justify-center gap-[20px]">
              {cartData.map((data, index) => (
                <div
                  key={index}
                  className="xl:w-[780px] w-[280px] sm:w-[640px] mx-auto"
                >
                  <div className="w-[280px] sm:w-[640px] xl:w-[780px] flex flex-col items-center justify-center border border-[#CECECC]">
                    {/* table */}
                    <table className="w-[240px] sm:w-[600px] xl:w-[740px] mt-5 text-[14px] text-left rtl:text-right text-black flex flex-col">
                      <thead className="text-[12px] text-[#6B6B66] sm:w-[600px] sm:h-[28px] xl:w-[740px] sm:items-center sm:justify-center">
                        <tr className="sm:border-b-2 w-[240px] h-[18px] sm:w-[600px] xl:w-[740px] sm:h-[28px] flex flex-row items-center justify-end sm:justify-normal sm:gap-[10px] font-normal">
                          <th
                            scope="col"
                            className="w-[60px] xl:w-[80px] h-[18px] hidden sm:flex items-center justify-center me-[10px]"
                          >
                            <span className="font-normal">Image</span>
                          </th>
                          <th
                            scope="col"
                            className="w-[180px] xl:w-[200px] h-[18px] hidden sm:flex items-center justify-center font-normal pe-2"
                          >
                            Product Details
                          </th>
                          <th
                            scope="col"
                            className="w-[60px] xl:w-[80px] h-[18px] hidden sm:flex items-center font-normal ps-2"
                          >
                            Price
                          </th>
                          <th
                            scope="col"
                            className="w-[60px] xl:w-[80px] h-[18px] hidden sm:flex items-center font-normal justify-center pe-2"
                          >
                            Qty
                          </th>
                          <th
                            scope="col"
                            className="w-[60px] xl:w-[80px] h-[18px] hidden sm:flex items-center font-normal justify-center"
                          >
                            Discount
                          </th>
                          <th
                            scope="col"
                            className="w-[60px] xl:w-[80px] h-[18px] hidden sm:flex items-center font-normal justify-center"
                          >
                            Subtotal
                          </th>
                          <th
                            scope="col"
                            className="flex w-[62px] sm:w-[60px] xl:w-[80px] h-[18px] underline sm:text-center text-nowrap items-center justify-center text-black font-normal text-[12px]"
                          >
                            Remove All
                          </th>
                        </tr>
                      </thead>
                      <tbody className="mt-5 border-[#CECECC]">
                        {data?.items.map((tdata, index2) => (
                          <tr
                            key={index2}
                            className="border-b bg-white w-[240px] h-[134px] sm:w-[600px] sm:h-[70px] xl:w-[740px] xl:h-[90px] flex gap-4 mb-[11px] sm:items-start sm:gap-[10px]"
                          >
                            {/* mobile col 1 */}
                            {/* image for all devices*/}
                            <div className="flex flex-col sm:flex-row w-[94px] h-[124px] sm:w-[60px] xl:w-[80px] sm:h-[60px]">
                              <span className="">
                                <img
                                  // src={CartProduct}
                                  src={tdata.img}
                                  className="w-[94px] h-[94px] sm:w-[60px] sm:h-[60px] xl:w-[80px] xl:h-[80px] "
                                  alt="Product"
                                />
                              </span>
                              {/* + - buttton for mobile only*/}
                              <span className="h-[30px] mt-[10px] justify-center flex items-center sm:hidden">
                                <div className="flex w-[60px] h-[20px]">
                                  <form className="max-w-xs mx-auto">
                                    <label
                                      htmlFor="quantity-input"
                                      className="block text-[14px] font-medium text-black"
                                    ></label>
                                    <div className="relative flex items-center max-w-[8rem]">
                                      <button
                                        type="button"
                                        id="decrement-button"
                                        data-input-counter-decrement="quantity-input"
                                        className="bg-white border border-[#CECECC] rounded-s p-1 h-5 cursor-pointer text-black hover:bg-black hover:text-white"
                                      >
                                        <svg
                                          className="w-2 h-2 text-black hover:bg-black hover:text-white cursor-pointer"
                                          aria-hidden="true"
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 18 2"
                                        >
                                          <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M1 1h16"
                                          />
                                        </svg>
                                      </button>
                                      <input
                                        type="text"
                                        id="quantity-input"
                                        data-input-counter
                                        aria-describedby="helper-text-explanation"
                                        className="border-y placeholder:text-center placeholder:text-black border-[#CECECC] h-5 w-6 sm:w-6 text-[12px] text-center"
                                        placeholder={tdata.qty}
                                      />
                                      <button
                                        type="button"
                                        id="increment-button"
                                        data-input-counter-increment="quantity-input"
                                        className="bg-white border border-[#CECECC] rounded-e p-1 h-5 text-black hover:bg-black hover:text-white"
                                      >
                                        <svg
                                          className="w-2 h-2 text-black hover:bg-black hover:text-white"
                                          aria-hidden="true"
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 18 18"
                                        >
                                          <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M9 1v16M1 9h16"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </span>
                            </div>
                            {/* mobile col 2 */}
                            <div className="w-[131px] h-[123px] sm:w-[420px] sm:h-[70px] flex">
                              <div className="flex flex-col sm:flex-row">
                                {/* col 2-1 */}
                                <span className="w-[131px] h-[75px] sm:w-[180px] sm:h-[60px] xl:w-[200px] xl:h-[65px] text-[10px]">
                                  {/* product detail for all and delete button for mobile only */}
                                  {tdata?.productDetail.map(
                                    (detail, index3) => (
                                      <div
                                        key={index3}
                                        className="h-[65px] sm:w-[180px] sm:h-[60px] xl:w-[200px] xl:h-[65px] sm:flex sm:flex-col sm:items-center sm:justify-start flex flex-col justify-between gap-[5px]"
                                      >
                                        <div className="flex items-center justify-between">
                                          <p className="w-[131px] h-[18px] text-[12px] text-black">
                                            {detail.id}
                                          </p>
                                          <div className="flex sm:hidden">
                                            <span className="text-[#9D9C99] hover:text-black">
                                              <img
                                                src={DeleteIcon}
                                                className="w-[10px] h-[11.7px] text-[#9D9C99] hover:text-black"
                                              ></img>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="flex flex-col text-[#6B6B66]">
                                          <p className="">
                                            Design :{" "}
                                            <span className="ms-[2px] sm:ms-0">
                                              {detail.design}
                                            </span>
                                          </p>
                                          <p>
                                            Color :{" "}
                                            <span className="ms-[2px] sm:ms-0">
                                              {detail.color}
                                            </span>
                                          </p>
                                          <p>
                                            Collection :
                                            <span className="">
                                              {detail.collection}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </span>
                                <div className="flex flex-col sm:flex-row leading-[14px] sm:leading-4 text-gray-500">
                                  {/* price */}
                                  <div className="text-[10px] sm:w-[60px] xl:w-[80px] sm:h-[30px] flex sm:flex-col sm:items-center sm:justify-center sm:text-center mb-[1px] sm:mb-0">
                                    <div className="sm:hidden text-[#6B6B66]">
                                      Price:
                                    </div>
                                    <div className="sm:flex sm:flex-col">
                                      <span className="text-black text-[10px] sm:text-[12px] ms-1 sm:ms-0">
                                        ${tdata.price}
                                      </span>
                                      <span className="text-[10px] sm:text-[8px] text-[#6B6B66]">
                                        / Meter(s)
                                      </span>
                                    </div>
                                  </div>
                                  {/* + - buttton for desktop and tab only*/}
                                  <span className="h-[30px] justify-center sm:flex hidden sm:ms-[20px]">
                                    <div className="flex w-[60px] xl:w-[80px] h-[20px] items-start">
                                      <form className="max-w-xs mx-auto">
                                        <label
                                          htmlFor="quantity-input"
                                          className="block text-[14px] font-medium text-[#6B6B66]"
                                        ></label>
                                        <div className="relative flex items-center sm:items-start max-w-[8rem]">
                                          <button
                                            type="button"
                                            id="decrement-button"
                                            data-input-counter-decrement="quantity-input"
                                            className="bg-white border border-[#CECECC] rounded-s p-1 h-5 hover:bg-black hover:text-white group"
                                          >
                                            <svg
                                              className="w-2 h-2 text-black group-hover:bg-black group-hover:text-white"
                                              aria-hidden="true"
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 18 2"
                                            >
                                              <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M1 1h16"
                                              />
                                            </svg>
                                          </button>
                                          <input
                                            type="text"
                                            id="quantity-input"
                                            data-input-counter
                                            aria-describedby="helper-text-explanation"
                                            className="border-y placeholder:text-center placeholder:text-black border-[#CECECC] h-5 w-6 sm:w-6 text-[12px] sm:text-[10px] text-center text-black"
                                            placeholder={tdata.qty}
                                          />
                                          <button
                                            type="button"
                                            id="increment-button"
                                            data-input-counter-increment="quantity-input"
                                            className="bg-white border border-[#CECECC] rounded-e p-1 h-5 hover:bg-black hover:text-white group"
                                          >
                                            <svg
                                              className="w-2 h-2 text-black group-hover:bg-black group-hover:text-white"
                                              aria-hidden="true"
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 18 18"
                                            >
                                              <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M9 1v16M1 9h16"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                      </form>
                                    </div>
                                  </span>
                                  {/* discount */}
                                  <span className="text-[10px] sm:w-[60px] xl:w-[80px] sm:h-[20px] sm:flex sm:items-center sm:justify-center sm:mx-[10px]">
                                    <span className="sm:hidden text-[#6B6B66]">
                                      Discount:
                                    </span>
                                    <span className="text-black sm:text-[12px] ms-1 sm:ms-0">
                                      {tdata.discount}
                                    </span>
                                  </span>

                                  {/* subtotal */}
                                  <span className="text-[10px] mt-[10px] sm:mt-0 sm:w-[60px] xl:w-[80px] sm:h-[18px] sm:flex sm:justify-center sm:mx-[10px] sm:me-5 sm:pe-3">
                                    <span className="sm:hidden text-[#6B6B66]">
                                      Subtotal:{" "}
                                    </span>
                                    <span className="text-black font-semibold text-[10px] sm:text-[12px] ms-1 sm:ms-0">
                                      ${tdata.subTotal}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              {/* desktop view delete icon */}
                              <div className=" w-[60px] xl:w-[80px] h-[18px] sm:w-[60px] sm:h-[18px] hidden sm:flex sm:items-center sm:justify-center xl:ps-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-[13px] h-[13px] text-[#9D9C99] hover:text-black"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                  />
                                </svg>
                              </div>
                            </div>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
            {/* button */}
            <div className="w-[280px] h-[34px] sm:w-[640px] xl:w-[780px] flex justify-end">
              <button className="text-white bg-[#E2A856] rounded text-[12px] uppercase w-[101px] h-[34px] text-center hover:bg-black">
                ADD TO CART
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RapidOrder;
