import React, { useContext, useEffect } from 'react'
import { useState } from "react";
import { getModelRoute, showMessage } from "../../helpers/Utils";
import { Link, useParams, useSearchParams } from "react-router-dom";
import ApiService from "../../helpers/ApiServices";
import moment from "moment";
import { CustomerContext } from '../../states/contexts/CustomerContext';
import { UserContext } from '../../states/contexts/UserContext';
import constants from "../../helpers/Constants";

const CollectionGrid = ({ collections, category, FilterDataFromFiltration, page, sortKey, sortValue, setCollections }) => {

    const [searchParams, setSearchParams] = useSearchParams()
    const [isCollectionLoading, setIsCollectionLoading] = useState(false);
    const { dispatch, user } = useContext(UserContext)
    const { dispatch: customerDispatch, customer } = useContext(CustomerContext)
    const { id } = useParams();


    let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"
    let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))

    const getAllCollectionsByCategoryId = (id) => {
        setIsCollectionLoading(true)
        let isLoggedInUser
        if (customers != null || customer) {
            isLoggedInUser = true
        } else {
            isLoggedInUser = false
        }

        ApiService.setHeader()
        ApiService.get(`shop/collection/collections/${id}?protectModel=${getModelRoute(user).model}&brandType=${division}&isLoggedInUser=${isLoggedInUser}&filters=${encodeURI(JSON.stringify(FilterDataFromFiltration))}&page=${page}&limit=${constants.ITEMS_PER_PAGE}&sortKey=${sortKey}&sortValue=${sortValue}`).
            then(response => {
                if (response?.data.isSuccess) {
                    setIsCollectionLoading(false)
                    setCollections(response?.data)
                }
            }).catch(error => {
                setIsCollectionLoading(false)
                showMessage(error.response.data.message, "error")
            })
    }

    useEffect(() => {
        getAllCollectionsByCategoryId(id)
    }, [id, FilterDataFromFiltration, sortKey, sortValue])


    const getDateToDisplayInStoreOrAvailableFrom = (date, isArchived) => {

        const argDate = new Date(date);

        const currentDate = new Date();

        const argYear = argDate.getFullYear();
        const argMonth = argDate.getMonth() + 1;

        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;

        if (isArchived !== undefined && isArchived) {
            return `Archived from ${moment(argDate).format('MMM YYYY')}`;
        }
        if (currentYear === argYear && currentMonth === argMonth) {
            return `Available Now`;
        } else if (currentYear < argYear || (currentYear === argYear && currentMonth < argMonth)) {
            return `In stores in ${moment(argDate).format('MMM YYYY')}`;
        } else {
            return `Available Now`;
        }
    }


    return (
        <div className="col-span-3 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 md:gap-12">

            {!isCollectionLoading ? (collections.documents?.length ? collections.documents?.map((collection, index) => {
                return (
                    <div
                        key={index}
                        className="cursor-pointer w-[130px] h-[261px] lg:w-[180px] lg:h-[339px]"
                    >
                        <Link to={`/product-detail/${collection?._id}?division=${division}&categoryId=${category?.id}&categoryName=${category?.name}`}>
                            <img
                                src={collection?.imageName ? `${ApiService.getBaseImagePath()}collections/${collection?.imageName} ` : `${ApiService.getBaseImagePath()}collections/${collection?.name}.jpg`}
                                alt={collection?.imageName}
                                className="w-60 h-auto"
                            />
                            <div className="w-[130px] h-[121px] lg:w-[180px] lg:h-[149px] border">
                                <div className="mt-2 lg:mt-[25px] px-2 flex flex-col items-start justify-start">
                                    {" "}
                                    <h4 className="text-gray-400 text-[10px] lg:text-xs text-start text-nowrap w-[160px] break-words">
                                        {collection?.designer?.length ? `by ${collection?.designer[0]?.text} ` : ""}
                                    </h4>
                                    <h2 className="mt-[10px] w-[160px] tracking-tighter h-[50px] lg:h-[60px] font-semibold text-sm lg:text-base text-start text-wrap text-ellipsis overflow-hidden">
                                        {collection?.name}
                                    </h2>
                                    <h4 className="text-gray-400 text-[10px] lg:text-xs w-[103px] sm:w-[160px] h-[15px] text-nowrap text-start">
                                        {collection?.date && getDateToDisplayInStoreOrAvailableFrom(collection?.date, collection?.isArchive)}
                                    </h4>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
            }) : "") : <div className='flex text-black text-[20px] font-bold'>
                Loading ...
            </div>}
        </div>
    )
}

export default CollectionGrid