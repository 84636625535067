import React, { useContext, useState } from "react";
import { BrandIconContext } from "../../states/contexts/BrandIconContext";
import { UserContext } from "../../states/contexts/UserContext";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams, useParams } from "react-router-dom";
import ApiService from "../../helpers/ApiServices";
import { getModelRoute, showMessage } from "../../helpers/Utils";
import { useAlert } from 'react-alert'



const ChangePassword = () => {
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const { backgroundColor, color, darkBackgroundColor } = useContext(BrandIconContext)
  const { user } = useContext(UserContext)


  const { register, control, reset, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams()
  const alert = useAlert()

  let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"

  const onSubmit = (formData) => {

    setShowError(false);
    return updatePasswordHander(formData);
  }

  const updatePasswordHander = (data) => {

    if (data.password == data.passwordConfirm) {
      if (id) {
        setIsLoading(true)
        ApiService.patch(`shop/user/updateCustomerPassword/${id}`, data).then(response => {

          if (response.data.isSuccess) {
            setIsLoading(false)
            showMessage("Password update successfull.", "success")
            alert.success("Password update successfull.")
            navigate('/customer-profile')
          } else {
            setIsLoading(false)
            showMessage(response?.data.message, "error")
          }

        }).catch(error => {
          setIsLoading(false)
          console.log(error.response.data)
          showMessage(error.response.data.message, "error")
          alert.error(error.response?.data?.message)

        })

      } else {
        setIsLoading(true)
        ApiService.patch(`shop/user/updatePassword?protectModel=${getModelRoute(user).model}`, data).then(response => {
          console.log(response);

          if (response.data.isSuccess) {
            setIsLoading(false)
            showMessage("Password update successfull.", "success")
            alert.success("Password update successfull.")
            navigate('/profile')
          } else {
            setIsLoading(false)
            showMessage(response?.data.message, "error")
          }

        }).catch(er => {
          setShowError(true);
          showMessage(er.response.data.message, "error")
          alert.error(er.response?.data?.message)
        })
      }
    } else {
      setIsLoading(false)
      showMessage("Passwords are not the same!", "warning")
      alert.info("Passwords are not the same!")
    }
  }


  return (
    <div
      style={{ fontFamily: "Open Sans" }}
      className="flex items-center justify-center"
    >
      <div className="w-[320px] sm:w-[744px] xl:w-[1280px] flex items-center justify-center">
        <div className="my-[80px] sm:my-[100px] w-[280px] sm:w-[624px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col items-center xl:flex-row xl:items-start gap-[60px] 2xl:gap-[100px]">
          {/* left side */}
          <div className="w-[280px] h-[258px] sm:w-[624px] xl:w-[280px] xl:h-[258px] flex flex-col gap-5">
            {/* heading */}
            <div className="w-[280px] h-[38px] sm:w-[624px] xl:w-[280px] text-center sm:text-start font-bold text-[28px]">
              Customer Profile
            </div>
            {/* horizontal line */}
            <hr className=" text-gray-500 bg-gray-500 h-1 w-[280px] sm:w-[624px] xl:w-[280px]" />
            {/* content */}
            <div className="w-[280px] h-[180px] flex flex-col sm:w-[624px] xl:w-[280px] bg-white">
              <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] text-[14px] xl:text-[14px] text-center flex items-center cursor-pointer">
                <Link to={`/customer-profile?division=${division}`}>
                  <p className="ms-[15px]">Personal Info</p>
                </Link>
              </div>
              <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] text-[14px] text-center flex items-center cursor-pointer">
                <Link to={`/customer-profile/shippingAddress?division=${division}`}>
                  <p className="ms-[15px]">Shipping Addresses</p>
                </Link>
              </div>
              <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] bg-[#F0EFEB] text-[14px] text-center flex items-center cursor-pointer">
                <Link to={"/product-collection/profileChangePassword"}>
                  <p className="ms-[15px]">Change Password</p>
                </Link>
              </div>
              <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] text-[14px] text-center flex items-center cursor-pointer">
                <Link to={"/"}>
                  <p className="ms-[15px]"> Communication Preferences</p>
                </Link>
              </div>
            </div>
          </div>

          {/* right side */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-[280px] h-[625px] sm:w-[624px] xl:w-[820px] 2xl:w-[940px] flex flex-col gap-[20px]">
              {/* Change Password */}
              <div className="w-[280px] h-[625px] sm:w-[624px] sm:h-[181px]  xl:w-[820px] xl:h-[181px] flex flex-col gap-5">
                {/* heading */}
                <div className="w-[280px] h-[38px] font-bold text-[28px] text-center sm:text-start">
                  Change Password
                </div>
                {/* horizontal line */}
                <hr className=" text-gray-500 bg-gray-500 h-[1px] w-[280px] sm:w-[624px] xl:w-[820px] 2xl:w-[940px]" />

                {/* content */}
                <div className="w-[280px] h-[547px] flex flex-col sm:w-[624px] sm:h-[347px] xl:w-[820px] gap-[30px]">
                  {/* email */}
                  <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                    <label
                      htmlFor="email"
                      className="block text-[14px] font-bold text-gray-700 w-[203px] h-[21px] text-nowrap"
                    >
                      Email
                    </label>
                    <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        className="appearance-none relative block px-3 py-1 border border-gray-300 bg-[#CECECC] placeholder-[#9D9C99] text-[#090800]  focus:outline-non focus:z-10 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                        placeholder="christina.pallotta@northcott.com"
                      />
                    </div>
                  </div>
                  {/* Current password */}
                  {id ? "" : <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                    <label
                      htmlFor="password"
                      className="block text-[14px] font-bold text-gray-700 w-[203px] h-[21px] text-nowrap"
                    >
                      Current Password
                    </label>
                    <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                      <input
                        id="passwordCurrent"
                        name="passwordCurrent"
                        type="password"
                        {...register("passwordCurrent")}
                        className="appearance-none relative block px-3 py-1 border border-gray-300 placeholder-[#9D9C99] text-[#090800]  focus:outline-non focus:z-10 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                        placeholder="Enter your current password"
                      />
                    </div>
                  </div>}
                  {/* password */}
                  <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                    <label
                      htmlFor="password"
                      className="block text-[14px] font-bold text-gray-700 w-[203px] h-[21px] text-nowrap"
                    >
                      Password
                    </label>
                    <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        {...register(`password`)}
                        className="appearance-none relative block px-3 py-1 border border-gray-300 placeholder-[#9D9C99] text-[#090800]  focus:outline-non focus:z-10 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                        placeholder="Enter your new password"
                      />
                    </div>
                  </div>
                  {/* re enter password */}
                  <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                    <label
                      htmlFor="reEnterEMail"
                      className="block text-[14px] font-bold text-gray-700 sm:w-[280px] sm:h-[21px] text-nowrap"
                    >
                      Re-enter Your New Password
                    </label>
                    <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                      <input
                        id="reEnterPassword"
                        name="reEnterPassword"
                        type="password"
                        {...register("passwordConfirm")}
                        className="appearance-none relative block px-3 py-1 border border-gray-300 placeholder-[#9D9C99] text-[#090800]  focus:outline-non focus:z-10 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                        placeholder="Re-enter your new password"
                      />
                    </div>
                  </div>

                  {/* buttons */}
                  <div className="h-[49px] flex items-center justify-center sm:justify-start">
                    <div className="w-[205px] h-[49px] flex items-center justify-center sm:justify-start">
                      <button
                        disabled={isLoading}
                        className={`w-[100px] h-[40px] rounded text-center text-[16px] font-semibold text-[#FFFFFF] hover:bg-black ${isLoading ? "bg-[#EECB9A]" : "bg-[#E2A856] cursor-pointer"} `}
                        type="submit"
                      >
                        {isLoading ? "loading..." : "UPDATE"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
