import topBanner from "../../assets/Designer/DesignerTopBanner.svg";
import BecomeADesigner from "../../assets/Designer/BecomeADesigner.svg";
import Default from "../../assets/Designer/Default.png";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ApiService from "../../helpers/ApiServices";

const DesignerList = () => {
  const [designersAlldata, setDesignersAllData] = useState([]);
  const [designerdataByDesign, setDesignerdataByDesign] = useState([]);
  const [buttonclick, setButtonClick] = useState("COLLECTION DESIGNER");
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  let division = searchParams.get('division')?.toUpperCase() || "NORTHOCOTT";


  const getDesignerList = () => {
    setLoading(true)
    ApiService.setHeader();
    ApiService.get(`shop/designer/getAll?division=${division}`)
      .then((response) => {
        if (response?.data.isSuccess) {
          setLoading(false)
          setDesignersAllData(response?.data?.documents)
          buttonclicked("COLLECTION DESIGNER", response?.data?.documents)
        }
      })
      .catch((error) => {
        setLoading(false)
      });
  };


  const buttonclicked = (buttonType, data = []) => {
    setLoading(true)
    if (buttonType == "PATTERN DESIGNER") {
      setButtonClick("PATTERN DESIGNER")
    } else if (buttonType == "COLLECTION DESIGNER") {
      setButtonClick("COLLECTION DESIGNER")
    }
    let designerDataResponse = data.length > 0 ? data : designersAlldata
    let designersData = designerDataResponse?.find(element => element._id === buttonType)
    setDesignerdataByDesign(designersData?.designers)
    setLoading(false)
  }


  useEffect(() => {
    getDesignerList()
  }, [division])


  return (
    <div
      className="mb-[100px] flex flex-col items-center justify-center"
      style={{ fontFamily: "Open Sans" }}
    >
      {/* row 1 top banner */}
      <div className="w-full">
        <img
          src={topBanner}
          alt="Image"
          className="object-cover w-full h-[235px] sm:h-[257px] lg:h-[288px]"
        />
      </div>
      {/* row 2 content */}
      <div className="w-full flex flex-col items-center justify-center gap-[60px] sm:gap-[100px] mt-[60px] sm:mt-[80px] xl:mt-[100px]">
        {/* row 2.1 top heading , buttons, designer name and photo*/}
        <div className="w-[280px] sm:w-[624px] xl:w-[1160px] 2xl:w-[1200px] flex flex-col items-center justify-center gap-[60px]">
          {/* top heading buttons */}
          <div className="w-[280px] h-[190px] sm:w-[624px] sm:h-[101px] xl:w-[1160px] flex flex-col items-center justify-between">
            {/* top heading */}
            {division == "NORTHCOTT" && <div className="w-[280px] h-[76px] sm:w-[325px] sm:h-[44px] font-bold text-[28px] sm:text-[32px] text-center text-nowrap">
              Northcott Designers
            </div>}
            {division == "PATRICK LOSE" && <div className="w-[280px] h-[76px] sm:w-[500px] sm:h-[44px] font-bold text-[28px] sm:text-[32px] text-center text-nowrap">
              Patrick Lose Designers
            </div>}
            {division == "BANYAN BATIKS" && <div className="w-[280px] h-[76px] sm:w-[500px] sm:h-[44px] font-bold text-[28px] sm:text-[32px] text-center text-nowrap">
              Banyan Batiks Designers
            </div>}
            {/* buttons */}
            <div className="w-[280px] h-[94px] sm:w-[302px] sm:h-[37px] flex flex-col sm:flex-row items-center justify-between">
              <button
                type="button"
                className={`font-medium rounded-full text-[14px] text-center w-[146px] h-[37px] ${buttonclick == "COLLECTION DESIGNER" ? "bg-black border-0 text-white" : "text-[#9D9C99] bg-white border-2 border-[#9D9C99]"}`}
                onClick={() => buttonclicked("COLLECTION DESIGNER", [])}
              >
                Fabric Designers
              </button>
              <button
                type="button"
                className={`font-medium rounded-full text-[14px] text-center w-[146px] h-[37px] ${buttonclick == "PATTERN DESIGNER" ? "bg-black border-0 text-white" : "text-[#9D9C99] bg-white border-2 border-[#9D9C99]"}`}

                onClick={() => buttonclicked("PATTERN DESIGNER", [])}
              >
                Pattern Designers
              </button>
            </div>
          </div>
          {/*designer name and photo */}
          {!loading ? <div className="w-[270px] sm:w-[624px] xl:w-[900px] grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 grid-flow-row gap-x-[25px] gap-y-[60px] sm:gap-x-[42px] xl:gap-x-[60px] justify-items-center">
            {designerdataByDesign?.length ? designerdataByDesign?.map((data, index) => {
              return (
                <Link
                  to={`/designer-profile/${data._id}?division=${division}`}
                  className="cursor-pointer"
                  key={buttonclick + index}
                >
                  <div
                    className="w-[125px] h-[153px] sm:w-[180px] sm:h-[214px] flex flex-col items-center justify-between"
                    key={buttonclick + index}
                  >
                    <div
                      className={`w-[125px] h-[125px] sm:w-[180px] sm:h-[180px] rounded-full`}
                      style={{ backgroundImage: `url(${Default})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                    >
                      <img
                        src={`${ApiService.getBaseImagePath()}designers/${data?.imageName}`}
                        alt="D"
                        className="w-[125px] h-[125px] sm:w-[180px] sm:h-[180px] rounded-full"
                        key={buttonclick + index}
                      />
                    </div>
                    <p className="text-black text-[12px] text-center sm:text-[16px] font-bold h-[18px] sm:h-[24px]">
                      {data?.name}
                    </p>
                  </div>
                </Link>
              )
            }) : ""}
          </div>
            :
            <div className="text-black text-[20px] flex items-center justify-center">
              Loading ...
            </div>
          }
        </div>
        {/* row 2.2 horizontal line */}
        <div className="text-[#CECECC] bg-[#CECECC] h-[1px] w-[280px] sm:w-[640px] xl:w-[1160px] 2xl:w-[1200px]"></div>
        {/* row 2.3 become a designer */}

        <div className="w-[280px] sm:w-[640px] xl:w-[1160px] 2xl:w-[1200px] flex flex-col xl:flex-row items-center justify-center gap-y-[40px] sm:gap-y-[80px] xl:gap-y-0 xl:gap-x-[59px] 2xl:gap-x-[99px] gap-x-0">
          <img
            src={BecomeADesigner}
            alt="Designer"
            className="w-[280px] h-[280px] sm:w-[500px] sm:h-[500px]"
          />
          <div className="w-[280px] sm:w-[640px] xl:w-[1160px] 2xl:w-[1200px] flex flex-col items-center justify-center gap-y-[20px] xl:items-start">
            <h1 className="font-bold text-[28px] sm:text-[32px] text-black text-center">
              Become a Northcott Designer!
            </h1>
            <p className="text-black text-[14px] sm:text-[16px] text-center xl:text-start">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
            <Link to={`/designers-list/become-a-northcott-designer?division=${division}`}>
              <button
                type="button"
                className={`text-white bg-[#E2A856] rounded-[4px] hover:bg-black hover:text-white text-[16px] text-center w-[256px] h-[49px] font-semibold uppercase mt-[20px]`}
              >
                Submission Guidelines
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div >
  );
};

export default DesignerList;