import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import constants from "../../helpers/Constants";
export default function Pagination({ page, setPage, handlePage, totalItems }) {

  const totalPages = Math.ceil(totalItems / constants.ITEMS_PER_PAGE);

  return (
    <div className=" bg-white px-4 py-3 sm:px-6 mt-[60px]">
      <div className="flex flex-1 items-center justify-center">
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-sm"
            aria-label="Pagination"
          >
            <div
              className="relative inline-flex items-center rounded-l-sm px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:text-white hover:bg-black focus:z-20 focus:outline-offset-0 cursor-pointer"
              onClick={(e) => handlePage(page > 1 ? page - 1 : page)}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </div>
            {Array.from({ length: totalPages }).map((el, index) => {
              return (
                <div
                  key={index}
                  onClick={(e) => handlePage(index + 1)}
                  className={`${index + 1 === page
                    ? 'font-bold'
                    : 'font-normal'} w-[50px] h-[44px] relative inline-flex items-center px-4 py-2 text-sm text-black cursor-pointer focus:z-20`}
                >
                  {index + 1}
                </div>
              )
            })}
            <div
              className="relative inline-flex items-center rounded-r-sm px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:text-white hover:bg-black focus:z-20 focus:outline-offset-0 cursor-pointer"
              onClick={(e) => handlePage(page < totalPages ? page + 1 : page)}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </div>
          </nav>
        </div>
      </div >
    </div >
  );
}
