
import { Fragment, useContext, useState } from "react";
import TopHeader from "./TopHeader";
import { UserContext } from "../../../states/contexts/UserContext";
import { CustomerContext } from "../../../states/contexts/CustomerContext";
import { BrandIconContext } from "../../../states/contexts/BrandIconContext";

export default function AppHeader() {
  const [open, setOpen] = useState(false);

  const { dispatch, user } = useContext(UserContext);
  const { dispatch: customerDispatch, customer } = useContext(CustomerContext);
  const { dispatch: iconDispatch, ...rest } = useContext(BrandIconContext)

  return (
    <TopHeader />
  );
}
