export const links = [
  {
    name: "Home",
    isVisible: true,
    submenu: false,
  },
  {
    name: "About",
    isVisible: true,
    submenu: true,
    sublinks: [
      {
        Head: "",
        sublink: [
          {
            name: "About Us",
            href: "/about-us",
          },
          {
            name: "Wholesale Contacts",
            href: "/wholesale-contacts",
          },
          {
            name: "Charities We Support",
            href: "/charities-we-support",
          },
          {
            name: "Calendar",
            href: "/calendar",
          },
          {
            name: "Contact Us",
            href: "contact-us",
          },
        ],
      },
    ],
  },
  {
    name: "Products",
    isVisible: true,
    submenu: true,
    sublinks: [
      {
        Head: "Categories",
        sublink: [],
      },
      {
        Head: "a",
        sublink: [],
      },
      {
        Head: "Brands to Know",
        sublink: [
          { name: "Banyan Batiks", href: "/coming-soon" },
          { name: "Patrick Lose", href: "/coming-soon" },
          { name: "FIGO", href: "/coming-soon" },
        ],
      },
    ],
  },
  {
    name: "LookBook",
    isVisible: true,
    submenu: false,
    href: "/look-book",

  },
  {
    name: "Patterns",
    isVisible: true,
    submenu: false,
    href: "/coming-soon",
  },
  {
    name: "Designers",
    isVisible: true,
    submenu: false,
    href: "/designers-list",
  },
  {
    name: "Resources",
    isVisible: true,
    submenu: true,
    sublinks: [
      {
        Head: "",
        sublink: [
          {
            name: "Free Patterns",
            href: "/coming-soon",
          },
          {
            name: "Get Inspired!",
            href: "/coming-soon",
          },
          {
            name: "Videos",
            href: "/coming-soon",
          },
          {
            name: "Quilting Tips",
            href: "/coming-soon",
          },
          {
            name: "Advertisements",
            href: "/coming-soon",
          },
          {
            name: "Editorial features",
            href: "/coming-soon",
          },
          {
            name: "News",
            href: "/coming-soon",
          },
        ],
      },
    ],
  },
  {
    name: "Customer",
    isVisible: false,
    submenu: true,
    sublinks: [
      {
        Head: "",
        sublink: [
          {
            name: "Personal Info",
            href: "/customer-profile",
          },
          {
            name: "Order History",
            href: "/my-orders",
          },
          {
            name: "Invoice History",
            href: "/coming-soon",
          },
          {
            name: "Statement",
            href: "/coming-soon",
          },
          {
            name: "Dashboard",
            href: "/coming-soon",
          },
        ],
      },
    ],
  },
  {
    name: "Rapid Order",
    isVisible: true,
    submenu: false,
    href: "/coming-soon",
  }
];

