import { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
    MinusIcon,
    PlusIcon,
} from "@heroicons/react/20/solid";
import ApiService from "../../helpers/ApiServices";
import { showMessage } from "../../helpers/Utils";
import { useParams, useSearchParams, } from "react-router-dom";


const Filteration = ({ collectionsAfterFilters }) => {

    const [searchParams, setSearchParams] = useSearchParams()
    const [FilterOptions, setFilterOptions] = useState({});
    const [selectedFilters, setSelectedFilters] = useState([]);
    const { id } = useParams();
    let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"


    const getAllFilterOptions = (categoryId) => {

        ApiService.setHeader()
        ApiService.get(`shop/collection/feature/collections?categoryId=${categoryId}&brandType=${division}`).then(response => {
            if (response?.data?.isSuccess) {
                setFilterOptions(response?.data?.documents)
            }
        }).catch(error => {
            showMessage(error.response.data.message, "error")
        })
    }


    const clearAllFilters = () => {
        setSelectedFilters([]);
        // Uncheck all checkboxes
        FilterOptions.forEach((section) => {
            section.options.forEach((option) => {
                const checkbox = document.getElementById(
                    `filter - ${option?._id}`
                );
                if (checkbox) {
                    checkbox.checked = false;
                }
            });
        });
    };

    const handleFilterSelection = (sectionId, optionValue) => {
        const updatedFilters = [...selectedFilters];
        const index = updatedFilters.findIndex(
            (filter) => filter.sectionId === sectionId
        );

        if (index === -1) {
            updatedFilters.push({ sectionId, optionValues: [optionValue] });
        } else {
            const options = updatedFilters[index].optionValues;
            const optionIndex = options.indexOf(optionValue);
            if (optionIndex === -1) {
                options.push(optionValue);
            } else {
                options.splice(optionIndex, 1);
            }
        }
        setSelectedFilters(updatedFilters);
        collectionsAfterFilters(updatedFilters)
    };


    const removeOptionValue = (sectionId, optionValue) => {
        const updatedFilters = selectedFilters.map((filter) => {
            if (filter.sectionId === sectionId) {
                filter.optionValues = filter.optionValues.filter(
                    (value) => value !== optionValue
                );
            }
            return filter;
        });

        setSelectedFilters(updatedFilters);

        // Uncheck the corresponding checkbox
        const checkbox = document.getElementById(
            `filter - ${optionValue}`
        );
        if (checkbox) {
            checkbox.checked = false;
        }
    };


    useEffect(() => {
        getAllFilterOptions(id)
    }, [id])

    return (
        <div>

            {/* Filters */}
            < form className="hidden md:block col-span-1" >
                <h3 className="sr-only text-[14px]">Categories</h3>
                {/* Clear All button Desktop*/}
                {/* {
                    selectedFilters.length >= 0 && (
                        <div className="px-1 pb-[25px] flex justify-between flex-col xl:flex-row gap-[10px] xl:gap-0 mt-[40px]">
                            <h3 className="text-[18px] sm:text-[16px] xl:text-[18px] font-bold text-nowrap">
                                Selected Filters
                            </h3>

                            <button
                                type="button"
                                onClick={() => clearAllFilters()}
                                className="text-[14px] text-gray-500 hover:text-gray-700 underline text-nowrap text-start xl:text-center"
                            >
                                Clear all
                            </button>
                        </div>
                    )
                }
                {
                    selectedFilters.map((filter, index) => (
                        <ul key={index} className="space-y-2 text-[14px] font-medium text-black border-b-[1px] border-[#CECECC]">
                            <li
                                className={`flex flex-wrap gap-y-[10px] mb-[10px] gap-[10px]`}
                            >
                                {filter.optionValues.map((optionValue, index) => (
                                    <div
                                        key={index + 1000}
                                        className="flex items-center justify-between bg-[#F0EFEB] text-black cursor-pointer hover:bg-[#CECECC] p-[8px] m-[2px] gap-[10px]"
                                        style={{
                                            flexBasis: "calc(50% - 20px)",
                                        }}
                                    >
                                        <span className="mr-1 text-center">{optionValue}</span>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                removeOptionValue(filter.sectionId, optionValue)
                                            }
                                        >
                                            <XMarkIcon className="h-4 w-4 text-black" />
                                        </button>
                                    </div>
                                ))}
                            </li>
                        </ul>
                    ))
                } */}

                {
                    FilterOptions.length && FilterOptions?.map((section, index) => (
                        <Disclosure
                            as="div"
                            key={index}
                            className="border-b-[1px] border-[#CECECC] py-6"
                        >
                            {({ open }) => (
                                <>
                                    <h3 className="-my-3 flow-root">
                                        <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-[14px] text-black">

                                            <span className="font-bold text-[#090800] sm:text-[16px] xl:text-[18px] text-start">
                                                {section?.name}
                                            </span>
                                            <span className="ml-6 flex items-center">
                                                {open ? (
                                                    <MinusIcon
                                                        className="w-[22px] h-[18px] font-bold"
                                                        aria-hidden="true"
                                                    />
                                                ) : (
                                                    <PlusIcon
                                                        className="w-[22px] h-[18px] font-bold"
                                                        aria-hidden="true"
                                                    />
                                                )}
                                            </span>
                                        </Disclosure.Button>
                                    </h3>
                                    <Disclosure.Panel className="pt-6">
                                        <div className="space-y-4">
                                            {section?.options.map((option, index) => (
                                                option?._id &&
                                                <div className="flex items-center justify-between" key={index + 1000}>
                                                    <div
                                                        className="flex flex-row"
                                                    >
                                                        <input
                                                            id={`filter - ${option?._id}`}
                                                            name={`{ ${option?._id}}[]`}
                                                            defaultValue={option?._id}
                                                            type="checkbox"
                                                            className="h-[17px] w-[17px] rounded-[2px] cursor-pointer border-[#D9D9D9] text-black focus:ring-black hover:border-black checked:text-black peer relative shrink-0 appearance-none border after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-[url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==')] after:bg-[length:30px] after:bg-center after:bg-no-repeat after:content-[''] checked:bg-black focus:outline-none" onClick={() =>
                                                                handleFilterSelection(
                                                                    section.name,
                                                                    option?._id
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            htmlFor={`filter - ${option?._id} `}
                                                            className="ml-3 text-[14px] text-[#3A3933]"
                                                        >
                                                            {option?._id ? option?._id : "-"}
                                                        </label>
                                                    </div>
                                                    <div className="text-[#3A3933] text-[14px]">({option?.total})</div>
                                                </div>
                                            ))}
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    ))
                }
            </form >

        </div>
    )
}

export default Filteration