import { createContext, useReducer, useEffect } from "react";

import BrandIconReducer from "../reducers/BrandIconReducer";

const initialState = {
  isNorthchott: false,
  isBanyan: false,
  isPatrick: false,
  isDefault: true,
  isFigo: false,
  color: "#ffce30",
  textColor: "#000000",
  headerColor: "#686661",
  divisionType: "NORTHCOTT",
  backgroundColor: "#E2A856",
  darkBackgroundColor: "#7d5402",
  footerColor: "#090800",
  footerLogoColor: "#E2A856",
  bodyLogoColor: "#E2A856",
  buttonBgColor: "#E2A856",
  textColorCommon: "#000000",
};

export const BrandIconContext = createContext(initialState);

export const BrandIconContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(BrandIconReducer, initialState);

  //   useEffect(() => {
  //     localStorage.setItem(constants.CURRENT_USER, JSON.stringify(state.user));
  //   }, [state.user]);

  return (
    <BrandIconContext.Provider
      value={{
        isNorthchott: state.isNorthchott,
        isBanyan: state.isBanyan,
        isPatrick: state.isPatrick,
        isDefault: state.isDefault,
        isFigo: state.isFigo,
        color: state.color,
        textColor: state.textColor,
        divisionType: state.divisionType,
        headerColor: state.headerColor,
        backgroundColor: state.backgroundColor,
        darkBackgroundColor: state.darkBackgroundColor,
        footerColor: state.footerColor,
        footerLogoColor: state.footerLogoColor,
        bodyLogoColor: state.bodyLogoColor,
        buttonBgColor: state.buttonBgColor,
        textColorCommon: state.textColorCommon,
        dispatch,
      }}
    >
      {children}
    </BrandIconContext.Provider>
  );
};
