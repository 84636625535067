import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { formatAddressForCustomer, getModelRoute, logout, showMessage } from "../../helpers/Utils";
import ApiService from "../../helpers/ApiServices";
import { useFieldArray, useForm } from "react-hook-form";
import { CustomerContext } from "../../states/contexts/CustomerContext";
import { UserContext } from "../../states/contexts/UserContext";
import { BrandIconContext } from "../../states/contexts/BrandIconContext";
import moment from "moment";

const CustomerProfile = () => {
  const [state, setState] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [country, setCountry] = useState("");
  const [secondaryCustomers, setSecondaryCustomers] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const [isSaveSubscription, setIsSaveSubscription] = useState(false);
  const [tabKey, setTabKey] = useState('mediaLinks');
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [checkDefault, setCheckDefault] = useState(false)
  const [checkDefaultArr, setCheckDefaultArr] = useState([]);
  const [isDefaultTick, setIsDefaultTick] = useState([]);
  const [backOrders, setBackOrders] = useState([]);
  const [openOrders, setOpenOrders] = useState([]);
  const [addressValue, setAddressValue] = useState('');
  const [addressValueLineLevel, setAddressValueLineLevel] = useState([]);
  const [editAddressModalState, setEditAddressModalState] = useState({});
  const [selectCustomer, setSelectCustomer] = useState({});
  const [editAddressModalIndex, setEditAddressModalIndex] = useState();
  const [isDefaultTickAdd, setIsDefaultTickAdd] = useState(false);
  const [activeCard, setActiveCard] = useState('');
  const [backOrdersTotal, setBackOrdersTotal] = useState('');
  const [openOrdersTotal, setOpenOrdersTotal] = useState('');
  const [billedOrdersTotal, setBilledOrdersTotal] = useState('');
  const [pfOrdersTotal, setPFOrdersTotal] = useState('');
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [addrTouchFields, setAddrTouchFields] = useState(null);
  const [addedAddress, setAddedAddress] = useState(null);
  const [updatedAddress, setupdatedAddress] = useState(null);
  const [regions, setRegions] = useState([]);
  const [customerdata, setCustomerdata] = useState(null);
  const [showAddress, setShowAddress] = useState(true);
  const [editOrAddaddressData, setEditOrAddAddressData] = useState(null);
  const [editedAddressData, setEditedAddressData] = useState(null);
  const [addedAddressData, setAddedAddressData] = useState(null);
  const [addressArray, setAddressArray] = useState([]);
  const [index, setIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddrRemoveLoading, setIsAddrRemoveLoading] = useState(false);
  const [defaultBillingAddr, setDefaultBillingAddr] = useState(null);


  const navigate = useNavigate();

  const { dispatch: customerDispatch, customer } = useContext(CustomerContext)
  const { dispatch, user } = useContext(UserContext)
  const { backgroundColor, darkBackgroundColor } = useContext(BrandIconContext)


  const { register, control, reset, handleSubmit, getValues, setValue, watch, formState: { errors, isDirty, dirtyFields, touchedFields } } = useForm({

  });

  const { register: defaultRegister } = useForm();
  const { append: addressAppend, remove: addressRemove, fields: addressFields, update: addressUpdate, insert: addressInsert } = useFieldArray({ control, name: "addresses" });

  // const watchFields = watch(["firstName", "lastName", "storeEmail", "storeWebsite", "storePhone", "cellPhone"]);

  const [searchParams, setSearchParams] = useSearchParams()

  let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"
  let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))
  // let recentCustomers = customers


  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const handleSearch = (e) => {
    gridApi.setQuickFilter(e.target.value);
  }

  const handleExportAsCsv = (e) => {
    gridApi.exportDataAsCsv();
  }


  const onSubmit = (formData) => {
    let id;


    if (user?.userTypes.includes("CUSTOMER")) id = user?._id
    if (user?.userTypes.includes("SALES_MANAGER") || user?.userTypes.includes("SALES_REP") || user?.userTypes.includes("POWER_USER") || user?.userTypes.includes("ADMIN_USER")) id = customer?._id

    if (isSaveSubscription) {
      return saveSubscription(id, formData)
    }
    return updateDocument(id, formData)
  }

  const updateDocument = async (id, data) => {
    setIsLoading(true)

    // Delete address field from addresses array of object
    data?.addresses?.length && data?.addresses?.map(address => {
      delete address['address']
    })

    data.changedFields = Object.keys(touchedFields).filter(function (item) { return item !== "addresses" })



    await ApiService.patch(`shop/customer/${id}?protectModel=${getModelRoute(user).model}&updatedFrom=website`, data).then(response => {
      if (response.data.isSuccess) {
        let array = response.data.document?.addresses
        let document = response.data.document


        document.customerUpdate?.length && document.customerUpdate?.map(ele => {
          if (ele?.fieldName !== 'addresses')
            delete document[ele.fieldName]
        })


        document.customerUpdate?.length && document.customerUpdate?.map(ele => {
          reset({ [ele.fieldName]: ele?.new })
        })

        let map = {};
        array.forEach(item => map[item._id] = item);
        document.customerUpdate.filter(ele => ele?.fieldName == "addresses").forEach(item => map[item.new?._id] = item?.new);
        const result = Object.values(map);

        document['addresses'] = result
        reset(document)
        setIsEditMode(false)
        showMessage("Profile has been updated successfully..", "success")
        setIsLoading(false)

      }
    }).catch(error => {
      showMessage(error?.response?.data?.message, "error")
      setIsLoading(false)
    })
  }

  const findOneDocument = () => {
    ApiService.setHeader()
    ApiService.get(`shop/customer/${customer?._id}`).then(response => {
      if (response.data.isSuccess) {
        response.data.document?.addresses?.map(ele => {
          if (ele?.isDefaultBilling) {
            setDefaultBillingAddr(ele)
          }
        })

        let array = response.data.document?.addresses
        let document = response.data.document

        document.customerUpdate?.length && document.customerUpdate?.map(ele => {
          if (ele?.fieldName !== 'addresses')
            delete document[ele.fieldName]
        })


        document.customerUpdate?.length && document.customerUpdate?.map(ele => {
          reset({ [ele.fieldName]: ele?.new })
        })


        // Comparing two array of objects and replacing an object with another and add new
        let map = {};
        array.forEach(item => map[item._id] = item);
        document.customerUpdate.filter(ele => ele?.fieldName == "addresses").forEach(item => map[item.new?._id] = item?.new);
        const result = Object.values(map);

        document['addresses'] = result

        reset(document)
        setIsEditMode(false)
        showMessage("Profile has been updated successfully..", "success")
        setIsLoading(false)

      }
    }).catch(error => {
      showMessage(error?.response?.data?.message, "error")
      setIsLoading(false)
    })
  }

  const removeAddress = async (data, removedAddrIndex) => {
    setIsAddrRemoveLoading(true)

    ApiService.setHeader()
    await ApiService.patch(`shop/customer/removeAddr/${customer?._id}?protectModel=${getModelRoute(user).model}`, data).then(response => {
      if (response.data.isSuccess) {
        setCustomer()

        setIsAddrRemoveLoading(false)
        showMessage("Address removed!", "success")
        addressRemove(removedAddrIndex);
      }
    }).catch(error => {
      showMessage(error?.response?.data?.message, "error")
      setIsAddrRemoveLoading(false)
    })

  }

  const saveSubscription = (id, data) => {
    ApiService.patch(`shop/customer/subscrption/${id}`, data).then(response => {
      if (response.data.isSuccess) {
        let document = response.data.document

        reset(document)
        showMessage("Subscription has been updated!", "info")
        setIsSaveSubscription(false)
      }
    }).catch(error => {
      showMessage(error?.response?.data?.message, "error")
    })
  }

  const isLoggedIn = async () => {
    ApiService.setHeader()
    await ApiService.get(`shop/user/loggedIn`).then(response => {
      dispatch({ type: "LOGIN_SUCCESS", payload: response.data.document });

      if (response.data.document?.userTypes.includes("CUSTOMER")) reset(response.data.document)
    }).catch(async error => {
      try {
        await logout()
        dispatch({ type: "LOGOUT_SUCCESS" });
        customerDispatch({ type: "SELECT_CUSTOMER", payload: null })
      } catch (error) {
        dispatch({ type: "LOGIN_FAILURE" });
      }
      navigate(`/`)

    })

  }

  const configureAddressArray = (customer) => {
    let addressArray = []

    customer?.addresses?.map(address => {
      let addressObj = address
      const v = formatAddressForCustomer(address)
      addressObj.address = v
      addressArray.push(addressObj)
    })
    return addressArray
  }

  const setCustomer = async () => {

    let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))

    ApiService.setHeader()
    await ApiService.get(`shop/customer/${customers?.length ? customers[customers?.length - 1]?._id : user?._id}`).then(response => {

      if (response.data.isSuccess) {
        let customer = response?.data.document
        setCustomerdata(customer)

        customer.addresses = configureAddressArray(customer)

        // Load the selected customer into reducer
        customerDispatch({ type: "SELECT_CUSTOMER", payload: customer });

        // Set customer data into field's
        customer.customerUpdate?.length && customer.customerUpdate?.map(ele => {
          if (ele?.fieldName !== 'addresses')
            customer[ele.fieldName] = ele?.new
        })

        // Comparing two array of objects and replacing an object with another and add new
        let map = {};
        customer?.addresses?.forEach(item => map[item._id] = item);
        customer?.customerUpdate?.filter(ele => ele?.fieldName == "addresses").forEach(item => map[item.new?._id] = item?.new);
        const result = Object.values(map);

        customer['addresses'] = result
        customer['state'] = ''
        customer['city'] = ''
        reset(customer)
      }
    }).catch(error => {

      console.log(error)
      showMessage(error.response.data.message, "error")

    })

  }

  const handleShow = (value) => {
    setShowAddressModal(value);

    if (isDefaultTick.length > 1) {
      let flag = true;
      isDefaultTick.map(e => {
        if (e) {
          flag = false;
          setCheckDefault(true);
        }
      })

      if (flag) {
        setCheckDefault(false)
      }
    }

  }

  const toggleHandler = () => {
    setIsUpdateForm(!isUpdateForm)
  }

  const getSecondaryCustomers = async (customerNetSuiteId) => {
    try {

      const response = await ApiService.get(`shop/user/secondarycustomer?customerNetSuiteId=${customerNetSuiteId}`)

      if (response?.data.isSuccess) {
        setSecondaryCustomers(response?.data.documents)
      }

    } catch (error) {
      showMessage(error.response.data.message, "error")
    }
  }

  const customerChangeHandler = (e, data) => {
    const id = data?.targetValue?.split('||')[0]

    ApiService.setHeader()
    ApiService.get(`shop/user/${id}`).then(response => {
      let customer = response?.data.document
      customer.addresses = configureAddressArray(customer)

      // Load the selected customer into reducer
      customerDispatch({ type: "SELECT_CUSTOMER", payload: response.data.document });
      reset(response.data.document)

      customers.push(response.data.document)
      // Save the customer array into localStorage, so that after refresh we can get the customer
      localStorage.setItem("PCTeRP.CUSTOMER_IDS", JSON.stringify(customers))



    }).catch(error => {
      showMessage(error.response?.data.message, "error")
    })
  }

  const getInvoicesByCustomer = () => {
    ApiService.post(`shop/nsInv/getByCust`, { id: customers[customers?.length - 1]?.customerNetsuiteId, getDocuments: "INVOICES" }).then(response => {
      if (response?.data.isSuccess) {
        setInvoices(response?.data?.document)
        setLoadingStatus(false)

      }
      if (!response?.data.isSuccess) {
        showMessage(response?.data?.response?.message, "error")
        setLoadingStatus(false)

      }
    }).catch(error => {
      showMessage(error?.response?.message, "error")
      setLoadingStatus(false)
    })
  }

  const getOpenSalesOrders = (type) => {
    ApiService.get(`shop/nsSalesOrder/${type}?id=${customers[customers?.length - 1]?.customerNetsuiteId}`).then(response => {
      if (response?.data.isSuccess) {
        setOpenOrders(response?.data?.documents.data)
      }

      if (!response?.data.isSuccess) {
        showMessage(response?.data?.response?.message, "error")

      }
    }).catch(error => {
      showMessage(error?.response?.message, "error")
    })
  }

  const getOpenSalesOrdersTotal = (type) => {
    ApiService.get(`shop/nsSalesOrder/${type}?id=${customers[customers?.length - 1]?.customerNetsuiteId}`).then(response => {
      if (response?.data.isSuccess) {
        setOpenOrdersTotal(response?.data?.documents)
        getBilledSalesOrdersTotal()
      }

      if (!response?.data.isSuccess) {
        showMessage(response?.data?.response?.message, "error")

      }
    }).catch(error => {
      showMessage(error?.response?.message, "error")
    })
  }

  const getBackSalesOrders = (type) => {
    ApiService.get(`shop/nsSalesOrder/${type}?id=${customers[customers?.length - 1]?.customerNetsuiteId}`).then(response => {
      if (response?.data.isSuccess) {
        setBackOrders(response?.data?.documents.data)

      }

      if (!response?.data.isSuccess) {
        showMessage(response?.data?.response?.message, "error")

      }
    }).catch(error => {
      showMessage(error?.response?.message, "error")
    })
  }

  const getBackSalesOrdersTotal = (type) => {
    ApiService.get(`shop/nsSalesOrder/${type}?id=${customers[customers?.length - 1]?.customerNetsuiteId}`).then(response => {
      if (response?.data.isSuccess) {
        setBackOrdersTotal(response?.data?.documents)
      }

      if (!response?.data.isSuccess) {
        showMessage(response?.data?.response?.message, "error")

      }
    }).catch(error => {
      showMessage(error?.response?.message, "error")
    })
  }

  const getBilledSalesOrdersTotal = () => {
    ApiService.get(`shop/nsSalesOrder/${"BILLED ORDERS TOTAL"}?id=${customers[customers?.length - 1]?.customerNetsuiteId}`).then(response => {
      if (response?.data.isSuccess) {
        setBilledOrdersTotal(response?.data?.documents.data?.total)
        getPFSalesOrdersTotal()
      }

      if (!response?.data.isSuccess) {
        showMessage(response?.data?.response?.message, "error")

      }
    }).catch(error => {
      showMessage(error?.response?.message, "error")
    })
  }

  const getPFSalesOrdersTotal = () => {
    ApiService.get(`shop/nsSalesOrder/${"PF ORDERS TOTAL"}?id=${customers[customers?.length - 1]?.customerNetsuiteId}`).then(response => {
      if (response?.data.isSuccess) {
        setPFOrdersTotal(response?.data?.documents.data?.total)

      }

      if (!response?.data.isSuccess) {
        showMessage(response?.data?.response?.message, "error")

      }
    }).catch(error => {
      showMessage(error?.response?.message, "error")
    })
  }



  const columns = [
    { headerName: 'DOCUMENT NUMBER', field: 'docNumber', width: 300, cellRendererFramework: (params) => <span style={{ cursor: "pointer" }} onClick={() => { navigate(`/invoice/${params.data.id}`) }}>{params.data.docNumber}</span> },
    { headerName: 'WEB ORDER NUMBER', field: 'webOrderNumber', width: 400 },
    { headerName: 'DATE', field: 'date', width: 160, valueGetter: (params) => params.data?.date ? moment(params.data?.date).format("MM/DD/YYYY ") : "Not Available" },
    { headerName: 'SHIPPING DATE', field: 'shipDate', width: 160, valueGetter: (params) => params.data?.shipDate ? moment(params.data?.shipDate).format("MM/DD/YYYY ") : "Not Available" },
    { headerName: 'STATUS', field: 'status', width: 240 },
    { headerName: 'AMOUNT', field: 'amount', width: 240 },
  ]


  useEffect(() => {
    setCustomer()

    if (customers != null && !customer?.isTestAccount) {
      findOneDocument()
      getInvoicesByCustomer()
      getOpenSalesOrders("OPEN ORDERS")
      getBackSalesOrders("BACK ORDERS")
      getOpenSalesOrdersTotal("OPEN ORDERS TOTAL")
      getBackSalesOrdersTotal("BACK ORDERS TOTAL")

    }

  }, []);


  if (user) {
    return <div
      style={{ fontFamily: "Open Sans" }}
      className="flex items-center justify-center"
    >
      <div className="w-[320px] sm:w-[744px] xl:w-[1280px] 2x:w-[1728px] flex items-center justify-center">
        <div className="my-[80px] sm:my-[100px] w-[280px] sm:w-[624px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col items-center xl:flex-row xl:items-start gap-[60px] 2xl:gap-[100px]">
          {/* left side */}
          <div className="w-[280px] h-[258px] sm:w-[624px] xl:w-[280px] xl:h-[258px] flex flex-col gap-5">
            {/* heading */}
            <div className="w-[280px] h-[38px] sm:w-[624px] xl:w-[280px] text-center sm:text-start font-bold text-[28px]">
              Customer Profile
            </div>
            {/* horizontal line */}
            <hr className=" text-gray-500 bg-gray-500 h-1 w-[280px] sm:w-[624px] xl:w-[280px]" />
            {/* content */}
            <div className="w-[280px] h-[180px] flex flex-col sm:w-[624px] xl:w-[280px] bg-white">
              <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] bg-[#F0EFEB] text-[14px] xl:text-[14px] text-center flex items-center cursor-pointer">
                <Link to={`/customer-profile?division=${division}`}>
                  <p className="ms-[15px]">Personal Info</p>
                </Link>
              </div>
              <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] text-[14px] text-center flex items-center cursor-pointer">
                <Link to={`/customer-profile/shippingAddress?division=${division}`}>
                  <p className="ms-[15px]">Shipping Addresses</p>
                </Link>
              </div>
              <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] text-[14px] text-center flex items-center cursor-pointer">
                {
                  user?.userTypes.includes("CUSTOMER") ? <Link to={`/update-password?division=${division}`}>
                    <p className="ms-[15px]">Change Password</p>
                  </Link> : user?.userTypes.includes("ADMIN_USER") && <Link to={`/update-password/${customer?._id}?division=${division}`}>
                    <p className="ms-[15px]">Change Password</p>
                  </Link>
                }
              </div>
              <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] text-[14px] text-center flex items-center cursor-pointer">
                <Link to={"/coming-soon"}>
                  <p className="ms-[15px]"> Communication Preferences</p>
                </Link>
              </div>
            </div>
          </div>

          {/* right side */}

          <div className="w-[280px] h-[984px] sm:w-[624px] sm:h-[750px] xl:w-[820px] xl:h-[750px] 2xl:w-[940px] flex flex-col gap-[60px]">
            {/* personalinfo */}
            <div className="w-[280px] h-[277px] sm:w-[624px] sm:h-[181px]  xl:w-[820px] xl:h-[181px] flex flex-col gap-5">
              {/* heading and button */}
              <div className="w-[280px] h-[92px] sm:w-[624px] sm:h-[38px] xl:w-[820px] 2xl:w-[940px] flex flex-col items-center justify-center sm:flex-row sm:justify-between sm:items-center gap-5 sm:gap-0">
                {/* heading */}
                <div className="w-[186px] h-[38px] font-bold text-[28px] text-center sm:text-start">
                  Personal Info
                </div>
                {/* button */}
                <div className="text-center w-[80px] h-[34px]">
                  <Link to={`/customer-profile/editPersonalInfo?division=${division}`}>
                    <button
                      className=" w-[80px] h-[34px] text-center text-[12px] cursor-pointer bg-[#E2A856] text-[#FFFFFF] hover:bg-black rounded-[4px]"
                      type="button"
                    >
                      EDIT
                    </button>
                  </Link>
                </div>
              </div>
              {/* horizontal line */}
              <hr className=" text-gray-500 bg-gray-500 h-[1px] w-[280px] sm:w-[624px] xl:w-[820px] 2xl:w-[940px]" />

              {/* content */}
              <div className="w-[280px] h-[145px] flex flex-col sm:w-[624px] sm:h-[103px] xl:w-[820px] 2xl:w-[940px] gap-5">
                {/* first name */}
                <div className="w-[280px] h-[42px] sm:w-[480px] sm:h-[21px] gap-[10px] flex">
                  <div className="w-[80px] h-[42px]  sm:w-[150px] sm:h-[21px] font-bold text-[14px]">
                    First Name:
                  </div>
                  <div className="w-[190px] h-[21px] text-[14px]">
                    {customer?.firstName}
                  </div>
                </div>
                {/* last name */}
                <div className="flex w-[280px] h-[21px] sm:w-[480px] gap-[10px]">
                  <div className="text-[14px] font-bold w-[80px] sm:w-[150px]">
                    Last Name:
                  </div>
                  <div className="text-[14px] w-[190px] sm:w-[320px] lg:w-[320px]">
                    {customer?.lastName}
                  </div>
                </div>
                {/* email */}
                <div className="w-[280px] h-[42px] sm:w-[480px] sm:h-[21px] gap-[10px] flex">
                  <div className="w-[80px] h-[42px]  sm:w-[150px] sm:h-[21px] font-bold text-[14px]">
                    Email:
                  </div>
                  <div className="w-[190px] h-[21px] sm:w-[300px] text-[14px] text-wrap overflow-hidden">
                    {customer?.email}
                  </div>
                </div>
              </div>
            </div>

            {/* store info */}
            <div className="w-[280px] h-[647px] sm:w-[624px] sm:h-[509px] xl:w-[820px] flex flex-col gap-5">
              {/* heading and button */}
              <div className="w-[280px] h-[92px] sm:w-[624px] sm:h-[38px] xl:w-[820px] 2xl:w-[940px] flex flex-col items-center justify-center sm:flex-row sm:justify-between sm:items-center gap-5 sm:gap-0">
                {/* heading */}
                <div className="w-[186px] h-[38px] font-bold text-[28px] text-center sm:text-start">
                  Store Info
                </div>
                {/* button */}
                <div className="text-center w-[80px] h-[34px]">
                  <Link to={`/customer-profile/editStoreInfo?division=${division}`}>
                    <button
                      className="cursor-pointer w-[80px] h-[34px] text-center text-[12px] bg-[#E2A856] text-[#FFFFFF] hover:bg-black rounded-[4px]"
                      type="button"
                    >
                      EDIT
                    </button>
                  </Link>
                </div>
              </div>
              {/* horizontal line */}
              <hr className=" text-gray-500 bg-gray-500 h-[1px] w-[280px] sm:w-[624px] xl:w-[820px] 2xl:w-[940px]" />

              {/* content */}
              <div className="w-[280px] h-[515px] flex flex-col sm:w-[624px] sm:h-[431px] xl:w-[820px] 2xl:w-[940px]  gap-5">
                {/* comapny name */}
                <div className="w-[280px] h-[42px] sm:w-[480px] sm:h-[21px] gap-[10px] flex">
                  <div className="w-[80px] h-[42px]  sm:w-[150px] sm:h-[21px] font-bold text-[14px] sm:text-nowrap">
                    Company Name:
                  </div>
                  <div className="w-[190px] sm:w-[300px] h-[42px] text-[14px]">
                    {customer?.companyName}
                  </div>
                </div>
                {/* comapny number */}
                <div className="w-[280px] h-[42px] sm:w-[480px] sm:h-[21px] gap-[10px] flex">
                  <div className="w-[80px] h-[42px]  sm:w-[150px] sm:h-[21px] font-bold text-[14px]">
                    Company Number:
                  </div>
                  <div className="w-[190px] sm:w-[300px] h-[21px] text-[14px]">
                    {customer?.customerId}
                  </div>
                </div>
                {/* contact name */}
                <div className="w-[280px] h-[42px] sm:w-[480px] sm:h-[21px] gap-[10px] flex">
                  <div className="w-[80px] h-[42px]  sm:w-[150px] sm:h-[21px] font-bold text-[14px]">
                    Contact Name :
                  </div>
                  <div className="w-[190px] sm:w-[300px] h-[21px] text-[14px]"></div>
                </div>
                {/* address 1*/}
                <div className="flex w-[280px] h-[21px] sm:w-[480px] gap-[10px]">
                  <div className="text-[14px] font-bold w-[80px] sm:w-[150px]">
                    Address 1 :
                  </div>
                  <div className="text-[14px] w-[190px] sm:w-[320px]">
                    {defaultBillingAddr?.address1}
                  </div>
                </div>
                {/* address 2*/}
                <div className="flex w-[280px] h-[21px] sm:w-[480px] gap-[10px]">
                  <div className="text-[14px] font-bold w-[80px] sm:w-[150px]">
                    Address 2 :
                  </div>
                  <div className="text-[14px] w-[190px] sm:w-[320px]">
                    {defaultBillingAddr?.address2}
                  </div>
                </div>
                {/* city*/}
                <div className="flex w-[280px] h-[21px] sm:w-[480px] gap-[10px]">
                  <div className="text-[14px] font-bold w-[80px] sm:w-[150px]">
                    City:
                  </div>
                  <div className="text-[14px] w-[190px] sm:w-[320px]">

                  </div>
                </div>
                {/* state*/}
                <div className="w-[280px] h-[42px] sm:w-[480px] sm:h-[21px] gap-[10px] flex">
                  <div className="w-[80px] h-[42px] sm:w-[150px] sm:h-[21px] font-bold text-[14px] flex flex-col sm:flex-row">
                    <p>State/</p>
                    <p>Province:</p>
                  </div>
                  <div className="w-[190px] sm:w-[300px] h-[21px] text-[14px]">
                    {defaultBillingAddr?.state?.text}
                  </div>
                </div>
                {/* phone*/}
                <div className="flex w-[280px] h-[21px] sm:w-[480px] gap-[10px]">
                  <div className="text-[14px] font-bold w-[80px] sm:w-[150px]">
                    Phone:
                  </div>
                  <div className="text-[14px] w-[190px] sm:w-[320px]">{customer?.storePhone}</div>
                </div>
                {/* cell*/}
                <div className="flex w-[280px] h-[21px] sm:w-[480px] gap-[10px]">
                  <div className="text-[14px] font-bold w-[80px] sm:w-[150px]">
                    Cell:
                  </div>
                  <div className="text-[14px] w-[190px] sm:w-[320px]">{customer?.cellPhone}</div>
                </div>
                {/* email*/}
                <div className="flex w-[280px] h-[21px] sm:w-[480px] gap-[10px]">
                  <div className="text-[14px] font-bold w-[80px] sm:w-[150px]">
                    Email:
                  </div>
                  <div className="text-[14px] w-[190px] sm:w-[320px] ">{customer?.storeEmail}</div>
                </div>
                {/* website*/}
                <div className="flex w-[280px] h-[21px] sm:w-[480px] gap-[10px]">
                  <div className="text-[14px] font-bold w-[80px] sm:w-[150px]">
                    Website:
                  </div>
                  <div className="text-[14px] w-[190px] sm:w-[320px]">
                    {customer?.storeWebsite}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  }
}

export default CustomerProfile;
