import { React, useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Controller } from 'react-hook-form';
import ApiService from '../../../helpers/ApiServices';
import '../../../components/elements/fields/DropDown.css'
import 'react-bootstrap-typeahead/css/Typeahead.css';

export default function ConditionalObjectSelectField({ control, field, states, errors, queryPath, index, multiple, changeHandler, blurHandler }) {
  const [state, setState] = useState([]);
  const [selected, setSelected] = useState([]);

  const getList = async () => {
    if (field?.selectRecordType.includes("/")) {

      ApiService.setHeader();
      const response = await ApiService.get(`/${field?.selectRecordType}`);
      if (response.data.isSuccess) {
        if (response.data?.document?.options) {
          setState(response.data?.document.options)
        } else {
          setState(response.data?.documents)
        }

      }
    }
  }

  const convertObjectToArray = (obj) => {
    if (!obj) return [];

    const arr = [];
    arr.push(obj)
    return arr;
  }

  useEffect(() => {
    getList();
  }, []);

  return <>
    <p className="block text-[14px] font-bold text-[#090800]  sm:w-[77px] sm:h-[21px] text-nowrap">{field?.label}</p>


    <Controller
      name={field?.fieldId}
      control={control}
      rules={{ required: field?.required ? field?.validationMessage : false }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className="w-[280px] h-[76px] sm:w-[320px] flex flex-col items-center justify-between">
          <select
            required
            id="pct_objectSelect"
            multiple={field?.multiple}
            disabled={field?.disabled}
            onChange={(event) => {
              const selectedOptions = Array.from(event.target.selectedOptions, option => ({
                value: option.value,
                text: option.text
              }));

              onChange(field?.multiple ? selectedOptions : selectedOptions[0]);

              if (changeHandler) {
                changeHandler(selectedOptions, {
                  type: field.type,
                  id: field.fieldId,
                  value: field?.multiple ? selectedOptions : selectedOptions[0]
                });
              }
            }}
            value={field?.multiple ? value.map(v => v.value) : value?.value || ""}
            className={`form-select ${error ? 'is-invalid' : 'w-[280px] sm:w-[320px] appearance-none relative block px-3 py-2 border border-[#CECECC] text-black focus:outline-none focus:z-10 sm:text-sm'}`}
          >
            <option value="" disabled>{field?.placeholder || "Select an option"}</option>
            {(field?.selectRecordType === "modal" ? states : state)?.map((option, idx) => (
              <option key={idx} value={option.value}>
                {option.text || option.name}
              </option>
            ))}
          </select>
          {error && <div className="invalid-feedback">{error.message}</div>}
        </div>
      )}
    />

  </>
}

