import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CustomerContext } from "../../states/contexts/CustomerContext";
import { UserContext } from "../../states/contexts/UserContext";
import { BrandIconContext } from "../../states/contexts/BrandIconContext";
import { useForm } from "react-hook-form";
import { getModelRoute, showMessage } from "../../helpers/Utils";
import ApiService from "../../helpers/ApiServices";

const EditPersonalInfo = () => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const { dispatch: customerDispatch, customer } = useContext(CustomerContext)
  const { dispatch, user } = useContext(UserContext)
  const { backgroundColor, darkBackgroundColor } = useContext(BrandIconContext)


  const { register, control, reset, handleSubmit, getValues, setValue, watch, formState: { errors, isDirty, dirtyFields, touchedFields } } = useForm({

  });

  const [searchParams, setSearchParams] = useSearchParams()

  let division = searchParams.get('division')?.toUpperCase() ? searchParams.get('division')?.toUpperCase() : "NORTHCOTT"
  let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))


  const onSubmit = (formData) => {
    let id;

    // console.log(formData);

    if (user?.userTypes.includes("CUSTOMER")) id = user?._id
    if (user?.userTypes.includes("SALES_MANAGER") || user?.userTypes.includes("SALES_REP") || user?.userTypes.includes("POWER_USER") || user?.userTypes.includes("ADMIN_USER")) id = customer?._id
    // console.log(id);

    // if (isSaveSubscription) {
    //   return saveSubscription(id, formData)
    // }
    return updateDocument(id, formData)
  }

  const updateDocument = async (id, data) => {
    setIsLoading(true)

    // Delete address field from addresses array of object
    data?.addresses?.length && data?.addresses?.map(address => {
      delete address['address']
    })

    console.log(getModelRoute(user).model);
    data.changedFields = Object.keys(touchedFields).filter(function (item) { return item !== "addresses" })


    await ApiService.patch(`shop/customer/${id}?protectModel=${getModelRoute(user).model}&updatedFrom=website`, data).then(response => {
      if (response.data.isSuccess) {
        let array = response.data.document?.addresses
        let document = response.data.document

        document.customerUpdate?.length && document.customerUpdate?.map(ele => {
          console.log(ele.fieldName, document[ele.fieldName])
          if (ele?.fieldName !== 'addresses')
            delete document[ele.fieldName]
        })

        document.customerUpdate?.length && document.customerUpdate?.map(ele => {
          reset({ [ele.fieldName]: ele?.new })
        })

        // Comparing two array of objects and replacing an object with another and add new
        let map = {};
        array.forEach(item => map[item._id] = item);
        document.customerUpdate.filter(ele => ele?.fieldName == "addresses").forEach(item => map[item.new?._id] = item?.new);
        const result = Object.values(map);
        document['addresses'] = result

        reset(document)
        showMessage("Profile has been updated successfully..", "success")
        setIsLoading(false)
        navigate(`/customer-profile?division=${division}`)
      }
    }).catch(error => {
      showMessage(error?.response?.data?.message, "error")
      setIsLoading(false)
    })
  }

  useEffect(() => {
    // Reset these data
    if (customer)
      reset(customer)
  }, [])



  return (
    <div
      style={{ fontFamily: "Open Sans" }}
      className="flex items-center justify-center"
    >
      <div className="w-[320px] sm:w-[744px] xl:w-[1280px] 2x:w-[1728px] flex items-center justify-center">
        <div className="my-[80px] sm:my-[100px] w-[280px] sm:w-[624px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col items-center xl:flex-row xl:items-start gap-[60px] 2xl:gap-[100px]">
          {/* left side */}
          <div className="w-[280px] h-[258px] sm:w-[624px] xl:w-[280px] xl:h-[258px] flex flex-col gap-5">
            {/* heading */}
            <div className="w-[280px] h-[38px] sm:w-[624px] xl:w-[280px] text-center sm:text-start font-bold text-[28px]">
              Customer Profile
            </div>
            {/* horizontal line */}
            <hr className=" text-gray-500 bg-gray-500 h-1 w-[280px] sm:w-[624px] xl:w-[280px]" />
            {/* content */}
            <div className="w-[280px] h-[180px] flex flex-col sm:w-[624px] xl:w-[280px] bg-white">
              <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] bg-[#F0EFEB] text-[14px] xl:text-[14px] text-center flex items-center cursor-pointer">
                <Link to={`/customer-profile?division=${division}`}>
                  <p className="ms-[15px]">Personal Info</p>
                </Link>
              </div>
              <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] text-[14px] text-center flex items-center cursor-pointer">
                <Link to={`/customer-profile/shippingAddress?division=${division}`}>
                  <p className="ms-[15px]">Shipping Addresses</p>
                </Link>
              </div>
              <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] text-[14px] text-center flex items-center cursor-pointer">
                {
                  user?.userTypes.includes("CUSTOMER") ? <Link to={`/update-password?division=${division}`}>
                    <p className="ms-[15px]">Change Password</p>
                  </Link> : user?.userTypes.includes("ADMIN_USER") && <Link to={`/update-password/${customer?._id}?division=${division}`}>
                    <p className="ms-[15px]">Change Password</p>
                  </Link>
                }
              </div>
              <div className=" w-[280px] h-[45px] sm:w-[624px] lg:w-[280px] text-[14px] text-center flex items-center cursor-pointer">
                <Link to={"/"}>
                  <p className="ms-[15px]"> Communication Preferences</p>
                </Link>
              </div>
            </div>
          </div>

          {/* right side */}
          <div className="w-[280px] h-[521px] sm:w-[624px] sm:h-[521px] xl:w-[820px] 2xl:w-[940px] flex flex-col gap-[60px]">
            {/* personalinfo */}
            <div className="w-[280px] h-[277px] sm:w-[624px] sm:h-[181px]  xl:w-[820px] xl:h-[181px] flex flex-col gap-5">
              {/* heading */}
              <div className="w-[280px] h-[92px] sm:w-[624px] sm:h-[38px] xl:w-[820px] 2xl:w-[940px] flex flex-col items-center justify-center sm:flex-row sm:justify-between sm:items-center gap-5 sm:gap-0">
                {/* heading */}
                <div className="w-[186px] h-[38px] font-bold text-[28px] text-center sm:text-start">
                  Personal Info
                </div>
              </div>
              {/* horizontal line */}
              <hr className=" text-gray-500 bg-gray-500 h-[1px] w-[280px] sm:w-[624px] xl:w-[820px] 2xl:w-[940px]" />
              {/* content */}
              <form className="w-[280px] h-[364px] sm:w-[624px] sm:h-[364px] xl:w-[820px] 2xl:w-[940px] flex flex-col gap-[20px]" onSubmit={handleSubmit(onSubmit)}>
                <div className="w-[280px] h-[443px] flex flex-col sm:w-[624px] sm:h-[443px] xl:w-[820px] 2xl:w-[940px] gap-[30px]">
                  {/* input fields */}
                  {/* firstname */}
                  <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                    <label
                      htmlFor="firstName"
                      className="block text-[14px] font-bold text-[#090800] sm:w-[77px] sm:h-[21px] text-nowrap"
                    >
                      First Name
                    </label>
                    <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                      <input
                        {...register('firstName')}
                        id="firstName"
                        name="firstName"
                        type="text"
                        className="appearance-none relative block px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-non focus:z-10 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                        placeholder=""
                      />
                    </div>
                  </div>
                  {/* lastname */}
                  <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                    <label
                      htmlFor="lastName"
                      className="block text-[14px] font-bold text-[#090800] sm:w-[77px] sm:h-[21px] text-nowrap"
                    >
                      Last Name
                    </label>
                    <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                      <input
                        {...register('lastName')}
                        id="lastName"
                        name="lastName"
                        type="text"
                        className="appearance-none relative block px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-non focus:z-10 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                        placeholder=""
                      />
                    </div>
                  </div>
                  {/* email */}
                  <div className="w-[280px] h-[76px] sm:w-[624px] sm:h-[76px] xl:w-[320px] flex flex-col gap-[10px]">
                    <label
                      htmlFor="email"
                      className="block text-[14px] font-bold text-[#090800] sm:w-[77px] sm:h-[21px] text-nowrap"
                    >
                      Email
                    </label>
                    <div className="w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]">
                      <input
                        {...register('email')}
                        id="email"
                        name="email"
                        type="email"
                        className="appearance-none relative block px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-non focus:z-10 text-[14px] w-[280px] h-[45px] sm:w-[624px] sm:h-[45px] xl:w-[320px]"
                        placeholder=""
                      />
                    </div>
                  </div>

                  {/* buttons */}
                  <div className="h-[49px] flex items-center justify-center sm:justify-start">
                    <div className="w-[205px] h-[49px] flex items-center justify-between">
                      <button
                        disabled={isLoading}
                        className={`w-[100px] h-[40px] rounded text-center text-[16px] font-semibold text-[#FFFFFF] hover:bg-black ${isLoading ? "bg-[#EECB9A]" : "bg-[#E2A856] cursor-pointer"} `}
                        type="submit"
                      >
                        {isLoading ? "loading..." : "UPDATE"}
                      </button>
                      {/* </Link> */}
                      <Link to={"/customer-profile"}>
                        <button
                          className="underline w-[66px] h-[24px] text-center text-[16px] text-[#E2A856] font-semibold bg-white text-nowrap hover:text-black"
                          type="button"
                        >
                          Go Back
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
export default EditPersonalInfo;
