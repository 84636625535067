const constants = {
  CURRENT_USER: "PCTeRP.CURRENTUSER",
  CURRENT_CUSTOMER: "PCTeRP.CURRENTCUSTOMER",
  // TOKEN_KEY: "PCTeRP.TOKEN",
  TOKEN_KEY: "WEBSITE.TOKEN",
  REFRESH_TOKEN_KEY: "PCTeRP.REFRESH_TOKEN",
  CUSTOMER_ID: "PCTeRP.CUSTOMER_ID",
  ITEMS_PER_PAGE: 100,
};

export default constants;
