import { Link, useNavigate } from "react-router-dom";
import CartProduct from "../../assets/ShoppingCart/CartProduct.svg";
import DeleteIcon from "../../assets/ShoppingCart/DeleteIcon.svg";
import calendar_month from "../../assets/ShoppingCart/calendar_month.svg";
import { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { CustomerContext } from "../../states/contexts/CustomerContext";
import { UserContext } from "../../states/contexts/UserContext";
import { BrandIconContext } from "../../states/contexts/BrandIconContext";
import { CartContext } from "../../states/contexts/CartContext";
import { getModelRoute, showMessage } from "../../helpers/Utils";
import ApiService from "../../helpers/ApiServices";
import Swal from 'sweetalert2'
import moment from "moment";
import DisplayCartDetails from "../../components/elements/components/DisplayCartDetails";
import "./cartDetail.css"
import help from "../../assets/help.svg";
import { useAlert } from "react-alert";

const CartDetail = () => {
  // for Sales Rep, Sales Manager, Power User, Admin User
  const [salesRep, setSalesRep] = useState(true);
  // for tooltip 1
  const [tooltip, setToolTip] = useState(false);
  // for tooltip 2
  const [tooltip2, setToolTip2] = useState(false);
  // for mobile table dropdown list
  const [isOpen, setIsOpen] = useState(false);
  const [collection, setCollection] = useState(null);
  const [subTotalLoading, setSubTotalLoading] = useState(false);
  const [deleteIndividualLoading, setDeleteIndividualLoading] = useState(false);
  const [state, setState] = useState([]);
  const [carts, setCarts] = useState([]);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [cartItemQuantity, setCartItemQunatity] = useState(0);
  const [shipDates, setShipDates] = useState([]);
  const [total, setTotal] = useState(null);
  const [totalDiscount, setTotalDiscount] = useState(null);
  const [usdMultiplier, setUSDMultiplier] = useState(null);
  const [cadMultiplier, setCADMultiplier] = useState(null);
  const [shippingType, setShippingType] = useState(null);
  const [shippingDate, setShippingDate] = useState(null);
  const [shippingInstruction, setShippingInstruction] = useState(null);

  const [isEditCart, setIsEditCart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShipComplete, setIsShipComplete] = useState(false);
  const [isCombineShipment, setIsCombineShipment] = useState(false);
  const alert = useAlert();

  const { dispatch: customerDispatch, customer } = useContext(CustomerContext)
  const { user } = useContext(UserContext)
  const { backgroundColor } = useContext(BrandIconContext)
  const { dispatch: cartDispatch, shipType, quantity } = useContext(CartContext)


  const navigate = useNavigate()



  let customers = JSON.parse(localStorage.getItem("PCTeRP.CUSTOMER_IDS"))



  const { register, control, reset, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
    }
  });
  const { append: cartAppend, remove: cartRemove, fields: cartItemFields } = useFieldArray({ control, name: "cartItems" });

  let updateCartData = { quantity: "", cartId: "", price: "", subTotal: "" }

  const formatData = (cart) => {
    let array = []
    cart.multipliers?.map(e => {
      let obj = {}
      obj = {
        id: e,
        name: String(e)
      }
      array.push(obj)
    })

    return {
      productQuantity: [{
        id: cart.productQuantity,
        name: String(cart.productQuantity)
      }],
      multipliers: array
    }
  }


  const checkPricesOfCart = (prices) => {
    let obj = {}
    let updatedCartItems = []
    let country = null
    if (customers !== null) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        country = "UNITED STATES"
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
        country = "CANADA"
    }

    ApiService.setHeader()
    ApiService.get(`shop/cart/${customers[customers?.length - 1]?._id}?protectModel=${getModelRoute(user).model}`).then(async response => {
      if (response?.data.isSuccess) {
        const isAddedBySpecialButton = response?.data.documents?.every(cartItem => cartItem?.isAddedBySpecialButton)
        if (!isAddedBySpecialButton) {
          response?.data.documents?.map(cartItem => {
            const key = `${country?.toUpperCase() === "CANADA" ? "CANADA" : "USA"} ${customers[customers?.length - 1].priceLevel}`?.toUpperCase()

            const pm = prices.filter(price => price.Code === cartItem.productId.priceCode)
            // Set price from pricing matrix in each product
            if (pm.length) {
              for (let ele of Object.entries(pm[0])) {
                if (ele[0]?.toUpperCase() === key) {
                  // Set new price from pricing matrix into product if changed
                  if (ele[1] !== cartItem?.price) {
                    cartItem.price = ele[1]
                    cartItem.subTotal = parseFloat(parseFloat(ele[1]) * cartItem.productQuantity).toFixed(2)
                    updatedCartItems.push(cartItem)
                  }
                }
              }

            }
          })
          updatePriceInCartItems(updatedCartItems)
        } else {
          getCartDetail()
        }
      }
    }).catch(error => {
      console.log(error)
    })
  }

  const updateCartItem = (cartId) => {
    setDeleteIndividualLoading(true)
    let country = null
    if (customers !== null) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        country = "UNITED STATES"
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
        country = "CANADA"
    }

    if (updateCartData.cartId && cartId == updateCartData.cartId) {
      ApiService.setHeader()
      ApiService.patch(`shop/cart/increaseCartItemQuantity/${updateCartData.cartId}?protectModel=${getModelRoute(user).model}&country=${country}&collection=${updateCartData?.collectionName}&customerId=${customer?._id}&promoCode=${updateCartData?.promoCode}&promoCodeType=${updateCartData?.promoCodeType}`, updateCartData).then(response => {
        if (response?.data.isSuccess) {
          let totalItemQuantity = carts.length > 0
            ? carts.map(data => data[1].reduce((accumulator, item) => accumulator + parseFloat(item.subTotal || 0), 0))
            : 0;
          setCartItemQunatity(totalItemQuantity)
          getCartDetail();
        }
      }).catch(error => {
        getCartDetail();
      })

      setIsEditCart(!isEditCart)
      getCartDetail()
    } else {
      showMessage("Please select correct line for update!", "warning")
    }
    getCartDetail()
  }

  const getCartDetail = (orderType) => {
    setIsLoading(true)
    let obj = {}
    let array = []
    let groupBycollectionName
    let updatedCartItems = []

    setSubTotalLoading(true);
    ApiService.setHeader()
    ApiService.get(`shop/cart/${customers[customers?.length - 1]?._id}?protectModel=${getModelRoute(user).model}`).then(response => {
      if (response?.data.isSuccess) {
        // Set all cart ship date into an array
        response?.data.documents.map(cart => array.push(moment(cart?.shipDate)))
        setShipDates(array)
        cartDispatch({ type: "ADD_TO_CART_QUANTITY", payload: response?.data.documents?.length });
        setState(response?.data.documents)
        setSubTotalLoading(false)
        // console.log(response.data.documents);
        const tot = response?.data.documents?.reduce(function getSum(total, cart) {
          return (parseFloat(total) + parseFloat(cart?.subTotal)).toFixed(2);
        }, 0)
        const actualTot = response?.data.documents?.reduce(function getSum(total, cart) {
          return (parseFloat(total) + (parseFloat(cart?.productQuantity) * parseFloat(cart?.price))).toFixed(2);
        }, 0)
        setTotalDiscount(parseFloat(actualTot) - parseFloat(tot))
        setTotal(tot)
        response?.data.documents?.map(ele => {
          ele.productQuantity = formatData(ele).productQuantity
          ele.multipliers = formatData(ele).multipliers
          ele.productId.name = ele.productId.name.includes(":") ? ele.productId.name.split(":")[1]?.trim() : ele.productId.name
        })

        obj.cartItems = response?.data.documents
        reset(obj)
        if (response?.data.documents[0]?.isCombineShip && !response?.data.documents[0]?.isShipOrderComplete) {
          setValue("combineShip", true)

          groupBycollectionName = response?.data.documents?.reduce((group, cartItem) => {
            const { newshipdate } = cartItem;

            group[newshipdate] = group[newshipdate] ?? [];
            group[newshipdate].push(cartItem);

            return group;
          }, {});
        } else if (response?.data.documents[0]?.isShipOrderComplete && !response?.data.documents[0]?.isCombineShip) {
          setValue("orderByCollection", true)

          // Group all cart items by collection name
          groupBycollectionName = response?.data.documents?.reduce((group, cartItem) => {
            const { collectionName } = cartItem;

            group[collectionName] = group[collectionName] ?? [];
            group[collectionName].push(cartItem);

            return group;
          }, {});
        } else if (response?.data.documents[0]?.isShipOrderComplete && response?.data.documents[0]?.isCombineShip) {
          setValue("orderByCollection", true)
          setValue("combineShip", true)

          // Group all cart items by collection name
          groupBycollectionName = response?.data.documents?.reduce((group, cartItem) => {
            const { newshipdate } = cartItem;

            group[newshipdate] = group[newshipdate] ?? [];
            group[newshipdate].push(cartItem);

            return group;
          }, {});
        } else {
          // Group all cart items by collection name
          groupBycollectionName = response?.data.documents?.reduce((group, cartItem) => {
            const { collectionName } = cartItem;

            group[collectionName] = group[collectionName] ?? [];
            group[collectionName].push(cartItem);
            return group;
          }, {});
        }

        // Apply promo code which have auto apply checkbox checked on cart items
        let test = Object.entries(groupBycollectionName)
        // Set formated cart items
        let totalItemCount = carts.length > 0 ? carts.reduce(((initial, data) => data[1].length + initial), 0) : 0
        setCartItemCount(totalItemCount)
        setCarts(Object.entries(groupBycollectionName))
        setIsLoading(false)
        setDeleteIndividualLoading(false)
      }
    }).catch(error => {
      setSubTotalLoading(false)
    })
  }

  const removeDiscountFromAllItemsAddedBySelectFullCollection = (cartItems, buttonType) => {

    ApiService.setHeader()
    ApiService.post(`shop/cart/removeDiscount?protectModel=${getModelRoute(user).model}&buttonType=${buttonType}`, cartItems).then(response => {
      if (response?.data.isSuccess) {
        getCartDetail()
      }
    }).catch(error => {
      console.log(error.response.data)
      // showMessage(error.response.data.message, "error")
    })
  }

  const removeDiscountFromAllItemsAddedBySelectFullCollectionFreeFreight = (cartItems, buttonType) => {

    ApiService.setHeader()
    ApiService.post(`shop/cart/removeDiscount?protectModel=${getModelRoute(user).model}&buttonType=${buttonType}`, cartItems).then(response => {
      if (response?.data.isSuccess) {
        getCartDetail()
      }
    }).catch(error => {
      console.log(error.response.data)
      // showMessage(error.response.data.message, "error")
    })
  }

  const removeDiscountFromAllItemsAddedBySelectFullCollectionHalfPiece = (cartItems, buttonType) => {

    ApiService.setHeader()
    ApiService.post(`shop/cart/removeDiscount?protectModel=${getModelRoute(user).model}&buttonType=${buttonType}`, cartItems).then(response => {
      if (response?.data.isSuccess) {
        getCartDetail()
      }
    }).catch(error => {
      console.log(error.response.data)
      // showMessage(error.response.data.message, "error")
    })
  }

  const removePromoFromItemsForPieceRestriction = (cartItems, cartItem) => {
    let country = null

    if (customers !== null) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        country = "UNITED STATES"
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
        country = "CANADA"
    }

    ApiService.setHeader()
    ApiService.post(`shop/cart/removePromo?protectModel=${getModelRoute(user).model}&customerId=${customer?._id}&collection=${cartItems[0]?.collectionName.split("\n")[0]}&country=${country}&promoCode=${cartItem?.appliedPromo}`, cartItems).then(response => {
      if (response?.data.isSuccess) {
        setTimeout(() => {
          getCartDetail()
        }, 2000);
      }
    }).catch(error => {
      console.log(error.response.data)
      // showMessage(error.response.data.message, "error")
      getCartDetail()
    })
  }

  const removePromoFromItemsForPieceRestrictionManually = (cartItems) => {
    let country = null

    if (customers !== null) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        country = "UNITED STATES"
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
        country = "CANADA"
    }

    ApiService.setHeader()
    ApiService.post(`shop/cart/removePromoManually?protectModel=${getModelRoute(user).model}&customerId=${customer?._id}&collection=${cartItems[0]?.collectionName.split("\n")[0]}&country=${country}&promoCode=${cartItems[0]?.appliedPromo}`, cartItems).then(response => {
      if (response?.data.isSuccess) {
        setTimeout(() => {
          getCartDetail()
        }, 2000);
      }
    }).catch(error => {
      console.log(error.response.data)
      // showMessage(error.response.data.message, "error")
      getCartDetail()
    })
  }

  const removePromoFromItemsForYardMeterRestriction = (cartItems, cartItem) => {
    let country = null
    if (customers !== null) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        country = "UNITED STATES"
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
        country = "CANADA"
    }

    ApiService.setHeader()
    ApiService.post(`shop/cart/removePromoYardMeter?protectModel=${getModelRoute(user).model}&customerId=${customer?._id}&collection=${cartItems[0]?.collectionName.split("\n")[0]}&country=${country}&promoCode=${cartItem?.appliedPromo}`, cartItems).then(response => {
      if (response?.data.isSuccess) {
        setTimeout(() => {
          getCartDetail()
        }, 2000);
      }
    }).catch(error => {
      console.log(error.response.data)
      // showMessage(error.response.data.message, "error")
      getCartDetail()
    })
  }

  const removePromoFromItemsForYardMeterRestrictionManually = (cartItems) => {
    let country = null
    if (customers !== null) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        country = "UNITED STATES"
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
        country = "CANADA"
    }

    ApiService.setHeader()
    ApiService.post(`shop/cart/removePromoYardMeterManually?protectModel=${getModelRoute(user).model}&customerId=${customer?._id}&collection=${cartItems[0]?.collectionName.split("\n")[0]}&country=${country}&promoCode=${cartItems[0]?.appliedPromo}`, cartItems).then(response => {
      if (response?.data.isSuccess) {
        setTimeout(() => {
          getCartDetail()
        }, 2000);
      }
    }).catch(error => {
      console.log(error.response.data)
      // showMessage(error.response.data.message, "error")
      getCartDetail()
    })
  }

  const removeDiscountOrQtyFromAllItemsAddedBySpecialButton = async (cartItems, buttonType, prodPromo, cartItem) => {

    let country = null
    if (customers !== null) {
      if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
        country = "UNITED STATES"
      } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
        country = "CANADA"
    }

    ApiService.setHeader()
    ApiService.post(`shop/cart/removeDiscount?protectModel=${getModelRoute(user).model}&buttonType=${buttonType}&customerId=${customer?._id}&collection=${cartItems[0]?.collectionName.split("\n")[0]}&country=${country}&productPromo=${prodPromo}`, { cartItems: cartItems, "deletedItem": cartItem }).then(response => {
      if (response?.data.isSuccess) {
        ApiService.post(`shop/cart/autoApplyPromo?protectModel=${getModelRoute(user).model}&buttonType=${buttonType}&customerId=${customer?._id}&collection=${cartItems[0]?.collectionName.split("\n")[0]}&country=${country}`, cartItems).then(response => {
          if (response?.data.isSuccess) {
            setTimeout(() => {
              getCartDetail()
            }, 2000);
          }
        }).catch(error => {
          setTimeout(() => {
            getCartDetail()
          }, 2000);
        })
      }
    }).catch(error => {
      setTimeout(() => {
        getCartDetail()
      }, 2000);
    })
  }

  const deleteCartItem = (cartId, cartItem, cartItems, prodPromo) => {
    setDeleteIndividualLoading(true)
    setIsLoading(true)
    cartItems?.length && cartItems?.map(ele => {
      ele['productQuantity'] = [{ id: parseInt(ele?.productQuantity), name: ele.productQuantity }]
    })

    ApiService.setHeader()
    ApiService.delete(`shop/cart/${cartId}?protectModel=${getModelRoute(user).model}`).then(response => {
      if (response.status == 204) {
        // console.log(carts);
        let totalItemQuantity = carts.length > 0
          ? carts.map(data => data[1].reduce((accumulator, item) => accumulator + parseFloat(item.subTotal || 0), 0))
          : 0;
        setCartItemQunatity(totalItemQuantity)
        if (cartItem.isAddedBySelectFullCollection) {
          // Remove all discount from items added by "Select Full Collection" button
          console.log("1");
          removeDiscountOrQtyFromAllItemsAddedBySpecialButton(cartItems.filter(ele => ele?.isAddedBySelectFullCollection), 'FullCollectionButton', prodPromo, cartItem)

        } else if (cartItem.isAddedBySelectFullCollectionFreeFreight) {
          console.log("2");
          removeDiscountOrQtyFromAllItemsAddedBySpecialButton(cartItems?.filter(ele => ele?.isAddedBySelectFullCollectionFreeFreight), 'FullCollectionFreeFreightButton', prodPromo)

        } else if (cartItem.isAddedBySelectFullCollectionHalfPiece) {
          console.log("3");
          removeDiscountOrQtyFromAllItemsAddedBySpecialButton(cartItems.filter(ele => ele?.isAddedBySelectFullCollectionHalfPiece), 'FullCollectionHalfPieceButton', prodPromo)
        } else if (cartItem.isAddedByAddThisColorwayOnly) {
          // Remove all half piece to full piece from items added by "Add This Colorway Only" button
          console.log("4");
          removeDiscountOrQtyFromAllItemsAddedBySpecialButton(cartItems.filter(ele => ele?.isAddedByAddThisColorwayOnly), 'addThisColorwayOnly', prodPromo)
        } else if (cartItem.isAddedByAddThisColorwayHalfPieces) {
          // Remove all half piece to full piece from items added by "Add This Colorway Only - Half Piece" button
          console.log("5");
          removeDiscountOrQtyFromAllItemsAddedBySpecialButton(cartItems.filter(ele => ele?.isAddedByAddThisColorwayHalfPieces), 'addThisColorwayHalfPieces', prodPromo)
        } else if (cartItem.isAddedForPieceDiscount) {
          // Remove promo from items for piece restriction
          console.log("6");
          removePromoFromItemsForPieceRestriction(cartItems.filter(ele => ele?.isAddedForPieceDiscount), cartItem)
        } else if (cartItem.isAddedForYarsMeterDiscount) {
          // Remove promo from items for piece restriction
          console.log("7");
          removePromoFromItemsForYardMeterRestriction(cartItems.filter(ele => ele?.isAddedForYarsMeterDiscount), cartItem)
        } else if (cartItem.isAddedForYardsMeterDiscountManually) {
          // Remove promo from items for piece restriction
          console.log("8");
          removePromoFromItemsForYardMeterRestrictionManually(cartItems.filter(ele => ele?.isAddedForYardsMeterDiscountManually))
        } else if (cartItem.isAddedForPieceDiscountManually) {
          // Remove promo from items for piece restriction
          console.log("9");
          removePromoFromItemsForPieceRestrictionManually(cartItems.filter(ele => ele?.isAddedForPieceDiscountManually))
        } else {
          console.log("10");
          getCartDetail()
        }

      }
    }).catch(error => {
      setDeleteIndividualLoading(false)
    })


  }

  const deleteAllSelectedCartItem = (cartIds) => {
    setDeleteIndividualLoading(true)
    ApiService.setHeader()
    cartIds?.length && cartIds?.map(cartId => {
      ApiService.delete(`shop/cart/${cartId}?protectModel=${getModelRoute(user).model}`).then(response => {
        if (response.status == 204) {
          setTimeout(() => {
            getCartDetail();
          }, 2000);
        }
      }).catch(error => {
        console.log(error.response)
      })
    })
    getCartDetail()
  }

  const updatePriceInCartItems = (updatedCartItems) => {
    // Not completed
    if (updatedCartItems?.length) {
      ApiService.setHeader()
      ApiService.patch(`shop/cart/bulkUpdatePrice?protectModel=${getModelRoute(user).model}`, updatedCartItems).then(response => {
        if (response?.data.isSuccess) {
          getCartDetail()
        }
      }).catch(error => {
        console.log(error.response?.data)
      })
    } else {
      getCartDetail()
    }
  }

  const toggleEdit = () => {
    setIsEditCart(!isEditCart)
  }

  const findProductMultiplierAndFormatArray = (productId) => {
    let country = null;
    ApiService.setHeader()
    ApiService.get(`shop/product/${productId}?protectModel=${getModelRoute(user).model}`).then(response => {
      if (response?.data.isSuccess) {
        if (customers !== null) {
          if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
            country = "UNITED STATES"
          } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
            country = "CANADA"
        }


        if (country == "CANADA") {
          formatArray(response.data.document.cadMultiplier)
        } else {
          formatArray(response.data.document.usdMultiplier)
        }
      }
    }).catch(error => {
      showMessage(error.response.data.message, "error")
    })
  }

  const formatArray = (multiplier) => {
    let array = []
    for (let i = 1; i <= 10; i++) {
      array.push((i * parseFloat(multiplier)).toFixed(2))
    }
    setCADMultiplier(array)
    return array
  }

  const getMultipliers = () => {
    let country = null;

    ApiService.setHeader()
    ApiService.get(`shop/multiplier?protectModel=${getModelRoute(user).model}`).then(response => {
      if (response?.data.isSuccess) {
        if (customers !== null) {
          if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
            country = "UNITED STATES"
          } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
            country = "CANADA"
        }

        if (country == "CANADA") {
          setCADMultiplier(response?.data.document?.cad)
        } else {
          setCADMultiplier(response?.data.document?.usd)
        }
      }
    }).catch(error => {
      showMessage(error.response.data.message, "error")
    })
  }

  const emptyEntireCart = () => {
    setDeleteIndividualLoading(true)
    ApiService.setHeader()
    ApiService.delete(`shop/cart/deleteAllItems/${customers[customers?.length - 1]?._id}?protectModel=${getModelRoute(user).model}`).then(response => {
      if (response?.status == 204) {
        getCartDetail()
      }
    }).catch(error => {
      console.log(error.response)
    })
  }

  const calculateSubTotal = function () {
    const sum = state.reduce(function getSum(total, cart) {
      return parseFloat(total) + (parseFloat(cart?.productQuantity[0]?.id || 0) * parseFloat(cart?.price || 0));
    }, 0);
    return isNaN(sum) ? "..." : sum.toFixed(2);
  }

  useEffect(() => {
    if (customers?.length) {
      ApiService.setHeader()
      ApiService.get(`shop/pricingMatrix?protectModel=${getModelRoute(user).model}`).then(response => {
        if (response?.data.isSuccess) {
          getCartDetail()
        }
      }).catch(error => {
        console.log(error.response)
      })

    }

  }, []);


  // mobile dropdown table open
  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };
  // mobile dropdown table close
  const handleDropUp = () => {
    setIsOpen(false);
  };

  const applyUniversalPromo = () => {
    setDeleteIndividualLoading(true)
    let country
    carts?.length > 0 && carts?.map((cart) => {
      const collection = cart[0]
      const promocode = getValues("globalPromoCode")
      if (customers !== null) {
        if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("USA")) {
          country = "UNITED STATES"
        } else if (customers[customers?.length - 1]?.subsidiary?.text?.toUpperCase()?.includes("CANADA"))
          country = "CANADA"
      }

      if (!promocode) {
        alert.error("Please enter promocode!")
        setDeleteIndividualLoading(false)
        setValue("globalPromoCode", "")
        return showMessage("Please enter promocode!", 'info')
      }

      ApiService.setHeader()
      ApiService.patch(`shop/cart/globalApplyPromo/${promocode}?protectModel=${getModelRoute(user).model}&country=${country}&collection=${collection}&customerId=${customer?._id}`, cart[1]).then(response => {

        if (response?.data.isSuccess) {
          if (response?.data?.isPromoApplied && response?.data?.isSuccess) {
            alert.success(response?.data?.message)
          } else if ((!response?.data?.isPromoApplied && response?.data?.isSuccess) || !response?.data?.isSuccess) {
            alert.error(response?.data?.message)
          }
          getCartDetail()

          setValue("globalPromoCode", "")
          setDeleteIndividualLoading(false)
        }
      }).catch(error => {
        setDeleteIndividualLoading(false)
        alert.error("The promo code is invalid!")
        showMessage(error.response?.data?.message, "error")
        setValue("globalPromoCode", "")
      }
      )
    }
    )
  }

  return (
    <div className=" flex items-center justify-center">
      <div className=" flex flex-col rounded-lg overflow-hidden bg-white shadow relative">
        {/* loading starts here*/}
        <div className={` ${deleteIndividualLoading ? "absolute" : "hidden"} bg-white bg-opacity-90 z-10 h-full w-full flex items-start justify-center`}>
          <div className="h-screen flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2400 2400" width="24" height="24"><g strokeWidth="200" strokeLinecap="round" stroke="#000" fill="none"><path d="M1200 600V100" /><path opacity=".5" d="M1200 2300v-500" /><path opacity=".917" d="M900 680.4l-250-433" /><path opacity=".417" d="M1750 2152.6l-250-433" /><path opacity=".833" d="M680.4 900l-433-250" /><path opacity=".333" d="M2152.6 1750l-433-250" /><path opacity=".75" d="M600 1200H100" /><path opacity=".25" d="M2300 1200h-500" /><path opacity=".667" d="M680.4 1500l-433 250" /><path opacity=".167" d="M2152.6 650l-433 250" /><path opacity=".583" d="M900 1719.6l-250 433" /><path opacity=".083" d="M1750 247.4l-250 433" /><animateTransform attributeName="transform" attributeType="XML" type="rotate" keyTimes="0;0.08333;0.16667;0.25;0.33333;0.41667;0.5;0.58333;0.66667;0.75;0.83333;0.91667" values="0 1199 1199;30 1199 1199;60 1199 1199;90 1199 1199;120 1199 1199;150 1199 1199;180 1199 1199;210 1199 1199;240 1199 1199;270 1199 1199;300 1199 1199;330 1199 1199" dur="0.83333s" begin="0s" repeatCount="indefinite" calcMode="discrete" /></g></svg>
          </div>
        </div>
        {/* loading ends here*/}


        {/* content starts here*/}

        <div
          className="mb-20 max-w-[1320px] min-w-[320px] xl:w-[1160px] 2xl:w-[1320px] flex flex-col gap-10 xl:gap-0 xl:flex-col xl:mx-auto items-center justify-center"
          style={{ fontFamily: "Open Sans" }}
        >
          {/* shoppingcart heading desktop only*/}
          <div className="hidden w-[1024px] xl:flex xl:w-[1160px] 2xl:w-[1320px] xl:h-[44px] items-center justify-center xl:mt-[100px] xl:mb-[60px]">
            <p className="xl:text-[32px] font-bold mx-auto">Shopping Cart</p>
          </div>

          {/* orderdetail and summary for desktop */}
          <div className="flex flex-col xl:flex-row xl:gap-10">
            {/* orders details */}
            {<div aria-disabled={isLoading} className="mx-auto w-[320px] sm:w-[744px] xl:w-[780px] mt-20 xl:mt-0 sm:rounded-lg">
              {/* top section */}
              <div className="flex flex-col items-center justify-center">
                {/* shoppingcart heading mobile */}
                <div className="h-[38px] sm:h-[44px] flex xl:hidden flex-col items-center justify-center">
                  <p className="text-[28px] xl:text-[32px] font-bold mx-auto">
                    Shopping Cart
                  </p>
                </div>
                {/* top buttons */}
                <div className="mt-[60px] xl:mt-0 min-w-[280px] w-[280px] sm:w-[640px] xl:w-[780px]">
                  <div className="h-[78px] min-w-[280px] w-[280px] sm:w-[640px] sm:h-[34px] xl:w-[780px] flex flex-col sm:flex-row items-center justify-center sm:justify-between gap-[10px] sm:gap-0">
                    {/* button 1 */}
                    <Link to={"/"}>
                      <button
                        type="button"
                        className="w-[154px] h-[34px] text-white bg-[#E2A856] focus:ring-4 focus:ring-[#E2A856] font-medium rounded text-[12px] focus:outline-none hover:bg-white hover:text-[#E2A856] hover:border-2 hover:border-[#E2A856]"
                      >
                        CONTINUE SHOPPING
                      </button>
                    </Link>
                    {/* button 2 */}
                    <button
                      type="button"
                      className={`w-[154px] h-[34px] font-medium uppercase rounded text-[12px] focus:outline-none ${carts.length > 0 ? "hover:border-black hover:bg-black hover:text-white bg-white cursor-pointer text-[#E2A856] border-2 border-[#E2A856]" : "bg-gray-400 text-white cursor-not-allowed"}`}
                      onClick={emptyEntireCart}
                      disabled={carts.length > 0 ? false : true}
                    >
                      empty entire cart
                    </button>
                  </div>
                </div>
              </div>
              {/* table 1 */}
              {
                carts.length > 0
                  ?
                  carts?.map(cart => {
                    return <DisplayCartDetails subTotalLoading={subTotalLoading} setDeleteIndividualLoading={setDeleteIndividualLoading} deleteIndividualLoading={deleteIndividualLoading} key={cart?._id} isEditCart={isEditCart} cart={cart} updateCartData={updateCartData} updateCartItem={updateCartItem} total={total} deleteAllSelectedCartItem={deleteAllSelectedCartItem} getCartDetail={getCartDetail} calendar_month={calendar_month} deleteicon={DeleteIcon} cartProduct={CartProduct} isShipComplete={isShipComplete} isCombineShipment={isCombineShipment} deleteCartItem={deleteCartItem} cartItemCount={cartItemCount} cartItemQuantity={cartItemQuantity} />
                  })
                  : ''
              }
            </div>
            }

            {/* order summary */}
            <div
              className={`w-[320px] sm:w-[740px] xl:w-[340px] ${salesRep
                ? "h-[522px] sm:h-[504px] xl:h-[576px]"
                : "h-[380px] sm:h-[362px] xl:h-[434px]"
                } mt-10 xl:mt-0`}
            >
              {/* universal promo input */}
              <div className="w-[340px] h-[34px] sm:h-[34px] mb-[20px] flex justify-between">
                <input
                  type="text"
                  className="hidden w-[240px] h-[34px] sm:w-[240px] border-2 px-2 py-2 placeholder:text-[12px] text-[12px] text-black"
                  placeholder="Universal Promo Code"
                  id="globalPromoCode"
                  name="globalPromoCode"
                  {...register(`globalPromoCode`)}
                />
                <button
                  type="button"
                  className="hidden w-[80px] h-[34px] text-white bg-[#E2A856] font-medium rounded-[4px] text-[12px] uppercase hover:bg-black hover:text-white"
                  onClick={applyUniversalPromo}
                >
                  Submit
                </button>
              </div>
              <div
                className={`mx-auto w-[280px] sm:w-[500px] xl:w-[340px] ${salesRep
                  ? "h-[522px] sm:h-[504px] xl:h-[532px]"
                  : "h-[380px] sm:h-[362px] xl:h-[400px]"
                  } border-2 mb-20 flex flex-col`}
              >
                <div
                  className={`flex flex-col items-center justify-center gap-[40px] my-[20px] sm:my-0 xl:my-0 xl:w-[340px] ${salesRep ? "xl:h-[522px]" : "xl:h-[380px]"
                    } `}
                >
                  {/* row1 */}
                  <div className="sm:mt-5 w-[240px] h-[57px] sm:w-[460px] xl:w-[300px] xl:h-[57px] flex items-center justify-center sm:justify-start font-bold text-[18px] border-b">
                    Order Summary
                  </div>
                  {/* row2 */}
                  <div className="w-[240px] h-[154px] sm:w-[460px] sm:h-[136px] xl:w-[300px] xl:h-[154px]">
                    <div className="flex justify-between text-base">
                      <p className="">Subtotal:</p>
                      {!subTotalLoading ? <p>
                        $ {
                          Array.isArray(state) && state.length > 0 ?
                            calculateSubTotal() : "0.00"
                        }
                      </p> :
                        <p>
                          ...
                        </p>}
                    </div>
                    <div className="flex justify-between text-red-400">
                      <p className="">Discount:</p>
                      {totalDiscount ? <p>- $ {totalDiscount ? totalDiscount?.toFixed(2) : "..."}</p> :
                        <p>- $ 0.00</p>}
                    </div>
                    <div className="mt-1 font-bold flex justify-between">
                      <p className="">Total:</p>

                      {!subTotalLoading ? <p>
                        $ {
                          Array.isArray(state) && state.length > 0 ?
                            (function () {
                              const sum = state.reduce(function getSum(total, cart) {
                                return parseFloat(total) + (parseFloat(cart?.subTotal || 0))
                              }, 0);
                              return isNaN(sum) ? "..." : sum.toFixed(2);
                            })() : "0.00"

                        }
                      </p> :
                        <p>
                          ...
                        </p>}
                    </div>
                    <div className="w-[240px] sm:w-[460px] sm:h-[36px] xl:w-[300px] h-[54px] mt-5 text-gray-400 text-[12px]">
                      <ul className="ms-5">
                        <li className="list-disc">
                          Total does not include applicable taxes or delivery. All prices and product availability are subject to change.
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* checkbox and tooltips */}
                  {(salesRep && user && (user?.userTypes[0] == "ADMIN_USER" || user?.userTypes[0] == "POWER_USER" || user?.userTypes[0] == "SALES_REP"))
                    ?
                    <div className="w-[240px] sm:w-[460px] xl:w-[240px] h-[102px] flex flex-col justify-center gap-[20px]">
                      <div className="w-[230px] h-[24px] text-[16px] font-bold">
                        Optional Order Processing :
                      </div>
                      <div className="w-[213px] h-[58px] flex flex-col justify-center gap-[10px]">
                        {/* checkbox and tooltip 1*/}
                        <div className="w-[200px] h-[24px] flex flex-col justify-between">
                          <div className="flex items-center justify-self-center gap-2">
                            <input
                              id="default-checkbox"
                              type="checkbox"
                              {...register("combineShip")}
                              value=""
                              className="w-4 h-4 rounded-full text-black bg-gray-100 border-gray-300 border-r-8 focus:ring-black-500 focus:ring-2"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setShippingType('orderComplete')
                                  setIsCombineShipment(true)

                                  // Popup when combined shipmet checkbox is checked
                                  Swal.fire({
                                    html: `
                                          <div className="z-1 opacity-100 right-0 sm:left-0 sm:bottom-6 bottom-5 absolute mt-5 w-[171px] h-[140px] flex items-center justify-center text-black bg-white shadow-xl border " style={{fontFamily:"Open Sans"}}>
                                            <p className="w-[155px] h-[120px] text-[10px] text-black">
                                              By selecting “Combine Shipping”, all the items
                                              in the cart will ship together on one sales
                                              order. The shipping date will be set to the
                                              earliest availability date based on all the
                                              items ordered. You can edit the date in the cart
                                              after confirming the changes
                                            </p>
                                          </div>
                                `,
                                    showDenyButton: true,
                                    // showCancelButton: true,
                                    confirmButtonText: `OK`,
                                    denyButtonText: `CANCEL`,
                                    confirmButtonColor: "#E2A856",
                                    denyButtonColor: "#E2A856",
                                    customClass: {
                                      confirmButton: 'swal2-confirm',
                                      cancelButton: 'swal2-deny',
                                      popup: 'custom-swal-font'
                                    }
                                  }).then((result) => {

                                    if (result.isConfirmed) {
                                      // update all cart items with max date
                                      ApiService.setHeader()
                                      ApiService.patch(`shop/cart/bulkUpdate?protectModel=${getModelRoute(user).model}`, { cartItems: state, newShipDate: moment.max(shipDates)._i, orderType: "orderComplete", isCombineShip: true }).then(response => {
                                        if (response?.data.isSuccess) {
                                          getCartDetail('orderComplete')

                                          setValue("combineShip", true)

                                        }
                                      }).catch(error => {

                                        getCartDetail('orderComplete')
                                      })
                                      // }

                                    } else if (result.isDenied) {
                                      setValue("combineShip", false)
                                      setIsCombineShipment(false)
                                      getCartDetail()
                                    }
                                  })

                                } else if (!e.target.checked) {
                                  setIsCombineShipment(false)

                                  Swal.fire({
                                    title:
                                      `
                                  <Container className="p-0" style="font-size: 27px">
                                      THE CURRENT CHANGES WILL BE REVERSED.
                                  </Container>
                                  `
                                    ,
                                    showDenyButton: true,
                                    // showCancelButton: true,
                                    confirmButtonText: `OK`,
                                    denyButtonText: `CANCEL`,
                                    confirmButtonColor: "#E2A856",
                                    denyButtonColor: "#E2A856",
                                    customClass: {
                                      confirmButton: 'swal2-confirm',
                                      cancelButton: 'swal2-deny',
                                      popup: 'custom-swal-font'
                                    }
                                  }).then((result) => {
                                    /* Read more about isConfirmed, isDenied below */

                                    if (result.isConfirmed) {

                                      // update all cart items with max date
                                      ApiService.setHeader()
                                      ApiService.patch(`shop/cart/bulkUpdate?protectModel=${getModelRoute(user).model}`, { cartItems: state, newShipDate: '', orderType: "", isCombineShip: false }).then(response => {
                                        if (response?.data.isSuccess) {
                                          getCartDetail()
                                        }
                                      }).catch(error => {

                                        getCartDetail()
                                      })
                                      // }

                                    } else if (result.isDenied) {
                                      setValue("combineShip", true)
                                      setIsCombineShipment(true)
                                      getCartDetail()
                                    }
                                  })
                                }
                              }}
                            />
                            <label
                              htmlFor="default-checkbox"
                              className="text-sm font-medium text-gray-900 -ms-1 text-nowrap"
                            >
                              Combine shipment
                            </label>
                            {/* tooltip1 */}
                            <div
                              className="cursor-pointer flex flex-col relative"
                              onMouseEnter={() => setToolTip(true)}
                              onMouseLeave={() => setToolTip(false)}
                            >
                              <img
                                src={help}
                                alt="Help"
                                className="w-[20px] h-[20px]"
                              />
                              {tooltip && (
                                <div className="z-1 opacity-100 right-0 sm:left-0 sm:bottom-6 bottom-5 absolute mt-5 w-[171px] h-[140px] flex items-center justify-center text-black bg-white shadow-xl border ">
                                  <p className="w-[155px] h-[120px] text-[10px]">
                                    By selecting “Combine Shipping”, all the items
                                    in the cart will ship together on one sales
                                    order. The shipping date will be set to the
                                    earliest availability date based on all the
                                    items ordered. You can edit the date in the cart
                                    after confirming the changes
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* checkbox and tooltip 2*/}
                        <div className="w-[210px] h-[24px] flex flex-col justify-between">
                          <div className="flex items-center justify-self-center gap-2">
                            <input
                              id="default-checkbox"
                              type="checkbox"
                              {...register("orderByCollection")}
                              value=""
                              className="w-4 h-4 rounded-full text-black bg-gray-100 border-gray-300 border-r-8 focus:ring-black-500 focus:ring-2"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setShippingType('orderByCollection')
                                  setIsShipComplete(true)

                                  Swal.fire({
                                    html: `
                                <div className="z-1 opacity-100 right-0 sm:left-0 sm:bottom-6 bottom-5 absolute mt-5 w-[171px] h-[140px] flex items-center justify-center text-black bg-white shadow-xl border" style={{fontFamily:"Open Sans"}}>
                                <p className="w-[155px] h-[105px] text-[6px] text-black">
                                  By selecting “Ship Complete”, the order will
                                  ship once all the items on the sales order are
                                  available. There will be no back orders on the
                                  order. If an item is unavailable, the customer
                                  will be notified.
                                </p>
                              </div>
                        `,
                                    showDenyButton: true,
                                    // showCancelButton: true,
                                    confirmButtonText: `OK`,
                                    denyButtonText: `CANCEL`,
                                    confirmButtonColor: "#E2A856",
                                    denyButtonColor: "#E2A856",
                                    customClass: {
                                      confirmButton: 'swal2-confirm',
                                      cancelButton: 'swal2-deny',
                                      popup: 'custom-swal-font'
                                    }
                                    // customClass: {
                                    //     confirmButton: 'confirm-button-class',
                                    // },
                                  }).then((result) => {
                                    /* Read more about isConfirmed, isDenied below */

                                    if (result.isConfirmed) {
                                      // update all cart items with max date
                                      ApiService.setHeader()
                                      ApiService.patch(`shop/cart/bulkUpdate?protectModel=${getModelRoute(user).model}`, { cartItems: state, newShipDate: null, orderType: "orderByCollection", isShipOrderComplete: true }).then(response => {
                                        if (response?.data.isSuccess) {
                                          getCartDetail()
                                        }
                                      }).catch(error => {
                                        getCartDetail()
                                      })
                                    } else if (result.isDenied) {
                                      setValue("orderByCollection", false)
                                      getCartDetail()
                                    }
                                  })



                                } else if (!e.target.checked) {
                                  setIsShipComplete(false)
                                  ApiService.setHeader()
                                  ApiService.patch(`shop/cart/bulkUpdate?protectModel=${getModelRoute(user).model}`, { cartItems: state, isShipOrderComplete: false }).then(response => {
                                    getCartDetail()
                                    if (response?.data.isSuccess) {
                                    }
                                  }).catch(error => {
                                    getCartDetail()
                                  })
                                }

                              }}
                            />
                            <label
                              htmlFor="default-checkbox"
                              className="w-[155px] text-sm font-medium text-gray-900 -ms-1 text-nowrap"
                            >
                              Ship order complete
                            </label>
                            {/* tooltip1 */}
                            <div
                              className="cursor-pointer flex flex-col relative"
                              onMouseEnter={() => setToolTip2(true)}
                              onMouseLeave={() => setToolTip2(false)}
                            >
                              <img
                                src={help}
                                alt="Help"
                                className="w-[20px] h-[20px]"
                              />
                              {tooltip2 && (
                                <div className="z-1 opacity-100 right-0 sm:left-0 sm:bottom-6 bottom-5 absolute mt-5 w-[171px] h-[140px] flex items-center justify-center text-black bg-white shadow-xl border">
                                  <p className="w-[155px] h-[105px] text-[10px]">
                                    By selecting “Ship Complete”, the order will
                                    ship once all the items on the sales order are
                                    available. There will be no back orders on the
                                    order. If an item is unavailable, the customer
                                    will be notified.
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    ""}
                  {/* row3 */}
                  <div className="w-[240px] h-[49px] sm:w-[300px]">
                    <button
                      type="button"
                      disabled={carts.length > 0 ? false : true}
                      className={`w-[240px] sm:w-[300px] h-[49px] bg-[#E2A856] font-medium rounded text-base text-[#E2A856] ${carts.length > 0 ? "hover:border-black hover:bg-black hover:text-white bg-white cursor-pointer  border-2 border-[#E2A856] " : "bg-gray-400 text-white cursor-not-allowed"}`}
                      onClick={() => {
                        if (getValues('orderByCollection') && !getValues('combineShip')) {
                          navigate(`/order/create/${shippingType ? shippingType : "orderByCollection"}?orderComplete=${getValues('combineShip')}&orderByCollection=${getValues('orderByCollection')}`)
                        } else if (getValues('combineShip') && !getValues('orderByCollection')) {
                          navigate(`/order/create/${shippingType ? shippingType : "orderComplete"}?shippingDate=${shippingDate}&shippingInstruction=${shippingInstruction}&orderComplete=${getValues('combineShip')}&orderByCollection=${getValues('orderByCollection')}`)
                        } else if (user?.userTypes[0] == "CUSTOMER") {
                          navigate(`/order/create/${'orderByCollection'}?orderComplete=${getValues('combineShip')}&orderByCollection=${getValues('orderByCollection')}`)
                        } else if (getValues('combineShip') && getValues('orderByCollection')) {
                          navigate(`/order/create/${'orderComplete'}?shippingDate=${shippingDate}&shippingInstruction=${shippingInstruction}&orderComplete=${getValues('combineShip')}&orderByCollection=${getValues('orderByCollection')}`)
                        } else {
                          navigate(`/order/create/${'orderByCollection'}?orderComplete=${getValues('combineShip')}&orderByCollection=${getValues('orderByCollection')}`)
                        }

                      }}
                    >
                      Checkout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
        {/* content ends here*/}

      </div>
    </div>
  );
};

export default CartDetail;